import { colors } from "../../../config/style";
import WeightIcon from "../../../icons/WeightIcon";
import Papa from "papaparse";
import csvFile from "./niosh_lookup.csv";
import { niosh_lookup } from "./niosh_lookup";

export const defaultVli = {
  n_lavoratori: -1,
  cronogramma: {
    ora_inizio_turno: "09:00",
    durata_totale_turno: -1,
    durata_netta_sollevamento: -1,
    tipo_durata: 0,
    fasi: [],
  },
  elenco_pesi: [{ peso: -1, n_oggetti: -1, n_sollevamenti: -1 }],
  elenco_pesi_old: [],
  categorie_peso: [],
  locked: false,
  distanze: [
    [
      [false, false, false, false],
      [false, false, false, false],
      [false, false, false, false],
    ],
    [
      [false, false, false, false],
      [false, false, false, false],
      [false, false, false, false],
    ],
  ],
};

export function vliGlobal(vliMemory) {
  let elenco_pesi = [];
  let tot_oggetti_sollevati = 0;
  let totale_reale_oggetti = 0;
  let totale_massa_cum = 0;
  let totale_massa_op = 0;
  let n_oggetti_sollevati_operatore = -1;
  let VLI_M_young = -1;
  let VLI_F_young = -1;
  let VLI_M_old = -1;
  let VLI_F_old = -1;
  //console.log("vliMemory", vliMemory);
  if (vliMemory) {
    elenco_pesi = vliMemory.elenco_pesi.map((peso, id) => {
      let reale_numero_oggetti = -1;
      let massa_cumulata = -1;
      if (peso.n_oggetti >= 0 && peso.n_sollevamenti >= 0) {
        reale_numero_oggetti = peso.n_oggetti * peso.n_sollevamenti;
        totale_reale_oggetti = totale_reale_oggetti + reale_numero_oggetti;
      }
      if (reale_numero_oggetti >= 0 && peso.peso >= 0) {
        massa_cumulata = reale_numero_oggetti * peso.peso;
        totale_massa_cum = totale_massa_cum + massa_cumulata;
      }
      if (peso.n_oggetti >= 0)
        tot_oggetti_sollevati = tot_oggetti_sollevati + peso.n_oggetti;

      return {
        reale_numero_oggetti: reale_numero_oggetti,
        massa_cumulata: massa_cumulata,
      };
    });
    totale_massa_op =
      Math.round((10 * totale_massa_cum) / vliMemory.n_lavoratori) / 10;

    if (totale_reale_oggetti > 0 && vliMemory.n_lavoratori > 0)
      n_oggetti_sollevati_operatore =
        Math.round((totale_reale_oggetti * 10) / vliMemory.n_lavoratori) / 10;

    // CONVERSIONI A VARIABILI PYTHON
    let [
      distanze_origine_x_altezza,
      distanze_destinazione_x_altezza,
      altezze_origine_x_categoria,
      altezze_destinazione_x_categoria,
      asimmetrie_x_categoria,
      solo_un_arto,
      numero_operatori,
    ] = converti_matrici(vliMemory);

    let dati_produttivi = updateWeightCatVli(vliMemory.elenco_pesi);
    let durata_sollevamento = vliMemory.cronogramma.durata_netta_sollevamento;
    let reale_numero_sollevati = elenco_pesi.map(
      (peso, id) => peso.reale_numero_oggetti
    );
    let massa_cumulata = elenco_pesi.map((peso, id) => peso.massa_cumulata);
    let n_lavoratori_coinvolti = vliMemory.n_lavoratori;

    let scenario_di_durata_param = 60;
    if (vliMemory.cronogramma.tipo_durata == 1) scenario_di_durata_param = 60;
    if (vliMemory.cronogramma.tipo_durata == 2) scenario_di_durata_param = 120;
    if (vliMemory.cronogramma.tipo_durata == 3) scenario_di_durata_param = 180;

    //console.log("scenario di durata param", scenario_di_durata_param);

    let grave = false;
    Object.keys(altezze_origine_x_categoria).forEach((key, id) => {
      if (
        altezze_origine_x_categoria[key].grave_inf > 0 ||
        altezze_origine_x_categoria[key].grave_sup > 0 ||
        altezze_destinazione_x_categoria[key].grave_inf > 0 ||
        altezze_destinazione_x_categoria[key].grave_sup > 0
      )
        grave = true;
    });
    Object.keys(distanze_origine_x_altezza).forEach((key, id) => {
      if (
        distanze_origine_x_altezza[key][">64"] > 0 ||
        distanze_destinazione_x_altezza[key][">64"] > 0
      )
        grave = true;
    });
    if (grave) {
      VLI_M_young = 999;
      VLI_F_young = 999;
      VLI_M_old = 999;
      VLI_F_old = 999;
    } else {
      //console.log(
      //   "distanze_origine_x_altezza----converted",
      //   distanze_origine_x_altezza
      // );
      // //console.log(
      //   "distanze_destinazione_x_altezza----converted",
      //   distanze_destinazione_x_altezza
      // );
      // //console.log(
      //   "altezze_origine_x_categoria----converted",
      //   altezze_origine_x_categoria
      // );
      // //console.log(
      //   "altezze_destinazione_x_categoria----converted",
      //   altezze_destinazione_x_categoria
      // );
      // //console.log(
      //   "asimmetrie_x_categoria----converted",
      //   asimmetrie_x_categoria
      // );
      //console.log("dati_produttivi----converted", dati_produttivi);
      //console.log("solo_un_arto----converted", solo_un_arto);
      //console.log("numero_operatori----converted", numero_operatori);
      if (vliMemory.locked) {
        try {
          let dati_LI = calcola_dati_LI(
            distanze_origine_x_altezza,
            distanze_destinazione_x_altezza,
            altezze_origine_x_categoria,
            altezze_destinazione_x_categoria,
            dati_produttivi,
            durata_sollevamento,
            reale_numero_sollevati,
            n_lavoratori_coinvolti
          );
          //console.log("dati_LI", dati_LI);

          let M11_M40 = [
            0.78, 0.78, 0.78, 1.0, 1.0, 1.0, 0.78, 0.78, 0.78, 1.0, 1.0, 1.0,
            0.78, 0.78, 0.78, 1.0, 1.0, 1.0, 0.78, 0.78, 0.78, 1.0, 1.0, 1.0,
            0.78, 0.78, 0.78, 1.0, 1.0, 1.0,
          ];
          let R11_R40 = [
            0.71, 0.56, 0.4, 0.71, 0.56, 0.4, 0.71, 0.56, 0.4, 0.71, 0.56, 0.4,
            0.71, 0.56, 0.4, 0.71, 0.56, 0.4, 0.71, 0.56, 0.4, 0.71, 0.56, 0.4,
            0.71, 0.56, 0.4, 0.71, 0.56, 0.4,
          ];

          let CP_men = 25;
          let CP_women = 20;

          // Calcola il primo valore di LI_list
          let [FILI_men, peso_racc_men] = calculate_FILI(
            dati_LI,
            dati_produttivi,
            M11_M40,
            R11_R40,
            asimmetrie_x_categoria,
            solo_un_arto,
            numero_operatori,
            CP_men
          );
          let [FILI_women, peso_racc_women] = calculate_FILI(
            dati_LI,
            dati_produttivi,
            M11_M40,
            R11_R40,
            asimmetrie_x_categoria,
            solo_un_arto,
            numero_operatori,
            CP_women
          );

          //console.log(FILI_men);
          //console.log(peso_racc_men);
          //console.log(FILI_women);
          //console.log(peso_racc_women);

          let duration_param = calcola_duration_param(
            dati_LI,
            scenario_di_durata_param
          );
          //console.log("duration_paramduration_param", duration_param);

          // Esempio di calcolo LI_men
          const LI_men = calcola_LI_men(FILI_men, duration_param);

          // Stampa il risultato
          //console.log(`FILI_men: ${FILI_men}`);
          //console.log(`duration_param: ${duration_param}`);
          //console.log(`LI_men: ${LI_men}`);

          const I60_I89 = Array.from(
            { length: LI_men.length },
            () => scenario_di_durata_param
          );

          //console.log("I60_I89:", I60_I89);

          // 1. Estrai tutte le frequenze dal dizionario dati_LI
          let frequenze = [];
          for (let chiave in dati_LI) {
            let valori = dati_LI[chiave];
            for (let categoria in valori) {
              let fasce = valori[categoria];
              for (let fascia_eta in fasce) {
                let dati = fasce[fascia_eta];
                frequenze.push(dati["frequenza"]);
              }
            }
          }

          //console.log("Frequenze estratte:", frequenze);

          // 2. Ordina LI_men in ordine decrescente e ottieni gli indici originali
          // Usare una mappa per gestire duplicati
          let valore_to_indices = {};
          LI_men.forEach((value, index) => {
            if (!valore_to_indices[value]) {
              valore_to_indices[value] = [];
            }
            valore_to_indices[value].push(index);
          });

          // Ordinare i valori e ottenere gli indici
          let LI_men_ordinato = Array.from(new Set(LI_men)).sort(
            (a, b) => b - a
          ); // Set per eliminare duplicati
          let indici_originali = [];
          LI_men_ordinato.forEach((value) => {
            indici_originali = indici_originali.concat(
              valore_to_indices[value]
            );
          });

          //console.log("LI_men ordinato:", LI_men_ordinato);
          //console.log("Indici originali:", indici_originali);

          // 3. BG11 corrisponde a questi indici, meno 1 (Gestire indici negativi)
          let BG11 = indici_originali.map((i) => i - 1).filter((i) => i >= 0);

          //console.log("BG11:", BG11);

          // 4. Crea una nuova lista di frequenze ordinate secondo l'ordine di BG11
          // Assicurarsi che BG11 non contenga indici fuori dal range
          let frequenze_ordinate = indici_originali
            .filter((i) => i < frequenze.length)
            .map((i) => frequenze[i]);

          //console.log(
          //   "Frequenze finali ordinate secondo BG11:",
          //   frequenze_ordinate
          // );

          let freq_acum = [];
          for (let i = 60; i < 90; i++) {
            let somma = frequenze_ordinate
              .slice(0, i - 59)
              .reduce((acc, curr) => acc + curr, 0); // Somma cumulativa fino all'indice i-59
            freq_acum.push(parseFloat(somma.toFixed(2))); // Arrotonda la somma a 2 cifre decimali
          }

          //console.log("freq_acum", freq_acum);

          const FILI_non_zero = FILI_men.filter((value) => value !== 0);

          //console.log("FILI_non_zero", FILI_non_zero);

          // Percentili da calcolare
          const percentili = [83, 67, 50, 33, 17, 0];

          // Calcola i percentili
          const FILI_categories = percentili.map((p) =>
            parseFloat(percentile(FILI_non_zero, p).toFixed(3))
          );

          // Stampa i risultati
          //console.log("FILI_categories:", FILI_categories);

          // Esempio di uso della funzione
          const percentili_labels = ["0", "17", "33", "50", "67", "83"]; // Etichette delle categorie

          // Distribuzione dei valori nelle categorie usando un oggetto
          const categorie_fili_men_dict = {};

          FILI_non_zero.forEach((val) => {
            const categoria = assegna_categoria(
              val,
              FILI_categories,
              percentili_labels
            );
            if (!categorie_fili_men_dict[categoria]) {
              categorie_fili_men_dict[categoria] = [];
            }
            categorie_fili_men_dict[categoria].push(val);
          });

          // Stampa del dizionario delle categorie
          //console.log("Categorie assegnate:", categorie_fili_men_dict);

          // Oggetto per salvare la somma delle frequenze e il massimo di FILI_men per ogni categoria
          let results = {
            Categoria: [],
            Massimo_FILIMen: [],
            Somma_Frequenze: [],
          };

          // Itera su ogni categoria
          for (let categoria in categorie_fili_men_dict) {
            let valori = categorie_fili_men_dict[categoria];

            // Trova gli indici di questi valori in FILI_men
            let indici = valori.map((val) => FILI_men.indexOf(val));

            // Somma le frequenze corrispondenti
            let somma_frequenze = indici.reduce(
              (acc, i) => acc + frequenze[i],
              0
            );

            // Trova il massimo valore di FILI_men per questa categoria
            let massimo_fili = Math.max(...valori);

            // Aggiungi i risultati all'oggetto
            results["Categoria"].push(categoria);
            results["Massimo_FILIMen"].push(massimo_fili);
            results["Somma_Frequenze"].push(somma_frequenze);
          }

          // Creare il DataFrame simulato come un array di oggetti
          let df = results["Categoria"].map((categoria, index) => ({
            Categoria: categoria,
            Massimo_FILIMen: results["Massimo_FILIMen"][index],
            Somma_Frequenze: results["Somma_Frequenze"][index],
          }));

          // Ordinare il DataFrame per 'Categoria' in ordine decrescente
          df.sort((a, b) => b.Categoria.localeCompare(a.Categoria));

          // Calcolare la somma cumulativa delle frequenze
          let cum_freq = 0;
          df.forEach((row) => {
            cum_freq += row.Somma_Frequenze;
            row.cum_freq = cum_freq;
          });

          // Visualizzare il "DataFrame"
          //console.log("df", df);

          // Calcolare la colonna FM
          df.forEach((row) => {
            row.FM = calcola_valore(
              row.cum_freq,
              scenario_di_durata_param,
              niosh_lookup
            );
          });

          // Visualizzare il risultato
          //console.log("df updated", df);

          df.forEach((row) => {
            row.sum = calcola_sum(row, df);
          });

          // Visualizzare il risultato
          //console.log("df updated calcola sum", df);

          // Esempio di utilizzo
          const moltiplicatore_VLI =
            calcola_mnoltiplicatore_VLI(durata_sollevamento); // EV18

          // Calcolare la somma di tutti gli elementi della colonna 'sum'
          const VLI_EV18 = df.reduce(
            (acc, row) => acc + Math.round(row.sum * 10000) / 10000,
            0
          ); // Somma tutti gli elementi della colonna 'sum'
          const VLI_EV18_con_moltplicatore = VLI_EV18 / moltiplicatore_VLI; // AN51

          //console.log("moltiplicatore_VLI", moltiplicatore_VLI);
          //console.log("VLI:", VLI_EV18);
          //console.log("VLI_con_moltplicatore:", VLI_EV18_con_moltplicatore);

          let indice_F = calcola_indice_F(freq_acum, scenario_di_durata_param);

          //console.log("indice_F", indice_F);

          let L60_L89 = calcola_L60_L89(indice_F);

          //console.log("Nuovo Vettore:", L60_L89);

          // Esegui le funzioni
          const indici = trova_indici_ordinati(LI_men);
          const FILI_ordinato = ordina_secondo_indici(FILI_men, indici);

          //console.log("FILI ordinato", FILI_ordinato);
          //console.log("LI_men", LI_men);
          //console.log("L60_L89", L60_L89);

          // Calcola VLI
          const VLI_AN51 = calcola_vli(L60_L89, FILI_ordinato, LI_men);
          //console.log("Valore VLI:", VLI_AN51);

          const VLI_AN51_con_moltiplicatore = VLI_AN51 / moltiplicatore_VLI;

          const n_task = FILI_men.reduce(
            (count, x) => count + (x > 0 ? 1 : 0),
            0
          );

          VLI_M_young = calcola_vli_finale(n_task, VLI_EV18, VLI_AN51);
          VLI_F_young = (VLI_M_young * 25) / 20;
          VLI_M_old = VLI_F_young;
          VLI_F_old = (VLI_M_young * 25) / 15;

          //console.log("VLI_M_young:", VLI_M_young);
          //console.log("VLI_F_young:", VLI_F_young);
          //console.log("VLI_M_old:", VLI_M_old);
          //console.log("VLI_F_old:", VLI_F_old);
        } catch (err) {
          //console.log("err", err);
        }
      }
    }
  }

  return {
    elenco_pesi: elenco_pesi,
    elenco_pesi_sum: {
      tot_oggetti_sollevati: tot_oggetti_sollevati,
      totale_reale_oggetti: totale_reale_oggetti,
      totale_massa_cum: totale_massa_cum,
      totale_massa_op: totale_massa_op,
    },
    n_oggetti_sollevati_operatore: n_oggetti_sollevati_operatore,
    frequenza: -1,
    m: {
      mid: Math.round(100 * VLI_M_young) / 100,
      ext: Math.round(100 * VLI_M_old) / 100,
    },
    f: {
      mid: Math.round(100 * VLI_F_young) / 100,
      ext: Math.round(100 * VLI_F_old) / 100,
    },
  };
}

export function updateWeightCatVli(elenco_pesi) {
  // ALGO PER CATEGORIES

  //console.log("elenco_pesielenco_pesielenco_pesi", elenco_pesi);

  // Esempi di input
  // let pesi = [3.5, 5.5, 7.5, 11.5, 14.5];
  // let n_oggetti_sollevati = [100, 5, 90, 100, 100];
  // let n_sollevamenti_per_oggetto = [2, 5, 1, 2, 3];

  let pesi = elenco_pesi.map((item) => item.peso);
  let n_oggetti_sollevati = elenco_pesi.map((item) => item.n_oggetti);
  let n_sollevamenti_per_oggetto = elenco_pesi.map(
    (item) => item.n_sollevamenti
  );

  // Assegna i pesi agli intervalli
  let categorie = assegna_pesi_a_intervalli(pesi);

  // Calcola le grandezze per ciascuna categoria
  let dati_produttivi = calcola_grandezze_categorie(
    pesi,
    n_oggetti_sollevati,
    n_sollevamenti_per_oggetto,
    categorie
  );

  dati_produttivi = dati_produttivi.map((dato, id) => {
    return {
      ...dato,
      perc_pesi_trasportati: -1,
      n_lavoratori: -1,
      un_arto: false,
      asimmetrie: [false, false],
      altezze: [
        [false, false, false, false, false],
        [false, false, false, false, false],
      ],
    };
  });

  return dati_produttivi;
}

export const durataOpt = [
  { label: "ND", id: 0 },
  { label: "Breve", id: 1 },
  { label: "Media", id: 2 },
  { label: "Lunga", id: 3 },
];

export function updateChronogram(phases) {
  let durata_totale_turno = 0;
  let durata_netta_sollevamento = 0;
  let tipo_durata = 0;
  //console.log("phases", phases);

  if (phases) {
    let phasesConverted = phases.map((phase, id) => {
      durata_totale_turno = durata_totale_turno + parseInt(phase.duration);
      if (phase.phase == 0)
        durata_netta_sollevamento =
          durata_netta_sollevamento + parseInt(phase.duration);
      return {
        etichetta: phasesVli[phase.phase].etichetta,
        durata: parseInt(phase.duration),
      };
    });

    const phasesConvertedObject = {};
    phasesConverted.forEach((item, id) => {
      phasesConvertedObject[id] = item;
    });

    // Calcolo della durata
    const scenario_di_durata = calcola_durata_sollevamenti(
      phasesConvertedObject
    );
    if (scenario_di_durata == "breve") tipo_durata = 1;
    else if (scenario_di_durata == "media") tipo_durata = 2;
    else if (scenario_di_durata == "lunga") tipo_durata = 3;

    return {
      durata_totale_turno: durata_totale_turno,
      durata_netta_sollevamento: durata_netta_sollevamento,
      tipo_durata: tipo_durata,
    };
  }
}

export const phasesVli = {
  0: {
    label: "Sollevamento manuale (incluso trasporto)",
    fillColor: colors.negativePale,
    borderColor: colors.negative,
    icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
    minDuration: 10,
    etichetta: "SOLLEVAMENTO MANUALE (incluso il trasporto di carichi)",
  },
  1: {
    label: "Pausa generica",
    fillColor: colors.positivePale,
    borderColor: colors.positive,
    icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
    minDuration: 10,
    etichetta: "compiti (senza sollevamenti) o pause",
  },
  2: {
    label: "Pausa mensa",
    fillColor: colors.positivePale,
    borderColor: colors.positive,
    icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
    minDuration: 30,
    etichetta: "compiti (senza sollevamenti) o pause",
  },
  3: {
    label: "Compiti con pesi <3kg e trasporti <1m",
    fillColor: colors.positive2Pale,
    borderColor: colors.positive2,
    icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
    minDuration: 10,
    etichetta: "compiti (senza sollevamenti) o pause",
  },
  4: {
    label: "Traino e spinta",
    fillColor: colors.riskUnsurePale,
    borderColor: colors.riskUnsure,
    icon: (fill, size) => <WeightIcon fill={fill} size={size} />,
    minDuration: 10,
    etichetta: "traino e spinta",
  },
};

// FUNZIONI

// 1.CALCOLO DATI PRODUTTIVI (PRIMO FOGLIO)

function trova_categoria(peso) {
  /**
   * Trova la categoria in cui rientra un dato peso secondo categorie predefinite.
   *
   * @param {number} peso - Il peso da classificare.
   * @returns {Array} - Un array che rappresenta la categoria [da, a].
   */
  if (peso >= 24.5) {
    return [24.5, 25.5];
  } else {
    let da = Math.floor(peso);
    let a = da + 1;
    return [da, a];
  }
}

function crea_categorie_personalizzate(pesi) {
  /**
   * Crea le categorie personalizzate se ci sono più di 5 pesi.
   *
   * @param {Array} pesi - Lista dei pesi da classificare.
   * @returns {Array} - Una lista di array con i valori "da" e "a".
   */
  let peso_min = Math.min(...pesi);
  let peso_max = Math.max(...pesi);
  let step = (peso_max - peso_min) / 5;

  let categorie = [];
  let current_min = peso_min;

  for (let i = 0; i < 5; i++) {
    let current_max = current_min + step;
    categorie.push([
      parseFloat(current_min.toFixed(1)),
      parseFloat(current_max.toFixed(1)),
    ]);
    current_min = current_max;
  }

  return categorie;
}

function assegna_pesi_a_intervalli(pesi) {
  /**
   * Assegna i pesi agli intervalli predefiniti o personalizzati.
   *
   * @param {Array} pesi - Lista dei pesi da classificare.
   * @returns {Array} - Una lista di array con i valori "da" e "a".
   */
  let categorie;
  if (pesi.length > 5) {
    categorie = crea_categorie_personalizzate(pesi);
  } else {
    categorie = pesi.map((peso) => trova_categoria(peso));
  }

  return categorie;
}

function calcola_peso_medio(massa_cumulata, reale_numero_sollevati) {
  /**
   * Calcola il peso medio per categoria arrotondato a una cifra decimale.
   *
   * @param {number} massa_cumulata - La massa cumulata degli oggetti sollevati.
   * @param {number} reale_numero_sollevati - Il numero di oggetti realmente sollevati.
   * @returns {number} - Il peso medio per categoria arrotondato a una cifra decimale.
   */
  if (reale_numero_sollevati > 0) {
    return parseFloat((massa_cumulata / reale_numero_sollevati).toFixed(1));
  } else {
    return 0;
  }
}

function calcola_grandezze_categorie(
  pesi,
  n_oggetti_sollevati,
  n_sollevamenti_per_oggetto,
  categorie
) {
  /**
   * Calcola il numero di oggetti realmente sollevati, la massa cumulata,
   * il peso medio per categoria e la massa cumulata trasportata nel turno.
   *
   * @param {Array} pesi - Lista dei pesi.
   * @param {Array} n_oggetti_sollevati - Numero di oggetti sollevati.
   * @param {Array} n_sollevamenti_per_oggetto - Numero di sollevamenti per ciascun oggetto.
   * @param {Array} categorie - Lista delle categorie assegnate ai pesi.
   * @returns {Array} - Una lista di oggetti con le grandezze calcolate per ciascuna categoria.
   */
  let dati_produttivi = [];

  for (let i = 0; i < categorie.length; i++) {
    let [da, a] = categorie[i];
    let reale_numero_sollevati =
      n_oggetti_sollevati[i] * n_sollevamenti_per_oggetto[i];
    let massa_cumulata = pesi[i] * reale_numero_sollevati;

    // Peso medio per categoria
    let peso_medio = calcola_peso_medio(massa_cumulata, reale_numero_sollevati);

    // Massa cumulata trasportata nel turno
    let massa_cumulata_turno = peso_medio * reale_numero_sollevati;

    dati_produttivi.push({
      categoria: `${da} - ${a}`,
      n_oggetti_realmente_sollevati: reale_numero_sollevati,
      massa_cumulata: massa_cumulata,
      peso_medio_per_categoria: peso_medio,
      massa_cumulata_turno: massa_cumulata_turno,
    });
  }

  return dati_produttivi;
}

// WORKPLACE DESCRIPTION INPUT DELLE GEOMETRIE

function converti_matrici(vliMemory) {
  // Chiavi degli oggetti per mantenere l'ordine

  let chiaviAltezzeXDistanze = ["alta", "media", "bassa"];
  let chiaviDistanze = ["25-40", "41-50", "51-63", ">64"];
  let distanze_origine_x_altezza = {
    alta: {
      "25-40": 0,
      "41-50": 0,
      "51-63": 0,
      ">64": 0,
    },
    media: {
      "25-40": 0,
      "41-50": 1,
      "51-63": 0,
      ">64": 0,
    },
    bassa: {
      "25-40": 0,
      "41-50": 0,
      "51-63": 0,
      ">64": 0,
    },
  };
  let distanze_destinazione_x_altezza = {
    alta: {
      "25-40": 0,
      "41-50": 0,
      "51-63": 0,
      ">64": 0,
    },
    media: {
      "25-40": 0,
      "41-50": 1,
      "51-63": 0,
      ">64": 0,
    },
    bassa: {
      "25-40": 0,
      "41-50": 0,
      "51-63": 0,
      ">64": 0,
    },
  };

  // Assegna i valori dall'array all'oggetto distanzeOrigine
  for (let i = 0; i < vliMemory.distanze[0].length; i++) {
    for (let j = 0; j < vliMemory.distanze[0][i].length; j++) {
      distanze_origine_x_altezza[chiaviAltezzeXDistanze[i]][chiaviDistanze[j]] =
        vliMemory.distanze[0][i][j] ? 1 : 0;
    }
  }

  for (let i = 0; i < vliMemory.distanze[1].length; i++) {
    for (let j = 0; j < vliMemory.distanze[1][i].length; j++) {
      distanze_destinazione_x_altezza[chiaviAltezzeXDistanze[i]][
        chiaviDistanze[j]
      ] = vliMemory.distanze[1][i][j] ? 1 : 0;
    }
  }

  let altezze_origine_x_categoria = {};
  let altezze_destinazione_x_categoria = {};
  let asimmetrie_x_categoria = {};
  let solo_un_arto = {};
  let numero_operatori = {};

  vliMemory.categorie_peso.forEach((cat, id) => {
    let altezze_origine = {
      grave_inf: cat.altezze[0][4] ? 1 : 0,
      bassa: cat.altezze[0][3] ? 1 : 0,
      media: cat.altezze[0][2] ? 1 : 0,
      alta: cat.altezze[0][1] ? 1 : 0,
      grave_sup: cat.altezze[0][0] ? 1 : 0,
    };
    altezze_origine_x_categoria[id + 1] = altezze_origine;
    let altezze_destinazione = {
      grave_inf: cat.altezze[1][4] ? 1 : 0,
      bassa: cat.altezze[1][3] ? 1 : 0,
      media: cat.altezze[1][2] ? 1 : 0,
      alta: cat.altezze[1][1] ? 1 : 0,
      grave_sup: cat.altezze[1][0] ? 1 : 0,
    };
    altezze_destinazione_x_categoria[id + 1] = altezze_destinazione;

    let asimmetria = {
      ">45, +50%": cat.asimmetrie[0],
      ">135": cat.asimmetrie[1],
    };
    asimmetrie_x_categoria[id + 1] = asimmetria;
    solo_un_arto[id + 1] = cat.un_arto ? 1 : 0;
    numero_operatori[id + 1] = cat.n_lavoratori >= 0 ? cat.n_lavoratori : 0;
  });

  return [
    distanze_origine_x_altezza,
    distanze_destinazione_x_altezza,
    altezze_origine_x_categoria,
    altezze_destinazione_x_categoria,
    asimmetrie_x_categoria,
    solo_un_arto,
    numero_operatori,
  ];
}

// CALCOLO 5 MACRO TABELLE DEL FOGLIO 5.LI

function calcola_dati_LI(
  distanze_origine_x_altezza,
  distanze_destinazione_x_altezza,
  altezze_origine_x_categoria,
  altezze_destinazione_x_categoria,
  dati_produttivi,
  durata_sollevamento,
  reale_numero_sollevati,
  n_lavoratori_coinvolti
) {
  /**
   * La funzione calcola le percentuali degli oggetti sollevati per ciascuna categoria di peso per ciascuna combinazione di altezza e distanza,
   * raggruppando le altezze "alta" e "bassa" in una singola categoria "alta_bassa" e mantenendo "media" come categoria separata.
   * Queste percentuali e le frequenze vengono memorizzate in un oggetto chiamato dati_LI.
   */

  // Numero di categorie di peso
  const numero_categorie = dati_produttivi.length;

  // Inizializza l'oggetto dati_LI
  let dati_LI = {};

  for (let i = 1; i <= numero_categorie; i++) {
    dati_LI[i] = {
      alta_bassa: {
        "25-40": { percentuale: 0, frequenza: 0 },
        "41-50": { percentuale: 0, frequenza: 0 },
        "51-63": { percentuale: 0, frequenza: 0 },
      },
      media: {
        "25-40": { percentuale: 0, frequenza: 0 },
        "41-50": { percentuale: 0, frequenza: 0 },
        "51-63": { percentuale: 0, frequenza: 0 },
      },
    };

    // Calcola la frequenza per categoria

    let frequenza_per_categoria =
      reale_numero_sollevati[i - 1] /
      n_lavoratori_coinvolti /
      durata_sollevamento;

    // Somma dei valori 'bassa', 'media', e 'alta' per origine e destinazione
    let somma_origine =
      altezze_origine_x_categoria[i]["bassa"] +
      altezze_origine_x_categoria[i]["media"] +
      altezze_origine_x_categoria[i]["alta"];
    let somma_destinazione =
      altezze_destinazione_x_categoria[i]["bassa"] +
      altezze_destinazione_x_categoria[i]["media"] +
      altezze_destinazione_x_categoria[i]["alta"];

    // Totale di oggetti considerati (origine e destinazione)
    let totale = somma_origine + somma_destinazione;

    if (totale === 0) {
      continue; // Evita la divisione per zero se non ci sono valori validi
    }

    // Calcola la percentuale per 'alta_bassa'
    let alta_bassa_origine =
      altezze_origine_x_categoria[i]["bassa"] +
      altezze_origine_x_categoria[i]["alta"];
    let alta_bassa_destinazione =
      altezze_destinazione_x_categoria[i]["bassa"] +
      altezze_destinazione_x_categoria[i]["alta"];

    for (let distanza of ["25-40", "41-50", "51-63"]) {
      if (
        distanze_origine_x_altezza["alta"][distanza] ||
        distanze_origine_x_altezza["bassa"][distanza] ||
        distanze_destinazione_x_altezza["alta"][distanza] ||
        distanze_destinazione_x_altezza["bassa"][distanza]
      ) {
        let percentuale_alta_bassa =
          (alta_bassa_origine + alta_bassa_destinazione) / totale;
        dati_LI[i]["alta_bassa"][distanza]["percentuale"] =
          percentuale_alta_bassa;
        dati_LI[i]["alta_bassa"][distanza]["frequenza"] = parseFloat(
          (frequenza_per_categoria * percentuale_alta_bassa).toFixed(2)
        );
      }

      if (
        distanze_origine_x_altezza["media"][distanza] ||
        distanze_destinazione_x_altezza["media"][distanza]
      ) {
        let percentuale_media =
          (altezze_origine_x_categoria[i]["media"] +
            altezze_destinazione_x_categoria[i]["media"]) /
          totale;
        dati_LI[i]["media"][distanza]["percentuale"] = percentuale_media;
        dati_LI[i]["media"][distanza]["frequenza"] = parseFloat(
          (frequenza_per_categoria * percentuale_media).toFixed(2)
        );
      }
    }
  }

  return dati_LI;
}

function calculate_FILI(
  dati_LI,
  dati_produttivi,
  M11_M40,
  R11_R40,
  asimmetrie_x_categoria,
  solo_un_arto,
  numero_operatori,
  CP
) {
  //console.log("dati_LI", dati_LI);
  // Inizializza la lista LI_list
  let LI_list = [];
  let Wi_list = [];

  // Ciclare su ciascun elemento di dati_LI per calcolare i valori di LI_list
  let indice = 0;
  const keys = Object.keys(dati_LI);
  const lastKey = keys[keys.length - 1];
  for (let categoria = 1; categoria <= lastKey; categoria++) {
    // Itera sulle categorie di peso
    for (let sezione of ["alta_bassa", "media"]) {
      // Itera sulle sezioni
      for (let distanza of ["25-40", "41-50", "51-63"]) {
        // Itera sulle distanze
        let valore_percentuale =
          dati_LI[categoria][sezione][distanza]["percentuale"];

        let LI_value;
        let Wi;

        if (valore_percentuale > 0) {
          // Se la percentuale è maggiore di 0, calcola LI_list
          let peso_medio_per_categoria =
            dati_produttivi[categoria - 1]["peso_medio_per_categoria"] /
            numero_operatori[categoria];
          let M_value = M11_M40[indice];
          let R_value = R11_R40[indice];
          let asimmetria = asimmetrie_x_categoria[categoria][">45, +50%"];
          let solo_un_arto_val = solo_un_arto[categoria];
          let numero_operatori_val = numero_operatori[categoria];

          //console.log(
          //   peso_medio_per_categoria,
          //   M_value,
          //   R_value,
          //   asimmetria,
          //   solo_un_arto_val,
          //   numero_operatori_val
          // );

          // Calcola i fattori per il calcolo di LI_list
          let Vi = 0.9; // Fattore fisso
          let AMi = 1; // Fattore fisso

          // Calcola il fattore Ti basato sull'asimmetria
          let Ti = asimmetria === false ? 1 : 0.81;

          // Calcola il fattore AEi basato su solo_un_arto
          let AEi = solo_un_arto_val === 0 ? 1 : 0.6;

          // Calcola il fattore AIi basato su numero_operatori
          let AIi = numero_operatori_val === 1 ? 1 : 0.85;

          // Fattore Pi fisso (sempre 1)
          let Pi = 1;

          // Calcola Wi
          Wi = CP * M_value * Pi * R_value * Ti * Vi * AEi * AIi * AMi;

          // Calcola il valore LI_list
          LI_value = Wi !== 0 ? peso_medio_per_categoria / Wi : 0;
        } else {
          LI_value = 0;
          Wi = 0;
        }

        // Aggiungi il valore calcolato a LI_list
        LI_list.push(parseFloat(LI_value.toFixed(3)));
        Wi_list.push(Wi);

        // Incrementa l'indice per M11_M40 e R11_R40
        indice++;
      }
    }
  }

  // Restituisce la lista completa di LI_list
  return [LI_list, Wi_list];
}

function calcola_duration_param(dati_LI, scenario_di_durata_param) {
  // Colonna AZ che va da 0 a 16 con step di 0.01
  const AZ = Array.from({ length: Math.ceil(16.01 / 0.01) + 1 }, (_, i) =>
    (i * 0.01).toFixed(2)
  ).map(Number);

  // Funzione interna per calcolare il valore per singola frequenza
  function calcola_valore(AA11, durata_sollevamento, df) {
    let colonna;
    if (durata_sollevamento <= 60) {
      colonna = "BD";
    } else if (durata_sollevamento <= 120) {
      colonna = "BE";
    } else if (durata_sollevamento <= 480) {
      colonna = "BF";
    } else {
      return null; // Gestisci il caso in cui la durata è oltre 480
    }

    // Trova l'indice corrispondente in AZ
    const indice = AZ.findIndex((value) => value >= AA11);
    if (indice === -1 || !df[indice]) {
      //console.error(
      //   `Indice non valido o riga mancante per AA11: ${AA11}, indice: ${indice}`
      // );
      return null;
    }

    // Assicurati che la colonna esista
    if (!df[indice][colonna]) {
      //console.error(`Colonna ${colonna} non trovata nella riga ${indice}`);
      return null;
    }

    // Estrarre il valore dalla colonna corrispondente
    // //console.log(AA11);
    const valore = df[indice][colonna];

    return parseFloat(valore);
  }

  // Funzione per arrotondare a due cifre decimali
  function round(num, decimals) {
    return +(Math.round(num + `e+${decimals}`) + `e-${decimals}`);
  }

  let df = niosh_lookup;
  const frequenze = [];
  for (const key in dati_LI) {
    for (const altezza in dati_LI[key]) {
      for (const distanza in dati_LI[key][altezza]) {
        frequenze.push(dati_LI[key][altezza][distanza]["frequenza"]);
      }
    }
  }
  // Calcola i valori per tutte le frequenze
  const duration_param = frequenze.map((AA11) =>
    round(calcola_valore(AA11, scenario_di_durata_param, df), 2)
  );

  return duration_param;
}

// Funzione per calcolare LI_men
function calcola_LI_men(FILI_men, duration_param) {
  // Controlla che le liste abbiano la stessa lunghezza
  if (FILI_men.length !== duration_param.length) {
    throw new Error(
      "Le liste FILI_men e duration_param devono avere la stessa lunghezza."
    );
  }

  // Calcola LI_men come una lista
  const LI_men = FILI_men.map((fili, index) => {
    const dur = duration_param[index];
    return parseFloat((fili / dur).toFixed(2));
  });

  return LI_men;
}

function percentile(arr, p) {
  arr.sort((a, b) => a - b);
  const index = (p / 100) * (arr.length - 1);
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index - lower;
  if (upper >= arr.length) return arr[lower];
  return arr[lower] * (1 - weight) + arr[upper] * weight;
}

function assegna_categoria(valore, percentili, percentili_labels) {
  // Ordinare percentili e etichette in ordine decrescente
  percentili = percentili.slice().sort((a, b) => b - a);
  percentili_labels = percentili_labels.slice().sort((a, b) => b - a);

  //console.log(`Valore: ${valore}`);
  //console.log(`Percentili ordinati: ${percentili}`);
  //console.log(`Etichette ordinati: ${percentili_labels}`);

  // Assegnare categorie in base ai percentili
  for (let i = 0; i < percentili.length - 1; i++) {
    //console.log(
    //   `Verifica: ${percentili[i]} > ${valore} >= ${percentili[i + 1]}`
    // );
    if (percentili[i] > valore && valore >= percentili[i + 1]) {
      //CAMBIA
      //console.log(`Categoria assegnata: ${percentili_labels[i + 1]}%`); //CAMBIA
      return `${percentili_labels[i + 1]}%`; //CAMBIA
    }
  }

  // Gestire il caso per il percentile più basso
  if (valore <= percentili[percentili.length - 1]) {
    //console.log(
    //   `Categoria assegnata: ${percentili_labels[percentili_labels.length - 1]}%`
    // );
    return `${percentili_labels[percentili_labels.length - 1]}%`;
  }

  // Gestire il caso per valori superiori al percentile più alto
  //console.log(`Categoria assegnata: ${percentili_labels[0]}%`);
  return `${percentili_labels[0]}%`;
}

// Funzione per calcolare il valore FM
function calcola_valore(AA11, durata_sollevamento, df_csv) {
  const AZ = df_csv.map((row) => row.AZ);
  let colonna;

  if (durata_sollevamento <= 60) {
    colonna = "BD";
  } else if (durata_sollevamento <= 120) {
    colonna = "BE";
  } else if (durata_sollevamento <= 480) {
    colonna = "BF";
  } else {
    return NaN; // Gestisci il caso in cui la durata è oltre 480
  }

  // Trova l'indice corrispondente in AZ
  const indice = AZ.findIndex((value) => value > AA11) - 1;
  //console.log(AA11);
  //console.log(indice);
  if (indice < 0 || indice >= df_csv.length) {
    return NaN; // Gestisci l'uscita dall'intervallo
  }

  const valore = df_csv[indice][colonna];
  return valore;
}

// Funzione per calcolare il valore somma
function calcola_sum(row, df) {
  let categoria_ordine = ["83%", "67%", "50%", "33%", "17%", "0%"];
  let categoria = row.Categoria;
  const fm_valore = row.FM;
  const massimo_fili_men = row.Massimo_FILIMen;

  //console.log(`\nCalcolo per Categoria: ${categoria}`);
  //console.log(`  Massimo_FILIMen: ${massimo_fili_men}`);
  //console.log(`  FM: ${fm_valore}`);

  // Verifica se la categoria è undefined o null
  if (categoria === undefined || categoria === null) {
    //console.log("  Categoria è undefined o null, restituisco NaN");
    return NaN;
  }

  // Assicurati che la categoria sia una stringa
  categoria = categoria.toString().trim();

  // Trova l'indice della categoria corrente
  const idx = categoria_ordine.indexOf(categoria);
  if (idx === -1) {
    //console.log(`  Categoria ${categoria} non trovata in categoria_ordine`);
    return NaN;
  }

  // Se la categoria è '83%', applica la formula specifica
  if (categoria === "83%") {
    const result = massimo_fili_men / fm_valore;
    //console.log(
    //   `  Calcolo (Categoria 83%): ${massimo_fili_men} / ${fm_valore} = ${result}`
    // );
    return result;
  } else {
    // Trova la categoria precedente
    const prev_idx = idx - 1;
    if (prev_idx >= 0) {
      const prev_categoria = categoria_ordine[prev_idx];
      const prev_row = df.find((row) => row.Categoria === prev_categoria);
      if (!prev_row) {
        //console.log(
        //   `  Categoria Precedente ${prev_categoria} non trovata nel DataFrame`
        // );
        return NaN;
      }
      const prev_value = prev_row.FM;

      //console.log(`  Categoria Precedente: ${prev_categoria}`);
      //console.log(`  FM Categoria Precedente: ${prev_value}`);

      const result = massimo_fili_men * (1 / fm_valore - 1 / prev_value);
      //console.log(
      //   `  Calcolo (Altra Categoria): ${massimo_fili_men} * (1 / ${fm_valore} - 1 / ${prev_value}) = ${result}`
      // );
      return result;
    } else {
      //console.log(`  Categoria Precedente non trovata per ${categoria}`);
      return NaN; // Se non esiste una categoria precedente
    }
  }
}

function calcola_mnoltiplicatore_VLI(durata_sollevamento) {
  if (480 < durata_sollevamento && durata_sollevamento <= 540) {
    return 0.97;
  } else if (540 < durata_sollevamento && durata_sollevamento <= 600) {
    return 0.93;
  } else if (600 < durata_sollevamento && durata_sollevamento <= 660) {
    return 0.89;
  } else if (durata_sollevamento > 660) {
    return 0.85;
  } else {
    return 1;
  }
}

function calcola_durata_sollevamenti(timeline) {
  let somma_cumulata = 0;
  let durata = "breve";
  let durata_massima = 0;
  let azzerata = false;

  const sortedSteps = Object.keys(timeline).sort((a, b) => a - b);

  for (let step of sortedSteps) {
    const dettagli = timeline[step];
    const etichetta = dettagli.etichetta;
    const durata_step = dettagli.durata;

    //console.log(`Step ${step}: ${etichetta} - Durata: ${durata_step} minuti`);
    //console.log(`Somma cumulata prima dell'aggiornamento: ${somma_cumulata}`);

    if (
      [
        "SOLLEVAMENTO MANUALE (incluso il trasporto di carichi)",
        "traino e spinta",
      ].includes(etichetta)
    ) {
      somma_cumulata += durata_step;
      durata_massima = Math.max(durata_massima, somma_cumulata);
      //console.log(`Somma cumulata aggiornata: ${somma_cumulata}`);

      if (durata_massima >= 120) {
        durata = "lunga";
        //console.log(
        //   `Durata aggiornata a lunga (irreversibile), somma cumulata: ${somma_cumulata}`
        // );
        break;
      }

      if (durata_step > 60) {
        const prossima_pausa = timeline[step + 1]
          ? timeline[step + 1].durata
          : 0;
        //console.log(`Pausa successiva: ${prossima_pausa} minuti`);
        if (prossima_pausa >= 0.3 * durata_step) {
          //console.log(
          //   `Azzeramento somma cumulata a causa della pausa: ${prossima_pausa} minuti`
          // );
          somma_cumulata = 0;
          azzerata = true;
        } else {
          durata = "media";
          azzerata = false;
        }
      }

      if (somma_cumulata > 60 && durata !== "lunga") {
        durata = "media";
        //console.log(
        //   `Durata aggiornata a media, somma cumulata: ${somma_cumulata}`
        // );
      }
    } else if (etichetta === "compiti (senza sollevamenti) o pause") {
      if (somma_cumulata <= 60 && durata_step >= somma_cumulata) {
        //console.log(
        //   `Azzeramento somma cumulata a causa della pausa sufficiente: ${durata_step} minuti`
        // );
        somma_cumulata = 0;
        azzerata = true;
      } else if (somma_cumulata > 60 && durata_step >= somma_cumulata) {
        //console.log(
        //   `Azzeramento somma cumulata a causa della pausa sufficiente dopo 60: ${durata_step} minuti`
        // );
        somma_cumulata = 0;
        azzerata = true;
      }
    }

    //console.log(`Durata attuale: ${durata}`);
    //console.log("-------------------------");
  }

  return durata;
}

function calcola_indice_F(dati_LI, scenario_di_durata_param) {
  // Leggi il file CSV
  const df = niosh_lookup;

  // Colonne dei dati
  const AZ = Array.from({ length: Math.ceil(16.01 / 0.01) + 1 }, (_, i) =>
    (i * 0.01).toFixed(2)
  ).map(Number);

  // Carica i dati delle colonne BD, BE, BF dal file CSV
  const bd = df.map((row) => parseFloat(row["BD"]));
  const be = df.map((row) => parseFloat(row["BE"]));
  const bf = df.map((row) => parseFloat(row["BF"]));

  // Funzione interna per calcolare il valore per singola frequenza
  function calcola_valore(AA11, durata_sollevamento) {
    let colonna;
    if (durata_sollevamento <= 60) {
      colonna = bd;
    } else if (durata_sollevamento <= 120) {
      colonna = be;
    } else if (durata_sollevamento <= 480) {
      colonna = bf;
    } else {
      return null; // Gestisci il caso in cui la durata è oltre 480
    }

    // Trova l'indice corrispondente in AZ
    const indice = AZ.findIndex((value) => value >= AA11);
    if (indice === -1 || indice >= colonna.length) {
      return null; // Assicurati che l'indice sia valido
    }

    // Estrarre il valore dalla colonna corrispondente
    const valore = colonna[indice];

    return valore;
  }

  // Calcola i valori per tutte le frequenze
  const duration_param = dati_LI.map((AA11) =>
    calcola_valore(AA11, scenario_di_durata_param)
  );

  return duration_param;
}

function calcola_L60_L89(indice_F) {
  // Inizializza il nuovo array
  let L60_L89 = [];

  // Aggiungi il primo valore
  L60_L89.push(indice_F[0]);

  // Calcola i valori successivi
  for (let i = 1; i < indice_F.length; i++) {
    let valore = 1 / indice_F[i] - 1 / indice_F[i - 1];
    L60_L89.push(valore);
  }

  return L60_L89;
}

function trova_indici_ordinati(vettore) {
  // Trova gli indici ordinati in base ai valori decrescenti nel vettore
  const indici_ordinati = [...Array(vettore.length).keys()].sort(
    (a, b) => vettore[b] - vettore[a]
  );
  return indici_ordinati;
}

function ordina_secondo_indici(vettore, indici) {
  // Crea un nuovo vettore ordinato in base agli indici forniti
  const vettore_ordinato = indici.map((i) => vettore[i]);
  return vettore_ordinato;
}

function calcola_vli(L60_L89, FILI_men, LI_men) {
  // Controlla se la somma dei valori in FILI_men è 1
  const somma_ponderata = FILI_men.slice(1).reduce(
    (acc, f, i) => acc + f * L60_L89[i + 1],
    0
  );

  // Calcola il valore VLI
  const vli = Math.max(...LI_men) + somma_ponderata;
  return vli;
}

function calcola_vli_finale(n_task, VLI_EV18, VLI_AN51) {
  if (n_task > 10) {
    return VLI_EV18;
  } else {
    return VLI_AN51;
  }
}
