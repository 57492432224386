import { useToast } from "../../hooks/use-toast";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        open,
        ...props
      }) {
        return open ? (
          <Toast key={id} {...props}>
            <div className="grid ">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription className="text-[0.82034vw] font-normal font-atkinson  truncate overflow-hidden ">
                  {description}
                </ToastDescription>
              )}
            </div>
            {action}
          </Toast>
        ) : null;
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
