import { useClerk } from "@clerk/clerk-react";
import { useRef, useState } from "react";
import {
  filterInvalidTasks,
  handleLoadDepartment,
} from "../../../remote/utils";
import { getToken } from "../../../userManagement/utilities";
import WsScreen from "./WsScreen";

import { updateStationTasks } from "../../../remote/stations";
import {
  deleteTask,
  getTaskPerStation,
  updateStation,
} from "../../../remote/structure";
import { getTasks } from "../../../remote/tasks";

const MainWsScreen = ({
  data,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
  location,
  deleted,
}) => {
  const [saving, setSaving] = useState(false);
  const [savingEnabled, setSavingEnabled] = useState(false);
  const [tasks, setTasks] = useState([]);

  const wsRef = useRef();
  const popupRef = useRef();
  const tasksRef = useRef();

  tasksRef.current = tasks;

  const { session } = useClerk();

  const handleSaving = async () => {
    setSaving(true);
    let request = await updateStation(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      data.line,
      data.id,
      { name: wsRef.current.name, workshift: wsRef.current.workshift },
      await getToken(session)
    );
    setSavingEnabled(false);
    setSaving(false);
  };

  const handleDeleteTask = async (ids) => {
    for (const id of ids) {
      let request = await deleteTask(id, await getToken(session));
    }

    handleLoadDepartment(
      currentDepartment,
      () => {
        loadTasks();
        popupRef.current.close();
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );
  };
  const loadTasks = async (depIn) => {
    let tasks = [];
    let station;
    if (depIn) {
      station = depIn.lines[data.line][data.id];
      tasks = station.tasks ? station.tasks.map((x) => x.task) : [];
    } else {
      station = departmentDetail[currentDepartment].lines[data.line][data.id];
      tasks = station.tasks ? station.tasks.map((x) => x.task) : [];
    }

    console.log("LOADINGGGGG TASKKKKK", tasks);

    if (tasks.length == 0) {
      tasks = await getTaskPerStation(
        departmentDetail[currentDepartment].locationId,
        currentDepartment,
        data.line,
        data.id,
        await getToken(session)
      );
    } else {
      let jwt = await getToken(session);
      tasks = await getTasks(tasks, jwt);
      tasks = tasks.map((x) => {
        x.durata = station.tasks.filter((y) => y.task == x.id)[0].perc;
        return x;
      });
    }
    console.log("AFTERRR", tasks);

    let taskDep = tasks.map((x) => {
      return {
        ...x,
        department: currentDepartment,
        station: data.id,
      };
    });
    let validTask = filterInvalidTasks(taskDep);
    setTasks(
      filterInvalidTasks(taskDep).sort((a, b) => a.name.localeCompare(b.name))
    );
  };

  const handleChangeTime = async (id, value) => {
    let station = departmentDetail[currentDepartment].lines[data.line][data.id];

    let stationTasks = station.tasks ? station.tasks : [];

    let updatedTasks = tasks.map((task) => {
      if (task.id == id) return { ...task, durata: value };
      else return task;
    });

    setTasks(updatedTasks);

    if (updatedTasks.reduce((a, b) => a + b.durata, 0) == 100) {
      let result = await updateStationTasks(
        departmentDetail[currentDepartment].locationId,
        departmentDetail[currentDepartment].id,
        data.line,
        data.id,
        updatedTasks.map((x) => ({ task: x.id, perc: x.durata })),
        await getToken(session)
      );
    }
    // setTasks(updatedTasks);

    // let result = await updateStationTasks(
    //   departmentDetail[currentDepartment].locationId,
    //   departmentDetail[currentDepartment].id,
    //   data.line,
    //   data.id,
    //   updatedTasks,
    //   await getToken(session)

    // )
  };
  // return (
  //   <JobWsScreen
  //     key={data.id}
  //     id={data.id}
  //     setData={(x) => setData(x)}
  //     data={data}
  //     departmentDetail={departmentDetail}
  //     setDepartmentDetail={(x) => setDepartmentDetail(x)}
  //     currentDepartment={currentDepartment}
  //     location={location}
  //     handleSaving={handleSaving}
  //     handleDelete={() => {}}
  //     handleDeleteJobTask={handleDeleteTask}
  //     handleChangeTime={handleChangeTime}
  //     saving={saving}
  //     savingEnabled={savingEnabled}
  //     setSavingEnabled={setSavingEnabled}
  //     deleted={false}
  //     jobRef={wsRef}
  //     tasks={tasks}
  //     setTasks={setTasks}
  //     popupRef={popupRef}
  //   />
  // );

  return (
    <WsScreen
      key={data.id}
      data={data}
      departmentDetail={departmentDetail}
      setDepartmentDetail={(x) => setDepartmentDetail(x)}
      currentDepartment={currentDepartment}
      setData={(x) => setData(x)}
      handleSaving={handleSaving}
      handleChangeTime={handleChangeTime}
      location={location}
      wsRef={wsRef}
      saving={saving}
      setSaving={setSaving}
      setSavingEnabled={setSavingEnabled}
      savingEnabled={savingEnabled}
      handleDeleteTask={handleDeleteTask}
      tasks={tasksRef.current}
      loadTasks={loadTasks}
      popupRef={popupRef}
      deleted={deleted}
    />
  );
};

export default MainWsScreen;
