import { useClerk } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner/Spinner";
import ButtonTextIcon from "../components/buttons/ButtonTextIcon/ButtonTextIcon";
import { colors } from "../config/style";
import { getInspections } from "../remote/structure";
import { useStateValue } from "../stores/services/StateProvider";
import { getToken } from "../userManagement/utilities";
import InspectionTable from "../components/Tables/InspectionTable/InspectionTable";

const MainInspections = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [inspections, setInspections] = useState();
  const [loaded, setLoaded] = useState(false);

  const { session } = useClerk();

  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(state.current_location).length != 0) loadInspection();
    setLoaded(true);
  }, [state.current_location]);

  const loadInspection = async (onSuccess) => {
    setInspections(); // Inizializza setInspections, forse vuoi passare un valore o lasciare così se si tratta di un reset
    try {
      let token = await getToken(session);
      let res = await getInspections(state.current_location.id, token);
      setInspections(ordinaPerDataCreazione(res)); // Imposta le ispezioni ordinate se la chiamata ha successo
      if (onSuccess) onSuccess(); // Chiama onSuccess se fornita e tutto va a buon fine
    } catch (error) {
      console.error("Errore durante la chiamata a getInspections:", error);
      setInspections(-1); // Gestisce l'errore impostando setInspections a false o ad un valore appropriato
    }
  };

  function ordinaPerDataCreazione(array) {
    const inspectionLocation = array.filter(
      (element) => element.locationId === state.current_location.id
    );

    return inspectionLocation.sort((a, b) => {
      // Converti le date in oggetti Date per il confronto
      let dataA = new Date(a.creationDate);
      let dataB = new Date(b.creationDate);

      // Ordina in modo crescente
      return dataB - dataA;
    });
  }

  return (
    <div
      style={{
        width: "88vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          width: "73.5vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "0.8vw",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "1.6vw",
              fontWeight: "700",

              marginTop: "1.9vw",
              boxSizing: "border-box",
            }}
          >
            {t("inspection")}
          </p>

          <ButtonTextIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
            label={t("refresh")}
            backgroundH={colors.mainH}
            background={colors.main}
            color={"white"}
            onClick={() => {
              loadInspection();
            }}
            style={{
              width: "7vw",
            }}
          />
        </div>

        <div
          style={{
            overflowY: "scroll",
            width: "73.5vw",
            height: "88vh",
            // padding: "2vw",
          }}
        >
          {" "}
          {(inspections == -1 || inspections?.length == 0) && loaded ? (
            <div
              style={{
                width: "72.8vw",
                height: "100%",
                borderRadius: "0.3vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_RESOURCES_BUCKET + "InspectionPh.png"
                }
                style={{
                  width: "23.5vw",
                }}
              />{" "}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "400",
                  marginLeft: "0vw",
                  marginTop: "0vw",
                  boxSizing: "border-box",
                  textAlign: "center",
                  width: "25vw",
                  marginTop: "1vw",
                }}
              >
                {t("mainInspectionNoIsp")}
              </p>
              <ButtonTextIcon
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
                label={t("refresh")}
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => {
                  loadInspection();
                }}
              />
            </div>
          ) : inspections ? (
            <div style={{}}>
              {inspections?.map((inspection, index) => {
                return (
                  <InspectionTable
                    key={inspection.id}
                    inspection={inspection}
                    inspectionIndex={index}
                    onSave={() => loadInspection()}
                  />
                );
              })}
            </div>
          ) : (
            <Spinner />
          )}
          {/* <InspectionTable inspections={inspections} /> */}
        </div>
      </div>
    </div>
  );
};

export default MainInspections;
