import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfoTab, {
  Row,
  BasicText,
  TextBox,
  InfoBox,
  InfoTabScroller,
  CheckBox,
} from "../../InfoTab/InfoTab";
import ButtonIcon from "../../buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";
import { colors, layout } from "../../../config/style";
import { shift_global } from "../../../utils/Protocols/OCRA/singleTask";
import {
  formatDecimal,
  isPositiveInteger,
} from "../../AnalysisContainer/AnalysisContainerFunctions";
import { tips } from "../../../config/tips";

const ShiftScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  handleSaving,
  handleDelete,
  saving,
  shiftRef,
  savingEnabled,
  setSavingEnabled,
  deleted,
}) => {
  const [shift, setShift] = useState(
    departmentDetail[currentDepartment].workshifts[id]
  );
  console.log("departmentdetails", departmentDetail);

  const { t } = useTranslation();

  shiftRef.current = shift;

  let shiftRes = shift_global(shift);

  useEffect(() => {
    //Create an updated version of the department
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      workshifts: {
        ...departmentDetail[currentDepartment].workshifts,
        [id]: shift,
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);

    // setSavingEnabled(true);
  }, [shift]);

  useEffect(() => {
    const updatedShift = departmentDetail[currentDepartment]?.workshifts?.[id];
    if (updatedShift && updatedShift !== shift) {
      setShift(updatedShift);
      console.log("updatedShift", updatedShift);
    }
  }, [departmentDetail, currentDepartment, id]);

  useEffect(() => {
    return () => {
      if (!deleted.current) {
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          workshifts: {
            ...departmentDetail[currentDepartment].workshifts,
            [id]: shiftRef.current,
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        handleSaving(id, shiftRef.current);
      } else {
      }
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.1vw",
          marginTop: layout.paddingTop,
        }}
      >
        {" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",

            margin: "0vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{shift.name}</b>
        </p>
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
            marginRight: "0.1vw",
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={handleDelete}
            style={{ width: "1.875vw" }}
          />
          <ButtonTextIcon
            disabled={!savingEnabled}
            label={t("save")}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "700",
              width: "3vw",
              boxSizing: "border-box",
            }}
            // icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            // iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            // backgroundH={colors.details}
            // background={"white"}
            color={colors.main}
            backgroundH={colors.mainOpaque}
            background={"transparent"}
            onClick={() => {
              handleSaving(id, shiftRef.current);
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("shiftScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={shift.name}
                  onChange={(value) => {
                    setSavingEnabled(true);
                    setShift({ ...shift, name: value });
                  }}
                  locked={false}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("department")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={departmentDetail[currentDepartment].name}
                  onChange={(value) => {}}
                  locked={true}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenShiftLenght")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={shift.durata}
                  err={shift.durata <= 0}
                  textAlign={"start"}
                  onChange={(value) => {
                    if (
                      isPositiveInteger(value) &&
                      (parseInt(value) < 1000 || !value)
                    ) {
                      setSavingEnabled(true);
                      setShift({
                        ...shift,
                        durata: value ? parseInt(value) : 0,
                      });
                    }
                  }}
                  locked={false}
                  unit={"m"}
                  unitMargin={"-7vw"}
                />
              </Row>
            </>,
          ]}
        />
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("shiftScreenResults")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenDurationP1")}</b>
                    {t("shiftScreenDurationP2")}
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.durata_media_netta}
                  err={shiftRes.output.durata_media_netta <= 0}
                  unit={"m"}
                  ai={false}
                  info={tips[document.language].durata_media_netta}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryP1")}
                    <b>{t("shiftScreenRecoveryP2")}</b>
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.numero_ore_senza_recupero_automatico}
                  unit={"h"}
                  ai={false}
                  info={
                    tips[document.language].numero_ore_senza_recupero_automatico
                  }
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "16vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryMulti")}
                  </BasicText>
                </div>
                <InfoBox
                  value={shiftRes.output.moltiplicatore_recupero}
                  decimals={2}
                  unit={""}
                  ai={false}
                  info={tips[document.language].moltiplicatore_recupero}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <BasicText
                    style={{
                      width: "17vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRecoveryManual")}
                  </BasicText>
                </div>
                <TextBox
                  value={shift.d_no_rec <= -1 ? "-" : shift.d_no_rec}
                  onChange={(valueIn) => {
                    let value = valueIn.replace(/-/g, "");
                    if (!value) {
                      setShift({
                        ...shift,
                        d_no_rec: -1,
                      });
                    } else if (formatDecimal(value)) {
                      setSavingEnabled(true);

                      setShift({
                        ...shift,
                        d_no_rec: formatDecimal(value)
                          ? formatDecimal(value)
                          : 0,
                      });
                    }
                  }}
                  locked={false}
                  unit={"h"}
                  info={
                    tips[document.language].numero_ore_senza_recupero_manuale
                  }
                  infoBox={true}
                  unitMargin={"0.2vw"}
                />
              </Row>
            </>
          }
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw" }}
        key={id}
        infoTab={
          <InfoTab
            fullWidth={false}
            style={{
              borderRadius: "0px",
              background: "transparent",
              border: "0px solid white",
              boxShadow: "0px 0px 0px 0px white",
            }}
            width={"100%"}
            height={"100%"}
            title={[t("shiftScreenPauses"), t("shiftScreenNotRepetitive")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            content={[
              <>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenOffPausesP1")}
                    <b>{t("shiftScreenOffPausesP2")}</b>
                    {t("shiftScreenOffPausesP3")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_ufficiali}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_ufficiali: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenLunchPausesP1")}</b>
                    {t("shiftScreenLunchPausesP2")}
                  </BasicText>
                  <TextBox
                    value={shift.d_pause_mensa}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          d_pause_mensa: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenPausesEffP1")}
                    <b>{t("shiftScreenPausesEffP2")}</b>
                    {t("shiftScreenPausesEffP3")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_effettive}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_effettive: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenPausesExtra")}
                  </BasicText>
                  <TextBox
                    value={shift.n_pause_extra}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          n_pause_extra: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={false}
                    info={""}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenPausesNoLunchP1")}</b>
                    {t("shiftScreenPausesNoLunchP2")}
                  </BasicText>
                  <TextBox
                    value={shift.d_pause}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          d_pause: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={false}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenPausesEffHoursP1")}</b>
                    {t("shiftScreenPausesEffHoursP2")}
                  </BasicText>
                  <InfoBox
                    value={shiftRes.output.durata_complessiva_pause_mensa}
                    unit={"m"}
                    ai={false}
                    info={
                      tips[document.language]
                        .somma_delle_pause_in_orario_di_lavoro
                    }
                    infoBox={true}
                  />
                </Row>
              </>,
              <>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenRefeptitive")}
                  </BasicText>
                  <CheckBox
                    label={t("present")}
                    value={shift.task_non_ripetitivi}
                    onClick={() => {
                      setSavingEnabled(true);
                      setShift({
                        ...shift,
                        task_non_ripetitivi: !shift.task_non_ripetitivi,
                      });
                    }}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenTimeReach")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_ragg_post : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_ragg_post: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={!shift.task_non_ripetitivi}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenTimeDPI")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_vestirsi : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_vestirsi: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={!shift.task_non_ripetitivi}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenOther")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_altro : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_altro: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    locked={!shift.task_non_ripetitivi}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    infoBox={true}
                  />
                </Row>
                <Row justifyContent={"space-between"}>
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    {t("shiftScreenCleaning")}
                  </BasicText>
                  <TextBox
                    value={shift.task_non_ripetitivi ? shift.t_pulizie : "-"}
                    onChange={(value) => {
                      if (isPositiveInteger(value)) {
                        setSavingEnabled(true);
                        setShift({
                          ...shift,
                          t_pulizie: value ? parseInt(value) : 0,
                        });
                      }
                    }}
                    unit={"m"}
                    unitMargin={"0.2vw"}
                    locked={!shift.task_non_ripetitivi}
                    infoBox={true}
                  />
                  <BasicText
                    style={{
                      width: "18vw",
                      color: colors.text,
                    }}
                  >
                    <b>{t("shiftScreenNonRepP1")}</b>
                    {t("shiftScreenNonRepP2")}
                  </BasicText>
                  <InfoBox
                    value={shiftRes.output.durata_non_ripetitivi}
                    unit={"m"}
                    ai={false}
                    info={tips[document.language].durata_lavori_non_ripetitivi}
                    infoBox={true}
                  />
                </Row>
              </>,
            ]}
          />
        }
      />
    </>
  );
};
export default ShiftScreen;
