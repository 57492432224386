import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../config/style";
import { InfoButton } from "../../InfoTab/InfoTab";
import { tips } from "../../../config/tips";
import { ModularScoreIndicator } from "../../Indicators/Indicators";
import { getOcraColor } from "../../../utils/Protocols/OCRA/OCRA";
import { getColorFromMapping } from "../../../utils/Protocols/commonFunction";

const ScoreColumn = ({ result, header, open, protocol }) => {
  const { t } = useTranslation();


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 2,
        gap: "0.2vw",
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginRight: "0.2vw",
            }}
          >
            {t("mainStructureScore")}
          </p>

          <InfoButton
            ai={false}
            err={false}
            std={true}
            info={tips[document.language].punteggio_parziale}
          />
        </>
      ) : (
        protocol && (
          <>
            {result &&
              open &&
              protocol?.category?.map((x, id) => {
                return (
                  <ModularScoreIndicator
                    scores={protocol?.score?.map((score, id) => {
                      return {
                        score: result?.taskDetail?.[protocol.ref]?.[x]?.[score],
                        label: protocol?.topLabel?.[id],
                      };
                    })}
                    label={protocol?.labels[id]}
                    getColor={(value) =>
                      getColorFromMapping(value, protocol.mapping)
                    }
                  />
                );
              })}
          </>
        )
      )}
    </div>
  );
};
export default ScoreColumn;
