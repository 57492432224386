import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import InfoTab, { Selector } from "../InfoTab/InfoTab";
import { Video, VideoContainerWrapper } from "./VideoContainer.styled";
import { useTranslation } from "react-i18next";
import { NONE } from "../../config/generalConst";
import { colors } from "../../config/style";
import { setAlpha } from "../../utils/generalFunctions";

const VideoContainer = ({
  video,
  videos,
  videoId,
  videoLoaded,
  setVideoLoaded,
}) => {
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    if (videos != NONE && videos?.left_video != NONE && videos) {
      let opt = [
        { value: videos.left_video.video_path, label: t("Left") },
        { value: videos.right_video.video_path, label: t("Right") },
        { value: videos.hands.video_path, label: t("hands") },
      ];
      setOptions(opt);
      setSelectedOption(opt[1]);
    }
  }, []);

  useEffect(() => {
    if (videoId && videos?.left_video != NONE && videos != NONE) {
      let video = document.getElementById(videoId);
      const handleCanPlayThrough = () => {
        if (!videoLoaded) setVideoLoaded(video);
      };
      // Gestore dell'evento 'error'
      const handleError = () => {
        video.src = video.src.replace("_obscured.webm", ".mp4");
        video.load();
      };

      video.addEventListener("canplaythrough", handleCanPlayThrough);
      video.addEventListener("error", handleError);

      // Funzione di pulizia per rimuovere l'event listener
      return () => {
        video.removeEventListener("canplaythrough", handleCanPlayThrough);
      };
    }
  }, [videoId, videoLoaded]);

  if (videos?.left_video != NONE && videos != NONE)
    return (
      <VideoContainerWrapper>
        {options && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: 99,
            }}
          >
            <Selector
              options={options}
              value={selectedOption}
              onChange={(x) => setSelectedOption(x)}
            />
          </div>
        )}
        <Video
          id={videoId}
          src={selectedOption ? selectedOption.value : video}
          controls
        ></Video>
      </VideoContainerWrapper>
    );
  else
    return (
      <InfoTab
        width={"36vw"}
        height={"19.5vw"}
        fullWidth={true}
        style={{
          background: colors.riskUnacceptable,
          borderBottom: "0px solid white",
          border: "0.14vw solid " + setAlpha(colors.main, 0.18),
        }}
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.REACT_APP_RESOURCES_BUCKET + "WorkerLaptop.png"}
              style={{ width: "10vw" }}
            />
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.9vw",
                fontWeight: "400",
                width: "70%",
                margin: 0,
                marginBottom: "0.5vw",
                boxSizing: "border-box",
                textAlign: "center",
              }}
            >
              {"Video mancante, non necessario per analisi MMC. "}
            </p>
          </div>
        }
      />
    );
};

VideoContainer.propTypes = {
  dataTestId: PropTypes.string,
  status: PropTypes.number,
  video: PropTypes.string,
};

VideoContainer.defaultProps = {
  dataTestId: "Default dataTestId", // Imposta un valore predefinito per la prop "title"
  status: 0,
  video: null,
};

export default VideoContainer;
