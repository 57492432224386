import React from "react";

const PlusIcon = ({ fill = "none", size, onClick, disableHover = false }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center rounded-md  p-1  ${
        disableHover ? "" : "group hover:bg-[#BEBCFB] cursor-pointer"
      } ${disableHover ? "cursor-default" : "cursor-pointer"}`}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.8125C10.932 0.8125 11.6875 1.56802 11.6875 2.5V8.3125H17.5C18.432 8.3125 19.1875 9.06802 19.1875 10C19.1875 10.932 18.432 11.6875 17.5 11.6875L11.6875 11.6875L11.6875 17.5C11.6875 18.432 10.932 19.1875 10 19.1875C9.06802 19.1875 8.3125 18.432 8.3125 17.5V11.6875H2.5C1.56802 11.6875 0.8125 10.932 0.8125 10C0.8125 9.06802 1.56802 8.3125 2.5 8.3125H8.3125V2.5C8.3125 1.56802 9.06802 0.8125 10 0.8125Z"
          className={`fill-[#212040] ${
            disableHover ? "" : "group-hover:fill-[rgba(109,105,242,1)]"
          }`}
        />
      </svg>
    </div>
  );
};

export default PlusIcon;
