import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../../config/style";
import {
  ButtonTextIconWrapper,
  IconButtonWrapper,
} from "./ButtonTextIcon.styled";

const ButtonTextIcon = ({
  onClick,
  icon,
  iconH,
  toggle,
  label,
  disabled,
  background,
  backgroundH,
  id,
  width,
  color,
  colorH,
  margin,
  style,
  className,
  iconHeight,
  loading,
  loadingLabel,
  loadingStyle,
  iconObject,
  shadowColor,
  noShadow,
}) => {
  const [hover, setHover] = useState(false);
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    if (buttonRef.current && !loading) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [loading, label, icon]);

  const getBackground = () => {
    if (toggle) return colors.main;
    if (hover && !disabled) return backgroundH;
    return background;
  };

  const getBorder = () => {
    if (toggle) return colors.main;
    return colors.details;
  };

  const getColor = () => {
    if (color && colorH) return hover ? colorH : color;
    if (color) return color;
    return "white";
  };

  const renderIcon = (iconProp) => {
    if (typeof iconProp === "string") {
      // If it's a string, render it as an image
      return (
        <IconButtonWrapper style={{ height: iconHeight }} src={iconProp} />
      );
    }
    // If it's not a string, assume it's a React component and render it directly
    return <div style={{ height: iconHeight }}>{iconProp}</div>;
  };

  return (
    <ButtonTextIconWrapper
      ref={buttonRef}
      onMouseEnter={() => !loading && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={disabled || loading === true ? () => {} : onClick}
      id={id}
      className={`${className}`}
      style={{
        cursor: disabled || loading === true ? "not-allowed" : "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: getBackground(),
        width: width ? width : "",

        color: getColor(),
        // paddingRight: loading ? "0vw" : label ? "0.8vw" : "0.4vw",
        // paddingLeft: loading && "0vw",
        padding: "0.3vw",
        paddingLeft: "0.4vw",
        paddingRight: "0.5vw",
        boxSizing: "border-box",
        opacity: disabled && 0.6,
        margin: margin,
        gap: "0.4vw",
        boxShadow: noShadow
          ? ""
          : shadowColor
          ? shadowColor
          : "0vw 0.2vw 0.7vw 0.2vw rgba(113, 109, 242, 0.1)",
        ...style,
      }}
    >
      {loading ? (
        <>
          <i
            className="fa fa-circle-o-notch fa-spin"
            style={
              loadingStyle
                ? loadingStyle
                : { marginLeft: "-12px", marginRight: "8px" }
            }
          ></i>
          {loadingLabel || loadingLabel == "" ? loadingLabel : "Caricamento"}
        </>
      ) : (
        <>
          {icon && (
            <IconButtonWrapper
              style={{ height: iconHeight }}
              src={hover && iconH ? iconH : icon}
            />
          )}
          {iconObject && iconObject}
          {label}
        </>
      )}
    </ButtonTextIconWrapper>
  );
};

ButtonTextIcon.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  onClick: PropTypes.func, // Definisci il tipo di prop "onClick"
  children: PropTypes.node, // Definisci il tipo di prop "children"
};

ButtonTextIcon.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  onClick: () => {}, // Imposta una funzione vuota come valore predefinito per "onClick"
};

export default ButtonTextIcon;
