import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";

const EmptyScreen = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",

        width: "100%",
        height: "100%",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0.3vw",
          boxShadow: "0px 0.2vw 0.8vw 0.2vw rgba(113, 109, 242, 0.1)",
          background: "white",
          width: "60vw",
          height: "39vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={
            data?.type == "delete"
              ? process.env.REACT_APP_RESOURCES_BUCKET + "DeletePh.png"
              : process.env.REACT_APP_RESOURCES_BUCKET + "WorkstationsPh.png"
          }
          style={{
            width: "22.3vw",
          }}
        />{" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.25vw",
            fontWeight: "400",
            marginLeft: "0vw",
            marginTop: "0vw",
            boxSizing: "border-box",
            textAlign: "center",
            width: "25vw",
            marginTop: "1vw",
          }}
        >
          {data?.type == "delete" ? (
            <>
              {t("deleteScreenTitleP1")}
              <b>'{data.element}'</b>
              {t("deleteScreenTitleP2")}
            </>
          ) : (
            t("deleteScreenMissingDep")
          )}
        </p>
        {data?.type != "delete" && (
          <ButtonTextIcon
            label={t("deleteScreenCreateDep")}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "FactoryWh.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "FactoryWh.png"}
            backgroundH={colors.tertiaryH}
            background={colors.tertiary}
            color={"white"}
            onClick={() => {
              let childElements =
                document.getElementById("newDepartment").children;
              for (var i = 0; i < childElements.length; i++) {
                childElements[i].click();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyScreen;
