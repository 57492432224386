import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../config/style";
import { InfoButton } from "../../InfoTab/InfoTab";
import { tips } from "../../../config/tips";

const ProtocolColumn = ({
  availableProtocols,
  header,
  protocol,
  setProtocol,
}) => {

  const { t } = useTranslation();

  const ToggleElement = ({ on, label, onClick }) => {
    const [hover, setHover] = useState(false);
    return (
      <div
        onClick={() => onClick()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          background: on ? "white" : "transparent",
          width: "1.85vw",
          height: "1.85vw",
          borderRadius: "50%",
          cursor: on ? "default" : "pointer",
          boxShadow: on
            ? "0vw 0.2vw 0.45vw 0vw rgba(113, 109, 242, 0.25)"
            : "0vw 0vw 0vw 0vw rgba(113, 109, 242, 0.25)",
        }}
      >
        <p
          style={{
            color: on || hover ? colors.main : colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.7vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
          }}
        >
          {label}
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 0.8,
        gap: "0.2vw",
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginRight: "0.2vw",
            }}
          >
            {t("protocol")}
          </p>

          <InfoButton
            ai={false}
            err={false}
            std={true}
            info={tips[document.language].protocollo}
          />
        </>
      ) : (
        availableProtocols && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: colors.background,
              padding: "0.3vw",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "2vw",
              gap: "0vw",
            }}
          >
            {availableProtocols &&
              availableProtocols.map((x, id) => {
                return (
                  <ToggleElement
                    key={id}
                    label={x.short}
                    on={id == protocol}
                    onClick={() => setProtocol(id)}
                  />
                );
              })}
          </div>
        )
      )}
    </div>
  );
};

export default ProtocolColumn;
