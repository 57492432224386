import React from "react";

const DeleteIcon = ({ size, fill }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 36 36"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.8486 15.041C26.8486 14.701 26.5694 14.4251 26.2254 14.4251H9.77711C9.43184 14.4251 9.15388 14.701 9.15388 15.041C9.15388 16.269 9.13144 17.4132 9.11025 18.4737C9.01801 23.0914 8.95694 26.1423 10.8453 28.0453C12.1865 29.3965 14.389 30 17.9875 30C21.5898 30 23.796 29.3952 25.141 28.0416C27.0381 26.13 26.9782 23.0643 26.8897 18.4208C26.8698 17.375 26.8486 16.248 26.8486 15.041Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.0652 10.3319H22.9036C22.8525 9.52637 22.6269 8.39074 21.8154 7.46942C20.9578 6.49514 19.674 6 17.9988 6C16.3248 6 15.0409 6.49514 14.1833 7.46942C13.3719 8.39074 13.145 9.52637 13.0939 10.3319H7.93484C7.41881 10.3319 7 10.7458 7 11.2557C7 11.7656 7.41881 12.1795 7.93484 12.1795H28.0652C28.5812 12.1795 29 11.7656 29 11.2557C29 10.7458 28.5812 10.3319 28.0652 10.3319Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default DeleteIcon;
