import { useClerk, useUser } from "@clerk/clerk-react";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";
import { nav_paths } from "../../config/navigationConst";
import { colors } from "../../config/style";
import {
  CLEAR_ALL,
  CLEAR_DATA,
  CLEAR_INSPECTIONS,
  CLEAR_LOCATION,
} from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import { useStateValue } from "../../stores/services/StateProvider";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import LocationSelector from "../LocationSelector/LocationSelector";
import { PopupCreate, PopupCreateEdit } from "../Popup/Popup";
import { PopupAlert } from "../Popup/PopupContent";
import ButtonNavigation from "../buttons/ButtonNavigation/ButtonNavigation";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import {
  LateralNavigationWrapper,
  LogoWrapper,
} from "./LateralNavigation.styled";
import Cookies from "js-cookie";
import ButtonToggle from "../buttons/ButtonToggle/ButtonToggle";

const LateralNavigation = ({ dataTestId, hideLocation }) => {
  const [state, dispatch] = useStateValue();
  const popupRef = useRef();
  const { signOut } = useClerk();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isSignedIn, isLoaded } = useUser();
  const { t, i18n } = useTranslation();
  let menuItems = [
    {
      label: t("inspection"),
      location: nav_paths.inspections,
      icon: process.env.REACT_APP_RESOURCES_BUCKET + "search.png",
      iconH: process.env.REACT_APP_RESOURCES_BUCKET + "searchH.png",
      disabled: false,
    },
    {
      label: t("structure"),
      location: nav_paths.structure,
      icon: process.env.REACT_APP_RESOURCES_BUCKET + "Organigram.png",
      iconH: process.env.REACT_APP_RESOURCES_BUCKET + "Organigramhw.png",
      disabled: false,
    },
    // {
    //   label: t("analysis"),
    //   location: nav_paths.analysis,
    //   icon: process.env.REACT_APP_RESOURCES_BUCKET+"analysis.png",
    //   iconH:
    //     process.env.REACT_APP_RESOURCES_BUCKET+"analysis.png",
    //   disabled: true,
    // },
  ];

  function handleLogout() {
    Cookies.remove("lastLocation");

    dispatch({
      type: DATA_A,
      subtype: CLEAR_ALL,
    });

    signOut();
  }

  return (
    <LateralNavigationWrapper>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LogoWrapper
          src={process.env.REACT_APP_RESOURCES_BUCKET + "logo.png"}
        />
        {(user?.publicMetadata?.expertise == "reviewer" ||
          user?.publicMetadata?.expertise == "dataset") && (
          <div
            style={{
              marginBottom: "1vw",
              display: "flex",
              flexDirection: "column",
              gap: "0.5vw",
            }}
          >
            <ButtonToggle
              onClick={() => navigate(nav_paths.review, { replace: true })}
              label={"Revisione"}
              toggle={location.pathname == nav_paths.review}
              disabled={false}
            />
            {user?.publicMetadata?.expertise == "reviewer" && (
              <ButtonToggle
                onClick={() => navigate(nav_paths.dataset, { replace: true })}
                label={"Dataset"}
                toggle={location.pathname == nav_paths.dataset}
                disabled={false}
              />
            )}
          </div>
        )}
        {hideLocation != true && (
          <div style={{ marginBottom: "1vw" }}>
            <LocationSelector />
          </div>
        )}

        {menuItems.map((x, id) => (
          <ButtonNavigation
            key={id}
            icon={x.icon}
            iconH={x.iconH}
            label={x.label}
            toggle={location.pathname == x.location}
            onClick={() => navigate(x.location, { replace: true })}
            disabled={x.disabled}
          />
        ))}
      </div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: "0.2vw",
        }}
      >
        <img
          src={process.env.REACT_APP_RESOURCES_BUCKET + "UserPh.png"}
          style={{
            width: "1.9vw",
            height: "1.9vw",
          }}
        />
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",

            marginTop: "0vw",
            marginBottom: "0vw",
            boxSizing: "border-box",
            marginBottom: "0.3vw",
          }}
        >
          {user.fullName}
        </p>
        <ButtonTextIcon
          label={t("logout")}
          color={colors.text}
          colorH={colors.negative}
          backgroundH={colors.negativePale}
          background={"transparent"}
          icon={process.env.REACT_APP_RESOURCES_BUCKET + "LogoutBlk.png"}
          iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Logout.png"}
          onClick={() => popupRef.current.open()}
          style={{
            fontSize: "0.8vw",
            boxShadow: "0px 0px 0px 0px white",
            height: "2vw",
            marginBottom: "1.8vw",
          }}
        />

        <DividerSmall margin={"0vw 1.2vw 0vw 1.2vw"} />
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.7vw",
            fontWeight: "400",
            marginTop: "0.8vw",
            marginBottom: "1vw",
            boxSizing: "border-box",
          }}
        >
          V{packageJson.version}
        </p>
      </div>
      <PopupCreate
        ref={popupRef}
        title={t("logoutConfirmTitle")}
        children={
          <PopupAlert
            message={t("logoutConfirmText")}
            negativeLabel={t("cancel")}
            positiveLabel={t("logout")}
            positive={handleLogout}
            negative={() => {}}
          />
        }
      />
    </LateralNavigationWrapper>
  );
};

LateralNavigation.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

LateralNavigation.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default LateralNavigation;
