import React from "react";
import { useTranslation } from "react-i18next";
import { WARNING_VISIBILITY } from "../../../config/generalConst";
import { indexColors, wrongColors } from "../../../config/graphsConfig";
import { colors } from "../../../config/style";
import { tips } from "../../../config/tips";
import { DividerSmall } from "../../AnalysisSelector/AnalysisSelector";
import { InfoButton, TextBox } from "../../InfoTab/InfoTab";
import ButtonIcon from "../../buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";
import ButtonTextIconSquare from "../../buttons/ButtonTextIconSquare/ButtonTextIconSquare";
import { findClosestIndexAndCheckError, setAlpha } from "../ChartUtlis";

const ChartSideControls = ({
  data,
  selectedChart,
  editingBatch,
  setEditingBatch,
  dataForChart,
  showVisisbility,
  setVisibilityIndex,
  visibilityIndex,
  visibilityScore,
  time,
  batchLevel,
  setBatchLevel,
  batchIndex,
  setBatchIndex,
  videoLoaded,
  chartInstance,
  enableMouseLocation,
  visibilityIndexRef,
  dataForChartHistory,
  setShowVisisbility,
  historyIndex,
  handleChartUpdate,
  moveHistory,
  setEditingBatchIndex,
  editingBatchIndex,
  updateBatchLevel,
  index,
}) => {
  const { t } = useTranslation();

  let localIndex = data.orderIndex;
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Formatta con zeri iniziali se necessario
    const minutesFormatted = String(minutes).padStart(2, "0");
    const secondsFormatted = String(remainingSeconds).padStart(2, "0");

    return `${minutesFormatted}:${secondsFormatted}''`;
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            gap: "0.1vw",
            width: "100%",
            marginBottom: "0.5vw",
          }}
        >
          <InfoButton
            ai={false}
            std={true}
            err={false}
            info={
              data.graph[selectedChart].type == 0
                ? tips[document.language].edit_completo
                : tips[document.language].edit_parziale
            }
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.8vw",
              fontWeight: "800",
              boxSizing: "border-box",
              margin: 0,
              marginLeft: "0.2vw",
            }}
          >
            {t("EDIT")}
          </p>
        </div>
        <DividerSmall />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0.5vw",
            gap: "0.1vw",
            marginBottom: "0.5vw",
          }}
        >
          {/* CASE SQUARE */}
          {data.graph[selectedChart].type >= 1 && (
            <>
              {editingBatch &&
                index &&
                data.graph[selectedChart].errorsIndex && (
                  <IndexEditor
                    editingBatchIndex={editingBatchIndex}
                    setEditingBatchIndex={setEditingBatchIndex}
                    dataForChart={dataForChart}
                    localIndex={localIndex}
                    wrongColors={wrongColors}
                    updateBatchLevel={updateBatchLevel}
                    setEditingBatch={setEditingBatch}
                    editingBatch={editingBatch}
                    data={data}
                    selectedChart={selectedChart}
                  />
                )}
              {editingBatch &&
                (!index || !data.graph[selectedChart].errorsIndex) && (
                  <ButtonTextIconSquare
                    noBorder={true}
                    label={t("lock")}
                    disabled={false}
                    icon={process.env.REACT_APP_RESOURCES_BUCKET + "Ignore.png"}
                    iconH={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Ignorehw.png"
                    }
                    backgroundH={
                      editingBatch ? colors.neutral : colors.negative2
                    }
                    background={"white"}
                    width={"7.3vw"}
                    onClick={() => {
                      setBatchLevel(batchLevel.slice(batchIndex));
                      setBatchIndex(0);
                      setEditingBatch(!editingBatch);
                    }}
                  />
                )}
              {!editingBatch && (
                <ButtonTextIconSquare
                  noBorder={true}
                  label={t("edit")}
                  disabled={false}
                  icon={process.env.REACT_APP_RESOURCES_BUCKET + "Add.png"}
                  iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Addhw.png"}
                  backgroundH={colors.negative2}
                  background={"white"}
                  width={"7.3vw"}
                  onClick={() => {
                    setEditingBatchIndex(
                      Math.floor(videoLoaded.currentTime / 10)
                    );

                    setEditingBatch(!editingBatch);
                  }}
                />
              )}
            </>
          )}
          {/* CASE SINUSOIDAL */}
          {data.graph[selectedChart].type == 0 && (
            <>
              <ButtonTextIconSquare
                noBorder={true}
                label={t("add")}
                disabled={findClosestIndexAndCheckError(
                  dataForChart.current,
                  time
                )}
                icon={
                  editingBatch
                    ? process.env.REACT_APP_RESOURCES_BUCKET + "Ignore.png"
                    : process.env.REACT_APP_RESOURCES_BUCKET + "Add.png"
                }
                iconH={
                  editingBatch
                    ? process.env.REACT_APP_RESOURCES_BUCKET + "Ignorehw.png"
                    : process.env.REACT_APP_RESOURCES_BUCKET + "Addhw.png"
                }
                backgroundH={editingBatch ? colors.neutral : colors.negative2}
                background={"white"}
                width={"7.3vw"}
                onClick={() => {
                  // Lock mouse for an instance
                  enableMouseLocation.current = [
                    chartInstance.current.scales.x.getPixelForValue(
                      videoLoaded.currentTime
                    ),
                    (chartInstance.current.chartArea.top +
                      chartInstance.current.chartArea.bottom) /
                      2,
                  ];
                  // Call function update
                  handleChartUpdate(videoLoaded.currentTime);
                }}
              />
              <ButtonTextIconSquare
                noBorder={true}
                label={t("ignore")}
                disabled={
                  !findClosestIndexAndCheckError(dataForChart.current, time)
                }
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Ignore.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Ignorehw.png"}
                backgroundH={colors.neutral}
                background={"white"}
                width={"7.3vw"}
                onClick={() => {
                  // Lock mouse for an instance
                  enableMouseLocation.current = [
                    chartInstance.current.scales.x.getPixelForValue(
                      videoLoaded.currentTime
                    ),
                    (chartInstance.current.chartArea.top +
                      chartInstance.current.chartArea.bottom) /
                      2,
                  ];
                  // Call function update
                  handleChartUpdate(videoLoaded.currentTime);
                }}
              />
            </>
          )}
        </div>
        {dataForChart?.current?.visibility?.length ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.1vw",
                width: "100%",
                marginBottom: "0.5vw",
              }}
            >
              <InfoButton
                ai={false}
                std={false}
                err={false}
                war={t("uncertaintyData")}
                info={
                  false
                    ? tips[document.language].dati_incerti
                    : tips[document.language].dati_incerti_trigger
                }
                trigger={visibilityScore > WARNING_VISIBILITY}
              />
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.8vw",
                  fontWeight: "800",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                {t("uncertaintyData")}
                <span
                  style={{
                    color:
                      visibilityScore > WARNING_VISIBILITY
                        ? colors.riskUnsureBase
                        : colors.text,
                  }}
                >
                  {" - " + Math.round(visibilityScore) + "%"}
                </span>
              </p>
            </div>
            <DividerSmall />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.4vw",
                width: "100%",
                marginBottom: "0.5vw",
                marginTop: "0.5vw",
              }}
            >
              <img
                src={
                  showVisisbility
                    ? process.env.REACT_APP_RESOURCES_BUCKET + "toggleTrue.png"
                    : process.env.REACT_APP_RESOURCES_BUCKET + "toggleFalse.png"
                }
                onClick={() => setShowVisisbility(!showVisisbility)}
                style={{
                  width: "1.8vw",
                  cursor: "pointer",
                }}
              />
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.8vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                {t("show")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "0.4vw",
                width: "100%",
                marginBottom: "0.5vw",
              }}
            >
              {" "}
              <ButtonIcon
                icon={
                  process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmall.png"
                }
                iconH={
                  process.env.REACT_APP_RESOURCES_BUCKET +
                  "leftArrowSmallHW.png"
                }
                onClick={() => {
                  if (visibilityIndexRef.current < 0) {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[0].start;
                    setVisibilityIndex(0);
                  } else if (visibilityIndexRef.current > 0) {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[
                        visibilityIndexRef.current - 1
                      ].start;
                    setVisibilityIndex(visibilityIndexRef.current - 1);
                  } else {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[
                        dataForChart.current.visibility.length - 1
                      ].start;
                    setVisibilityIndex(
                      dataForChart.current.visibility.length - 1
                    );
                  }
                }}
                style={{ width: "1.875vw", height: "1.875vw" }}
              />
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.8vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: 0,
                }}
              >
                {/* {(findNextVisibility(
                        dataForChart.current?.visibility,
                        videoLoaded.currentTime
                      ).currentIntervalIndex == -1
                        ? "-"
                        : findNextVisibility(
                            dataForChart.current?.visibility,
                            videoLoaded.currentTime
                          ).currentIntervalIndex) +
                        " " +
                        t("of") +
                        " " +
                        (dataForChart?.current?.visibility
                          ? dataForChart?.current?.visibility?.length
                          : "0")} */}
                {(visibilityIndex == -1 ? "-" : visibilityIndex + 1) +
                  " " +
                  t("of") +
                  " " +
                  (dataForChart?.current?.visibility
                    ? dataForChart?.current?.visibility?.length
                    : "0")}
              </p>
              <ButtonIcon
                icon={
                  process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmall.png"
                }
                iconH={
                  process.env.REACT_APP_RESOURCES_BUCKET +
                  "leftArrowSmallHW.png"
                }
                onClick={() => {
                  if (visibilityIndexRef.current < 0) {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[0].start;
                    setVisibilityIndex(0);
                  } else if (
                    visibilityIndex <
                    dataForChart?.current?.visibility?.length - 1
                  ) {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[
                        visibilityIndexRef.current + 1
                      ].start;
                    setVisibilityIndex(visibilityIndexRef.current + 1);
                  } else {
                    videoLoaded.currentTime =
                      dataForChart.current.visibility[0].start;
                    setVisibilityIndex(0);
                  }
                }}
                style={{
                  width: "1.875vw",
                  height: "1.875vw",
                  transform: "rotate(180deg)",
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5vw",
          width: "70%",
          alignItems: "center",
        }}
      >
        <DividerSmall />
        <div
          style={{
            display: "flex",
            gap: "0.5vw",
            marginBottom: "0vw",
          }}
        >
          <ButtonIcon
            disabled={
              data.graph[selectedChart].type >= 1
                ? batchIndex >= batchLevel.length - 1
                : dataForChartHistory.current.length - historyIndex.current <= 1
            }
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "returnViolet.png"}
            iconH={
              process.env.REACT_APP_RESOURCES_BUCKET + "returnVioletHW.png"
            }
            iconDisabled={
              process.env.REACT_APP_RESOURCES_BUCKET + "returnVioletDB.png"
            }
            onClick={
              data.graph[selectedChart].type >= 1
                ? () => {
                    setBatchIndex(batchIndex + 1);
                  }
                : () => {
                    moveHistory(-1);
                  }
            }
            style={{ width: "1.875vw", height: "1.875vw" }}
          />
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "returnViolet.png"}
            iconH={
              process.env.REACT_APP_RESOURCES_BUCKET + "returnVioletHW.png"
            }
            iconDisabled={
              process.env.REACT_APP_RESOURCES_BUCKET + "returnVioletDB.png"
            }
            disabled={
              data.graph[selectedChart].type >= 1
                ? batchIndex == 0
                : historyIndex.current == 0
            }
            onClick={
              data.graph[selectedChart].type >= 1
                ? () => {
                    setBatchIndex(batchIndex - 1);
                  }
                : () => {
                    moveHistory(1);
                  }
            }
            style={{
              width: "1.875vw",
              height: "1.875vw",
              transform: "scaleX(-1)",
            }}
          />
        </div>
      </div>
    </>
  );
};

function IndexEditor({
  editingBatchIndex,
  setEditingBatchIndex,
  dataForChart,
  localIndex,
  wrongColors,
  updateBatchLevel,
  setEditingBatch,
  editingBatch,
  data,
  selectedChart,
}) {
  const { t } = useTranslation();
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Formatta con zeri iniziali se necessario
    const minutesFormatted = String(minutes).padStart(2, "0");
    const secondsFormatted = String(remainingSeconds).padStart(2, "0");

    return `${minutesFormatted}:${secondsFormatted}''`;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        border: "0.15vw solid " + colors.background,
        padding: "0.3vw",
        boxSizing: "border-box",
        borderRadius: "0.3vw",
      }}
    >
      <div
        style={{
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.82vw",
          fontWeight: "400",
          width: "100%",
          marginBottom: "0.2vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ButtonIcon
          icon={process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmall.png"}
          iconH={
            process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmallHW.png"
          }
          onClick={() => {
            setEditingBatchIndex(editingBatchIndex - 1);
          }}
          style={{
            width: "1.4vw",
            height: "1.4vw",
            visibility: editingBatchIndex == 0 ? "hidden" : "",
          }}
        />
        {formatTime(editingBatchIndex * 10)}
        {"-"}
        {formatTime((editingBatchIndex + 1) * 10)}
        <ButtonIcon
          icon={process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmall.png"}
          iconH={
            process.env.REACT_APP_RESOURCES_BUCKET + "leftArrowSmallHW.png"
          }
          onClick={() => {
            setEditingBatchIndex(editingBatchIndex + 1);
          }}
          style={{
            width: "1.4vw",
            height: "1.4vw",
            transform: "rotate(180deg)",
            visibility:
              editingBatchIndex ==
              dataForChart?.current?.errorsIndex?.length - 1
                ? "hidden"
                : "",
          }}
        />
      </div>
      <DividerSmall />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.3vw",
          marginTop: "0.2vw",
          marginBottom: "0.2vw",
        }}
      >
        {data.graph[selectedChart].orderIndex.map((key, id) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "10vw",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "1vw",
                  height: "1vw",
                  background: setAlpha(indexColors[id], 0.2),
                  borderRadius: "1vw",
                  boxSizing: "border-box",
                  border: "0.18vw solid " + indexColors[id],
                }}
              ></div>
              <div
                style={{
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.82vw",
                  fontWeight: "400",
                  width: "5vw",
                }}
              >
                {t(key)}
              </div>

              <TextBox
                borderColorFixed={
                  dataForChart?.current?.errorsIndex[editingBatchIndex][key] !=
                    dataForChart?.current?.errorsIndexOriginal[
                      editingBatchIndex
                    ][key] && colors.riskUnsure
                }
                key={key}
                focusColor={colors.riskUnsure}
                // width={"5vw"}
                style={{
                  width: "3.1vw",
                  minWidth: "3.1vw",
                  height: "1.4vw",
                }}
                onFocus={() => {}}
                ai={false}
                edited={false}
                value={Math.round(
                  dataForChart?.current?.errorsIndex[editingBatchIndex][key]
                )}
                onChange={(valueIn) => {
                  let value = parseInt(valueIn == "" ? 0 : valueIn);

                  // Controllo se non supera 100
                  let totalError = data.graph[selectedChart].orderIndex.reduce(
                    (acc, chiave) => {
                      if (chiave == key) return acc + value;
                      return (
                        acc +
                        (dataForChart.current.errorsIndex[editingBatchIndex][
                          chiave
                        ] || 0)
                      );
                    },
                    0
                  );
                  if (totalError <= 100) {
                    dataForChart.current.errorsIndex[editingBatchIndex][
                      data.graph[selectedChart].indexNoError
                    ] = 100 - totalError;
                    dataForChart.current.errorsIndex[editingBatchIndex][key] =
                      parseInt(value);
                    let checklistValue = data.graph[
                      selectedChart
                    ].index2checklist.reduce((acc, chiave) => {
                      return (
                        acc +
                        (dataForChart.current.errorsIndex[editingBatchIndex][
                          chiave
                        ] || 0)
                      );
                    }, 0);

                    updateBatchLevel(
                      parseInt(checklistValue),
                      editingBatchIndex
                    );
                  }
                }}
                locked={false}
                err={false}
                unit={"%"}
              />
            </div>
          );
        })}
      </div>
      <DividerSmall />
      <ButtonTextIcon
        label={"Termina modifiche"}
        color={"white"}
        backgroundH={setAlpha(colors.riskUnsure, 0.8)}
        background={colors.riskUnsure}
        iconHeight={"1.7vw"}
        onClick={() => {
          setEditingBatch(!editingBatch);
        }}
        style={{
          fontSize: "0.8vw",
          boxShadow: "0px 0px 0px 0px white",
          height: "1.875vw",
          width: "100%",
          minWidth: "6.25vw",
          borderRadius: "0.3vw",
          paddingLeft: "0.15vw",
          marginTop: "0.3vw",
        }}
      />
    </div>
  );
}

export default ChartSideControls;
