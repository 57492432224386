import { useState } from "react";
import { colors } from "../../../config/style";
import { useTranslation } from "react-i18next";

const NameColumn = ({ task, header, result, onClick, img, id }) => {
  const [hover, setHover] = useState(false);
  const [imgSrc, setImgSrc] = useState(
    img && result
      ? img
      : process.env.REACT_APP_RESOURCES_BUCKET + "TaskPlaceholder.png"
  );

  const handleError = () => {
    setImgSrc(process.env.REACT_APP_RESOURCES_BUCKET + "TaskPlaceholder.png");
  };
  const { t } = useTranslation();

  return [
    <div
      key={task?.id + task?.inspection + id}
      onMouseEnter={() => result && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => result && onClick()}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1.2,
        cursor: result ? "pointer" : "not-allowed",
      }}
    >
      {header ? (
        <>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Task.png"}
            style={{
              width: "1.4vw",
              height: "1.4vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{t("mainStructureTaskList")}</b>
          </p>
        </>
      ) : (
        <>
          <div
            style={{
              width: "3.1vw",
              height: "3.1vw",
              borderRadius: "0.2vw",
              marginRight: "0.3vw",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={
                imgSrc
                // result
                //   ? imgSrc
                //   : process.env.REACT_APP_RESOURCES_BUCKET +
                //     "TaskPlaceholder.png"
              }
              style={{
                height: "3.1vw",
              }}
              onError={handleError}
            />
          </div>
          <p
            style={{
              width: "6vw",
              color: hover ? colors.main : colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              textDecoration: result && "underline",
              cursor: result && "pointer",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
            }}
          >
            {task.name}
          </p>
        </>
      )}
    </div>,
  ];
};

export default NameColumn;
