import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyScreen from "../components/EmptyScreen/EmptyScreen";
import MainJobScreen from "../components/MainScreen/MainJobScreen/MainJobScreen";
import MainShiftScreen from "../components/MainScreen/MainShiftScreen/MainShiftScreen";
import MainWsScreen from "../components/MainScreen/MainWsScreen/MainWsScreen";
import { TreeStructure } from "../components/Tree/TreeStructure";
import { colors, layout } from "../config/style";
import { useStateValue } from "../stores/services/StateProvider";

export const MainStructure = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [data, setData] = useState(null); // {type:"info"/"job"/"ws", id:[], dep:{}}
  const [screen, setScreen] = useState(null);
  const [departmentDetail, setDepartmentDetail] = useState({});
  const [currentDepartment, setCurrentDepartment] = useState({});

  const departmentDetailRef = useRef();
  const currentDepartmentRef = useRef();
  const dataPreviusRef = useRef();
  const deleted = useRef(false);

  const { t } = useTranslation();

  departmentDetailRef.current = departmentDetail;
  currentDepartmentRef.current = currentDepartment;

  useEffect(() => {}, [screen]);

  useEffect(() => {
    setScreen(<EmptyScreen />);
  }, [state.current_location]);

  useEffect(() => {
    if (data?.id && data?.id != dataPreviusRef?.current?.id) {
    } else {
      if (data?.type == "shift") {
        setScreen(
          <MainShiftScreen
            key={data.id}
            id={data.id}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            setData={(x) => setData(x)}
            deleted={deleted}
          />
        );
      } else if (data?.type == "job") {
        setScreen(
          <MainJobScreen
            key={data.id}
            id={data.id}
            setData={(x) => setData(x)}
            data={data}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            location={state.current_location}
            deleted={deleted}
          />
        );
      } else if (data?.type == "ws") {
        setScreen(
          // <MainJobWsScreen
          //   key={data.id}
          //   id={data.id}
          //   setData={(x) => setData(x)}
          //   data={data}
          //   departmentDetail={departmentDetailRef.current}
          //   setDepartmentDetail={(x) => setDepartmentDetail(x)}
          //   currentDepartment={currentDepartmentRef.current}
          //   location={state.current_location}
          // />
          <MainWsScreen
            key={data.id}
            data={data}
            departmentDetail={departmentDetailRef.current}
            setDepartmentDetail={(x) => setDepartmentDetail(x)}
            currentDepartment={currentDepartmentRef.current}
            setData={(x) => setData(x)}
            location={state.current_location}
            deleted={deleted}
          />
        );
      } else if (data?.type == "delete") {
        setScreen(<EmptyScreen data={data} />);
      } else {
        setScreen(<EmptyScreen />);
      }
    }
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "20.8vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // height: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              width: "100%",
            }}
          >
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "1.6vw",
                fontWeight: "700",
                marginLeft: "2.4vw",
                marginTop: layout.paddingTop,
                boxSizing: "border-box",
              }}
            >
              {t("structure")}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "90%",
            }}
          >
            {state.current_location.id && (
              <TreeStructure
                width={"18vw"}
                setDepartmentDetail={(x) => setDepartmentDetail(x)}
                departmentDetail={departmentDetailRef.current}
                departmentDetaiState={departmentDetail}
                setCurrentDepartment={(x) => setCurrentDepartment(x)}
                setData={(x) => {
                  dataPreviusRef.current = x;
                  setData(x);
                }}
                deleted={deleted}
              />
            )}
          </div>
        </div>
        <div
          style={{
            width: "67.2vw",
            display: "flex",
            flexDirection: "column",
            gap: "0.5vw",
          }}
        >
          {screen}
        </div>
      </div>
    </>
  );
};
