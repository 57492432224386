import * as React from "react";

import { cn } from "../../utils/utils";

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "flex  w-full rounded-md border border-input bg-background px-3 py-2 text-base ring-offset-background placeholder:text-[#ADACBF] focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50 resize-none",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
