const URL = process.env.REACT_APP_API_URL;

/*****
 ***** GET
 *****/

async function getResults(result, jwt = null) {
  // console.log("[API] getResults");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "results/" + result, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getInspections(location, jwt = null) {
  // console.log("[API] getInspections");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "inspections/all/" + location,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getInspection(id, jwt = null) {
  // console.log("[API] getInspection");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "inspections/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getCompanies(jwt = null) {
  // console.log("[API] getCompanies");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "companies/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocation(id, jwt = null) {
  // console.log("[API] getLocation");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "locations/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocations(jwt = null) {
  // console.log("[API] getLocations");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "locations/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getVideoByStatus(status, jwt = null) {
  // console.log("[API] getLocations");
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify({ status: status }),
    };

    const response = await fetch(
      URL + "inspections/data/status",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getCustomers(jwt = null) {
  // console.log("[API] getLocations");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "customers/all", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLocationsByCompany(company, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "companies/" + company + "/locations/all",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getDepartment(location, department, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "locations/" + location + "/departments/" + department,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getTask(task, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "tasks/" + task, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getTaskPerStation(
  location,
  department,
  line,
  station,
  jwt = null
) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/tasks",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

/*****
 ***** POST
 *****/

async function newCompany(company, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(company),
    };

    const response = await fetch(URL + "companies/add", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newLocation(company, location, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(location),
    };

    const response = await fetch(
      URL + "companies/" + company + "/locations/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newDepartment(location, department, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(department),
    };

    const response = await fetch(
      URL + "locations/" + location + "/departments/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateDepartment(
  location,
  departmentId,
  updatedData,
  jwt = null
) {
  try {
    const requestObject = {
      method: "POST", // or "PUT" based on your API specification
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(updatedData), // Updated department data
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        departmentId +
        "/update", // Assuming this is the correct endpoint
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Handle the error response
      throw new Error("Error in request");
    }
  } catch (error) {
    // Handle the error here
    console.error(error);
    throw error; // Re-throw the error if needed
  }
}

async function deleteDepartment(locationId, departmentId, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + `locations/${locationId}/departments/${departmentId}`,
      requestObject
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Error while trying to delete the department");
    }
  } catch (error) {
    // Handle the error
    console.error("Failed to delete department:", error);
    throw error;
  }
}

async function newWorkshift(location, department, workshift, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(workshift),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/turns/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function editWorkshift(wsId, workshift, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(workshift),
    };

    const response = await fetch(
      URL + "workshifts/" + wsId + "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteWorkshift(wsId, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      // body: JSON.stringify(workshift),
    };

    const response = await fetch(URL + "workshifts/" + wsId, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newJob(location, department, job, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(job),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateJob(location, department, job, id, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(job),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        id +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteJob(location, department, id, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        id,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newJobTask(location, department, job, jobTask, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(jobTask),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/tasks/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteJobTask(location, department, job, jobTask, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/jobtasks/" +
        jobTask,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateJobTask(
  location,
  department,
  job,
  jobTask,
  durata,
  jwt = null
) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(durata),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/jobs/" +
        job +
        "/jobtasks/" +
        jobTask +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newLine(location, department, line, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(line),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateLineCar(location, department,line, lineUpdate, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(lineUpdate),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/"+line+"/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getLines(location, department, jwt = null) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        // "User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "locations/" + location + "/departments/" + department + "/lines",
      requestObject
    );

    https: if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateLine(locationId, departmentId, oldLineId, newLineId, jwt) {
  try {
    const requestObject = {
      method: "POST", // Use the appropriate HTTP method (POST or PUT)
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify({ newLineId }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        locationId +
        "/departments/" +
        departmentId +
        "/lines/" +
        oldLineId +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      throw new Error("Error in request");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function deleteLine(location, department, lineId, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + `locations/${location}/departments/${department}/lines/${lineId}`,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Handle response error
      throw new Error("Errore nella richiesta di eliminazione");
    }
  } catch (error) {
    // Handle any errors
    console.error("Errore:", error);
    throw error; // Re-throw the error for further handling if needed
  }
}

async function newStation(location, department, line, station, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(station),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateStation(
  location,
  department,
  line,
  station,
  name,
  jwt = null
) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(name),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteStation(location, department, line, station, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function newTask(location, department, line, station, task, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(task),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/tasks/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateTaskOcra(task, ocra, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(ocra),
    };

    const response = await fetch(
      URL + "tasks/" + task + "/ocra/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateTaskNiosh(task, niosh, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(niosh),
    };

    const response = await fetch(
      URL + "tasks/" + task + "/niosh/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function deleteTask(id, jwt = null) {
  try {
    const requestObject = {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(URL + "tasks/" + id, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

export {
  getCompanies,
  getResults,
  getInspections,
  getInspection,
  getLocation,
  getLocations,
  getLocationsByCompany,
  getDepartment,
  getTask,
  deleteTask,
  getTaskPerStation,
  newCompany,
  newLocation,
  newLine,
  updateLine,
  updateLineCar,
  deleteLine,
  newStation,
  updateStation,
  deleteStation,
  updateJobTask,
  newDepartment,
  updateDepartment,
  deleteDepartment,
  newWorkshift,
  editWorkshift,
  deleteWorkshift,
  newJob,
  updateJob,
  deleteJob,
  newJobTask,
  deleteJobTask,
  newTask,
  getVideoByStatus,
  updateTaskNiosh,
  updateTaskOcra,
  getCustomers,
  getLines,
};
