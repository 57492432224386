import React from "react";

const CopyIcon = ({ size, fill }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4783 6C15.1732 6 13.3043 7.86889 13.3043 10.1739V18.5217C13.3043 20.8268 15.1732 22.6957 17.4783 22.6957H25.8261C28.1311 22.6957 30 20.8268 30 18.5217V10.1739C30 7.86889 28.1311 6 25.8261 6H17.4783Z"
          fill={fill}
        />
        <path
          d="M10.1739 13.3043H11.2174V18.5217C11.2174 21.9803 14.0207 24.7826 17.4783 24.7826H22.6957V25.8261C22.6957 28.1311 20.8268 30 18.5217 30H10.1739C7.86889 30 6 28.1311 6 25.8261V17.4783C6 16.1576 6.61345 14.9796 7.57082 14.2154C8.28465 13.6447 9.18954 13.3043 10.1739 13.3043Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default CopyIcon;
