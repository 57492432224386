import { useClerk } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { DividerSmall } from "../../AnalysisSelector/AnalysisSelector";
import { getToken } from "../../../userManagement/utilities";
import { colors } from "../../../config/style";
import { NONE } from "../../../config/generalConst";
import {
  NIOSH_ID,
  OCRA_ID,
  protocols,
} from "../../../config/availableProtocols";
import { CLEAR_DATA, LOAD_DATA } from "../../../stores/actions/DataAction";
import { DATA_A } from "../../../stores/actions/TypeActions";
import {
  fetchDataLeftRight,
  getInspectionTaskData,
  runVideoAgain,
} from "../../../remote/tasks";
import { getResults } from "../../../remote/structure";
import AnalysisContainer from "../../AnalysisContainer/AnalysisContainer";
import Spinner from "../../Spinner/Spinner";
import { decompressObjectLZ } from "../../../remote/compressionFunctions";
import { useStateValue } from "../../../stores/services/StateProvider";
import NameColumn from "../../Columns/NameColumn/NameColumn";
import StatusColumn from "../../Columns/StatusColumn/StatusColumn";
import LineColumn from "../../Columns/LineColumn/LineColumn";
import DurationColumn from "../../Columns/DurationColumn/DurationColumn";
import WorkstationColumn from "../../Columns/WorkstationColumn/WorkstationColumn";
import ScoreColumn from "../../Columns/ScoreColumn/ScoreColumn";
import ProtocolColumn from "../../Columns/ProtocolColumn/ProtocolColumn";
import ControlColumn from "../../Columns/ControlColumn/ControlColumn";
import { deepEqual } from "../../../utils/generalFunctions";

const TaskTable = ({
  task,
  taskIndex,
  selected,
  setSelected,
  duration,
  columns,
  errPerc,
  deleteBtn,
  excludeControl,
  onChangeTime,
  onSave,
  loaded,
  setLoaded,
  unlinkIcon,
  dividerMargin,
  externalSelectedProtocol,
  setGroupStatus,
}) => {
  const [state, dispatch] = useStateValue();
  const [result, setResult] = useState();
  const [localTask, setLocalTask] = useState();
  const [status, setStatus] = useState({
    err: 2,
    cover: "",
    open: false,
  });

  const [selectedTask, setSelectedTask] = useState();
  const [availableProtocols, setAvailableProtocols] = useState();
  const [protocol, setProtocol] = useState(0);

  const { session, user } = useClerk();

  useEffect(() => {
    let localAvailableProtocols = [];
    let indexProtocol = 0;
    protocols.forEach((protocol, id) => {
      if (
        task.hasOwnProperty(protocol.ref) ||
        (protocol.ref === protocols[OCRA_ID].ref &&
          (task?.last_result_obj || task?.last_result))
      ) {
        localAvailableProtocols.push(protocol);
      }
    });
    setAvailableProtocols(localAvailableProtocols);
    if (externalSelectedProtocol) {
      indexProtocol = localAvailableProtocols?.findIndex(
        (oggetto) => oggetto.ref === externalSelectedProtocol.ref
      );
    }
    setProtocol(indexProtocol);
  }, [externalSelectedProtocol]);

  useEffect(() => {
    if (setGroupStatus) {
      if (status.open) setGroupStatus(true);
      else setGroupStatus(false);
    }
  }, [status]);


  function getOcraValid(err, state, user) {
    let ocraValid = false;
    if (
      state.current_customer.expertCompany === false ||
      !state.current_customer.expertCompany ||
      (state.current_customer.expertCompany === true &&
        (user?.publicMetadata?.expertise == "" ||
          !user?.publicMetadata?.expertise))
    ) {
      ocraValid = err > 5;
    } else if (
      user?.publicMetadata?.expertise == "expert" ||
      user?.publicMetadata?.expertise == "reviewer" ||
      user?.publicMetadata?.expertise == "dataset"
    ) {
      ocraValid = err > 3;
    }
    return ocraValid;
  }

  const loadResult = async (task) => {
    let resultTemp;
    let statusTemp = { ...status };
    let errorTemp;
    let localAvailableProtocols = [];
    // Inizializza protocollo
    protocols.forEach((protocol, id) => {
      if (
        task.hasOwnProperty(protocol.ref) ||
        (protocol.ref === protocols[OCRA_ID].ref &&
          (task?.last_result_obj || task?.last_result))
      ) {
        localAvailableProtocols.push(protocol);
      }
    });
    if (localAvailableProtocols.length == 0) {
      localAvailableProtocols.push(protocols[OCRA_ID]);
    }
    setAvailableProtocols(localAvailableProtocols);
    let nioshValid = false;
    // NIOSH
    if (
      localAvailableProtocols.some(
        (protocol) => protocol.ref === protocols[NIOSH_ID].ref
      )
    ) {
      nioshValid = true;
      resultTemp = { ...resultTemp, taskDetail: task };
      statusTemp = { ...statusTemp, open: true };
    }

    // OCRA già dati: Se c'è già l'oggetto scaricato non andare a scaricare il risultato
    if (
      task?.last_result_obj &&
      task.last_result_obj.ai &&
      task.last_result_obj.ai.LEFT &&
      task.last_result_obj.ai.RIGHT &&
      task.last_result_obj.ai.HANDS
    ) {
      let res = task.last_result_obj;
      let err = getStatus(res.ai.LEFT, res.ai.RIGHT, res.ai.HANDS, task.scores);
      let ocraValid = getOcraValid(err, state, user);

      // let ocraValid =
      //   (err == 4 || err == 5) && !user?.publicMetadata?.expertise
      //     ? false
      //     : true;
      console.log("ocraValidocraValid", ocraValid);
      // setStatus({
      //   err: err,
      //   cover: getImage(res.ai.LEFT.id, err),
      //   open: ocraValid || nioshValid,
      // });
      statusTemp = {
        ...statusTemp,
        err: err,
        cover: getImage(res.ai.LEFT.id, err),
        open: ocraValid || statusTemp.open,
      };

      let resAdapted = {
        ...res,
        ai: {
          LEFT: res.ai.LEFT.id,
          RIGHT: res.ai.RIGHT.id,
          HANDS: res.ai.HANDS.id,
          edit: decompressObjectLZ(res.ai.edit),
        },
      };
      resultTemp = {
        ...resultTemp,
        ...resAdapted,
        taskDetail: task,
        ocraValid: ocraValid,
      };
      // setResult({ ...resAdapted, taskDetail: task });
    } //OCRA da scaricare: In caso non c'è l'oggetto scaricalo insieme con i dati del task per ciascun video (usato per la struct)
    else if (task?.last_result) {
      let res = task.last_result.id
        ? task.last_result
        : await getResults(task.last_result, await getToken(session));
      let resAdapted = {
        ...res,
        ai: {
          LEFT: res.ai.LEFT,
          RIGHT: res.ai.RIGHT,
          HANDS: res.ai.HANDS,
          edit: decompressObjectLZ(res.ai.edit),
        },
      };

      if (res?.ai?.LEFT) {
        let left = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.LEFT.id ? res.ai.LEFT.id : res.ai.LEFT,
          await getToken(session)
        );
        let right = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.RIGHT.id ? res.ai.RIGHT.id : res.ai.RIGHT,
          await getToken(session)
        );
        let hands = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.HANDS.id ? res.ai.HANDS.id : res.ai.HANDS,
          await getToken(session)
        );
        let err = getStatus(left, right, hands, task.scores);

        let ocraValid = getOcraValid(err, state, user);

        // let ocraValid =
        //   (err == 4 || err == 5) &&
        //   (!user?.publicMetadata?.expertise ||
        //     state.current_customer.expertCompany === false)
        //     ? false
        //     : true;

        statusTemp = {
          ...statusTemp,
          err: err,
          cover: getImage(res.ai.LEFT.id ? res.ai.LEFT.id : res.ai.LEFT, err),
          open: ocraValid || statusTemp.open,
        };

        resultTemp = {
          ...resultTemp,
          ...resAdapted,
          taskDetail: task,
          ocraValid: ocraValid,
        };
      }
    }
    setStatus(statusTemp);
    if (resultTemp) {
      setResult(resultTemp);
    }
    setLoaded();
  };

  if (!deepEqual(task, localTask)) {
    // TASK DIVERSO DAL LOCALE, AGGIORNO RISULTATO
    setLocalTask(task);
    loadResult(task);
  }

  function getImage(id, error) {
    let imgAdress = process.env.REACT_APP_S3_BUCKET + id + "_obscured.jpg";
    if (error < 4) {
      return "";
    } else return imgAdress;
  }

  function getStatus(left, right, hands, scores) {
    if (
      left.status == "ERROR_OCRA_ANALYSIS" ||
      right.status == "ERROR_OCRA_ANALYSIS" ||
      hands.status == "ERROR_OCRA_ANALYSIS" ||
      left?.status?.includes("ERROR") ||
      right?.status?.includes("ERROR") ||
      hands?.status?.includes("ERROR")
    ) {
      return 1;
    }
    if (
      left.status == "PROCESSING" ||
      right.status == "PROCESSING" ||
      hands.status == "PROCESSING"
    ) {
      return 3;
    }
    if (
      left.status == "PENDING" ||
      right.status == "PENDING" ||
      hands.status == "PENDING"
    ) {
      return 2;
    }
    if (
      left.status == "QUEUE" ||
      right.status == "QUEUE" ||
      hands.status == "QUEUE"
    ) {
      return -1;
    }
    if (
      left.status == "REVIEWED" ||
      right.status == "REVIEWED" ||
      hands.status == "REVIEWED"
    ) {
      if (scores?.dx >= 0 && scores?.sx >= 0) return 7;
      return 6;
    }
    if (
      left.status == "COMPLETED" &&
      right.status == "COMPLETED" &&
      hands.status == "COMPLETED"
    ) {
      if (scores?.dx >= 0 && scores?.sx >= 0) return 5;
      return 4;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    if (selectedTask) {
      if (selectedTask?.ai?.LEFT) {
        fetchDataLeftRight(
          selectedTask.ai.LEFT.id
            ? selectedTask.ai.LEFT.id
            : selectedTask.ai.LEFT,
          selectedTask.ai.RIGHT.id
            ? selectedTask.ai.RIGHT.id
            : selectedTask.ai.RIGHT,
          selectedTask.ai.HANDS.id
            ? selectedTask.ai.HANDS.id
            : selectedTask.ai.HANDS,
          selectedTask,
          (res) => {
            dispatch({ type: DATA_A, subtype: LOAD_DATA, action: res });
          },
          user?.publicMetadata?.expertise == "reviewer" ||
            user?.publicMetadata?.expertise == "dataset" ||
            state?.current_customer?.privacyOverride === true
        );
      }
    }
  }, [selectedTask]);

  const handleRerun = async (dir, clbk) => {
    console.log("Result: ", result);

    let p = [];

    let t = await getToken(session);

    if (dir.hands) {
      p.push(runVideoAgain(result.ai.HANDS + ".mp4", t));
    }

    if (dir.left) {
      p.push(runVideoAgain(result.ai.LEFT + ".mp4", t));
    }

    if (dir.right) {
      p.push(runVideoAgain(result.ai.RIGHT + ".mp4", t));
    }

    let res = await Promise.all(p);
    clbk();
  };

  console.log("RESULT: ", result);

  // CASO CLICK SU TASK
  if (selectedTask) {
    // CASO PRESENZA DI OCRA CONTROLLO DATI CARICATI
    if (selectedTask?.ocraValid) {
      // DATI NON CARICATI CORRETTAMENTE
      if (
        state.current_data.config == NONE ||
        state.current_data == NONE ||
        selectedTask?.checklist?.tempo_ciclo_osservato == -1
      )
        return (
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              backgroundColor: colors.mainBackground,
              zIndex: 9999,
            }}
          >
            <Spinner />
          </div>
        );
    }
    // TUTTO OK CARICA ANALISI
    return (
      <AnalysisContainer
        selectedTask={selectedTask}
        // onSave={() => onSave()}
        onClose={() => {
          dispatch({ type: DATA_A, subtype: CLEAR_DATA });
          onSave && onSave();
          setTimeout(() => {
            setSelectedTask(null);
          }, 2000);
        }}
      />
    );
  } else if (loaded && (availableProtocols || columns.length == 1))
    return (
      <>
        <div
          key={taskIndex}
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "0.2vw 0",
            height: "3.8vw",
            width: "100%",
            paddingLeft: excludeControl ? "0.8vw" : "1.25vw",
            paddingRight: excludeControl ? "0.8vw" : "0.8vw",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          {deleteBtn && (
            <img
              onClick={() => setSelected()}
              src={
                selected
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "checkboxhw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "checkbox.png"
              }
              style={{
                width: "1.2vw",
                height: "1.2vw",
                marginRight: "0.5vw",
                cursor: "pointer",
                filter:
                  selected &&
                  "drop-shadow(0px 0.4vw 0.8vw rgba(113, 109, 242, 0.30))",
              }}
            />
          )}
          {columns.map((col, id) => {
            if (col == "name")
              return (
                <NameColumn
                  id={taskIndex}
                  task={task}
                  result={result && status.open && !excludeControl}
                  onClick={() => setSelectedTask(result)}
                  img={status.cover}
                />
              );
            if (col == "state")
              return (
                <StatusColumn
                  protocol={availableProtocols[protocol]}
                  result={result}
                  data={deleteBtn && task.inspection_date}
                  err={status.err}
                  exp={status.open ? user?.publicMetadata?.expertise : false}
                />
              );
            if (col == "protocol")
              return (
                <ProtocolColumn
                  availableProtocols={availableProtocols}
                  protocol={protocol}
                  setProtocol={setProtocol}
                />
              );
            if (col == "ws")
              return <WorkstationColumn id={taskIndex} task={task} />;
            if (col == "line") return <LineColumn id={taskIndex} task={task} />;
            if (col == "duration")
              return (
                <DurationColumn
                  id={taskIndex}
                  task={task}
                  jobDuration={duration ? duration : 0}
                  errPerc={errPerc}
                  onChange={onChangeTime}
                />
              );
            if (col == "score")
              return (
                <ScoreColumn
                  id={taskIndex}
                  result={result}
                  open={status.open}
                  protocol={availableProtocols && availableProtocols[protocol]}
                />
              );
          })}

          {!excludeControl && (
            <ControlColumn
              id={taskIndex}
              task={task}
              result={result && status.open}
              onClick={() => setSelectedTask(result)}
              onRerun={handleRerun}
              deleteBtn={deleteBtn}
              unlinkIcon={unlinkIcon}
            />
          )}
        </div>
        <DividerSmall
          margin={
            dividerMargin
              ? dividerMargin
              : excludeControl
              ? "0vw 0.8vw 0vw 0.8vw"
              : "0vw 0.8vw 0vw 1.25vw"
          }
        />
      </>
    );
};
export default TaskTable;
