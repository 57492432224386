import { NONE } from "../../config/generalConst";
import {
  CLEAR_ALL,
  CLEAR_CUSTUMER,
  CLEAR_DATA,
  CLEAR_INSPECTIONS,
  CLEAR_LOCATION,
  LOAD_CUSTUMER,
  LOAD_DATA,
  LOAD_INSPECTIONS,
  LOAD_LOCATION,
  DELETE_DEPARTMENT,
  RESTORE_DEPARTMENT,
} from "../actions/DataAction";

const initialState = {};

const DataReducer = (state = initialState, action) => {
  switch (action.subtype) {
    case LOAD_DATA:
      return { ...state, current_data: action.action };
    case CLEAR_DATA:
      return { ...state, current_data: NONE };
    case LOAD_INSPECTIONS:
      return { ...state, current_inspections: action.action };
    case CLEAR_INSPECTIONS:
      return { ...state, current_inspections: NONE };
    case LOAD_LOCATION:
      return { ...state, current_location: action.action };
    case CLEAR_LOCATION:
      return { ...state, current_location: NONE };
    case LOAD_CUSTUMER:
      return { ...state, current_customer: action.action };
    case CLEAR_CUSTUMER:
      return { ...state, current_customer: NONE };

    case CLEAR_ALL:
      return {
        ...state,
        current_location: NONE,
        current_inspections: NONE,
        current_data: NONE,
        current_customer: NONE,
      };

    case DELETE_DEPARTMENT:
      const { departmentId } = action.action;
      const updatedDepartments = { ...state.current_location.departments };
      delete updatedDepartments[departmentId];

      return {
        ...state,
        current_location: {
          ...state.current_location,
          departments: updatedDepartments,
        },
      };

    case RESTORE_DEPARTMENT:
      const { department } = action.action;
      return {
        ...state,
        current_location: {
          ...state.current_location,
          departments: {
            ...state.current_location.departments,
            [department.id]: department,
          },
        },
      };

    default:
      return state;
  }
};

export default DataReducer;
