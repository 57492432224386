import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../../buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";
import { colors } from "../../../config/style";
import { PopupCreate } from "../../Popup/Popup";
import { PopupProcessVideos } from "../../Popup/PopupContent";
import { useClerk } from "@clerk/clerk-react";

const ControlColumn = ({
  task,
  header,
  result,
  onClick,
  selected,
  onRerun,
  deleteBtn,
  deleteSelected,
  unlinkIcon,
}) => {
  const { t } = useTranslation();

  const popupRef = useRef();
  const { session, user } = useClerk();

  return (
    <>
      {header ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            flex: 0.4,
            width: "1vw",
          }}
        >
          <div>
            <ButtonTextIcon
              label={
                unlinkIcon
                  ? t("mainStructureRemoveTasks")
                  : t("mainStructureRemoveSelection")
              }
              color={colors.negative}
              backgroundH={colors.negativePale}
              background={"transparent"}
              icon={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkNoHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHwNobg.png"
              }
              iconH={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkNoHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHwNobg.png"
              }
              onClick={() => {
                deleteSelected();
              }}
              style={{
                fontSize: "0.8vw",
                boxShadow: "0px 0px 0px 0px white",
                height: "2vw",
                width: "9.5vw",
                visibility:
                  selected.every((element) => element === false) && "hidden",
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            flex: 0.4,
          }}
        >
          {result && (
            <ButtonIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "Magnifier.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "MagnifierHw.png"}
              onClick={() => {
                onClick();
              }}
              style={{
                width: "1.875vw",
                // visibility: errors < 4 ? "hidden" : "visible",
              }}
            />
          )}

          {deleteBtn && (
            <ButtonIcon
              icon={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "Unlink.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"
              }
              iconH={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"
              }
              onClick={() => {
                deleteBtn([task?.idLink ? task?.idLink : task.id]);
              }}
              style={{ width: "1.875vw" }}
            />
          )}

          {result && user?.publicMetadata?.expertise == "reviewer" && (
            <>
              <ButtonIcon
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Refresh.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Refreshhw.png"}
                onClick={() => {
                  popupRef.current.open();
                  //deleteBtn([task?.idLink ? task?.idLink : task.id]);
                }}
                style={{ width: "1.875vw" }}
              />
              <PopupCreate
                ref={popupRef}
                title={t("logoutConfirmTitle")}
                children={
                  <PopupProcessVideos
                    onProcess={(dirs) =>
                      onRerun(dirs, () => popupRef.current.close())
                    }
                  />
                }
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ControlColumn;
