import styled from "styled-components";

export const ButtonTextIconWrapper = styled.div`
  height: 2.8vw;
  border-radius: 0.31vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Atkinson Hyperlegible;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 2.8vw;
  text-edge: cap;
  text-align: center;
  box-sizing: border-box;
`;

export const IconButtonWrapper = styled.img`
  height: 70%;
`;
