import { useEffect, useRef, useState } from "react";
import Spinner from "../../Spinner/Spinner";
import { colors } from "../../../config/style";
import TaskTable from "../../Tables/TaskTable/TaskTable";
import NameColumn from "../../Columns/NameColumn/NameColumn";
import StatusColumn from "../../Columns/StatusColumn/StatusColumn";
import DurationColumn from "../../Columns/DurationColumn/DurationColumn";
import WorkstationColumn from "../../Columns/WorkstationColumn/WorkstationColumn";
import ScoreColumn from "../../Columns/ScoreColumn/ScoreColumn";
import ProtocolColumn from "../../Columns/ProtocolColumn/ProtocolColumn";
import ControlColumn from "../../Columns/ControlColumn/ControlColumn";

const JobTable = ({
  refresh,
  tasks,
  duration,
  jobIndex,
  header,
  width,
  columns,
  empty,
  deleteBtn,
  onChangeTime,
  date,
  onSave,
  setLoadedJobs,
  unlinkIcon,
  loadedJobs,
  externalSelectedProtocol,
  icon,
}) => {
  const [selected, setSelected] = useState(new Array(tasks.length).fill(false));
  const [loaded, setLoaded] = useState(0);
  const [groupStatus, setGroupStatus] = useState({});
  console.log("taskstaskstasks", tasks);

  const loadedRef = useRef();
  const groupStatusRef = useRef();

  loadedRef.current = loaded;
  groupStatusRef.current = groupStatus;

  let columnsNumber = columns.length + 1;
  let columnWidth = Math.ceil(width / columnsNumber);
  let errPerc =
    duration != -1
      ? tasks.reduce((acc, task) => acc + task.durata, 0) != 100 &&
        tasks.length > 0
      : false;

  const toggleSelected = (index) => {
    setSelected((prevSelected) => {
      // Copia l'array corrente per non mutare lo stato direttamente
      const newSelected = [...prevSelected];

      // Inverte il valore all'indice specificato
      newSelected[index] = !newSelected[index];

      // Ritorna il nuovo array
      return newSelected;
    });
  };
  useEffect(() => {
    setSelected(new Array(tasks.length).fill(false));
  }, [tasks]);

  useEffect(() => {
    if (loadedRef.current == tasks.length && setLoadedJobs) setLoadedJobs();
  }, [loaded]);

  const getSelection = () => {
    if (selected.every((element) => element === true) && tasks.length > 0)
      return process.env.REACT_APP_RESOURCES_BUCKET + "checkboxhw.png";
    if (selected.every((element) => element === false) || tasks.length == 0)
      return process.env.REACT_APP_RESOURCES_BUCKET + "checkbox.png";
    return process.env.REACT_APP_RESOURCES_BUCKET + "checkboxPartial.png";
  };

  const deleteSelected = () => {
    const filteredIds = tasks
      .filter((obj, index) => selected[index])
      .map((task) => (task?.idLink ? task?.idLink : task.id));
    loadedRef.current = loadedRef.current - filteredIds.length;
    setLoaded(loadedRef.current);
    deleteBtn(filteredIds);
  };

  return (
    <div
      key={jobIndex + date}
      style={{
        padding: "0px",
        marginTop: header && "1.5vw",
        width: "100%",
        height: "100%",
      }}
    >
      {" "}
      {header && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "1.25vw",
            paddingRight: "1.25vw",
          }}
        >
          <img
            src={
              icon
                ? icon
                : process.env.REACT_APP_RESOURCES_BUCKET + "Worker.png"
            }
            style={{
              width: "1.9vw",
              height: "1.9vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{header.name}</b>
          </p>
        </div>
      )}
      {tasks.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            height: "3.8vw",
            width: "100%",
            // marginLeft: "-1.25vw",
            borderTop: "0.1vw solid rgba(237, 235, 255, 1)",
            borderBottom: "0.1vw solid rgba(237, 235, 255, 1)",
            backgroundColor: colors.disabled,
            padding: "1.25vw",
            boxSizing: "border-box",
          }}
        >
          {deleteBtn && tasks.length > 0 && (
            <img
              onClick={() => {
                if (selected.every((element) => element === false)) {
                  setSelected(new Array(tasks.length).fill(true));
                } else {
                  setSelected(new Array(tasks.length).fill(false));
                }
              }}
              src={getSelection()}
              style={{
                width: "1.2vw",
                height: "1.2vw",
                marginRight: "0.5vw",
                filter:
                  selected &&
                  "drop-shadow(0px 0.4vw 0.8vw rgba(113, 109, 242, 0.30))",
              }}
            />
          )}
          {columns.map((col, id) => {
            if (col == "name") return <NameColumn header={true} />;
            if (col == "state") return <StatusColumn header={true} />;
            if (col == "protocol") return <ProtocolColumn header={true} />;
            if (col == "ws") return <WorkstationColumn header={true} />;
            if (col == "duration")
              return <DurationColumn header={true} errPerc={errPerc} />;
            if (col == "score") return <ScoreColumn header={true} />;
          })}
          {/* {columns.includes("name") && <NameColumn header={true} />}
          {columns.includes("state") && <StatusColumn header={true} />}
          {columns.includes("protocol") && <ProtocolColumn header={true} />}
          {columns.includes("ws") && <WorkstationColumn header={true} />}
          {columns.includes("duration") && (
            <DurationColumn header={true} errPerc={errPerc} />
          )}
          {columns.includes("score") && <ScoreColumn header={true} />} */}
          <ControlColumn
            header={true}
            selected={selected}
            deleteSelected={deleteSelected}
            unlinkIcon={unlinkIcon}
          />
        </div>
      )}
      <div style={{ overflowY: "scroll", height: "100%" }}>
        {tasks.length > 0 ? (
          <>
            {loadedRef.current < tasks.length && (
              <div
                style={{ height: empty ? "100%" : 3.9 * tasks.length + "vw" }}
              >
                <Spinner size={"3vw"} />
              </div>
            )}
            {tasks?.map((task, taskIndex) => (
              <TaskTable
                key={task.id}
                task={task}
                columns={columns}
                unlinkIcon={unlinkIcon}
                taskIndex={taskIndex}
                deleteBtn={
                  deleteBtn
                    ? (id) => {
                        loadedRef.current = loadedRef.current - 1;
                        setLoaded(loadedRef.current);
                        deleteBtn(id);
                      }
                    : false
                }
                width={columnWidth}
                selected={selected[taskIndex]}
                setSelected={() => toggleSelected(taskIndex)}
                duration={duration}
                errPerc={errPerc}
                onChangeTime={onChangeTime}
                onSave={onSave}
                loaded={loadedRef.current >= tasks.length}
                setLoaded={() => {
                  loadedRef.current = loadedRef.current + 1;
                  setLoaded(loadedRef.current);
                }}
                externalSelectedProtocol={externalSelectedProtocol}
                setGroupStatus={(x) => {
                  task.open = x;
                  if (refresh) refresh();
                }}
              />
            ))}
          </>
        ) : (
          empty
        )}
      </div>
    </div>
  );
};

export default JobTable;
