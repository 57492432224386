import { useClerk, useUser } from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NIOSH_ID,
  OCRA_ID,
  protocols,
} from "../../../config/availableProtocols";
import { colors, layout } from "../../../config/style";
import { handleLoadDepartment, updateProtocols } from "../../../remote/utils";
import { useStateValue } from "../../../stores/services/StateProvider";
import { shift_global } from "../../../utils/Protocols/OCRA/singleTask";
import InfoTab, {
  BasicText,
  InfoTabScroller,
  Row,
  Selector,
  TextBox,
} from "../../InfoTab/InfoTab";
import { Pill } from "../../Pill/Pill";
import { PopupCreate } from "../../Popup/Popup";
import { PopupTask } from "../../Popup/PopupContent";
import ReportGenerator from "../../ReportGenerator/ReportGenerator";
import Spinner from "../../Spinner/Spinner";
import JobTable from "../../Tables/JobTable/JobTable";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";
import { multitaskResult } from "../StructureComponents";

const WsScreen = ({
  data,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  handleSaving,
  handleChangeTime,
  location,
  wsRef,
  saving,
  setSavingEnabled,
  savingEnabled,
  handleDeleteTask,
  tasks,
  loadTasks,
  popupRef,
  deleted,
}) => {
  const [state, dispatch] = useStateValue();
  const [generating, setGenerating] = useState(false);
  const [multitask, setMultitask] = useState();
  const [singletask, setSingletask] = useState();
  const [refresh, setRefresh] = useState(false);

  const [resultComponent, setResultComponent] = useState(
    <Spinner size={"3vw"} />
  );
  const [availableProtocols, setAvailableProtocols] = useState();
  const [selectedProtocol, setSelectedProtocol] = useState(-1);
  const [shift, setShift] = useState(
    departmentDetail[currentDepartment].workshifts[
      departmentDetail[currentDepartment].lines[data.line][data.id].workshift
    ]
  );
  const [shiftRes, setShiftRes] = useState();
  const [reportEnabled, setReportEnabled] = useState(false);
  const [ws, setWs] = useState({
    ...departmentDetail[currentDepartment].lines[data.line][data.id],
  });

  const reportEnabledRef = useRef(false);
  const resultComponentRef = useRef();
  const multitaskRef = useRef();
  const singletaskRef = useRef();

  const { user } = useUser();

  wsRef.current = ws;
  resultComponentRef.current = resultComponent;
  singletaskRef.current = singletask;
  multitaskRef.current = multitask;

  const { session } = useClerk();
  const { t } = useTranslation();

  const shiftOptions = Object.keys(
    departmentDetail[currentDepartment].workshifts
  ).map((key) => ({
    value: key,
    label: departmentDetail[currentDepartment].workshifts[key].name,
  }));

  // useEffect(() => {
  //   loadTasks();
  // }, []);

  useEffect(() => {
    //Create an updated version of the department
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      lines: {
        ...departmentDetail[currentDepartment].lines,
        [data.line]: {
          ...departmentDetail[currentDepartment].lines[data.line],
          [data.id]: ws,
        },
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);
    setSavingEnabled(true);
  }, [ws]);

  useEffect(() => {
    console.log("hereeee");
    updateProtocols(tasks, setAvailableProtocols, setSelectedProtocol);
    let res = multitaskResult(
      shift,
      tasks.map((x) => {
        return { durata: x.durata, task: x }; //Converto a modello job task per usare stessa funzione
      }),
      setReportEnabled,
      setShiftRes,
      setSingletask,
      setMultitask,
      t,
      availableProtocols,
      selectedProtocol
    );
    setResultComponent(res);
  }, [tasks, shift, selectedProtocol]);

  useEffect(() => {
    if (shift) setShiftRes(shift_global(shift));
  }, [shift]);

  useEffect(() => {
    loadTasks();
    updateProtocols(tasks, setAvailableProtocols, setSelectedProtocol);
    return () => {
      if (!deleted.current) {
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          lines: {
            ...departmentDetail[currentDepartment].lines,
            [data.line]: {
              ...departmentDetail[currentDepartment].lines,
              [data.id]: wsRef.current,
            },
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        handleSaving();
      }
    };
  }, []);

  function filterTaskProtocol(arrayOggetti) {
    console.log("arrayOggettiarrayOggetti", arrayOggetti);
    const result = arrayOggetti.filter((oggetto) => {
      // Filtra i task se selezionato un protocollo specifico

      return (
        selectedProtocol == -1 ||
        oggetto.hasOwnProperty(availableProtocols[selectedProtocol].ref) ||
        (availableProtocols[selectedProtocol].ref == protocols[OCRA_ID].ref &&
          !oggetto.hasOwnProperty(protocols[NIOSH_ID].ref))
      );

      // Se l'oggetto non soddisfa la condizione, restituisce undefined
    });

    return result;
  }

  // const handleDelete = async () => {
  //   deleted.current = true;

  //   let request = await deleteStation(
  //     departmentDetail[currentDepartment].locationId,
  //     departmentDetail[currentDepartment].id,
  //     data.line,
  //     data.id,
  //     await getToken(session)
  //   );

  //   // Crea una copia degli attuali workshifts
  //   const updatedWs = {
  //     ...departmentDetail[currentDepartment].lines[data.line],
  //   };

  //   // Rimuovi lo shift specifico
  //   delete updatedWs[data.id];

  //   // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
  //   const updatedCurrentDepartment = {
  //     ...departmentDetail[currentDepartment],
  //     lines: {
  //       ...departmentDetail[currentDepartment].lines,
  //       [data.line]: updatedWs,
  //     },
  //   };

  //   // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
  //   const updatedDepartmentDetails = {
  //     ...departmentDetail,
  //     [currentDepartment]: updatedCurrentDepartment,
  //   };
  //   setData({ type: "delete", element: wsRef.current.name });
  //   setDepartmentDetail(updatedDepartmentDetails);
  // };

  return (
    <>
      <div
        style={{
          // height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.1vw",
          marginTop: layout.paddingTop,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",

            margin: "0vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{ws.name}</b>
        </p>
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
            marginRight: "0.1vw",
          }}
        >
          <ButtonTextIcon
            label={t("save")}
            color={colors.main}
            backgroundH={colors.mainOpaque}
            background={"transparent"}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              width: "6.2vw",
              justifyContent: saving ? "center" : "start",
            }}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            backgroundH={colors.details}
            background={"white"}
            color={colors.main}
            onClick={() => {
              handleSaving();
            }}
            style={{
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "700",
              width: "3vw",
              boxSizing: "border-box",
            }}
          />

          <ButtonTextIcon
            label={"Report postazione"}
            color={"white"}
            backgroundH={colors.mainH}
            background={colors.main}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
            loadingStyle={{}}
            loading={generating}
            loadingLabel={""}
            disabled={!reportEnabled}
            onClick={() => {
              ReportGenerator({
                job: false,
                availableProtocols: availableProtocols,
                tasks: tasks.map((x) => {
                  return { durata: x.durata, task: x }; //Converto a modello job task per usare stessa funzione
                }),
                shift: shift,
                multitask: multitaskRef.current,
                singleTask: singletaskRef.current,
                department: departmentDetail[currentDepartment],
                location: location,
                user: user,
                session: session,
                setGenerating: setGenerating,
                t: t,
                fileName: ws.name,
              });
            }}
            style={{
              width: "9.4vw",
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "700",

              boxSizing: "border-box",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          width: "60.1vw",
          gap: "0.5vw",
          height: "2.5vw",
          background: colors.mainOpaque,
          borderRadius: "1.25vw",
          boxSizing: "border-box",
          padding: "0.6vw",
        }}
      >
        {(availableProtocols?.length > 1 ||
          availableProtocols?.length == 0 ||
          !availableProtocols?.length) && (
          <Pill
            label={"Riepilogo"}
            toggle={selectedProtocol === -1}
            background={"white"}
            backgroundToggle={colors.main}
            color={colors.text}
            colorToggle={"white"}
            onClick={() => {
              setSelectedProtocol(-1);
            }}
          />
        )}
        {availableProtocols?.map((protocol, id) => {
          return (
            <Pill
              label={protocol.mid}
              toggle={selectedProtocol === id}
              background={"white"}
              backgroundToggle={colors.main}
              color={colors.text}
              colorToggle={"white"}
              onClick={() => setSelectedProtocol(id)}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("workspaceScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={ws.name}
                  onChange={(value) => {
                    // ws.name = value;
                    setWs({ ...ws, name: value });
                  }}
                  locked={false}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("department")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={departmentDetail[currentDepartment].name}
                  onChange={() => {}}
                  locked={true}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Area.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("area")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={
                    departmentDetail[currentDepartment].linesData[data.line]
                      .name
                  }
                  onChange={() => {}}
                  locked={true}
                />
              </Row>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "6vw",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenShift")}
                  </BasicText>
                </div>

                <Selector
                  value={shiftOptions.find((x) => x.value == ws.workshift)}
                  options={shiftOptions}
                  onChange={(value) => {
                    // setJob({ ...job, workshift: value.value });
                    setWs({ ...ws, workshift: value.value });
                    setShift(
                      departmentDetail[currentDepartment].workshifts[
                        value.value
                      ]
                    );
                  }}
                  single={true}
                  width={"16.4vw"}
                  height={"2.5vw"}
                />
              </Row>
            </>,
          ]}
        />
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("SCORES")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={resultComponentRef.current}
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw" }}
        key={data.id}
        infoTab={
          <InfoTab
            fullWidth={true}
            width={"100%"}
            height={"100%"}
            title={[t("workspaceScreenTasks")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            content={
              <JobTable
                refresh={() => setRefresh(!refresh)}
                externalSelectedProtocol={
                  selectedProtocol >= 0 && availableProtocols[selectedProtocol]
                }
                tasks={filterTaskProtocol(tasks)}
                onSave={() => {
                  console.log("ONSAVE");
                  handleLoadDepartment(
                    currentDepartment,
                    () => {
                      loadTasks();
                    },
                    departmentDetail,
                    session,
                    departmentDetail[currentDepartment].locationId,
                    (x) => setDepartmentDetail(x)
                  );
                }}
                structure={true}
                columns={
                  selectedProtocol == -1
                    ? ["name", "protocol", "state", "score"]
                    : availableProtocols[selectedProtocol].ref ==
                      protocols[OCRA_ID].ref
                    ? ["name", "duration", "state", "score"]
                    : ["name", "state", "score"]
                }
                deleteBtn={(x) => handleDeleteTask(x)}
                duration={100}
                onChangeTime={handleChangeTime}
                empty={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      gap: "0.5vw",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES_BUCKET +
                        "WsPlaceholder.png"
                      }
                      style={{
                        width: "11.7vw",
                        marginTop: "-0.5vw",
                      }}
                    />{" "}
                    <p
                      style={{
                        color: colors.text,
                        fontFamily: "Atkinson Hyperlegible",
                        fontSize: "0.9vw",
                        fontWeight: "400",
                        marginLeft: "0vw",
                        marginTop: "0vw",
                        boxSizing: "border-box",
                        textAlign: "center",
                        width: "12vw",
                      }}
                    >
                      {t("workspaceScreenNoTasksAssigned")}
                    </p>
                    <ButtonTextIcon
                      label={t("workspaceScreenCreate")}
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      iconH={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      backgroundH={colors.tertiaryH}
                      background={colors.tertiary}
                      color={"white"}
                      onClick={() => {
                        popupRef.current.open();
                      }}
                      style={{
                        paddingTop: "0.3vw",
                        paddingBottom: "0.3vw",
                      }}
                    />
                  </div>
                }
              />
            }
            titleButton={
              <ButtonTextIcon
                label={t("workspaceScreenCreate")}
                color={colors.main}
                backgroundH={colors.details}
                background={"transparent"}
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                onClick={() => {
                  popupRef.current.open();
                }}
                style={{
                  fontSize: "0.82vw",
                  boxShadow: "0px 0px 0px 0px white",
                  height: "2vw",
                  fontWeight: "700",
                  marginRight: "0.5vw",
                  padding: "0.3vw",
                  paddingRight: "0.4vw",
                  boxSizing: "border-box",
                }}
              />
            }
          />
        }
      />
      <PopupCreate
        ref={popupRef}
        info={"info"}
        title={t("taskPopupTitle")}
        children={
          <PopupTask
            MainOwnerCompany={state.current_location.MainOwnerCompany}
            location={departmentDetail[currentDepartment].locationId}
            department={currentDepartment}
            line={data.line}
            station={data.id}
            onSuccess={() => {
              handleLoadDepartment(
                currentDepartment,
                (dep) => {
                  loadTasks(dep);
                  popupRef.current.close();
                  setSelectedProtocol(-1);
                },
                departmentDetail,
                session,
                departmentDetail[currentDepartment].locationId,
                (x) => setDepartmentDetail(x)
              );
            }}
          />
        }
      />
    </>
  );
};

export default WsScreen;
