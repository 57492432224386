import React from "react";
import { Selector } from "../../InfoTab/InfoTab";
import { colors } from "../../../config/style";

const ChartHeader = ({
  optionsGraph,
  selectedChart,
  setSelectedChart,
  setBatchLevel,
  setSelectedGraph,
  time,
  videoDuration,
  formatTime,
  t,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "1vw",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "1vw",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "33%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Selector
          value={optionsGraph[selectedChart]}
          onChange={(x) => {
            setBatchLevel([]);
            setSelectedChart(x.value);
            setSelectedGraph(x.value);
          }}
          single={false}
          visibility={true}
          noBorder={true}
          options={optionsGraph}
        />
      </div>
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "1vw",
          fontWeight: "400",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: colors.main,
            fontWeight: "800",
            minWidth: "50px",
            textAlign: "right",
            marginRight: "0.2vw",
          }}
        >
          {formatTime(time)}
        </span>
        {" - "}
        <span style={{ marginLeft: "0.2vw", marginRight: "0.2vw" }}>
          {videoDuration ? formatTime(videoDuration) : formatTime(0)}
        </span>
        {"|"}
        <span style={{ marginLeft: "0.2vw", marginRight: "0.2vw" }}>
          <b>{" " + t("Seconds")}</b>
        </span>
      </p>
      <div
        style={{ width: "33%", justifyContent: "end", alignItems: "center" }}
      >
        <Selector noBorder={true} visibility={"hidden"} />
      </div>
    </div>
  );
};

export default ChartHeader;
