import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import { multitask_global } from "../../utils/Protocols/OCRA/multiTask";
import {
  shift_global,
  trova_moltiplicatore_durata,
} from "../../utils/Protocols/OCRA/singleTask";
import { NIOSH_ID, protocols } from "../../config/availableProtocols";
import { BasicText, InfoBox, Row, ScoreTab } from "../InfoTab/InfoTab";
import { mappingOcra } from "../../utils/Protocols/OCRA/OCRA";
import { tips } from "../../config/tips";

const extractTaskList = (tasks, shiftRes) => {
  return tasks.map((x, id) => {
    return {
      percentuale_originale: x.durata / 100,
      min_lavorati_per_compito: 0,
      percentuale_su_480_min: 0,
      minuti_turno: 0,
      moltiplicatore_recupero: shiftRes.moltiplicatore_recupero,
      durata_media_netta: shiftRes.durata_media_netta,
      n_ore_senza_recupero: shiftRes.numero_ore_senza_recupero_automatico,
      sx: x.task?.ocra?.sx,
      dx: x.task?.ocra?.dx,
    };
  });
};

function areScoresValid(array) {
  console.log("array", array);
  for (let element of array) {
    if (
      !element?.task?.scores?.dx ||
      !element?.task?.scores?.sx ||
      (element?.task?.ocra && Object.keys(element?.task?.ocra).length === 0) ||
      (!element?.open && !element?.task?.open)
    ) {
      return false;
    }
  }
  return true;
}

export function multitaskResult(
  shift,
  tasks,
  setReportEnabled,
  setShiftRes,
  setSingletask,
  setMultitask,
  t,
  availableProtocols,
  selectedProtocol
) {
  setReportEnabled(false);
  let multitask;
  let singleTask;
  if (shift) {
    let shiftUpdated = shift_global(shift);
    setShiftRes(shiftUpdated);
    try {
      multitask = multitask_global(extractTaskList(tasks, shiftUpdated.output));
    } catch (err) {
      console.log("err", err);
      multitask = {};
    }
    try {
      let task = tasks[0].task;
      let cycleNumber = Math.round(
        (shiftUpdated.output.durata_media_netta * 60) /
          task.ocra.sx.otherTemp.tempoCiclo
      );
      let minuti_recupero_ciclo = Math.round(
        (cycleNumber * task.ocra.sx.otherTemp.totale_secondi_tempo_passivo) / 60
      );
      let durata_netta_esclusi_rec =
        shiftUpdated.output.durata_media_netta - minuti_recupero_ciclo; //Ho usato la durata calcolata come sensato diverso da excel, da verificare
      let moltiplicatore_durata = trova_moltiplicatore_durata(
        durata_netta_esclusi_rec
      );
      let moltiplicatore_recupero =
        task.ocra.sx.otherTemp.moltiplicatore_recupero == 1
          ? task.ocra.sx.otherTemp.moltiplicatore_recupero
          : shiftUpdated.output.moltiplicatore_recupero;
      singleTask = {
        dx: {
          intrinseco:
            Math.round(task.ocra.dx.parziale * moltiplicatore_recupero * 10) /
            10,
          ponderato:
            Math.round(
              task.ocra.dx.parziale *
                moltiplicatore_recupero *
                moltiplicatore_durata *
                10
            ) / 10,
        },
        sx: {
          intrinseco:
            Math.round(task.ocra.sx.parziale * moltiplicatore_recupero * 10) /
            10,
          ponderato:
            Math.round(
              task.ocra.sx.parziale *
                moltiplicatore_recupero *
                moltiplicatore_durata *
                10
            ) / 10,
        },
        moltiplicatore_recupero: moltiplicatore_recupero,
        molti_overwritten: task.ocra.sx.otherTemp.moltiplicatore_recupero == 1,
        cycleNumber: cycleNumber,
      };
    } catch (err) {
      console.log("err", err);
      singleTask = {};
    }
  }

  console.log("singleTaskmultitask", singleTask, multitask);
  setSingletask(singleTask);
  setMultitask(multitask);

  if (!shift)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
          }}
        >
          {t("jobScreenNoShift")}
        </p>
      </div>
    );
  if (tasks.length == 0)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            width: "80%",
            textAlign: "center",
          }}
        >
          {t("jobScreenNoTask")}
        </p>
      </div>
    );

  if (
    !areScoresValid(tasks) ||
    (tasks.lenght > 1 &&
      (!multitask?.mtUnder90?.dx ||
        !multitask?.mtUnder90?.sx ||
        !multitask?.mtOver90?.dx ||
        !multitask?.mtOver90?.sx))
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            width: "80%",
            textAlign: "center",
          }}
        >
          {t("jobScreenNoRes")}
        </p>
      </div>
    );
  }

  if (
    availableProtocols &&
    selectedProtocol != -1 &&
    availableProtocols[selectedProtocol]?.ref == protocols[NIOSH_ID].ref
  )
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            width: "80%",
            textAlign: "center",
          }}
        >
          {"NIOSH VLI non supporta il multitask"}
        </p>
      </div>
    );

  if (tasks.reduce((acc, curr) => acc + curr.durata, 0) != 100)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <p
          style={{
            color: colors.disabledText,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            width: "80%",
            textAlign: "center",
          }}
        >
          {t("jobScreenPerc")}
        </p>
      </div>
    );
  setReportEnabled(true);
  if (tasks.length == 1)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          width: "100%",

          gap: "2vw",
          marginBottom: singleTask.molti_overwritten && "-2vw",
        }}
      >
        <ScoreTab
          mapping={mappingOcra["prec"]}
          title={
            <>
              {"OCRA"}
              <span style={{ fontWeight: "400" }}>{" | TASK SINGOLO"}</span>
            </>
          }
          scores={[
            {
              label: "INTRINSECO",
              info: tips[document.language].finale_intrinseco,
              dx: singleTask.dx.intrinseco,
              sx: singleTask.sx.intrinseco,
              map: "prec",
            },
            {
              label: "FINALE PONDERATO",
              info: tips[document.language].finale_ponderato,
              dx: singleTask.dx.ponderato,
              sx: singleTask.sx.ponderato,
              map: "prec",
            },
          ]}
        />
        {singleTask.molti_overwritten && (
          <Row justifyContent={"start"}>
            <BasicText
              style={{
                width: "24vw",
              }}
            >
              {t("jobScreenMoltiRec")}
            </BasicText>
            <InfoBox
              value={1}
              ai={false}
              unit={""}
              info={tips[document.language].moltiplicatore_recupero}
            />
          </Row>
        )}
      </div>
    );
  return (
    <ScoreTab
      mapping={mappingOcra["prec"]}
      title={
        <>
          {"OCRA"}
          <span style={{ fontWeight: "400" }}>{" | MULTITASK"}</span>
        </>
      }
      scores={[
        {
          label: t("jobScreenLess90"),
          info: tips[document.language].rotazione_minore_90,
          dx: multitask.mtUnder90.dx,
          sx: multitask.mtUnder90.sx,
          map: "prec",
        },
        {
          label: t("jobScreenMore90"),
          info: tips[document.language].rotazione_maggiore_90,
          dx: multitask.mtOver90.dx,
          sx: multitask.mtOver90.sx,
          map: "prec",
        },
      ]}
    />
  );
}
