import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../config/style";

const WorkstationColumn = ({ task, header }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      {header ? (
        <>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
            style={{
              width: "1.4vw",
              height: "1.4vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {t("workspace")}
          </p>
        </>
      ) : (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {task.stationName ? task.stationName : task.ws}
          </p>
        </>
      )}
    </div>
  );
};
export default WorkstationColumn;
