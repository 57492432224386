import { useClerk, useUser } from "@clerk/clerk-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getAvailableAnalysis } from "../../config/availableAnalysis";
import { protocols } from "../../config/availableProtocols";
import { compressObjectLZURI } from "../../remote/compressionFunctions";
import { updateTaskNiosh } from "../../remote/structure";
import { setReviewed, updateTaskResult } from "../../remote/tasks";
import { useStateValue } from "../../stores/services/StateProvider";
import { getToken } from "../../userManagement/utilities";
import { updateWeightCatVli, vliGlobal } from "../../utils/Protocols/NIOSH/vli";
import { single_task_global } from "../../utils/Protocols/OCRA/singleTask";
import { compareArrayOfObjects } from "../../utils/generalFunctions";
import { PopupCreate } from "../Popup/Popup";
import { PopupAlert } from "../Popup/PopupContent";
import Spinner from "../Spinner/Spinner";
import { AnalysisContainerWrapper } from "./AnalysisContainer.styled";
import { checklists_menu } from "./AnalysisContainerComponents";
import {
  manageAiResult,
  manageIndexResult,
  matchIndexChecklist,
} from "./AnalysisContainerFunctions";
import { AnalysisContainerLeftColumn } from "./AnalysisContainerLeftColumn";
import { AnalysisContainerRightColumn } from "./AnalysisContainerRightColumn";
import { colors } from "../../config/style";
import { mergeErrors } from "../GroundTruthGenerator/GTGenerator";
import { rimuoviPuntiDaArray } from "../LineChart/ChartUtlis";

const AnalysisContainer = ({ dataTestId, onClose, onSave, selectedTask }) => {
  const [state, dispatch] = useStateValue();
  const [analysis, setAnalysis] = useState(null);
  const [checklist, setChecklist] = useState(null);
  const [screen, setScreen] = useState(0);
  const [selectedProtocol, setSelectedProtocol] = useState(0);
  const [availableProtocols, setAvailableProtocols] = useState();
  const [refresh, setRefresh] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [graphMemory, setGraphMemory] = useState(selectedTask?.ai?.edit);
  const [saving, setSaving] = useState(false);
  const [editingGraph, setEditingGraph] = useState(false);
  const [reviewedState, setReviewedState] = useState(
    selectedTask?.taskDetail?.last_result_obj?.ai?.LEFT?.status == "REVIEWED"
  );
  const [reviewing, setReviewing] = useState(false);
  const [availableAnalysis, setAvailableAnalysis] = useState();
  // OCRA
  const [ocraMemory, setOcraMemory] = useState();
  const [ocraRes, setOcraRes] = useState();
  // NIOSH
  const [vliMemory, setVliMemory] = useState();
  const [vliRes, setVliRes] = useState(vliGlobal(vliMemory));

  const [savingEnabled, setSavingEnabled] = useState(false);
  const [allowRecover, setAllowRecover] = useState(false);
  const [departmentDetail, setDepartmentDetail] = useState();

  const checklistRef = useRef();
  const graphMemoryRef = useRef();
  const lineChartRef = useRef();
  const videoLoadedRef = useRef();
  const popupRef = useRef();

  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  checklistRef.current = checklist;
  graphMemoryRef.current = graphMemory;
  videoLoadedRef.current = videoLoaded;

  const { session } = useClerk();

  const videoId = "videoId";

  console.log("SELECTED TASK", selectedTask);

  useEffect(() => {
    navigate({ search: "analysis" }, { replace: true });

    // PROTOCOLS
    // let localAvailableProtocols = protocols.filter((protocol) => {
    //   if (protocol.ref == protocols[0].ref) return selectedTask?.ocraValid;
    //   else return selectedTask.taskDetail.hasOwnProperty(protocol.ref);
    // });
    let localAvailableProtocols = [];

    protocols.forEach((protocol, id) => {
      // OCRA
      if (id == 0 && selectedTask?.ocraValid) {
        localAvailableProtocols.push(protocol); //Aggiungi OCRA a elenco protocolli
        let availableAnalysisLocal = getAvailableAnalysis(
          state.current_data,
          t
        );
        setAvailableAnalysis(availableAnalysisLocal);
        setAnalysis(
          availableAnalysisLocal[0].analysis[
            Object.keys(availableAnalysisLocal[0].analysis)[0]
          ]
        );
        setOcraMemory({
          //Inizializza memoria
          ...ocraMemory,
          ...selectedTask.checklist,
          uso_martelli_dx:
            selectedTask.checklist.uso_martelli_dx <= 0 ? false : true,
          uso_mani_colpi_dx:
            selectedTask.checklist.uso_mani_colpi_dx <= 0 ? false : true,
          strumenti_vibranti_dx:
            selectedTask.checklist.strumenti_vibranti_dx <= 0 ? false : true,
          altro_dx: selectedTask.checklist.altro_dx <= 0 ? false : true,
          uso_martelli_sx:
            selectedTask.checklist.uso_martelli_sx <= 0 ? false : true,
          uso_mani_colpi_sx:
            selectedTask.checklist.uso_mani_colpi_sx <= 0 ? false : true,
          strumenti_vibranti_sx:
            selectedTask.checklist.strumenti_vibranti_sx <= 0 ? false : true,
          altro_sx: selectedTask.checklist.altro_sx <= 0 ? false : true,
          targa_ciclo: selectedTask.checklist.targa_ciclo
            ? selectedTask.checklist.targa_ciclo
            : Math.round((400 * 60) / selectedTask.checklist.numero_cicli),
          ...manageIndexResult(selectedTask),
          ...manageAiResult(graphMemory, availableAnalysisLocal),
        });
      }
      // CASO NIOSH
      else if (
        id == 1 &&
        selectedTask.taskDetail.hasOwnProperty(protocol.ref)
      ) {
        localAvailableProtocols.push(protocol);
        setVliMemory(selectedTask.taskDetail.niosh);
      }
    });

    setAvailableProtocols(localAvailableProtocols);

    setChecklist(
      checklists_menu({
        ocraRes: ocraRes,
        ocraMemory: ocraMemory,
        vliRes: vliRes,
        vliMemory: vliMemory,
        t: t,
        selectedProtocol: localAvailableProtocols[selectedProtocol],
      })[0] //EDITTTT
    );
    setSavingEnabled(false);

    return () => {
      navigate({ search: "" }, { replace: true });
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    // Aggiungi l'evento
    if (savingEnabled && !window.location.hostname.includes("localhost"))
      window.addEventListener("beforeunload", handleBeforeUnload);
    else window.removeEventListener("beforeunload", handleBeforeUnload);
    // Rimuovi l'evento quando il componente viene smontato o quando `enabled` cambia
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [savingEnabled]);
  useEffect(() => {
    if (ocraMemory) {
      let ocraMemoryTemp = ocraMemory;
      if (state?.current_customer?.index)
        ocraMemoryTemp = matchIndexChecklist(ocraMemoryTemp);
      let singleTask = single_task_global(ocraMemoryTemp);
      setOcraRes(singleTask);
      setAllowRecover(true);
    }
  }, [ocraMemory]);

  useEffect(() => {
    if (vliMemory) {
      setSavingEnabled(true);
      setVliRes(vliGlobal(vliMemory));
    }
  }, [vliMemory]);

  useEffect(() => {
    if (vliMemory) {
      setSavingEnabled(true);
      setVliRes(vliGlobal(vliMemory));
      if (
        !compareArrayOfObjects(vliMemory.elenco_pesi, vliMemory.elenco_pesi_old)
      ) {
        let elenco_pesi_ordinato = vliMemory.elenco_pesi.sort(
          (a, b) => parseFloat(a.peso) - parseFloat(b.peso)
        );
        setVliMemory({
          ...vliMemory,
          elenco_pesi: elenco_pesi_ordinato,
          elenco_pesi_old: JSON.parse(JSON.stringify(vliMemory.elenco_pesi)),
          categorie_peso: updateWeightCatVli(elenco_pesi_ordinato),
        });
      }
    }
  }, [checklist]);

  useEffect(() => {
    if (analysis && ocraMemory) {
      setSavingEnabled(true);
      setAllowRecover(true);
    }
  }, [graphMemory, ocraMemory]);

  useEffect(() => {
    if (ocraMemory) {
      setOcraMemory({
        ...ocraMemory,
        ...manageAiResult(graphMemory, availableAnalysis),
      });
    }
  }, [graphMemory]);

  useEffect(() => {
    if (availableProtocols) {
      setChecklist(
        checklists_menu({
          ocraRes: ocraRes,
          ocraMemory: ocraMemory,
          vliRes: vliRes,
          vliMemory: vliMemory,
          t: t,
          selectedProtocol: availableProtocols[selectedProtocol],
        })[0] //EDITTTT
      );
      setScreen(0);
    }
  }, [selectedProtocol]);

  const save = (onSuccess) => {
    setSaving(true);
    updateResults(() => {
      onSuccess && onSuccess();
      setSavingEnabled(false);
      onSave && onSave();
      setSaving(false);
    });
  };

  function calculateOccupiedTime(data, fps) {
    if (!Array.isArray(data)) {
      throw new Error("Il primo parametro deve essere un array.");
    }
    if (typeof fps !== "number" || fps <= 0) {
      throw new Error(
        "Il secondo parametro deve essere un numero maggiore di 0."
      );
    }

    // Conta il numero totale di `1` nell'array
    const totalOnes = data.reduce((acc, value) => acc + value, 0);

    // Calcola il tempo totale degli `1`
    const timeInSeconds = totalOnes / fps;

    return Math.round(timeInSeconds * 10) / 10;
  }

  function countBatches(array) {
    if (!Array.isArray(array)) {
      throw new Error("Il parametro deve essere un array.");
    }

    let count = 0; // Numero di batch
    let inBatch = false; // Indica se ci troviamo in un batch

    for (const value of array) {
      if (value === 1) {
        if (!inBatch) {
          count++; // Inizia un nuovo batch
          inBatch = true;
        }
      } else {
        inBatch = false; // Fine del batch
      }
    }

    return count;
  }

  function sumObjectsTime(array, batchDuration) {
    if (!Array.isArray(array)) {
      throw new Error("Il parametro deve essere un array di oggetti.");
    }

    return array.reduce((accumulator, current) => {
      for (const key in current) {
        accumulator[key] =
          (accumulator[key] || 0) + current[key] / batchDuration;
      }
      return accumulator;
    }, {});
  }

  const updateResults = async (onSuccess) => {
    // OCRA
    if (availableProtocols.some((prot, id) => prot.ref == protocols[0].ref)) {
      // Save results

      // INDEX
      let indexResults = {};
      // Ciclo che per tutti i distretti controlla se c'è salvataggio e usa quel dato altrimenti prende distretto oriinale, calcola valori index e li salva
      if (state.current_data?.right_video?.version) {
        let allDistrict = {
          ...availableAnalysis[0].analysis,
          ...availableAnalysis[1].analysis,
        };

        for (let key in allDistrict) {
          let component =
            state.current_data[allDistrict[key].side][
              allDistrict[key].debug ? allDistrict[key].debug : "debug"
            ][allDistrict[key].component];
          let fps =
            state.current_data[allDistrict[key].side][
              allDistrict[key].debug ? allDistrict[key].debug : "debug"
            ].sampling_frequency;

          if (key == "rightShoulder" || key == "leftShoulder") {
            if (graphMemory.hasOwnProperty(key)) {
              let newErrors = mergeErrors(
                graphMemory[key][0].errors,
                graphMemory[key][0].addedErrors
              );
              indexResults = {
                ...indexResults,
                [key]: {
                  flexionTime: calculateOccupiedTime(
                    rimuoviPuntiDaArray(
                      [newErrors[0]],
                      graphMemory[key][0]?.posizioniAggiunte
                    )[0],
                    fps
                  ),
                  abductionTime: calculateOccupiedTime(
                    rimuoviPuntiDaArray(
                      [newErrors[1]],
                      graphMemory[key][0]?.posizioniAggiunte
                    )[0],
                    fps
                  ),
                  extensionTime: calculateOccupiedTime(
                    rimuoviPuntiDaArray(
                      [newErrors[2]],
                      graphMemory[key][0]?.posizioniAggiunte
                    )[0],
                    fps
                  ),
                },
              };
            } else {
              indexResults = {
                ...indexResults,
                [key]: {
                  flexionTime: calculateOccupiedTime(
                    component["flexion_errors"],
                    fps
                  ),
                  abductionTime: calculateOccupiedTime(
                    component["abduction_errors"],
                    fps
                  ),
                  extensionTime: calculateOccupiedTime(
                    component["extension_errors"],
                    fps
                  ),
                },
              };
            }
          }
          if (key == "rightElbow" || key == "leftElbow") {
            if (graphMemory.hasOwnProperty(key)) {
              indexResults = {
                ...indexResults,
                [key]: {
                  flexionExtensionTime: graphMemory[key][0].newValue,
                  pronoSupinationTime: graphMemory[key][1].newValue,
                },
              };
            } else {
              indexResults = {
                ...indexResults,
                [key]: {
                  flexionExtensionTime:
                    (countBatches(component["flexion_errors"]) +
                      countBatches(component["extension_errors"])) *
                    allDistrict[key]["graph"][0].stdDuration,
                  pronoSupinationTime:
                    component["prono_supinations_per_batch"].reduce(
                      (acc, value) => acc + value,
                      0
                    ) * allDistrict[key]["graph"][1].stdDuration,
                },
              };
            }
          }
          if (
            key == "rightWrist" ||
            key == "leftWrist" ||
            key == "leftHand" ||
            key == "rightHand"
          ) {
            if (graphMemory.hasOwnProperty(key)) {
              indexResults = {
                ...indexResults,
                [key]: {
                  ...sumObjectsTime(graphMemory[key][0]["errorsIndex"], 10),
                },
              };
            } else {
              indexResults = {
                ...indexResults,
                [key]: {
                  ...sumObjectsTime(component["batch_index_percentages"], 10),
                },
              };
            }
          }
        }
      }

      const compressedDataLZ = compressObjectLZURI(graphMemory);

      let updatedResults = {
        id: selectedTask.id,
        checklist: rimuoviChiaviESostituisci({
          ...ocraMemory,
          ...ocraRes.input,
        }), //INDEX updated to output to manage results
        ai: { ...selectedTask.ai, edit: compressedDataLZ },
        inspection: selectedTask.inspection,
      };

      // Update task
      let scores = {
        sx: ocraRes.output.punteggio_parziale_sx,
        dx: ocraRes.output.punteggio_parziale_dx,
      };
      let ocra = {
        sx: {
          otherTemp: {
            numeroCicli: ocraRes.output.numero_cicli,
            totale_secondi_tempo_passivo:
              ocraRes.output.totale_secondi_tempo_passivo,
            moltiplicatore_recupero: ocraRes.output.moltiplicatore_recupero,
            tempoCiclo: ocraRes.output.tempo_ciclo_posture_forza,
            stationName: selectedTask.taskDetail?.ocra?.sx?.otherTemp
              ?.stationName
              ? selectedTask.taskDetail?.ocra?.sx?.otherTemp?.stationName
              : selectedTask.taskDetail.stationName,
            ocraRes: ocraRes,
            index: {
              indexResults: indexResults,
              index_compl_precisione_dx:
                ocraMemory["index_compl_precisione_dx"],
              index_compl_vibrazioni_dx:
                ocraMemory["index_compl_vibrazioni_dx"],
              index_compl_compressioni_dx:
                ocraMemory["index_compl_compressioni_dx"],
              index_compl_movimenti_rapidi_dx:
                ocraMemory["index_compl_movimenti_rapidi_dx"],
              index_compl_altri_dx: ocraMemory["index_compl_altri_dx"],
              index_compl_colpi_dx: ocraMemory["index_compl_colpi_dx"],
              index_compl_precisione_sx:
                ocraMemory["index_compl_precisione_sx"],
              index_compl_vibrazioni_sx:
                ocraMemory["index_compl_vibrazioni_sx"],
              index_compl_compressioni_sx:
                ocraMemory["index_compl_compressioni_sx"],
              index_compl_movimenti_rapidi_sx:
                ocraMemory["index_compl_movimenti_rapidi_sx"],
              index_compl_altri_sx: ocraMemory["index_compl_altri_sx"],
              index_compl_colpi_sx: ocraMemory["index_compl_colpi_sx"],
              // Borg
              index_borg1_dx: ocraMemory["index_borg1_dx"],
              index_borg2_dx: ocraMemory["index_borg2_dx"],
              index_borg3_dx: ocraMemory["index_borg3_dx"],
              index_borg4_dx: ocraMemory["index_borg4_dx"],
              index_borg5_dx: ocraMemory["index_borg5_dx"],
              index_borg6_dx: ocraMemory["index_borg6_dx"],
              index_borg7_dx: ocraMemory["index_borg7_dx"],
              index_borg8_dx: ocraMemory["index_borg8_dx"],
              index_borg9_dx: ocraMemory["index_borg9_dx"],
              index_borg10_dx: ocraMemory["index_borg10_dx"],
              index_borg510_dx: ocraMemory["index_borg510_dx"],
              index_borg1_sx: ocraMemory["index_borg1_sx"],
              index_borg2_sx: ocraMemory["index_borg2_sx"],
              index_borg3_sx: ocraMemory["index_borg3_sx"],
              index_borg4_sx: ocraMemory["index_borg4_sx"],
              index_borg5_sx: ocraMemory["index_borg5_sx"],
              index_borg6_sx: ocraMemory["index_borg6_sx"],
              index_borg7_sx: ocraMemory["index_borg7_sx"],
              index_borg8_sx: ocraMemory["index_borg8_sx"],
              index_borg9_sx: ocraMemory["index_borg9_sx"],
              index_borg10_sx: ocraMemory["index_borg10_sx"],
              index_borg510_sx: ocraMemory["index_borg510_sx"],
              // Statiche
              index_static_dx: ocraMemory["index_static_dx"],
              index_static_sx: ocraMemory["index_static_sx"],
            },
          },
          frequenza: ocraRes.output.rischio_AT_sx,
          forza: ocraRes.output.risk_score_borg_sx,
          postura: {
            spalla: ocraRes.output.rischi_totali.spalla_sx,
            gomito: ocraRes.output.rischi_totali.gomito_sx,
            polso: ocraRes.output.rischi_totali.polso_sx,
            mano: ocraRes.output.rischi_totali.mano_sx,
          },
          stereotipia: ocraRes.output.stereotipia_sx,
          totale_postura: ocraRes.output.rischio_posture_sx,
          complementari: ocraRes.output.complementare_sx,
          parziale: ocraRes.output.punteggio_parziale_sx,
          intrinseco: ocraRes.output.punteggio_parziale_intrinseco_sx,
          finale: ocraRes.output.punteggio_finale_sx,
        },
        dx: {
          frequenza: ocraRes.output.rischio_AT_dx,
          forza: ocraRes.output.risk_score_borg_dx,
          postura: {
            spalla: ocraRes.output.rischi_totali.spalla_dx,
            gomito: ocraRes.output.rischi_totali.gomito_dx,
            polso: ocraRes.output.rischi_totali.polso_dx,
            mano: ocraRes.output.rischi_totali.mano_dx,
          },
          stereotipia: ocraRes.output.stereotipia_dx,
          totale_postura: ocraRes.output.rischio_posture_dx,
          complementari: ocraRes.output.complementare_dx,
          parziale: ocraRes.output.punteggio_parziale_dx,
          intrinseco: ocraRes.output.punteggio_parziale_intrinseco_dx,
          finale: ocraRes.output.punteggio_finale_dx,
        },
      };
      let body = { result: updatedResults, scores: scores, ocra: ocra };

      // CALL API PER UPDATE TASK
      let res = await updateTaskResult(
        selectedTask.task,
        body,
        await getToken(session)
      );
    }
    // NIOSH
    if (availableProtocols.some((prot, id) => prot.ref == protocols[1].ref)) {
      let updateNiosh = await updateTaskNiosh(
        selectedTask.taskDetail.id,
        { ...vliMemory, m: vliRes.m, f: vliRes.f },
        await getToken(session)
      );
    }
    onSuccess && onSuccess();
  };

  function isDeepEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Verifica se il numero di proprietà è lo stesso
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Controlla se i valori di ciascuna proprietà sono uguali
    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);

      // Se sono oggetti, effettua un controllo ricorsivo
      if (
        (areObjects && !isDeepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  function isObject(object) {
    return object != null && typeof object === "object";
  }

  function rimuoviChiaviESostituisci(oggettoOriginale) {
    const chiaviDaRimuovere = [
      "numero_azioni_dinamiche_dx",
      "numero_azioni_dinamiche_sx",
      "secondi_mano_incongrua_dx",
      "secondi_mano_incongrua_sx",
      "secondi_spalla_incongrua_dx",
      "secondi_spalla_incongrua_sx",
      "secondi_polso_incongrua_dx",
      "secondi_polso_incongrua_sx",
      "secondi_gomito_incongrua_dx",
      "secondi_gomito_incongrua_sx",
      //INDEX
      "index_compl_precisione_dx",
      "index_compl_vibrazioni_dx",
      "index_compl_compressioni_dx",
      "index_compl_movimenti_rapidi_dx",
      "index_compl_altri_dx",
      "index_compl_colpi_dx",
      "index_compl_precisione_sx",
      "index_compl_vibrazioni_sx",
      "index_compl_compressioni_sx",
      "index_compl_movimenti_rapidi_sx",
      "index_compl_altri_sx",
      "index_compl_colpi_sx",
      // Borg
      "index_borg1_dx",
      "index_borg2_dx",
      "index_borg3_dx",
      "index_borg4_dx",
      "index_borg5_dx",
      "index_borg6_dx",
      "index_borg7_dx",
      "index_borg8_dx",
      "index_borg9_dx",
      "index_borg10_dx",
      "index_borg510_dx",
      "index_borg1_sx",
      "index_borg2_sx",
      "index_borg3_sx",
      "index_borg4_sx",
      "index_borg5_sx",
      "index_borg6_sx",
      "index_borg7_sx",
      "index_borg8_sx",
      "index_borg9_sx",
      "index_borg10_sx",
      "index_borg510_sx",
      // Statiche
      "index_static_dx",
      "index_static_sx",
    ];

    // Crea una copia dell'oggetto originale
    let copiaOggetto = { ...oggettoOriginale };

    // Rimuovi le chiavi specificate dalla copia
    chiaviDaRimuovere.forEach((chiave) => {
      delete copiaOggetto[chiave];
    });

    return copiaOggetto;
  }

  const checkRecovery = (graphMemory) => {
    if (screen == 1) {
      if (graphMemory[analysis.id_local]) return false;
      else return true;
    } else {
      if (
        isDeepEqual(rimuoviChiaviESostituisci(ocraMemory), {
          ...selectedTask.checklist,
          uso_martelli_dx: 0,
          uso_mani_colpi_dx: 0,
          strumenti_vibranti_dx: 0,
          altro_dx: 0,
          uso_martelli_sx: 0,
          uso_mani_colpi_sx: 0,
          strumenti_vibranti_sx: 0,
          altro_sx: 0,
        })
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  function formattaData(dataISO) {
    const mesi = [
      t("gen"),
      t("feb"),
      t("mar"),
      t("apr"),
      t("may"),
      t("jun"),
      t("jul"),
      t("aug"),
      t("sep"),
      t("oct"),
      t("nov"),
      t("dec"),
    ];
    const data = new Date(dataISO);

    // Estrai i componenti della data
    const giorno = data.getDate();
    const mese = mesi[data.getMonth()];
    const anno = data.getFullYear().toString().slice(-2); // Estrai gli ultimi due numeri dell'anno

    // Formatta la data nel formato desiderato
    return `${giorno} ${mese} ‘${anno}`;
  }
  console.log("ocraMemory", ocraMemory);

  const setReviewedBtn = async () => {
    setReviewing(true);
    let res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.LEFT,
      await getToken(session)
    );
    res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.RIGHT,
      await getToken(session)
    );
    res = await setReviewed(
      selectedTask.inspection,
      selectedTask.task,
      selectedTask.ai.HANDS,
      await getToken(session)
    );
    setReviewing(false);
    setReviewedState(true);
  };

  if (!availableProtocols)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: colors.background, // Sfondo semitrasparente, ad esempio
          zIndex: 999, // Assicurati che sia sopra gli altri elementi
        }}
      >
        <Spinner />
      </div>
    );
  else
    return (
      <AnalysisContainerWrapper data-testid={dataTestId}>
        <AnalysisContainerLeftColumn
          saving={saving}
          savingEnabled={savingEnabled}
          popupRef={popupRef}
          onClose={onClose}
          selectedTask={selectedTask}
          availableProtocols={availableProtocols}
          selectedProtocol={selectedProtocol}
          setSelectedProtocol={setSelectedProtocol}
          t={t}
          screen={screen}
          setScreen={setScreen}
          formattaData={formattaData}
          ocraRes={ocraRes}
          ocraMemory={ocraMemory}
          checklistRef={checklistRef}
          setChecklist={setChecklist}
          checklists_menu={checklists_menu}
          analysis={analysis}
          setAnalysis={setAnalysis}
          setSelectedGraph={setSelectedGraph}
          availableAnalysis={availableAnalysis}
          vliMemory={vliMemory}
          setVliMemory={setVliMemory}
          vliRes={vliRes}
        />
        <AnalysisContainerRightColumn
          selectedTask={selectedTask}
          screen={screen}
          analysis={analysis}
          checklist={checklist}
          user={user}
          reviewedState={reviewedState}
          reviewing={reviewing}
          setReviewedBtn={setReviewedBtn}
          state={state}
          ocraRes={ocraRes}
          savingEnabled={savingEnabled}
          save={save}
          saving={saving}
          t={t}
          checkRecovery={checkRecovery}
          graphMemory={graphMemory}
          lineChartRef={lineChartRef}
          setOcraMemory={setOcraMemory}
          setAllowRecover={setAllowRecover}
          videoLoaded={videoLoaded}
          setVideoLoaded={setVideoLoaded}
          videoId={videoId}
          setSelectedGraph={setSelectedGraph}
          refresh={refresh}
          setRefresh={setRefresh}
          ocraMemory={ocraMemory}
          availableProtocols={availableProtocols}
          selectedProtocol={selectedProtocol}
          selectedGraph={selectedGraph}
          videoLoadedRef={videoLoadedRef}
          setGraphMemory={setGraphMemory}
          vliMemory={vliMemory}
          setVliMemory={setVliMemory}
          vliRes={vliRes}
          setEditingGraph={setEditingGraph}
          editingGraph={editingGraph}
        />
        <PopupCreate
          ref={popupRef}
          title={t("exitPopup")}
          children={
            <PopupAlert
              message={t("exitPopupText")}
              negativeLabel={t("exitPopupNegative")}
              positiveLabel={t("exitPopupPositive")}
              positive={() => save(onClose)}
              negative={onClose}
            />
          }
        />
      </AnalysisContainerWrapper>
    );
};

AnalysisContainer.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
  data: PropTypes.object,
  file: PropTypes.string,
};

AnalysisContainer.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
  data: { PROVA_ANGLE: { labels: [], values: [] } },
  file: null,
};

export default AnalysisContainer;
