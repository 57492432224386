import React, { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

const TreeToolTip = ({
  icon,
  heading,
  content,
  className = "",
  shouldClose,
  onEmptyClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TooltipProvider delayDuration={100}>
        <Tooltip
          open={isOpen}
          onOpenChange={(val) => {
            if (shouldClose) {
              setIsOpen(false);
            } else {
              setIsOpen(val);
            }
          }}
        >
          <TooltipTrigger asChild>
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                // e.stopPropagation();
                if (!content && onEmptyClick) {
                  onEmptyClick();
                }
              }}
            >
              {icon}
            </span>
          </TooltipTrigger>
          {content && (
            <TooltipContent
              className={`${
                className
                  ? className
                  : "w-[25vw] sm:w-[20vw] p-[1vw] bg-white shadow-[0px_10px_20px_0px_rgba(113,109,242,0.3)] "
              }`}
              side="bottom"
            >
              <div className="flex flex-col break-words whitespace-normal justify-center">
                {heading && (
                  <div className="text-[1vw] leading-[1.3vw] font-bold font-atkinson  break-words whitespace-normal">
                    {heading}
                  </div>
                )}

                {content && (
                  <div
                    className={`font-atkinson leading-[1.1vw] text-[1.4vw] sm:text-[1.2vw] md:text-[0.9vw] lg:text-[0.85vw] xl:text-[0.8vw] break-words whitespace-normal ${
                      heading ? "pt-[0.2vw]" : ""
                    }`}
                  >
                    {content}
                  </div>
                )}
              </div>
            </TooltipContent>
          )}
          {!content && (
            <TooltipContent
              className={`${
                className
                  ? className
                  : " text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18) "
              }`}
              side="bottom"
            >
              <div
                className={`font-atkinson leading-[1.1vw] text-[1.4vw] sm:text-[1.2vw] md:text-[0.9vw] lg:text-[0.85vw] xl:text-[0.8vw] break-words whitespace-normal`}
              >
                Descrizione mancante, clicca per aggiungerla
              </div>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </>
  );
};

export default TreeToolTip;
