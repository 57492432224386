import { DATA_A } from "../actions/TypeActions";
import DataReducer from "./DataReducer";

const rootReducer = (state, action) => {
  switch (action.type) {
    case DATA_A:
      return DataReducer(state, action);

    default:
      return state;
  }
};

export default rootReducer;
