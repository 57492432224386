import styled from "styled-components";
import { colors } from "../../config/style";

export const PopupWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.backgroundOpaque};
  z-index: 9999;
  position: absolute;
  top: 0px;
  left: 0px;
`;
