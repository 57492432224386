import { useClerk, useUser } from "@clerk/clerk-react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { colors } from "../../config/style";
import { defaultCompany } from "../../remote/defaultVariable";
import {
  getCompanies,
  getCustomers,
  getLocation,
  getLocations,
  newCompany,
  newLocation,
} from "../../remote/structure";
import { LOAD_CUSTUMER, LOAD_LOCATION } from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import { useStateValue } from "../../stores/services/StateProvider";
import { getToken } from "../../userManagement/utilities";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { PopupCreateEdit } from "../Popup/Popup";
import { PopupCompany } from "../Popup/PopupContent";

const LocationSelector = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [locations, setLocations] = useState();
  const [companies, setCompanies] = useState();
  const [popupContent, setPopupContent] = useState({
    info: "",
    children: "",
    actionLabel: "",
  });

  const popupRef = useRef();

  const { session } = useClerk();
  const { isSignedIn, user } = useUser();

  const { t } = useTranslation();

  useEffect(() => {
    loadLocations(true);
    return () => {
      setLocations();
    };
  }, []);

  const loadLocations = async (init) => {
    //Load all locations

    let jwt = await getToken(session);

    let companies = await getCompanies(jwt);

    setCompanies(companies);

    let locationsLocal = await getLocations(jwt);

    setLocations(convertiPerReactSelect(locationsLocal, companies));

    if (init && locationsLocal.length > 0) {
      let idCookie = Cookies.get("lastLocation");
      let defaultLocation;
      try {
        // defaultLocation = await getLocation(
        //   idCookie ? idCookie : locationsLocal[0].id,
        //   jwt
        // );
        await loadLocation(idCookie ? idCookie : locationsLocal[0].id);
      } catch (error) {
        // Gestione dell'errore
        console.log("ERROR", error);
        Cookies.remove("lastLocation");
        await loadLocation(locationsLocal[0].id);
        // defaultLocation = await getLocation(locationsLocal[0].id, jwt);
      }
      // LOAD LOCATION
      // dispatch({
      //   type: DATA_A,
      //   subtype: LOAD_LOCATION,
      //   action: {
      //     ...defaultLocation,
      //   },
      // });

      // let customers = await getCustomers(jwt);
      // let locationCustomer = customers.find(
      //   (item) => item.name === defaultLocation?.ownerCompany
      // );
      // // CASO ADMIN SOVRASCRIVI EXPERT COMPANY
      // if (user?.publicMetadata?.role == "admin")
      //   dispatch({
      //     type: DATA_A,
      //     subtype: LOAD_CUSTUMER,
      //     action: { ...locationCustomer, expertCompany: true },
      //   });
      // else
      //   dispatch({
      //     type: DATA_A,
      //     subtype: LOAD_CUSTUMER,
      //     action: { ...locationCustomer },
      //   });
    } else if (locationsLocal.length == 0 && init) {
    }
  };

  const loadLocation = async (id) => {
    Cookies.set("lastLocation", id);

    // LOCATION
    let location = await getLocation(id, await getToken(session));
    dispatch({
      type: DATA_A,
      subtype: LOAD_LOCATION,
      action: {
        ...location,
      },
    });

    try {
      // CUSTUMER
      let customers = await getCustomers(await getToken(session));
      let locationCustomer = customers.find(
        (item) => item.name === location?.ownerCompany
      );
      // SE CUSTOMER NON ESISTE CARICA PARAMETRI STANDARD
      if (!locationCustomer) {
        locationCustomer = {
          expertCompany: false,
          index: false,
          name: location?.ownerCompany,
        };
      }

      // CASO ADMIN SOVRASCRIVI EXPERT COMPANY
      if (user?.publicMetadata?.role == "admin")
        dispatch({
          type: DATA_A,
          subtype: LOAD_CUSTUMER,
          action: { ...locationCustomer, expertCompany: true, index: true },
        });
      else {
        dispatch({
          type: DATA_A,
          subtype: LOAD_CUSTUMER,
          action: { ...locationCustomer },
        });
      }
    } catch (error) {
      console.log("ERRORE CUSTOMER NON PRESENTE");
      dispatch({
        type: DATA_A,
        subtype: LOAD_CUSTUMER,
        action: { expertCompany: false, index: false },
      });
    }
  };

  function convertiPerReactSelect(data, companies) {
    // Inizializzazione dei gruppi con tutte le aziende
    if (!Array.isArray(companies)) {
      console.error('Il parametro "companies" deve essere un array.');
      return [];
    }
    const grouped = companies.reduce((acc, company) => {
      acc[company.name] = {
        label: company.name,
        id: company.id,
        options: [],
        hasLocations: false, // Indica se il gruppo ha delle sedi
      };
      return acc;
    }, {});

    // Aggiunta delle sedi esistenti ai rispettivi gruppi
    data.forEach((item) => {
      if (grouped[item.company]) {
        grouped[item.company].options.push({
          ...item,
        });
        grouped[item.company].hasLocations = true;
      }
    });

    // Separazione e ordinamento dei gruppi con e senza sedi
    let groups = [];

    Object.keys(grouped).forEach((key) => {
      const group = grouped[key];
      // Ordina le sedi all'interno del gruppo
      group.options.sort((a, b) => a.name.localeCompare(b.name));

      if (!group.hasLocations) {
        // Aggiungi opzione disabilitata per i gruppi vuoti
        group.options.push({
          label: t("companySelNoLocation"),
          isDisabled: true,
        });
      }

      groups.push(group);
    });

    // Ordinamento alfabetico dei gruppi
    groups.sort((a, b) => a.label.localeCompare(b.label));

    // Unione dei gruppi con e senza sedi

    // Aggiungi il gruppo "Add" alla fine
    groups.push({
      label: t("companySelAddCompany"),
      id: "add",
      options: [
        {
          label: t("companySelAddLocation"),
          value: "add_new_location",
          isDisabled: true,
        },
      ],
    });

    groups.unshift({
      label: t("companySelList"),
      id: "header",
      options: [
        {
          label: t("companySelAddLocation"),
          value: "add_new_location",
          isDisabled: true,
        },
      ],
    });

    const groupKeys = Object.keys(groups);
    groupKeys.forEach((key, index) => {
      groups[key].showDivider = index > 0; // Mostra il divisore solo dal secondo gruppo in poi
    });

    return Object.values(groups);

    // return finalGroups;
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontFamily: "Atkinson Hyperlegible, sans-serif",
      boxShadow: "none",
      outline: "none",
      fontSize: "0.82vw",
      width: "9.5vw",
      height: "4vw",
      minHeight: "4vw",
      borderWidth: "0.15vw",
      cursor: "pointer",
      padding: "0px",
      borderColor:
        state.isFocused || state.selectProps.menuIsOpen
          ? colors.background
          : colors.background,
      // boxShadow: state.isFocused ? `0 0 0 0.03vw ${colors.main}` : "none",

      "&:hover": {
        borderColor: colors.background,
        ".your-custom-dropdown-indicator-class": {
          backgroundImage: `url(${
            process.env.REACT_APP_RESOURCES_BUCKET + "SelectorOpenHw.png"
          })`,
        },
      },
    }),

    indicatorSeparator: () => ({
      display: "none", // This removes the vertical separator
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      svg: { display: "none" },
      className: "your-custom-dropdown-indicator-class",
      backgroundImage: `url(${
        state.selectProps.menuIsOpen
          ? process.env.REACT_APP_RESOURCES_BUCKET + "SelectorOpenHw.png"
          : process.env.REACT_APP_RESOURCES_BUCKET + "SelectorOpen.png"
      })`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      color: colors.text, // Cambia il colore dell'indicatore
      // Imposta altezza e larghezza più piccole per l'elemento dell'indicatore
      height: "1.5vw",
      width: "1.5vw",
      // Riduci il padding per ridurre lo spazio totale occupato dall'indicatore
      padding: "0.2vw",
      marginRight: "0.2vw",
      // Riduci la dimensione del font dell'icona (se applicabile)
      fontSize: "0.8vw",
      "&:hover": {
        color: colors.text, // Colore dell'indicatore al passaggio del mous
        backgroundImage: `url(${
          process.env.REACT_APP_RESOURCES_BUCKET + "SelectorOpenHw.png"
        })`,
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      // Allinea il testo a sinistra
      textAlign: "left",
      // Aumenta il padding a sinistra se necessario per spostare il testo più a sinistra
      padding: "0vw",
      marginLeft: "-0.3vw",
      width: "9vw",
      // Altre modifiche di stile secondo necessità
    }),

    menu: (base) => ({
      ...base,
      fontFamily: "Atkinson Hyperlegible, sans-serif",
      fontSize: "0.82vw",
      width: "16vw",
      whiteSpace: "nowrap", // Impedisce la suddivisione delle righe
      overflowX: "hidden", // Nasconde lo scroll orizzontale
    }),
    groupHeading: (provided) => ({
      ...provided,
      textTransform: "none", // Impedisce la trasformazione del testo in maiuscolo
    }),
    option: (base, state) => ({
      ...base,
      overflow: "hidden", // Impedisce il contenuto di estendersi oltre i bordi
      textOverflow: "ellipsis",
      display: state.isDisabled ? "none" : base.display, // Nasconde l'opzione disabilitata
      pointerEvents: state.isDisabled ? "none" : base.pointerEvents,
      width: "13.5vw",
      backgroundColor: state.isSelected
        ? "white"
        : state.isFocused
        ? "white"
        : null,
      color: colors.text,
      "&:hover": {
        backgroundColor: colors.disabled,
        color: colors.text,
      },
    }),
  };

  const formatGroupLabel = (data) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "14vw",
        marginTop: "0vw",
      }}
    >
      {data.showDivider && (
        <div
          style={{
            borderTop: "0.15vw solid " + colors.background,
            marginLeft: "-0.2vw",
            marginRight: "-0.2vw",
            marginTop: "-0.5vw",
            marginBottom: "0.2vw",
          }}
        />
      )}
      {data.id != "add" && data.id != "header" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "14vw",
            gap: "0vw",
            marginLeft: "-0.5vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={process.env.REACT_APP_RESOURCES_BUCKET + "Company.png"}
              alt={"props.data.label"}
              style={{ width: "1.4vw" }}
            />
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.8vw",
                fontWeight: "400",
                boxSizing: "border-box",
                margin: 0,
              }}
            >
              {data.label}
            </p>
          </div>

          <ButtonTextIcon
            color={colors.main}
            backgroundH={colors.details}
            background={"transparent"}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Cross.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Cross.png"}
            onClick={() => {
              setPopupContent({
                title: t("addLocationPopupTitle") + data.label,
                actionLabel: t("createLocation"),
                phName: t("locationName"),
                api: async (name, description, setLoading) => {
                  console.log("hereeee", name, description);
                  setLoading(true);
                  let request = await newLocation(
                    data.id,
                    { name: name, description: description },
                    await getToken(session)
                  );
                  loadLocations(false);
                  loadLocation(request.id);
                  popupRef.current.close();
                },
              });
              popupRef.current.open();
            }}
            style={{
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "1.6vw",
              fontWeight: "700",
              width: "1.6vw",
              paddingLeft: "0.25vw",
              // padding: "0.3vw",
              marginRight: "-1vw",
              boxSizing: "border-box",
            }}
          />
        </div>
      ) : data.id == "header" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "0.3vw",
            marginLeft: "-0.1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                color: colors.detail,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.8vw",
                fontWeight: "400",
                boxSizing: "border-box",
                margin: 0,
              }}
            >
              {data.label}
            </p>
          </div>
        </div>
      ) : (
        <ButtonTextIcon
          color={colors.main}
          label={t("companySelAddCompany")}
          backgroundH={colors.details}
          background={"transparent"}
          icon={process.env.REACT_APP_RESOURCES_BUCKET + "Crosshw.png"}
          iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Crosshw.png"}
          onClick={() => {
            setPopupContent({
              title: t("addCompanyPopupTitle"),
              actionLabel: t("createCompany"),
              phName: t("companyName"),
              api: async (name, description, setLoading) => {
                setLoading(true);
                let request = await newCompany(
                  {
                    name: name,
                    description: description
                      ? description
                      : defaultCompany.description,
                  },
                  await getToken(session)
                );

                loadLocations(false);
                popupRef.current.close();
              },
            });
            popupRef.current.open();
          }}
          style={{
            fontSize: "0.82vw",
            boxShadow: "0px 0px 0px 0px white",
            height: "1.5vw",
            fontWeight: "700",
            width: "8.5vw",
            paddingLeft: "0.2vw",
            paddingRight: "0vw",
            // padding: "0.3vw",
            gap: "0.1vw",
            marginRight: "0vw",
            marginLeft: "-0.5vw",
            boxSizing: "border-box",
          }}
        />
      )}
    </div>
  );

  const Option = (props) => (
    <components.Option {...props}>
      {props.data.id == "empty" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            width: "0vw",
            gap: "0vw",
            height: "0px",
          }}
        ></div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            // width: "7vw",
            gap: "0.3vw",
            marginLeft: "-0.2vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "0.8vw",
                fontWeight: "400",
                boxSizing: "border-box",
                margin: 0,
              }}
            >
              {props.data.name}
            </p>
          </div>
        </div>
      )}
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          width: "6vw",
          gap: "0.3vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Company.png"}
            alt={"props.data.label"}
            style={{ width: "1.4vw" }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.8vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: 0,
            }}
          >
            {props.data.company}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Location.png"}
            alt={"props.data.label"}
            style={{ width: "1.4vw" }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.8vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: 0,
            }}
          >
            {props.data.name}
          </p>
        </div>
      </div>
    </components.SingleValue>
  );

  if (locations)
    return (
      <>
        <Select
          options={locations}
          styles={customStyles}
          value={state.current_location}
          components={{ Option, SingleValue }}
          placeholder={
            state.current_location == -2
              ? t("companySelLoading")
              : t("companySelSelecting")
          }
          isSearchable={false}
          onChange={(selectedOption) => {
            dispatch({
              type: DATA_A,
              subtype: LOAD_LOCATION,
              action: -2,
            });
            loadLocation(selectedOption.id);
          }}
          formatGroupLabel={formatGroupLabel}
        />
        <PopupCreateEdit ref={popupRef} popupContent={popupContent} />
      </>
    );
};

LocationSelector.propTypes = {
  dataTestId: PropTypes.string, // Definisci il tipo di prop "dataTestId"
};

LocationSelector.defaultProps = {
  dataTestId: "default-data-testid", // Imposta un valore predefinito per "dataTestId"
};

export default LocationSelector;
