import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import ToolTipIcon from "../../icons/ToolTipIcon";
import EditIcon from "../../icons/EditIcon";
import CopyIcon from "../../icons/CopyIcon";
import DeleteIcon from "../../icons/DeleteIcon";

const ActionMenu = ({
  icon,
  onDelete,
  onClickEdit,
  onOpenChange,
  itemName,
  onClickDescription,
  onClickDuplicate,
  disableDuplicate,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        if (onOpenChange) {
          onOpenChange(isOpen);
        }
      }}
    >
      <DropdownMenuTrigger asChild>
        <span>{icon}</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[12.5vw] pb-1 flex flex-col gap-[0.42vw] rounded-lg shadow-[0px_10px_20px_0px_rgba(113,109,242,0.3)]"
        side="bottom"
        align="start"
      >
        <DropdownMenuItem
          className="rounded-md m-[0.1vw] mb-0 cursor-pointer"
          onClick={() => {
            if (onClickEdit) {
              onClickEdit();
            }
          }}
        >
          <div className="flex justify-center items-center gap-[0.42vw]">
            <EditIcon size={"1.6vw"} fill={"#212040"} />
            <span className="font-atkinson  text-[0.96vw]  font-normal text-popupText">
              Rinomina
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="rounded-md m-[0.1vw] mb-0 mt-[0vw] cursor-pointer"
          onClick={() => {
            if (onClickDescription) {
              onClickDescription();
            }
          }}
        >
          <div className="flex gap-[0.42vw] justify-center items-center ">
            <ToolTipIcon size={"1.6vw"} hover={false} />
            <span className="font-atkinson  text-[0.96vw] font-normal text-popupText">
              Descrizione
            </span>
          </div>
        </DropdownMenuItem>
        {disableDuplicate && (
          <DropdownMenuItem
            className="rounded-md m-[0.1vw] mb-0 mt-[0vw] cursor-pointer"
            onClick={() => {
              if (onClickDuplicate) {
                onClickDuplicate();
              }
            }}
          >
            <div className="flex gap-[0.42vw] justify-center items-center ">
              <CopyIcon size={"1.6vw"} fill={"#212040"} />
              <span className="font-atkinson  text-[0.96vw] font-normal text-popupText">
                Duplica
              </span>
            </div>
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator className="h-[0.3vh] bg-[#EFEDFF] w-[97%] m-auto mb-0" />
        <DropdownMenuItem
          className="rounded-md m-[0.1vw] mb-0 mt-[-0.15vw] cursor-pointer focus:bg-[#FFE1E0] "
          onClick={() => {
            if (onDelete) {
              onDelete();
            }
          }}
        >
          <div className="flex gap-[0.42vw] justify-center items-center">
            <DeleteIcon size={"1.6vw"} fill={"#EB5E5E"} />
            <span className="font-atkinson   text-[0.96vw] font-normal  text-red-500">
             Elimina
            </span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ActionMenu;
