import { useUser } from "@clerk/clerk-react";
import Chart from "chart.js/auto";
import _ from "lodash";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { editColors, lineColor } from "../../config/graphsConfig";
import { colors } from "../../config/style";
import { useStateValue } from "../../stores/services/StateProvider";
import { PopupError } from "../Popup/Popup";
import {
  backgroundPlugin,
  lineaVerticalePlugin,
  visibilityPlugin,
} from "./ChartPlugins";
import {
  changeSpan,
  checkpoint,
  createChart,
  createValueArray,
  extractRangeArray,
  formatTime,
  load,
  positionButtons,
  setAlpha,
} from "./ChartUtlis";
import { LineChartWrapper } from "./LineChart.styled";
import BatchLevelButtons from "./LineChartComponents/BatchlevelButtons";
import ChartCanvas from "./LineChartComponents/ChartCanvas";
import ChartHeader from "./LineChartComponents/ChartHeader";
import ChartSideControls from "./LineChartComponents/ChartSideControls";

const LineChart = forwardRef(
  (
    {
      data,
      videoId,
      videoLoaded,
      id,
      graphMemory,
      setGraphMemory,
      setSelectedGraph,
      setEditingGraph,
    },
    ref
  ) => {
    const [state, dispatch] = useStateValue();
    const [selectedChart, setSelectedChart] = useState(0);
    const [editing, setEditing] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [duration, setDuration] = useState(0);
    const [time, setTime] = useState(0);
    const [batchLevel, setBatchLevel] = useState([]);
    const [currentBatchLevel, setCurrentBatchLevel] = useState([0, 5]);
    const [editingBatch, setEditingBatch] = useState(false);
    const [editingBatchIndex, setEditingBatchIndex] = useState(0);
    const [batchIndex, setBatchIndex] = useState(0);
    const [videoDuration, setVideoDuration] = useState(0);
    const [showVisisbility, setShowVisisbility] = useState(true);
    const [visibilityIndex, setVisibilityIndex] = useState(-1);
    const [visibilityScore, setVisibilityScore] = useState(-1);

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const selectedSpan = useRef(0);
    const maxSpan = useRef(60);
    const editingRef = useRef(null);

    const batchLevelRef = useRef(null);
    const batchIndexRef = useRef(null);

    const dataForChart = useRef(null);
    const dataForChartSecondary = useRef(null);
    const dataForChartHistory = useRef([]);
    const historyIndex = useRef(0);
    // const batchIndex = useRef(0);
    const backupAddedError = useRef(null);
    const enableMouseLocation = useRef(null);
    const buttonsContainer = useRef(null);

    const visibilityIndexRef = useRef(null);
    const visibilityScoreRef = useRef(null);

    const popupErrorRef = useRef(null);

    const { t } = useTranslation();
    const { user } = useUser();

    editingRef.current = editing;
    visibilityIndexRef.current = visibilityIndex;
    visibilityScoreRef.current = visibilityScore;

    batchLevelRef.current = batchLevel;
    batchIndexRef.current = batchIndex;

    let memoryDepth = 10;
    let optionsGraph = Object.keys(data.graph).map((x) => {
      return { label: data.graph[x].label, value: x };
    });

    useImperativeHandle(ref, () => ({
      refresh: () => {
        setBatchLevel([]);
        load(
          graphMemory,
          dataForChart,
          data,
          state,
          videoLoaded,
          selectedChart,
          dataForChartSecondary,
          createChart,
          chartRef,
          chartInstance,
          maxSpan,
          user,
          selectedSpan,
          formatXAxisLabel,
          setBatchLevel,
          setVisibilityScore,
          setGraphMemory
        );
      },
    }));

    console.log("DATAFORCHART", dataForChart.current);
    console.log("STATE ANALYSIS", state);

    useEffect(() => {
      const backgroundPluginInstance = backgroundPlugin(dataForChart);

      const lineaVerticalePluginInstance = lineaVerticalePlugin(dataForChart);

      // Register the plugin when the component mounts
      Chart.register(backgroundPluginInstance);
      Chart.register(visibilityPlugin);
      Chart.register(lineaVerticalePluginInstance);

      return () => {
        Chart.unregister(backgroundPluginInstance);
        Chart.unregister(visibilityPlugin);
        Chart.unregister(lineaVerticalePluginInstance);
      };
    }, [dataForChart]);

    useEffect(() => {
      if (chartInstance.current) {
        if (editing) {
          chartInstance.current.lineColor = editColors[0];
          chartInstance.current.editing = editing;
          chartInstance.current.update("none");
        } else {
          chartInstance.current.lineColor = lineColor;
          chartInstance.current.editing = false;
          chartInstance.current.update("none");
        }
      }
    }, [editing]);

    useEffect(() => {
      if (chartInstance.current) {
        if (showVisisbility && !editing) {
          chartInstance.current.visibility = dataForChart.current.visibility;
          chartInstance.current.update("none");
        } else {
          chartInstance.current.visibility = [];
          chartInstance.current.update("none");
        }
      }
    }, [showVisisbility, editing]);

    useEffect(() => {
      if (batchLevel[batchIndex] != null) {
        dataForChart.current.batchLevel = batchLevel[batchIndex];

        if (batchLevel.length > 0 && dataForChart.current.originalBatchLevel) {
          let res = createValueArray(
            dataForChart.current.labels,
            batchLevel[batchIndex],
            dataForChart.current.originalBatchLevel,
            data.graph[selectedChart].batch
          );

          dataForChart.current.values = [res.values];
          dataForChart.current.errors = [res.errors];

          // INDEX

          let indexValues = [];
          let indexEdit = [];
          let indexValid = [];
          let indexSpan = res.values.map((x) => null);

          if (
            dataForChart.current?.errorsIndex &&
            dataForChart.current?.errorsIndex[editingBatchIndex] &&
            editingBatch &&
            state?.current_customer?.index
          ) {
            let comulative = data.graph[selectedChart].orderIndex.reduce(
              (acc, chiave) =>
                acc +
                (dataForChart.current.errorsIndex[editingBatchIndex][chiave] ||
                  0),
              0
            );
            data.graph[selectedChart].orderIndex.forEach((el, id) => {
              indexValues.push(res.values.map((x) => comulative));
              let edited =
                dataForChart.current.errorsIndex[editingBatchIndex][el] !=
                dataForChart.current.errorsIndexOriginal[editingBatchIndex][el];
              indexEdit.push(edited);
              indexValid.push(
                dataForChart.current.errorsIndex[editingBatchIndex][el] != 0
              );
              comulative =
                comulative -
                dataForChart.current.errorsIndex[editingBatchIndex][el];
            });
            let rangeInfo = extractRangeArray(res.values, editingBatchIndex);
            indexSpan = rangeInfo.outputArray;

            // Oscuramento laterale

            let currentEditing = {
              start: rangeInfo.rangeStart - 2,
              startFix: null,
              end: rangeInfo.rangeEnd + 2,
              endFix: null,
              center: 0,
            };
            chartInstance.current.editing = currentEditing;
            chartInstance.current.update("none");
          } else {
            let currentEditing = {
              start: null,
              startFix: null,
              end: null,
              endFix: null,
              center: null,
            };
            chartInstance.current.editing = currentEditing;
            chartInstance.current.update("none");
          }

          dataForChart.current.indexValues = indexValues;
          dataForChart.current.indexEdit = indexEdit;
          dataForChart.current.indexValid = indexValid;
          dataForChart.current.indexSpan = indexSpan;

          // INDEX

          createChart(
            dataForChart.current,
            chartRef.current.getContext("2d"),
            graphMemory,
            selectedChart,
            data,
            chartInstance,
            setAlpha,
            maxSpan,
            user,
            selectedSpan,
            formatXAxisLabel,
            dataForChartSecondary,
            setGraphMemory
          );
          setRefresh(!refresh);
        }
      }
    }, [
      batchLevel,
      batchIndex,
      selectedChart,
      editingBatchIndex,
      editingBatch,
    ]);

    useEffect(() => {
      if (videoDuration > 0) {
        dataForChart.current = null;
        load(
          graphMemory,
          dataForChart,
          data,
          state,
          videoLoaded,
          selectedChart,
          dataForChartSecondary,
          createChart,
          chartRef,
          chartInstance,
          maxSpan,
          user,
          selectedSpan,
          formatXAxisLabel,
          setBatchLevel,
          setVisibilityScore,
          setGraphMemory
        );
        let video = document.getElementById(videoId);
        let lastTime = -1;
        function checkTime() {
          if (video.currentTime !== lastTime) {
            lastTime = video.currentTime;
            timeUpdateHandler(); // Chiama la tua funzione handler
          }
          requestAnimationFrame(checkTime);
        }

        requestAnimationFrame(checkTime);
        const timeUpdateHandler = () => {
          aggiornaGrafico(video.currentTime, chartInstance.current);
          setTime(video.currentTime);
        };
        positionButtons(chartInstance.current, buttonsContainer);
      }
    }, [videoDuration, selectedChart]);

    useEffect(() => {
      if (videoLoaded) {
        let video = document.getElementById(videoId);
        setVideoDuration(video.duration);
        if (video) {
          video.addEventListener("loadedmetadata", function () {
            // Stampa la durata del video
            setVideoDuration(video.duration);
          });
        }
      }
    }, [videoLoaded]);

    useEffect(() => {
      if (editingBatch || editing) {
        setEditingGraph(true);
      } else {
        setEditingGraph(false);
      }
    }, [editingBatch, editing]);

    function moveHistory(shift) {
      let newIndex = historyIndex.current - shift;
      if (
        newIndex > memoryDepth - 1 ||
        newIndex < 0 ||
        newIndex > dataForChartHistory.current.length - 1
      ) {
        return;
      }
      historyIndex.current = newIndex;
      dataForChart.current = _.cloneDeep(dataForChartHistory.current[newIndex]);
      createChart(
        dataForChart.current,
        chartRef.current.getContext("2d"),
        graphMemory,
        selectedChart,
        data,
        chartInstance,
        setAlpha,
        maxSpan,
        user,
        selectedSpan,
        formatXAxisLabel,
        dataForChartSecondary,
        setGraphMemory
      );
      setRefresh(!refresh);
    }

    function handleChartUpdate(timeValue) {
      if (!editingRef.current) {
        dataForChart.current = updateErrori(
          dataForChart.current,
          timeValue,
          true
        );
      }
    }

    function updateErrori(dataForChart, targetTime, click, evt) {
      let editingError = false;

      // Trova l'indice del tempo più vicino a targetTime
      let closestIndex = dataForChart.labels.reduce(
        (prev, curr, index) =>
          Math.abs(curr - targetTime) <
          Math.abs(dataForChart.labels[prev] - targetTime)
            ? index
            : prev,
        0
      );

      // Itera sugli array degli errori
      if (!editingRef.current) {
        // Case removing original error
        dataForChart.errors.forEach((errorArray) => {
          // Controlla e aggiorna l'elemento all'indice trovato e i suoi vicini
          if (closestIndex >= 0 && closestIndex < errorArray.length) {
            if (errorArray[closestIndex] === 1) {
              editingError = true;
              // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
              let originalValue = errorArray[closestIndex];
              let newValue = originalValue === 1 ? 2 : 1;
              // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
              // Verso sinistra
              for (
                let i = closestIndex;
                i >= 0 && errorArray[i] === originalValue;
                i--
              ) {
                errorArray[i] = newValue;
              }
              // Verso destra
              for (
                let i = closestIndex + 1;
                i < errorArray.length && errorArray[i] === originalValue;
                i++
              ) {
                errorArray[i] = newValue;
              }
            }
          }
        });
        dataForChart.addedErrors.forEach((errorArray) => {
          // Controlla e aggiorna l'elemento all'indice trovato e i suoi vicini
          if (errorArray[closestIndex] === 1) {
            editingError = true;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            let originalValue = errorArray[closestIndex];
            let newValue = 0;
            // Aggiorna l'elemento trovato e quelli adiacenti con lo stesso valore originale
            // Verso sinistra
            for (
              let i = closestIndex;
              i >= 0 && errorArray[i] === originalValue;
              i--
            ) {
              errorArray[i] = newValue;
            }
            // Verso destra
            for (
              let i = closestIndex + 1;
              i < errorArray.length && errorArray[i] === originalValue;
              i++
            ) {
              errorArray[i] = newValue;
            }
          }
        });
      }

      if (editingError) {
        // dataForChart.current = dataForChart;
        checkpoint(
          historyIndex,
          dataForChartHistory,
          dataForChart,
          memoryDepth,
          setDuration,
          data,
          selectedChart
        );
        createChart(
          dataForChart,
          chartRef.current.getContext("2d"),
          graphMemory,
          selectedChart,
          data,
          chartInstance,
          setAlpha,
          maxSpan,
          user,
          selectedSpan,
          formatXAxisLabel,
          dataForChartSecondary,
          setGraphMemory
        );
      } else {
        let currentEditing = editingRef.current;
        // Enter editing mode
        if (!currentEditing && click) {
          currentEditing = {
            start: null,
            startFix: false,
            end: null,
            endFix: false,
            center: closestIndex,
          };
          setEditing(currentEditing);
          chartInstance.current.editing = currentEditing;
          chartInstance.current.update("none");
        }
        // Edit start
        else if (
          closestIndex <= currentEditing.center &&
          (!currentEditing.startFix || closestIndex < currentEditing.start)
        ) {
          currentEditing = {
            start: closestIndex,
            startFix: click,
            end: currentEditing.endFix ? currentEditing.end : null,
            endFix: currentEditing.endFix,
            center: currentEditing.center,
          };
          setEditing(currentEditing);
        }
        // Edit end
        else if (
          closestIndex >= currentEditing.center &&
          (!currentEditing.endFix || closestIndex > currentEditing.end)
        ) {
          currentEditing = {
            start: currentEditing.startFix ? currentEditing.start : null,
            startFix: currentEditing.startFix,
            end: closestIndex,
            endFix: click,
            center: currentEditing.center,
          };
          setEditing(currentEditing);
        }

        if (currentEditing.startFix && currentEditing.endFix && click) {
          popupErrorRef.current.open(evt);
        } else if (currentEditing.start || currentEditing.end) {
          let start = currentEditing.start
            ? currentEditing.start
            : currentEditing.center;
          let end = currentEditing.end
            ? currentEditing.end
            : currentEditing.center;

          for (let i = start; i <= end; i++) {
            dataForChart.addingError[i] = 1;
          }

          createChart(
            dataForChart,
            chartRef.current.getContext("2d"),
            graphMemory,
            selectedChart,
            data,
            chartInstance,
            setAlpha,
            maxSpan,
            user,
            selectedSpan,
            formatXAxisLabel,
            dataForChartSecondary,
            setGraphMemory
          );
        }

        // createChart(dataForChart, chartRef.current.getContext("2d"));
      }

      return dataForChart;
    }

    function formatXAxisLabel(value) {
      return Math.round(parseFloat(value)).toString();
    }

    function aggiornaGrafico(currentTime, chart) {
      let currentMin = selectedSpan.current * maxSpan.current;
      let currentMax = (selectedSpan.current + 1) * maxSpan.current;

      if (currentTime < currentMin) {
        changeSpan(
          selectedSpan.current - 1,
          chartInstance,
          maxSpan,
          setCurrentBatchLevel,
          selectedSpan
        );
      } else if (currentTime > currentMax) {
        changeSpan(
          selectedSpan.current + 1,
          chartInstance,
          maxSpan,
          setCurrentBatchLevel,
          selectedSpan
        );
      }

      chart.options.plugins.lineaVerticale = {
        currentTime: currentTime,
      };
      chart.update("none");
      setRefresh(!refresh);
    }

    function updateBatchLevel(value, id) {
      if (
        (dataForChart.current.type == 2 && value <= 100) ||
        dataForChart.current.type != 2
      ) {
        let tempBatchLevel = batchLevel.slice(batchIndex);

        // setBatchLevel(batchLevel.slice(batchIndex));
        setBatchIndex(0);

        let newLevel = [...tempBatchLevel[0]];
        if (value == "") {
          newLevel[id] = 0;
          setBatchLevel([newLevel, ...tempBatchLevel]);
          // setRefresh(!refresh);
        } else if (
          value !== "" &&
          !isNaN(value) &&
          Number.isInteger(Number(value))
        ) {
          newLevel[id] = parseInt(value, 10);
          setBatchLevel([newLevel, ...tempBatchLevel]);
          //setRefresh(!refresh);
        }
      }
    }

    if (videoLoaded)
      return (
        <>
          <LineChartWrapper
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1vw",
              paddingRight: "0vw",
              boxSizing: "border-box",
              userSelect: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                justifyContent: "start",
                alignItems: "center",
                borderRight: "0.14vw solid " + colors.background,
                boxSizing: "border-box",
              }}
            >
              <ChartHeader
                optionsGraph={optionsGraph}
                selectedChart={selectedChart}
                setSelectedChart={setSelectedChart}
                setBatchLevel={setBatchLevel}
                setSelectedGraph={setSelectedGraph}
                time={time}
                videoDuration={videoDuration}
                formatTime={formatTime}
                t={t}
              />
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  marginTop: "1vw",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90%",
                  width: "100%",
                }}
              >
                <ChartCanvas
                  id={id}
                  chartRef={chartRef}
                  chartInstance={chartInstance}
                  videoLoaded={videoLoaded}
                  dataForChart={dataForChart}
                  editingRef={editingRef}
                  updateErrori={updateErrori}
                  enableMouseLocation={enableMouseLocation}
                  data={data}
                  selectedChart={selectedChart}
                  handleChartUpdate={handleChartUpdate}
                />
                <BatchLevelButtons
                  buttonsContainer={buttonsContainer}
                  batchLevel={batchLevelRef.current}
                  batchIndex={batchIndexRef.current}
                  dataForChart={dataForChart}
                  currentBatchLevel={currentBatchLevel}
                  user={user}
                  setBatchIndex={setBatchIndex}
                  setBatchLevel={setBatchLevel}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  editingBatch={editingBatch}
                  setEditingBatchIndex={setEditingBatchIndex}
                  editingBatchIndex={editingBatchIndex}
                  updateBatchLevel={updateBatchLevel}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: "0.5vw",
                width: "20%",
                height: "100%",
                paddingLeft: "0.5vw",
                paddingRight: "0.5vw",
              }}
            >
              <ChartSideControls
                data={data}
                selectedChart={selectedChart}
                editingBatch={editingBatch}
                dataForChart={dataForChart}
                setEditingBatch={setEditingBatch}
                showVisisbility={showVisisbility}
                setVisibilityIndex={setVisibilityIndex}
                visibilityIndex={visibilityIndex}
                visibilityScore={visibilityScore}
                time={time}
                batchLevel={batchLevel}
                setBatchLevel={setBatchLevel}
                batchIndex={batchIndex}
                setBatchIndex={setBatchIndex}
                videoLoaded={videoLoaded}
                chartInstance={chartInstance}
                enableMouseLocation={enableMouseLocation}
                visibilityIndexRef={visibilityIndexRef}
                setShowVisisbility={setShowVisisbility}
                dataForChartHistory={dataForChartHistory}
                historyIndex={historyIndex}
                handleChartUpdate={handleChartUpdate}
                moveHistory={moveHistory}
                setEditingBatchIndex={setEditingBatchIndex}
                editingBatchIndex={editingBatchIndex}
                updateBatchLevel={updateBatchLevel}
                index={state?.current_customer?.index}
              />
            </div>
          </LineChartWrapper>
          <PopupError
            ref={popupErrorRef}
            errors={dataForChart.current?.errorsLabels}
            onSelection={(id) => {
              for (
                let i = editingRef.current.start;
                i <= editingRef.current.end;
                i++
              ) {
                dataForChart.current.addedErrors[id][i] = 1;
              }
              dataForChart.current.addingError = Array.from(
                { length: dataForChart.current.addingError.length },
                () => 0
              );
              setEditing(null);
              checkpoint(
                historyIndex,
                dataForChartHistory,
                dataForChart.current,
                memoryDepth,
                setDuration,
                data,
                selectedChart
              );
              createChart(
                dataForChart.current,
                chartRef.current.getContext("2d"),
                graphMemory,
                selectedChart,
                data,
                chartInstance,
                setAlpha,
                maxSpan,
                user,
                selectedSpan,
                formatXAxisLabel,
                dataForChartSecondary,
                setGraphMemory
              );
              popupErrorRef.current.close();
            }}
          />
        </>
      );
  }
);

LineChart.propTypes = {
  graph: PropTypes.object,
};

LineChart.defaultProps = {
  data: {
    label: "",
    side: "",
    graph: {
      values: [""],
      errors: [""],
    },
  },
};

export default LineChart;
