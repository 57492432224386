import { useRef } from "react";
import { OCRA_ID, protocols } from "../../config/availableProtocols";
import { colors } from "../../config/style";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import GTGenerator from "../GroundTruthGenerator/GTGenerator";
import { InfoTabLegend, InfoTabScore } from "../InfoTab/InfoTab";
import LineChart from "../LineChart/LineChart";
import { PopupCreate } from "../Popup/Popup";
import { PopupAlert, PopupProcessVideos } from "../Popup/PopupContent";
import Spinner from "../Spinner/Spinner";
import VideoContainer from "../VideoContainer/VideoContainer";
import {
  ChartContainer,
  RightColumn,
  TopContainer,
} from "./AnalysisContainer.styled";
import { ChecklistScreens, RightTitle } from "./AnalysisContainerComponents";
import { runVideoAgain } from "../../remote/tasks";
import { useClerk } from "@clerk/clerk-react";
import { getToken } from "../../userManagement/utilities";
import { json } from "react-router-dom";

export const AnalysisContainerRightColumn = ({
  selectedTask,
  screen,
  analysis,
  checklist,
  user,
  reviewedState,
  reviewing,
  setReviewedBtn,
  state,
  ocraRes,
  savingEnabled,
  save,
  saving,
  t,
  checkRecovery,
  graphMemory,
  lineChartRef,
  setOcraMemory,
  setAllowRecover,
  videoLoaded,
  setVideoLoaded,
  videoId,
  setSelectedGraph,
  refresh,
  setRefresh,
  ocraMemory,
  availableProtocols,
  selectedProtocol,
  selectedGraph,
  videoLoadedRef,
  setGraphMemory,
  vliMemory,
  setVliMemory,
  vliRes,
  setEditingGraph,
  editingGraph,
}) => {
  const popupRef = useRef();

  const { session } = useClerk();

  const handleVideoProcessing = async (dir) => {
    let p = [];

    let t = await getToken(session);

    if (dir.hands) {
      p.push(runVideoAgain(selectedTask.ai.HANDS + ".mp4", t));
    }

    if (dir.left) {
      p.push(runVideoAgain(selectedTask.ai.LEFT + ".mp4", t));
    }

    if (dir.right) {
      p.push(runVideoAgain(selectedTask.ai.RIGHT + ".mp4", t));
    }

    let res = await Promise.all(p);

    popupRef.current.close();
  };

  return (
    <RightColumn>
      <TopContainer>
        <RightTitle
          edit={selectedTask?.author}
          title={screen == 1 ? analysis?.label : checklist?.label}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5vw",
          }}
        >
          {user?.publicMetadata?.expertise == "reviewer" && (
            <>
              <PopupCreate
                ref={popupRef}
                title={t("logoutConfirmTitle")}
                children={
                  <PopupProcessVideos onProcess={handleVideoProcessing} />
                }
              />
              <ButtonTextIcon
                disabled={false}
                // icon={
                //   process.env.REACT_APP_RESOURCES_BUCKET+"download.png"
                // }
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => popupRef.current.open()}
                loading={false}
                label={t("rerunVideo")}
                loadingLabel={""}
                loadingStyle={{}}
                style={{
                  justifyContent: "center",
                  width: "7vw",
                }}
              />
              <ButtonTextIcon
                disabled={false}
                // icon={
                //   process.env.REACT_APP_RESOURCES_BUCKET+"download.png"
                // }
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() =>
                  window.open(
                    "https://www.notion.so/gymnasio/Postura-Review-analysis-f1568854727d42f6a68f62418c3521f8?pvs=4",
                    "_blank"
                  )
                }
                loading={false}
                label={t("helpRevision")}
                loadingLabel={""}
                loadingStyle={{}}
                style={{
                  justifyContent: "center",
                  width: "7vw",
                }}
              />
              <ButtonTextIcon
                disabled={reviewedState}
                // icon={
                //   process.env.REACT_APP_RESOURCES_BUCKET+"download.png"
                // }
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => setReviewedBtn()}
                loading={reviewing}
                label={reviewedState ? t("reviewed") : t("review")}
                loadingLabel={""}
                loadingStyle={{ width: "4.3vw" }}
                style={{
                  justifyContent: "center",
                  width: "7vw",
                }}
              />
              <ButtonTextIcon
                disabled={false}
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() =>
                  GTGenerator({
                    id_result: selectedTask.id,
                    json: state.current_data,
                    edit: graphMemory,
                    ocraRes: ocraRes,
                  })
                }
                loading={false}
                label={t("groundTruth")}
                loadingLabel={""}
                loadingStyle={{}}
                style={{
                  justifyContent: saving ? "center" : "start",
                  width: "10vw",
                }}
              />
            </>
          )}
          <ButtonTextIcon
            disabled={!savingEnabled || editingGraph}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Save.png"}
            backgroundH={colors.details}
            background={"white"}
            color={colors.main}
            onClick={() => save()}
            loading={saving}
            label={t("save")}
            loadingLabel={""}
            loadingStyle={{}}
            style={{
              justifyContent: saving ? "center" : "start",
              width: "6vw",
            }}
          />
          {availableProtocols[selectedProtocol].ref ==
            protocols[OCRA_ID].ref && (
            <ButtonTextIcon
              label={screen ? t("recover") : t("cancelEdit")}
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "HistoryWh.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "HistoryWh.png"}
              backgroundH={colors.mainH}
              background={colors.main}
              disabled={checkRecovery(graphMemory) || editingGraph}
              color={"white"}
              iconHeight={"60%"}
              onClick={() => {
                if (screen == 1) {
                  delete graphMemory[analysis.id_local];
                  lineChartRef.current.refresh();
                } else {
                  setOcraMemory({
                    ...selectedTask.checklist,
                    uso_martelli_dx:
                      selectedTask.checklist.uso_martelli_dx <= 0
                        ? false
                        : true,
                    uso_mani_colpi_dx:
                      selectedTask.checklist.uso_mani_colpi_dx <= 0
                        ? false
                        : true,
                    strumenti_vibranti_dx:
                      selectedTask.checklist.strumenti_vibranti_dx <= 0
                        ? false
                        : true,
                    altro_dx:
                      selectedTask.checklist.altro_dx <= 0 ? false : true,
                    uso_martelli_sx:
                      selectedTask.checklist.uso_martelli_sx <= 0
                        ? false
                        : true,
                    uso_mani_colpi_sx:
                      selectedTask.checklist.uso_mani_colpi_sx <= 0
                        ? false
                        : true,
                    strumenti_vibranti_sx:
                      selectedTask.checklist.strumenti_vibranti_sx <= 0
                        ? false
                        : true,
                    altro_sx:
                      selectedTask.checklist.altro_sx <= 0 ? false : true,
                    targa_ciclo: selectedTask.checklist.targa_ciclo
                      ? selectedTask.checklist.targa_ciclo
                      : Math.round(
                          (400 * 60) / selectedTask.checklist.numero_cicli
                        ),
                  }); //API Change with selectedTask.checklist
                  setAllowRecover(false);
                }
              }}
            />
          )}
        </div>
      </TopContainer>
      {screen == 1 ? (
        <>
          <TopContainer style={{ marginTop: "1vw" }}>
            <InfoTabScore analysis={analysis} ocraRes={ocraRes} />
            <InfoTabLegend legend={analysis.graph[selectedGraph].legend} />
            <VideoContainer
              videoLoaded={videoLoaded}
              setVideoLoaded={(x) => setVideoLoaded(x)}
              videoId={videoId}
              video={analysis && state.current_data[analysis.side].video_path}
            />
          </TopContainer>
          <ChartContainer>
            {analysis && videoLoadedRef.current ? (
              <LineChart
                ref={lineChartRef}
                key={JSON.stringify(analysis.label)}
                id={JSON.stringify(analysis.label)}
                videoId={videoId}
                data={{ ...analysis }}
                videoLoaded={videoLoadedRef.current}
                graphMemory={graphMemory}
                setGraphMemory={(x) => {
                  setGraphMemory(x);
                }}
                setEditingGraph={setEditingGraph}
                setSelectedGraph={setSelectedGraph}
              />
            ) : (
              <Spinner />
            )}
          </ChartContainer>
        </>
      ) : (
        <ChecklistScreens
          id={checklist?.id}
          onChange={() => setRefresh(!refresh)}
          ocraMemory={ocraMemory}
          setOcraMemory={(x) => setOcraMemory(x)}
          ocraRes={ocraRes}
          vliMemory={vliMemory}
          setVliMemory={(x) => setVliMemory(x)}
          vliRes={vliRes}
          videoEl={
            <VideoContainer
              videoLoaded={videoLoaded}
              setVideoLoaded={(x) => {}}
              videoId={"videoChecklist"}
              video={analysis && state.current_data[analysis.side].video_path}
              videos={state.current_data}
            />
          }
          protocol={availableProtocols[selectedProtocol]}
          index={state?.current_customer?.index}
        />
      )}
    </RightColumn>
  );
};
