let debounceTimeout;
let thresholdPercentage = 2;

export const handleClick = (
  id,
  evt,
  chartInstance,
  videoLoaded,
  dataForChart,
  updateErrori,
  editingRef
) => {
  if (!chartInstance.current || !dataForChart.current) {
    console.error("chartInstance or dataForChart is not initialized");
    return; // Prevent further execution if chartInstance or dataForChart isn't initialized
  }

  const rect = document.getElementById(id).getBoundingClientRect();
  const mouseX = evt.clientX - rect.left;
  const timeValue = chartInstance.current.scales.x.getValueForPixel(mouseX);
  videoLoaded.currentTime = timeValue; // Aggiorna il tempo del video
  if (editingRef.current) {
    dataForChart.current = updateErrori(
      dataForChart.current,
      timeValue,
      true,
      evt
    );
  }

  if (chartInstance.current) {
    chartInstance.current.update("none");
  }
};

export const handleDoubleClick = (
  id,
  evt,
  chartInstance,
  data,
  selectedChart,
  handleChartUpdate
) => {


  if (!chartInstance || !chartInstance.current) {
    console.error("Chart instance is not initialized");
    return;
  }

  if (!data || !data.graph || !data.graph[selectedChart]) {
    console.error("Data or selected chart is not available");
    return;
  }

  if (data.graph[selectedChart].type === 0) {
    let rect = document.getElementById(id).getBoundingClientRect();
    let mouseX = evt.clientX - rect.left;
    let timeValue = chartInstance.current.scales.x.getValueForPixel(mouseX);
    handleChartUpdate(timeValue);
  }
};

export const handleMouseOut = (evt, chartInstance) => {
  if (!chartInstance.current) {
    console.error("chartInstance is not initialized");
    return; // Prevent the function from executing if chartInstance is not ready
  }

  delete chartInstance.current.cursorPosition;
  chartInstance.current.update("none");
};

export const handleMouseMove = (
  evt,
  chartInstance,
  enableMouseLocation,
  id,
  editingRef,
  videoLoaded,
  dataForChart,
  chartRef,
  updateErrori
) => {
  if (!chartInstance.current) {
    console.error("chartInstance is not initialized");
    return; // Prevent the function from executing if chartInstance is not ready
  }

  const rect = document.getElementById(id).getBoundingClientRect();
  const mouseX = evt.clientX - rect.left;
  const mouseY = evt.clientY - rect.top;

  if (enableMouseLocation.current !== null) {
    if (
      !isWithinThreshold(
        mouseX,
        mouseY,
        thresholdPercentage,
        enableMouseLocation
      )
    ) {
      return; // Exit if the mouse is not within the threshold
    } else {
      enableMouseLocation.current = null;
    }
  }

  clearTimeout(debounceTimeout); // Clear any previous timeout

  debounceTimeout = setTimeout(() => {
    const rect = chartRef.current.getBoundingClientRect();
    const mouseX = evt.clientX - rect.left;

    if (chartInstance.current) {
      chartInstance.current.cursorPosition = mouseX;
      chartInstance.current.update("none"); // Update without animation
    }

    if (editingRef.current) {
      const timeValue = chartInstance.current.scales.x.getValueForPixel(mouseX);
      videoLoaded.currentTime = timeValue; // Update the video's current time
      dataForChart.current = updateErrori(
        dataForChart.current,
        timeValue,
        false
      );

      chartInstance.current.options.plugins.lineaVerticale = {
        currentTime: timeValue,
        highlightCursor: true,
      };
      chartInstance.current.update("none");
    }
  }, 1); // Set a delay of 50 milliseconds
};

function isWithinThreshold(mouseX, mouseY, threshold, enableMouseLocation) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const thresholdInPixels =
    Math.sqrt(Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2)) *
    (threshold / 100);

  const distance = calculateDistance(
    mouseX,
    mouseY,
    enableMouseLocation.current[0],
    enableMouseLocation.current[1]
  );
  return distance < thresholdInPixels;
}

function calculateDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}
