export function manageAiResult(graphMemory, availableAnalysis) {
  let newResults = {
    // AZIONI T DX
    numero_azioni_dinamiche_dx: graphMemory?.[
      availableAnalysis[1].analysis.dynamicRight.id_local
    ]
      ? graphMemory?.[availableAnalysis[1].analysis.dynamicRight.id_local][0]
          .newValue
      : availableAnalysis[1].analysis.dynamicRight.value,

    // AZIONI T SX
    numero_azioni_dinamiche_sx: graphMemory?.[
      availableAnalysis[1].analysis.dynamicLeft.id_local
    ]
      ? graphMemory?.[availableAnalysis[1].analysis.dynamicLeft.id_local][0]
          .newValue
      : availableAnalysis[1].analysis.dynamicLeft.value,

    // MANO DX
    secondi_mano_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightHand.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightHand.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightHand.value,

    // MANO SX
    secondi_mano_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftHand.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftHand.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftHand.value,

    // SPALLA DX
    secondi_spalla_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightShoulder.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightShoulder.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightShoulder.value,

    // SPALLA SX
    secondi_spalla_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftShoulder.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftShoulder.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftShoulder.value,

    // POLSO DX
    secondi_polso_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightWrist.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightWrist.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightWrist.value,

    // POLSO SX
    secondi_polso_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftWrist.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftWrist.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftWrist.value,

    // GOMITO DX
    secondi_gomito_incongrua_dx:
      graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local]?.[0]
        ?.newValue >= 0
        ? graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local][0]
            .newValue +
          graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local][1]
            .newValue
        : availableAnalysis[0].analysis.rightElbow.value,

    // GOMITO SX
    secondi_gomito_incongrua_sx:
      graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local]?.[0]
        ?.newValue >= 0
        ? graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local][0]
            .newValue +
          graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local][1]
            .newValue
        : availableAnalysis[0].analysis.leftElbow.value,
  };
  return newResults;
  // setOcraMemory({ ...ocraMemory, ...newResults });
}

export function matchIndexChecklist(ocraMemory) {
  let updatedOcraMemory = { ...ocraMemory };
  const thr_percentage_complementar = 0.75;

  // Vibranti
  if (
    ocraMemory["index_compl_vibrazioni_dx"] / ocraMemory["targa_ciclo"] >
    thr_percentage_complementar
  ) {
    updatedOcraMemory = { ...updatedOcraMemory, strumenti_vibranti_dx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      strumenti_vibranti_dx: false,
    };
  }
  if (
    ocraMemory["index_compl_vibrazioni_sx"] / ocraMemory["targa_ciclo"] >
    thr_percentage_complementar
  ) {
    updatedOcraMemory = { ...updatedOcraMemory, strumenti_vibranti_sx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      strumenti_vibranti_sx: false,
    };
  }

  // Colpi mani
  if (ocraMemory["index_compl_colpi_dx"] > 0) {
    updatedOcraMemory = { ...updatedOcraMemory, uso_mani_colpi_dx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      uso_mani_colpi_dx: false,
    };
  }
  if (ocraMemory["index_compl_colpi_sx"] > 0) {
    updatedOcraMemory = { ...updatedOcraMemory, uso_mani_colpi_sx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      uso_mani_colpi_sx: false,
    };
  }

  //Altri
  let sum_other_dx =
    ocraMemory["index_compl_precisione_dx"] +
    ocraMemory["index_compl_compressioni_dx"] +
    ocraMemory["index_compl_movimenti_rapidi_dx"] +
    ocraMemory["index_compl_altri_dx"];
  if (sum_other_dx / ocraMemory["targa_ciclo"] > thr_percentage_complementar) {
    updatedOcraMemory = { ...updatedOcraMemory, altro_dx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      altro_dx: false,
    };
  }
  let sum_other_sx =
    ocraMemory["index_compl_precisione_sx"] +
    ocraMemory["index_compl_compressioni_sx"] +
    ocraMemory["index_compl_movimenti_rapidi_sx"] +
    ocraMemory["index_compl_altri_sx"];
  if (sum_other_sx / ocraMemory["targa_ciclo"] > thr_percentage_complementar) {
    updatedOcraMemory = { ...updatedOcraMemory, altro_sx: true };
  } else {
    updatedOcraMemory = {
      ...updatedOcraMemory,
      altro_sx: false,
    };
  }

  // CONVERSIONE BORG
  updatedOcraMemory = {
    ...updatedOcraMemory,
    forza_elevata_dx_sec: 0,
    forza_elevata_sx_sec: 0,
    forza_moderata_dx_sec:
      parseInt(ocraMemory["index_borg1_dx"]) +
      parseInt(ocraMemory["index_borg2_dx"]) +
      parseInt(ocraMemory["index_borg3_dx"]) +
      parseInt(ocraMemory["index_borg4_dx"]),
    forza_moderata_sx_sec:
      parseInt(ocraMemory["index_borg1_sx"]) +
      parseInt(ocraMemory["index_borg2_sx"]) +
      parseInt(ocraMemory["index_borg3_sx"]) +
      parseInt(ocraMemory["index_borg4_sx"]),
    picchi_di_forza_dx_sec:
      parseInt(ocraMemory["index_borg5_dx"]) +
      parseInt(ocraMemory["index_borg6_dx"]) +
      parseInt(ocraMemory["index_borg7_dx"]) +
      parseInt(ocraMemory["index_borg8_dx"]) +
      parseInt(ocraMemory["index_borg9_dx"]) +
      parseInt(ocraMemory["index_borg10_dx"]),
    picchi_di_forza_sx_sec:
      parseInt(ocraMemory["index_borg5_sx"]) +
      parseInt(ocraMemory["index_borg6_sx"]) +
      parseInt(ocraMemory["index_borg7_sx"]) +
      parseInt(ocraMemory["index_borg8_sx"]) +
      parseInt(ocraMemory["index_borg9_sx"]) +
      parseInt(ocraMemory["index_borg10_sx"]),
  };

  // CONVERSIONE STATICHE
  updatedOcraMemory = {
    ...updatedOcraMemory,
    flag_azioni_statiche_dx:
      ocraMemory["index_static_dx"] / ocraMemory["targa_ciclo"] >= 1
        ? 2
        : ocraMemory["index_static_dx"] / ocraMemory["targa_ciclo"] >= 2 / 3
        ? 1
        : 0,
    flag_azioni_statiche_sx:
      ocraMemory["index_static_sx"] / ocraMemory["targa_ciclo"] >= 1
        ? 2
        : ocraMemory["index_static_sx"] / ocraMemory["targa_ciclo"] >= 2 / 3
        ? 1
        : 0,
  };

  return updatedOcraMemory;
}

export function manageIndexResult(selectedTask) {
  if (selectedTask?.taskDetail?.ocra?.sx?.otherTemp?.index) {
    return { ...selectedTask?.taskDetail?.ocra?.sx?.otherTemp?.index };
  } else {
    return {
      //INDEX
      //Complementari
      index_compl_precisione_dx: 0,
      index_compl_vibrazioni_dx: 0,
      index_compl_compressioni_dx: 0,
      index_compl_movimenti_rapidi_dx: 0,
      index_compl_altri_dx: 0,
      index_compl_colpi_dx: 0,
      index_compl_precisione_sx: 0,
      index_compl_vibrazioni_sx: 0,
      index_compl_compressioni_sx: 0,
      index_compl_movimenti_rapidi_sx: 0,
      index_compl_altri_sx: 0,
      index_compl_colpi_sx: 0,
      //Borg
      index_borg1_dx: 0,
      index_borg2_dx: 0,
      index_borg3_dx: 0,
      index_borg4_dx: 0,
      index_borg5_dx: 0,
      index_borg6_dx: 0,
      index_borg7_dx: 0,
      index_borg8_dx: 0,
      index_borg9_dx: 0,
      index_borg10_dx: 0,
      index_borg510_dx: 0,
      index_borg1_sx: 0,
      index_borg2_sx: 0,
      index_borg3_sx: 0,
      index_borg4_sx: 0,
      index_borg5_sx: 0,
      index_borg6_sx: 0,
      index_borg7_sx: 0,
      index_borg8_sx: 0,
      index_borg9_sx: 0,
      index_borg10_sx: 0,
      index_borg510_sx: 0,
      //Statiche
      index_static_dx: 0,
      index_static_sx: 0,
    };
  }
}

export function isPositiveInteger(numberString) {
  const number = Number(numberString);
  return (Number.isInteger(number) && number >= 0) || numberString == null;
}

export function formatDecimal(numberString) {
  const str = String(numberString);

  if (str === null || str.trim() === "") {
    return null;
  }
  let normalizedNumberString = str.replace(",", ".");
  // Controlla se l'ultimo carattere è un punto o una virgola
  const lastChar = normalizedNumberString.slice(-1);
  if (lastChar === ".") {
    // Verifica se ci sono già altri punti nella stringa
    const pointCount = (normalizedNumberString.match(/\./g) || []).length;
    if (pointCount > 1) {
      // Se c'è già un punto, rimuovi l'ultimo punto
      normalizedNumberString = normalizedNumberString.slice(0, -1);
    }
    return normalizedNumberString; // Restituisce la stringa così com'è se termina con un punto
  }

  // Sostituisci la virgola con il punto per la conversione

  const number = parseFloat(normalizedNumberString);
  if (isNaN(number)) {
    return null;
  }

  // Formatta il numero e usa la virgola come separatore decimale
  const formattedNumber = Number.isInteger(number)
    ? number.toFixed(0)
    : number.toFixed(1);

  return formattedNumber;
}
