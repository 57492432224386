import { NONE } from "../config/generalConst";

const URL = process.env.REACT_APP_API_URL;

const loadData = async (file) => {
  const response = await fetch(file);
  let text = await response.text(); // Ottieni la risposta come testo
  text = text.replace(/NaN/g, "-1");
  const json = JSON.parse(text, (key, value) => {
    // Controlla se il valore è una stringa che rappresenta "NaN"
    if (value === NaN) {
      return 0;
    }
    return value;
  });

  let current_data = {};
  console.log("JSONNN", json);

  if (json.version) {
    //Caso versione aggiornata JSON
    current_data = { outputs: { ...json.outputs, version: json.version } };
  } else {
    //Caso versioni vecchie JSON
    current_data = {
      left_video: {
        ...json.outputs_struct.left,
        visibility: json.outputs_struct?.visibility
          ? json.outputs_struct?.visibility
          : NONE,
      },
      right_video: json.outputs_struct.right,
      hands: json.outputs_struct.hands,
      config: json.parameters,
    };
  }

  return current_data;
};

const fetchDataLeftRight = async (
  left,
  right,
  hands,
  selectedTask,
  onSuccess,
  clear
) => {
  let baseUrl = process.env.REACT_APP_S3_BUCKET;
  let leftUrl = baseUrl + "json/" + left + ".json";
  let rightUrl = baseUrl + "json/" + right + ".json";
  let handsUrl = baseUrl + "json/" + hands + ".json";

  let leftVideoUrl = baseUrl + left + "_obscured.webm";
  let rightVideoUrl = baseUrl + right + "_obscured.webm";
  let handsVideoUrl = baseUrl + hands + "_obscured.webm";

  if (clear) {
    leftVideoUrl = baseUrl + left + ".mp4";
    rightVideoUrl = baseUrl + right + ".mp4";
    handsVideoUrl = baseUrl + hands + ".mp4";
  }

  try {
    let dataLeft = await loadData(leftUrl);
    let dataRight = await loadData(rightUrl);
    let dataHands = await loadData(handsUrl);

    let current_data = {
      left_video: dataLeft.outputs ? dataLeft.outputs : dataLeft.left_video,
      right_video: dataRight.outputs ? dataRight.outputs : dataRight.left_video,
      hands: dataHands.outputs
        ? dataHands.outputs
        : {
            ...dataHands.hands,
            sampling_frequency: dataHands.hands.sampling_frequency
              ? dataHands.hands.sampling_frequency
              : dataRight.left_video.sampling_frequency,
          },
      config: dataLeft.config,
    };

    current_data.left_video.video_path = leftVideoUrl;
    current_data.right_video.video_path = rightVideoUrl;
    current_data.hands.video_path = handsVideoUrl;
    selectedTask.checklist.tempo_ciclo_osservato =
      Math.round(
        ((current_data.left_video.debug.video_length +
          current_data.right_video.debug.video_length +
          (dataHands.outputs
            ? current_data.hands.debug_right.video_length
            : current_data.hands.debug.grabs.video_length)) *
          10) /
          3
      ) / 10;

    onSuccess && onSuccess(current_data);
    return current_data;
  } catch (error) {
    console.error("Errore nel caricamento dei dati:", error);
  }
};

async function getTask(task) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        //Authorization: jwtToken,
      }),
    };

    const response = await fetch(URL + "tasks/" + task, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function runVideoAgain(videoId, jwt) {
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "admin/instances/run_video/" + videoId,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getTasks(tasks, jwt) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change

        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify({ tasks }),
    };

    const response = await fetch(URL + "tasks", requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getResult(result) {
  // console.log("[API] getResult");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        //Authorization: jwtToken,
      }),
    };

    const response = await fetch(URL + "results/" + result, requestObject);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

/*****
 ***** POST
 *****/

async function newTask(location, department, line, station, task) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        //Authorization: jwtToken,
      }),
      body: JSON.stringify(task),
    };

    const response = await fetch(
      URL +
        "locations/" +
        location +
        "/departments/" +
        department +
        "/lines/" +
        line +
        "/stations/" +
        station +
        "/tasks/add",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function updateTaskResult(task, result, jwt = null) {
  try {
    const requestObject = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
      body: JSON.stringify(result),
    };

    const response = await fetch(
      URL + "tasks/" + task + "/result/update",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function getInspectionTaskData(inspection, task, data, jwt = null) {
  // console.log("[API] getInspectionTaskData");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL + "inspections/" + inspection + "/tasks/" + task + "/data/" + data,
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

async function setReviewed(inspection, task, data, jwt = null) {
  // console.log("[API] getInspectionTaskData");
  try {
    const requestObject = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
        Authorization: "Bearer " + jwt,
      }),
    };

    const response = await fetch(
      URL +
        "inspections/" +
        inspection +
        "/tasks/" +
        task +
        "/data/" +
        data +
        "/review",
      requestObject
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Se la risposta non è ok, gestisci l'errore
      throw new Error("Errore nella richiesta");
    }
  } catch (error) {
    // Gestisci l'errore qui
    console.error(error);
    throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
  }
}

export {
  getTask,
  getTasks,
  getResult,
  newTask,
  updateTaskResult,
  getInspectionTaskData,
  fetchDataLeftRight,
  setReviewed,
  runVideoAgain,
};
