function formattaData(dataISO, t, short) {
  const mesi = [
    t("gen"),
    t("feb"),
    t("mar"),
    t("apr"),
    t("may"),
    t("jun"),
    t("jul"),
    t("aug"),
    t("sep"),
    t("oct"),
    t("nov"),
    t("dec"),
  ];
  const data = new Date(dataISO);

  const giorno = data.getDate();
  const mese = mesi[data.getMonth()];
  const anno = data.getFullYear().toString();
  const annoShort = data.getFullYear().toString().slice(-2);

  const ore = data.getHours();
  const minuti = data.getMinutes().toString().padStart(2, "0");
  const secondi = data.getSeconds().toString().padStart(2, "0");

  if (short) return `${giorno} ${mese} '${annoShort}`;

  return `${giorno} ${mese} ${anno}  ${" - "}  ${ore}:${minuti}`;
}

export default formattaData;
