import JobScreen from "./JobScreen";
import React, { useState, useRef } from "react";
import { getToken } from "../../../userManagement/utilities";
import {
  deleteJob,
  deleteJobTask,
  updateJob,
  updateJobTask,
} from "../../../remote/structure";
import { useClerk } from "@clerk/clerk-react";
import {
  filterInvalidTasks,
  handleLoadDepartment,
} from "../../../remote/utils";

const MainJobScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
  data,
  location,
  deleted,
}) => {
  const [saving, setSaving] = useState(false);
  const [savingEnabled, setSavingEnabled] = useState(false);
  const [tasks, setTasks] = useState(
    departmentDetail[currentDepartment]?.jobs[id]?.tasks
  );

  const { session } = useClerk();
  const jobRef = useRef();
  const popupRef = useRef();


  const handleSaving = async (id, job, onSuccess) => {
    setSaving(true);
    if (job.workshift) {
      let request = await updateJob(
        departmentDetail[currentDepartment].locationId,
        departmentDetail[currentDepartment].id,
        {
          name: job.name,
          workshift: job.workshift,
          n_employees: job.n_employees,
        },
        id,
        await getToken(session)
      );
      setSaving(false);
    }
    setSavingEnabled(false);
    if (onSuccess) onSuccess();
  };

  const handleDelete = async () => {
    deleted.current = true;

    let request = await deleteJob(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      id,
      await getToken(session)
    );
    // Crea una copia degli attuali workshifts
    const updatedJobs = {
      ...departmentDetail[currentDepartment].jobs,
    };

    // Rimuovi lo shift specifico
    delete updatedJobs[data.id];

    // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      jobs: updatedJobs,
    };

    // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: jobRef.current.name });
    setDepartmentDetail(updatedDepartmentDetails);
  };

  const handleDeleteJobTask = async (ids) => {
    const updatedJob = {
      ...departmentDetail[currentDepartment].jobs[data.id],
    };

    for (const id of ids) {
      let request = await deleteJobTask(
        departmentDetail[currentDepartment].locationId,
        departmentDetail[currentDepartment].id,
        data.id,
        id,
        await getToken(session)
      );

      // Rimuovi lo shift specifico
      updatedJob.tasks = updatedJob.tasks.filter((task) => task.id !== id);
    }

    handleLoadDepartment(
      currentDepartment,
      (newDetail) => {
        setData({ ...data });

        setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
        popupRef.current.close();
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );
  };

  const handleChangeTime = async (id, value) => {
    let index = departmentDetail[currentDepartment].jobs[
      data.id
    ].tasks.findIndex((task) => task.id === id);
    departmentDetail[currentDepartment].jobs[data.id].tasks[index].durata =
      value;
    let updatedTasks = [...tasks];
    updatedTasks[index].durata = value;

    setTasks(updatedTasks);

    let request = await updateJobTask(
      departmentDetail[currentDepartment].locationId,
      departmentDetail[currentDepartment].id,
      data.id,
      id,
      { durata: value },
      await getToken(session)
    );
  };
  return (
    <JobScreen
      key={id}
      id={id}
      setData={(x) => setData(x)}
      data={data}
      departmentDetail={departmentDetail}
      setDepartmentDetail={(x) => setDepartmentDetail(x)}
      currentDepartment={currentDepartment}
      location={location}
      handleSaving={handleSaving}
      handleDelete={handleDelete}
      handleDeleteJobTask={handleDeleteJobTask}
      handleChangeTime={handleChangeTime}
      saving={saving}
      savingEnabled={savingEnabled}
      setSavingEnabled={setSavingEnabled}
      deleted={deleted}
      jobRef={jobRef}
      tasks={tasks}
      setTasks={setTasks}
      popupRef={popupRef}
    />
  );
};

export default MainJobScreen;
