import { useClerk } from "@clerk/clerk-react";
import { TreeView } from "@mui/x-tree-view";
import { useState } from "react";
import { colors } from "../../config/style";
import { useStateValue } from "../../stores/services/StateProvider";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { StyledTreeItem } from "./Tree";
import RightArrowIcon from "../../icons/RightArrowIcon";
import DownArrowIcon from "../../icons/DownArrowIcon";

export const TreeSmallStructure = ({
  width,
  setSelectedStation,
  department,
}) => {
  const [state, dispatch] = useStateValue();
  const [expanded, setExpanded] = useState([]);
  const [popupContent, setPopupContent] = useState({
    info: "",
    children: "",
    actionLabel: "",
  });

  const { session } = useClerk();

  const [selected, setSelected] = useState(null);

  const handleTreeSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const handleButtonClick = (key) => {
    setSelected(key);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  // Render workspaces
  const renderWorkspaces = (workspaces, line, depId) => {
    const workspaceItems = Object.keys(workspaces).map((workspaceId) => {
      const workspace = workspaces[workspaceId];
      return (
        <StyledTreeItem
          key={`ws-${workspace.id}`}
          nodeId={`ws-${workspace.id}`}
          labelText={workspace.name}
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "400",
            marginLeft: "0.3vw",
          }}
          labelIcon={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
          onClick={() => {
            setSelectedStation({ line: line, ws: workspace });
          }}
        ></StyledTreeItem>
      );
    });

    return workspaceItems;
  };

  // Render lines direttamente sotto il dipartimento
  const renderLines = (department) => {
    // renderLines(
    //   departmentDetail[department.id]["lines"],
    //   department.id
    // )

    let lines = department["lines"];


    function naturalCompare(a, b) {
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    }

    let sortedKeys = Object.keys(lines).sort(naturalCompare);
    let sortedLines = {};

    sortedKeys.forEach((key) => {
      sortedLines[key] = lines[key];
    });

    let lineItems;

    if (!lines) {
      lineItems = [];
    } else {
      lineItems = Object.keys(sortedLines).map((lineId) => {
        let sortedEntries = Object.entries(lines[lineId]).sort((a, b) => {
          return a[1].name.localeCompare(b[1].name);
        });

        let sortedWS = Object.fromEntries(sortedEntries);

        return (
          <StyledTreeItem
            key={`line-${lineId + department.id}`}
            nodeId={`line-${lineId + department.id}`}
            labelText={lineId}
            bgColor={colors.mainOpaque}
            font={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.95vw",
              fontWeight: "400",
              marginLeft: "0.3vw",
            }}
          >
            {renderWorkspaces(sortedWS, lineId, department.id)}
          </StyledTreeItem>
        );
      });
    }

    return lineItems;
  };

  return (
    <>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        selected={selected}
        onNodeSelect={handleTreeSelect}
        aria-label="departments navigator"
        defaultCollapseIcon={<DownArrowIcon size="0.6vw" />}
        defaultExpandIcon={<RightArrowIcon size="0.6vw" />}
        sx={{
          //   height: "100%",
          flexGrow: 1,
          maxWidth: width,
          width: width,
          height: "1vw",
          marginBottom: "1vw",

          overflowY: "scroll",

          "& .MuiTreeItem-content": {
            "& .MuiTreeItem-iconContainer": {
              marginRight: "0px",
            },
            "& .MuiTreeItem-label": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              marginLeft: "0px",
              paddingLeft: "0.5vw",
            },
          },
        }}
      >
        {renderLines(department)}
      </TreeView>
      <ButtonTextIcon
        label={"Comprimi tutti"}
        icon={process.env.REACT_APP_RESOURCES_BUCKET + "CompressMain.png"}
        iconH={process.env.REACT_APP_RESOURCES_BUCKET + "CompressMain.png"}
        backgroundH={colors.mainOpaque}
        background={""}
        color={colors.main}
        onClick={() => setExpanded([])}
        margin={"0vw 0vw 1vw 0vw"}
        style={{ boxShadow: "0 0 0", padding: "0.5vw" }}
      />
    </>
  );
};
