import { ClerkProvider } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import "./App.css";
import MainRouter from "./MainRouter";
import InitialState from "./stores/InitialState";
import rootReducer from "./stores/reducers/Index";
import { StateProvider } from "./stores/services/StateProvider";
import { Toaster } from "./components/ui/toaster";

function App() {
  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
  }
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  return (
    <StateProvider reducer={rootReducer} initialState={InitialState}>
      <ClerkProvider publishableKey={clerkPubKey}>
        <MainRouter />
        <Toaster />
      </ClerkProvider>
    </StateProvider>
  );
}

export default App;
