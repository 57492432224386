import ShiftScreen from "./ShiftScreen";
import { editWorkshift, deleteWorkshift } from "../../../remote/structure";
import { getToken } from "../../../userManagement/utilities";
import { useState, useRef } from "react";
import { useClerk } from "@clerk/clerk-react";

const MainShiftScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
  deleted,
}) => {
  const [saving, setSaving] = useState(false);
  const [savingEnabled, setSavingEnabled] = useState(false);
  const shiftRef = useRef();

  const { session } = useClerk();

  const handleSaving = async (id, shift) => {
    setSaving(true);
    let request = await editWorkshift(id, shift, await getToken(session));
    setSavingEnabled(false);
    setSaving(false);
  };

  const handleDelete = async () => {
    deleted.current = true;
    let request = await deleteWorkshift(id, await getToken(session));
    // Crea una copia degli attuali workshifts
    const updatedWorkshifts = {
      ...departmentDetail[currentDepartment].workshifts,
    };

    // Rimuovi lo shift specifico
    delete updatedWorkshifts[id];

    // Crea l'oggetto updatedCurrentDepartment senza lo shift rimosso
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      workshifts: updatedWorkshifts,
    };

    // Aggiorna i dettagli del dipartimento nel contesto globale o nello stato
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: shiftRef.current.name });

    setDepartmentDetail(updatedDepartmentDetails);
  };
  return (
    <ShiftScreen
      key={id}
      id={id}
      departmentDetail={departmentDetail}
      setDepartmentDetail={(x) => setDepartmentDetail(x)}
      currentDepartment={currentDepartment}
      setData={(x) => setData(x)}
      handleSaving={handleSaving}
      handleDelete={handleDelete}
      setSaving={setSaving}
      saving={saving}
      shiftRef={shiftRef}
      setSavingEnabled={setSavingEnabled}
      savingEnabled={savingEnabled}
      deleted={deleted}
    />
  );
};

export default MainShiftScreen;
