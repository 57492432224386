import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../config/style";
import { isPositiveInteger } from "../../AnalysisContainer/AnalysisContainerFunctions";

const DurationColumn = ({
  task,
  header,
  onChange,
  jobDuration,
  errPerc,
  noTime,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  if (header)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <img
          src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
          style={{
            width: "1.4vw",
            height: "1.4vw",
            marginLeft: "-0.3vw",
            marginRight: "0.3vw",
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", height: "80%" }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginTop: "0.9vw",
            }}
          >
            {t("duration") + " (%)"}
          </p>

          <p
            style={{
              // position: "relative",
              // marginTop: "2.6vw ",
              // marginLeft: "1.4vw",
              margin: 0,
              color: colors.negative,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.7vw",
              fontWeight: "400",
              boxSizing: "border-box",
              visibility: errPerc ? "visible" : "hidden",
            }}
          >
            {t("different100")}
          </p>
        </div>
      </div>
    );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    let value = event.target.value;

    if (value == "") {
      onChange(task.idLink, 0);
    }
    if (isPositiveInteger(value)) {
      if (parseInt(value) <= 100) {
        value = value ? parseInt(value) : 0;

        onChange(task.idLink?task.idLink:task.id, value);
      }
    }
  };

  const borderColor = () => {
    if (isFocused) return colors.main; // Blu quando è in focus
    if (!task.durata || errPerc) return colors.negative; // Rosso se il valore è vuoto
    return colors.background; // Colore di default se non in focus e non vuoto
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: jobDuration != -1 ? "space-between" : "center",
          width: "7.65vw",
          height: "2.5vw",
          background: "white",
          border: `0.14vw solid ${borderColor()}`,
          borderRadius: "0.31vw",
          padding: "0",
          boxSizing: "border-box",
          minWidth: "6.25vw",
        }}
      >
        {jobDuration != -1 ? (
          <input
            type="text"
            value={task.durata}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              width: "40%",
              height: "100%",
              border: "none",
              outline: "none",
              textAlign: "end",
              boxSizing: "border-box",
              backgroundColor: "transparent",
              marginRight: "0vw",
            }}
          />
        ) : (
          <></>
        )}
        <span
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            border: "none",
            outline: "none",
            textAlign: jobDuration != -1 ? "center" : "start",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            marginRight: jobDuration != -1 ? "0.5vw" : "0",
          }}
        >
          {jobDuration != -1 ? "%" : task.durata + "%"}
        </span>
        {jobDuration != -1 ? (
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              width: "65%",
              marginRight: "0vw",
            }}
          >
            ({Math.round((jobDuration * task.durata) / 100) + " "}
            m)
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default DurationColumn;
