import React from "react";

const FactoryIcon = ({ fill = "none", size = "1.9vw" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100%" height="100%" rx="12" fill="#DCDBFF" />
      <path
        d="M37.5413 18.5344C37.8319 18.3413 38.2013 18.3244 38.505 18.4875C38.8088 18.6506 39 18.9675 39 19.3144V38.0625C39 38.58 38.58 39 38.0625 39H9.93805C9.67743 39 9.42993 38.8912 9.25181 38.7019C9.07369 38.5106 8.98369 38.2556 9.00244 37.9969L10.8062 12.75H9.93805C9.42056 12.75 9.00056 12.33 9.00056 11.8125V9.9375C9.00056 9.42 9.42056 9 9.93805 9H19.3129C19.8304 9 20.2504 9.42 20.2504 9.9375V11.8125C20.2504 12.33 19.8304 12.75 19.3129 12.75H18.4448L19.2791 24.4575L28.1665 18.5344C28.4571 18.3413 28.8264 18.3244 29.1302 18.4875C29.4339 18.6506 29.6252 18.9675 29.6252 19.3144V23.8106L37.5413 18.5344Z"
        fill="#6D69F2"
      />
    </svg>
  );
};

export default FactoryIcon;
