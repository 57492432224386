import { useEffect, useState } from "react";
import { colors } from "../../config/style";
import CalculatorIcon from "../../icons/CalculatorIcon";
import WeightIcon, { WeightIconNumber } from "../../icons/WeightIcon";
import { DividerSmall } from "../AnalysisSelector/AnalysisSelector";
import ButtonIcon from "../buttons/ButtonIcon/ButtonIcon";
import {
  CheckBox,
  InfoBox,
  InfoButton,
  Selector,
  TextBox,
} from "../InfoTab/InfoTab";
import { setAlpha } from "../../utils/generalFunctions";
import PillSelector from "../PillSelector/PillSelector";
import { isInteger } from "mathjs";
import { formatDecimal } from "./AnalysisContainerFunctions";
import { tips } from "../../config/tips";
import { useTranslation } from "react-i18next";

export const TableWeightList = ({
  availableHeight,
  vliMemory,
  setVliMemory,
  vliRes,
}) => {
  function convertVwToPx(vw) {
    // Ottieni la larghezza del viewport in pixel
    const viewportWidth = window.innerWidth;
    // Calcola il valore in pixel
    return (viewportWidth / 100) * vw;
  }
  let heightComputed =
    availableHeight - 2 * convertVwToPx(3.8) - convertVwToPx(2.6);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <HeaderWeightList />
      <div style={{ overflowY: "scroll", height: heightComputed }}>
        {vliMemory.elenco_pesi.map((peso, pesoId) => {
          return (
            <RowWeightList
              key={pesoId}
              pesoId={pesoId}
              vliMemory={vliMemory}
              vliRes={vliRes}
              setVliMemory={setVliMemory}
              locked={vliMemory.locked}
            />
          );
        })}
      </div>
      <FooterWeightList
        data={[
          vliRes.elenco_pesi_sum.tot_oggetti_sollevati,
          vliRes.elenco_pesi_sum.totale_reale_oggetti,
          vliRes.elenco_pesi_sum.totale_massa_cum,
        ]}
      />
    </div>
  );
};
export const HeaderWeightList = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        height: "3.8vw",
        width: "100%",
        // marginLeft: "-1.25vw",
        borderTop: "0.14vw solid rgba(237, 235, 255, 1)",
        borderBottom: "0.14vw solid rgba(237, 235, 255, 1)",
        backgroundColor: colors.disabled,
        padding: "1.25vw",
        boxSizing: "border-box",
      }}
    >
      <img
        onClick={() => {}}
        src={""}
        style={{
          visibility: "hidden",
          width: "1.2vw",
          height: "1.2vw",
          marginRight: "0.5vw",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <WeightIcon size={"1.4vw"} />
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          <b>{t("pesoKg")}</b>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("numOggettiSollevatiDaTuttiILavoratori")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("numSollevamentiPerCiascunOggetto")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("realeNumeroDiOggettiSollevati")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          std={true}
          info={tips[document.language].reale_numero_oggetti}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("massaCumulata")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          std={true}
          info={tips[document.language].massa_cumulata}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      ></div>
    </div>
  );
};

export const FooterWeightList = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        height: "3.8vw",
        width: "100%",
        // marginLeft: "-1.25vw",
        // borderTop: "0.14vw solid rgba(237, 235, 255, 1)",
        // borderBottom: "0.14vw solid rgba(237, 235, 255, 1)",
        backgroundColor: "white",
        padding: "1.25vw",
        boxSizing: "border-box",
      }}
    >
      <img
        onClick={() => {}}
        src={""}
        style={{
          visibility: "hidden",
          width: "1.2vw",
          height: "1.2vw",
          marginRight: "0.5vw",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <CalculatorIcon fill={colors.positive2} size={"1.4vw"} />
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          <b>{t(t("totale"))}</b>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {data[0] != -1 ? data[0] : "-"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {""}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {data[1] != -1 ? data[1] : "-"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {data[2] != -1 ? data[2] + " kg" : "- kg"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      ></div>
    </div>
  );
};

export const RowWeightList = ({
  pesoId,
  vliMemory,
  vliRes,
  setVliMemory,
  locked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          padding: "0.2vw 0",
          height: "3.8vw",
          width: "100%",
          paddingLeft: "0.8vw",
          paddingRight: "0.8vw",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => {}}
          src={""}
          style={{
            width: "1.2vw",
            height: "1.2vw",
            marginRight: "0.5vw",
            cursor: "pointer",
            visibility: "hidden",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <TextBox
            locked={locked}
            value={
              vliMemory?.elenco_pesi[pesoId]?.peso != -1
                ? vliMemory?.elenco_pesi[pesoId]?.peso
                : "-"
            }
            width={"7vw"}
            err={false}
            unit={t("kg")}
            infoBox={true}
            onChange={(valueIn) => {
              let value = valueIn.replace(/-/g, "");
              let elencoPesi = [...vliMemory.elenco_pesi];
              if (!value) {
                elencoPesi[pesoId].peso = -1;
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              } else if (formatDecimal(value)) {
                elencoPesi[pesoId].peso = formatDecimal(value);
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <TextBox
            locked={locked}
            value={
              vliMemory?.elenco_pesi[pesoId]?.n_oggetti != -1
                ? vliMemory?.elenco_pesi[pesoId]?.n_oggetti
                : "-"
            }
            width={"7vw"}
            textAlign={"center"}
            err={false}
            unit={""}
            infoBox={false}
            onChange={(valueIn) => {
              let value = valueIn.replace(/-/g, "");
              let elencoPesi = [...vliMemory.elenco_pesi];
              if (!value) {
                elencoPesi[pesoId].n_oggetti = -1;
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              } else if (formatDecimal(value)) {
                elencoPesi[pesoId].n_oggetti = parseInt(
                  formatDecimal(value) != "." ? formatDecimal(value) : -1
                );
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <TextBox
            locked={locked}
            value={
              vliMemory?.elenco_pesi[pesoId]?.n_sollevamenti != -1
                ? vliMemory?.elenco_pesi[pesoId]?.n_sollevamenti
                : "-"
            }
            width={"7vw"}
            err={false}
            textAlign={"center"}
            unit={""}
            infoBox={true}
            onChange={(valueIn) => {
              let value = valueIn.replace(/-/g, "");
              let elencoPesi = [...vliMemory.elenco_pesi];
              if (!value) {
                elencoPesi[pesoId].n_sollevamenti = -1;
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              } else if (formatDecimal(value)) {
                elencoPesi[pesoId].n_sollevamenti = parseInt(
                  formatDecimal(value) != "." ? formatDecimal(value) : -1
                );
                setVliMemory({
                  ...vliMemory,
                  categorie_updated: false,
                  elenco_pesi: elencoPesi,
                });
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <InfoBox
            width={"7vw"}
            value={
              vliRes?.elenco_pesi[pesoId]?.reale_numero_oggetti != -1
                ? vliRes?.elenco_pesi[pesoId]?.reale_numero_oggetti
                : "-"
            }
            unit={""}
            ai={false}
            info={""}
            decimals={0}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <InfoBox
            width={"7vw"}
            value={
              vliRes?.elenco_pesi[pesoId]?.massa_cumulata != -1
                ? vliRes?.elenco_pesi[pesoId]?.massa_cumulata
                : "-"
            }
            unit={t("kg")}
            ai={false}
            info={""}
            decimals={0}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            flex: 1,
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={() => {
              let newElencoPesi = [...vliMemory.elenco_pesi];
              newElencoPesi.splice(pesoId, 1);
              setVliMemory({ ...vliMemory, elenco_pesi: newElencoPesi });
            }}
            disabled={locked}
            style={{ width: "1.875vw" }}
          />
        </div>
      </div>
      <DividerSmall margin={"0vw 1vw 0vw 1vw"} />
    </>
  );
};

// TABLE CATEGORY

export const TableWeightCategory = ({
  availableHeight,
  vliMemory,
  setVliMemory,
  vliRes,
}) => {
  function convertVwToPx(vw) {
    // Ottieni la larghezza del viewport in pixel
    const viewportWidth = window.innerWidth;
    // Calcola il valore in pixel
    return (viewportWidth / 100) * vw;
  }
  let heightComputed =
    availableHeight - 2 * convertVwToPx(3.8) - convertVwToPx(2.6);

  let rowsTemp = [0, 1, 2, 3, 4];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <HeaderWeightCategory />
      <div style={{ overflowY: "scroll", height: heightComputed }}>
        {vliMemory.categorie_peso.map((categoria, id) => {
          return (
            <RowWeightCategory
              key={id}
              catId={id}
              vliMemory={vliMemory}
              setVliMemory={setVliMemory}
            />
          );
        })}
      </div>
      <FooterWeightCategory
        data={[
          vliRes.elenco_pesi_sum.totale_reale_oggetti,
          vliRes.elenco_pesi_sum.totale_massa_cum,
          vliRes.elenco_pesi_sum.totale_massa_op,
        ]}
      />
    </div>
  );
};
export const HeaderWeightCategory = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        height: "3.8vw",
        width: "100%",
        // marginLeft: "-1.25vw",
        borderTop: "0.14vw solid rgba(237, 235, 255, 1)",
        borderBottom: "0.14vw solid rgba(237, 235, 255, 1)",
        backgroundColor: colors.disabled,
        padding: "1.25vw",
        boxSizing: "border-box",
      }}
    >
      <img
        onClick={() => {}}
        src={""}
        style={{
          visibility: "hidden",
          width: "1.2vw",
          height: "1.2vw",
          marginRight: "0.5vw",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1.2,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          <b>{t("categoriaDiPeso")}</b>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "7vw",
          }}
        >
          {t("pesiMediPerCategoria")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          info={tips[document.language].pesi_medi_categoria}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("realeNumeroDiOggettiSollevati")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          info={tips[document.language].reale_numero_oggetti}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 0.7,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "6vw",
          }}
        >
          {t("percentualePesiTrasportati")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          std={true}
          info={tips[document.language].perc_pesi_trasportati}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {t("massaCumulata")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          std={true}
          info={tips[document.language].massa_cumulata}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "6.5vw",
          }}
        >
          {t("numLavoratoriCoinvolti")}
        </p>
        <InfoButton
          ai={false}
          err={false}
          std={true}
          info={tips[document.language].n_lavoratori_coinvolti}
          addStyle={{ marginLeft: "-1.2vw" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 0.7,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "6vw",
          }}
        >
          {t("sollevataDaUnSoloArto")}
        </p>
      </div>
    </div>
  );
};

export const FooterWeightCategory = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        height: "3.8vw",
        width: "100%",
        // marginLeft: "-1.25vw",
        // borderTop: "0.14vw solid rgba(237, 235, 255, 1)",
        // borderBottom: "0.14vw solid rgba(237, 235, 255, 1)",
        backgroundColor: "white",
        padding: "1.25vw",
        boxSizing: "border-box",
      }}
    >
      <img
        onClick={() => {}}
        src={""}
        style={{
          visibility: "hidden",
          width: "1.2vw",
          height: "1.2vw",
          marginRight: "0.5vw",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1.2,
        }}
      >
        <CalculatorIcon fill={colors.positive2} size={"1.4vw"} />
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          <b>{t("totale")}</b>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {""}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "1vw",
            width: "8vw",
          }}
        >
          {data[0] != -1 ? data[0] : "-"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 0.7,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {""}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "8vw",
          }}
        >
          {data[1] != -1 ? data[1] + " kg" : "-"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <p
          style={{
            color: colors.positive2,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
            marginLeft: "0.3vw",
            width: "14vw",
          }}
        >
          {data[2] != -1 ? data[2] + " kg per lavoratore" : "-"}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 0.3,
        }}
      ></div>
    </div>
  );
};

export const RowWeightCategory = ({ catId, vliMemory, setVliMemory }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          padding: "0.2vw 0",
          height: "3.8vw",
          width: "100%",
          paddingLeft: "0.8vw",
          paddingRight: "0.8vw",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginLeft: "0.5vw",
          }}
        >
          <WeightIconNumber
            fill={colors.positive2}
            size={"1.2vw"}
            number={catId}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            marginLeft: "0.5vw",
            flex: 1.2,
          }}
        >
          <TextBox
            value={vliMemory.categorie_peso[catId].categoria}
            locked={true}
            width={"8.75vw"}
            err={false}
            unit={t("kg")}
            infoBox={true}
            onChange={() => {}}
            backgroundFixed={colors.positive2Pale}
            borderColorFixed={setAlpha(colors.positive2, 0.2)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <InfoBox
            width={"7vw"}
            value={vliMemory.categorie_peso[catId].peso_medio_per_categoria}
            unit={t("kg")}
            ai={false}
            info={""}
            decimals={0}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <InfoBox
            width={"7vw"}
            value={
              vliMemory.categorie_peso[catId].n_oggetti_realmente_sollevati
            }
            unit={""}
            ai={false}
            info={""}
            decimals={0}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 0.7,
          }}
        >
          <TextBox
            value={
              vliMemory.categorie_peso[catId].perc_pesi_trasportati != -1
                ? vliMemory.categorie_peso[catId].perc_pesi_trasportati
                : "-"
            }
            width={"4.5vw"}
            err={false}
            unit={"%"}
            infoBox={false}
            onChange={(valueIn) => {
              let value = valueIn.replace(/-/g, "");
              let categorie_new = vliMemory.categorie_peso;
              if (!value) {
                categorie_new[catId].perc_pesi_trasportati = -1;
                setVliMemory({
                  ...vliMemory,
                  categorie_peso: categorie_new,
                });
              } else if (formatDecimal(value) <= 100) {
                categorie_new[catId].perc_pesi_trasportati = parseInt(
                  formatDecimal(value) != "." ? formatDecimal(value) : -1
                );
                setVliMemory({
                  ...vliMemory,
                  categorie_peso: categorie_new,
                });
              }
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <InfoBox
            width={"7vw"}
            value={vliMemory.categorie_peso[catId].massa_cumulata}
            unit={t("kg")}
            ai={false}
            info={""}
            decimals={0}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 1,
          }}
        >
          <TextBox
            value={
              vliMemory.categorie_peso[catId].n_lavoratori != -1
                ? vliMemory.categorie_peso[catId].n_lavoratori
                : "-"
            }
            width={"4.5vw"}
            err={false}
            unit={""}
            infoBox={true}
            onChange={(valueIn) => {
              let value = valueIn.replace(/-/g, "");
              let categorie_new = vliMemory.categorie_peso;
              if (!value) {
                categorie_new[catId].n_lavoratori = -1;
                setVliMemory({
                  ...vliMemory,
                  categorie_peso: categorie_new,
                });
              } else if (formatDecimal(value)) {
                categorie_new[catId].n_lavoratori = parseInt(
                  formatDecimal(value) != "." ? formatDecimal(value) : -1
                );
                setVliMemory({
                  ...vliMemory,
                  categorie_peso: categorie_new,
                });
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flex: 0.7,
          }}
        >
          <CheckBox
            label={"\u00A0"}
            value={vliMemory.categorie_peso[catId].un_arto}
            onClick={() => {
              let categorie_new = vliMemory.categorie_peso;
              categorie_new[catId].un_arto =
                !vliMemory.categorie_peso[catId].un_arto;
              setVliMemory({
                ...vliMemory,
                categorie_peso: categorie_new,
              });
            }}
          />
        </div>
      </div>
      <DividerSmall margin={"0vw 1vw 0vw 1vw"} />
    </>
  );
};

// CHECKBOX TABLE

// Componente per l'intera tabella
export const WeightTable = ({ vliMemory, setVliMemory }) => {
  const [data, setData] = useState(formatTable(vliMemory));
  const { t } = useTranslation();

  useEffect(() => {
    // UPDATE VLI MEMORY
    let newCategories = [];
    vliMemory.categorie_peso.map((cat, id) => {
      let newCat = { ...cat };
      newCat.asimmetrie = [data[1].content[id], data[2].content[id]];
      newCategories.push(newCat);
    });
    setVliMemory({ ...vliMemory, categorie_peso: newCategories });
  }, [data]);

  function formatTable(vliMemory) {
    let header = ["--", "--", "--", "--", "--"];
    let rows = [
      ["--", "--", "--", "--", "--"],
      ["--", "--", "--", "--", "--"],
    ];

    if (vliMemory.categorie_peso)
      vliMemory.categorie_peso.map((cat, id) => {
        header[id] = cat.categoria;
        rows[0][id] = cat.asimmetrie[0];
        rows[1][id] = cat.asimmetrie[1];
      });

    return [
      {
        header: true,
        start: -1,
        content: header,
      },
      {
        header: false,
        start: ">45°, +50%",
        startColor: "y",
        startWidth: "6vw",
        content: rows[0],
      },
      {
        header: false,
        start: ">135°",
        startColor: "r",
        startWidth: "6vw",
        content: rows[1],
        red: true,
      },
    ];
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "0.35vw",
          paddingRight: "0.35vw",
          boxSizing: "border-box",
          gap: "0.7vw",
        }}
      >
        <TableAtom
          topHeader={true}
          width={"6vw"}
          el={
            <>
              <b>GRADI</b>
            </>
          }
        />
        {data.map((row, rowId) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TableAtom
                header={row.header}
                el={row.start}
                width={row.startWidth}
                borderColor={row.startColor}
                backgroundColor={row.startColor}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "27.5vw",
          paddingLeft: "0.35vw",
          paddingRight: "0.35vw",
          boxSizing: "border-box",
          gap: "0.7vw",
        }}
      >
        <TableAtom
          topHeader={true}
          width={"26.8vw"}
          el={
            <>
              <b>{t("categorieDiPeso")}</b> kg
            </>
          }
        />
        {data.map((row, rowId) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {row.content.map((el, elId) => {
                return (
                  <TableAtom
                    header={row.header}
                    el={el}
                    onClick={() => {
                      let dataUpdated = [...data];
                      dataUpdated[rowId].content[elId] =
                        !data[rowId].content[elId];
                      setData(dataUpdated);
                    }}
                    red={row.red}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const MovementsTable = ({ vliMemory, setVliMemory }) => {
  const { t } = useTranslation();

  const [phase, setPhase] = useState(0);

  const [data, setData] = useState(formatTable(vliMemory, phase));

  useEffect(() => {
    // UPDATE VLI MEMORY
    let newCategories = [];
    vliMemory.categorie_peso.map((cat, id) => {
      let newCat = { ...cat };
      newCat.altezze[phase] = [
        data[1].weight[id],
        data[2].weight[id],
        data[3].weight[id],
        data[4].weight[id],
        data[5].weight[id],
      ];
      newCategories.push(newCat);
    });
    setVliMemory({ ...vliMemory, categorie_peso: newCategories });
  }, [data]);

  useEffect(() => {
    setData(formatTable(vliMemory, phase));
  }, [phase]);

  function formatTable(vliMemory, phase) {
    let header = ["--", "--", "--", "--", "--"];
    let rows = Array(5)
      .fill(null)
      .map(() => Array(5).fill("--"));

    if (vliMemory.categorie_peso) {
      vliMemory.categorie_peso.forEach((cat, id) => {
        header[id] = cat.categoria;

        for (let i = 0; i < rows.length; i++) {
          if (cat.altezze[phase] && cat.altezze[phase][i] !== undefined) {
            rows[i][id] = cat.altezze[phase][i];
          }
        }
      });
    }

    return [
      {
        header: true,
        startWidth: "8.75vw",
        weight: header,
        distance: ["24-40", "41-50", "51-63", ">64"],
        distanceColors: ["g", "y", "y", "r"],
      },
      {
        header: false,
        start: (
          <>
            <b style={{ color: colors.negative }}>{"Grave > "}</b>
            {"(>175)"}
          </>
        ),
        startColor: "r",
        startWidth: "8.75vw",
        weight: rows[0],
        weightRed: [true, true, true, true, true],
        distanceRed: [false, false, false, true],
        distance: ["", "", "", ""],
      },
      {
        header: false,
        start: (
          <>
            <b style={{ color: colors.riskUnsure }}>{"Alta "}</b>
            {"(126-175)"}
          </>
        ),
        startColor: "y",
        startWidth: "8.75vw",
        weight: rows[1],
        distance: vliMemory.distanze[phase][0],
        distanceColors: ["g", "", "", ""],
        weightRed: [false, false, false, false, false],
        distanceRed: [false, false, false, true],
      },
      {
        header: false,
        start: (
          <>
            <b style={{ color: colors.positive }}>{"Media "}</b>
            {"(51-125)"}
          </>
        ),
        startColor: "g",
        startWidth: "8.75vw",
        weight: rows[2],
        distance: vliMemory.distanze[phase][1],
        contentColor: "g",
        distanceColors: ["g", "", "", ""],
        weightRed: [false, false, false, false, false],
        distanceRed: [false, false, false, true],
      },
      {
        header: false,
        start: (
          <>
            <b style={{ color: colors.riskUnsure }}>{"Bassa "}</b>
            {"(1-51)"}
          </>
        ),
        startColor: "y",
        startWidth: "8.75vw",
        weight: rows[3],
        distance: vliMemory.distanze[phase][2],
        distanceColors: ["g", "", "", ""],
        weightRed: [false, false, false, false, false],
        distanceRed: [false, false, false, true],
      },
      {
        header: false,
        start: (
          <>
            <b style={{ color: colors.negative }}>{"Grave < "}</b>
            {"(<0)"}
          </>
        ),
        startColor: "r",
        startWidth: "8.75vw",
        weight: rows[4],
        distance: ["", "", "", ""],
        weightRed: [true, true, true, true, true],
        distanceRed: [false, false, false, true],
      },
    ];
  }

  const phases = [{ label: "All'origine" }, { label: "A destinazione" }];

  // let data = [
  //   {
  //     header: true,
  //     start: false,
  //     content: ["3,5 - 7,1", "7,1 - 10,7", "10,7 - 14,3", "14,3 - 17,9", "--"],
  //   },
  //   {
  //     header: false,
  //     start: ">45°, +50%",
  //     content: [false, false, false, false, "--"],
  //   },
  //   {
  //     header: false,
  //     start: ">135°",
  //     content: [false, false, true, false, "--"],
  //   },
  // ];

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", marginTop: "-0.5vw" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          <PillSelector
            color={colors.tertiary}
            colorBackground={colors.tertiaryBackground}
            options={phases}
            width={19.3}
            option={phase}
            setOption={setPhase}
          />
          <TableAtom
            topHeader={true}
            width={"19.3vw"}
            el={
              <>
                <b>{t("altezze")}</b>
              </>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "27.5vw",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          <TableAtom
            topHeader={true}
            width={"26.8vw"}
            el={
              <>
                <b>{t("categorieDiPeso")} </b>kg
              </>
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {data[0].weight.map((el, elId) => {
              return (
                <TableAtom
                  header={data[0].header}
                  el={el}
                  backgroundColor={data[0].contentColor}
                  onClick={() => {
                    let dataUpdated = [...data];
                    dataUpdated[0].content[elId] = !data[0].content[elId];
                    setData(dataUpdated);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "22vw",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          <TableAtom
            topHeader={true}
            width={"21.4vw"}
            el={
              <>
                <b>{t("distanze").toUpperCase()}</b> cm
              </>
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {data[0].distance.map((el, elId) => {
              return (
                <TableAtom
                  header={data[0].header}
                  el={el}
                  backgroundColor={
                    data[0].distanceColors && data[0].distanceColors[elId]
                  }
                  borderColor={
                    data[0].distanceColors &&
                    data[0].header &&
                    data[0].distanceColors[elId]
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", overflow: "scroll" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "19.3vw",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "10vw",
                background: colors.disabled,
              }}
            >
              <img
                style={{ width: "4vw", height: "auto", height: "8vw" }}
                src={
                  process.env.REACT_APP_RESOURCES_BUCKET + "HumanCarrying.png"
                }
              ></img>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "0.7vw" }}
            >
              {data.slice(1).map((row, rowId) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TableAtom
                      header={row.header}
                      el={row.start}
                      width={row.startWidth}
                      borderColor={row.startColor}
                      backgroundColor={row.startColor}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "27.5vw",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          {data.slice(1).map((row, rowId) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {row.weight.map((el, elId) => {
                  return (
                    <TableAtom
                      header={row.header}
                      el={el}
                      backgroundColor={row.contentColor}
                      onClick={() => {
                        let dataUpdated = [...data];
                        dataUpdated[rowId + 1].weight[elId] =
                          !data[rowId + 1].weight[elId];
                        setData(dataUpdated);
                      }}
                      red={row.weightRed[elId]}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "22vw",
            paddingLeft: "0.35vw",
            paddingRight: "0.35vw",
            boxSizing: "border-box",
            gap: "0.7vw",
          }}
        >
          {data.slice(1).map((row, rowId) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {row.distance.map((el, elId) => {
                  return (
                    <TableAtom
                      header={row.header}
                      el={el}
                      backgroundColor={
                        row.distanceColors && row.distanceColors[elId]
                      }
                      borderColor={
                        row.distanceColors &&
                        row.header &&
                        row.distanceColors[elId]
                      }
                      onClick={() => {
                        let dataUpdated = [...data];
                        dataUpdated[rowId + 1].distance[elId] =
                          !data[rowId + 1].distance[elId];
                        setData(dataUpdated);
                      }}
                      red={row.distanceRed[elId]}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const TableAtom = ({
  topHeader,
  header,
  el,
  onClick,
  width,
  borderColor,
  backgroundColor,
  red,
}) => {
  function getBackgroundColor() {
    if (backgroundColor) {
      if (backgroundColor == "y") return colors.riskUnsurePale;
      if (backgroundColor == "r") return colors.riskNegativePale;
      if (backgroundColor == "g") return colors.positivePale;
    }
    if (topHeader) return colors.disabled;
    if (header) return colors.positive2Pale;
    else return colors.disabled;
  }

  function getBorderColor() {
    if (borderColor) {
      if (borderColor == "y") return setAlpha(colors.riskUnsure, 0.2);
      if (borderColor == "r") return setAlpha(colors.riskNegative, 0.2);
      if (borderColor == "g") return setAlpha(colors.positive, 0.2);
    }
    if (topHeader) return colors.background;
    if (header) return setAlpha(colors.positive2, 0.2);
    else return false;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width ? width : "5vw",
        height: "2.5vw",
        borderRadius: "0.31vw",
        boxSizing: "border-box",
        visibility: el == -1 ? "hidden" : "visible",
        border: getBorderColor() && "0.14vw solid " + getBorderColor(),
        background: getBackgroundColor(),
      }}
    >
      {typeof el == "boolean" && onClick ? (
        <CheckBox
          width={"0vw"}
          label={""}
          value={el}
          onClick={() => onClick()}
          red={red}
        />
      ) : (
        <p
          style={{
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.82vw",
            fontWeight: "400",
            margin: 0,
          }}
        >
          {el}
        </p>
      )}
    </div>
  );
};

// ROW CHRONOGRAM

export const RowChronogram = ({
  chronogram,
  setChronogram,
  activity,
  activityId,
  phases,
  selected,
  setSelected,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.2vw 0",
          height: "3.8vw",
          width: "100%",
          paddingLeft: "1vw",
          paddingRight: "0.8vw",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <img
            style={{
              width: "1.4vw",
              height: "1.4vw",
              cursor: !selected && "pointer",
              marginRight: "0.5vw",
            }}
            onClick={() => setSelected()}
            src={
              selected
                ? process.env.REACT_APP_RESOURCES_BUCKET + "RadioOn.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "Radio.png"
            }
          />
          <div
            style={{
              width: "3.1vw",
              height: "3.1vw",
              marginRight: "0.5vw",
              background: phases[activity.phase].fillColor,
              borderRadius: "0.16vw",
              border: "0.16vw solid " + phases[activity.phase].borderColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {phases[activity.phase].icon(
              phases[activity.phase].borderColor,
              "1.875vw"
            )}
          </div>
          <Selector
            err={false}
            options={Object.keys(phases).map((key) => {
              return { label: phases[key].label, value: key };
            })}
            value={{
              label: phases[activity.phase].label,
              value: activity.phase,
            }}
            onChange={(phase) => {
              let chronoLocal = [...chronogram];
              chronoLocal[activityId].phase = parseInt(phase.value);
              setChronogram(chronoLocal);
            }}
            width={"20.2vw"}
            height={"2.5vw"}
          />
          <TextBox
            style={{ marginLeft: "1.4vw", marginRight: "1.4vw" }}
            value={activity.duration}
            err={activity.duration < phases[activity.phase].minDuration}
            unit={"m"}
            infoBox={false}
            onChange={(value) => {
              if (formatDecimal(value)) {
                let chronoLocal = [...chronogram];
                chronoLocal[activityId].duration = formatDecimal(value);
                setChronogram(chronoLocal);
              }
            }}
          />
          <p
            style={{
              color:
                activity.duration < phases[activity.phase].minDuration
                  ? colors.negative
                  : colors.neutral,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.82vw",
              fontWeight: "400",
            }}
          >
            {"Minimo " + phases[activity.phase].minDuration + " m"}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "ArrowLeft.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "ArrowLeftHw.png"}
            iconDisabled={
              process.env.REACT_APP_RESOURCES_BUCKET + "ArrowLeftDis.png"
            }
            onClick={() => {
              let chronoLocal = [...chronogram];
              [chronoLocal[activityId], chronoLocal[activityId - 1]] = [
                chronoLocal[activityId - 1],
                chronoLocal[activityId],
              ];
              setChronogram(chronoLocal);
            }}
            disabled={!selected}
            style={{
              width: "1.875vw",
              visibility: activityId == 0 && "hidden",
            }}
          />

          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "ArrowRight.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "ArrowRightHw.png"}
            iconDisabled={
              process.env.REACT_APP_RESOURCES_BUCKET + "ArrowRightDis.png"
            }
            onClick={() => {
              let chronoLocal = [...chronogram];
              [chronoLocal[activityId], chronoLocal[activityId + 1]] = [
                chronoLocal[activityId + 1],
                chronoLocal[activityId],
              ];
              setChronogram(chronoLocal);
            }}
            disabled={!selected}
            style={{
              width: "1.875vw",
              visibility: activityId >= chronogram.length - 1 && "hidden",
              marginRight: "1vw",
            }}
          />

          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={() => {
              let chronoLocal = [...chronogram];
              chronoLocal.splice(activityId, 1);
              setChronogram(chronoLocal);
            }}
            style={{ width: "1.875vw" }}
          />
        </div>
      </div>
      <DividerSmall margin={"0vw 1vw 0vw 1vw"} />
    </>
  );
};
