export function getColorFromMapping(valueIn, mapping) {
  let lastAvailableColors = mapping[0].colors; // Imposta un valore di default

  if (!valueIn) {
    return mapping[0].colors;
  }

  for (let i = 0; i < mapping.length; i++) {
    if (parseFloat(valueIn) <= mapping[i].value) {
      return mapping[i].colors; // Restituisci i colori della fascia precedente
    }
    lastAvailableColors = mapping[i].colors; // Aggiorna con i colori della fascia corrente
  }

  return lastAvailableColors; // Restituisci l'ultima fascia se valueIn è maggiore di tutti i limiti superiori
}
