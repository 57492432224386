// components/BatchLevelButtons.jsx
import React from "react";
import { colors } from "../../../config/style";
import { TextBox } from "../../InfoTab/InfoTab";

const BatchLevelButtons = ({
  buttonsContainer,
  batchLevel,
  batchIndex,
  dataForChart,
  currentBatchLevel,
  setBatchLevel,
  setBatchIndex,
  setRefresh,
  refresh,
  user,
  editingBatch,
  setEditingBatchIndex,
  editingBatchIndex,
  updateBatchLevel,
}) => {
  return (
    <div
      ref={buttonsContainer}
      style={{
        position: "absolute",
        left: 0,
        bottom: "-1vw",
        width: "99%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        // padding: "10px 60px 10px 60px", // Aggiusta questo padding per allineare i bottoni agli assi
      }}
    >
      {batchLevel?.[batchIndex]?.length > 0 &&
        dataForChart?.current?.originalBatchLevel &&
        batchLevel[batchIndex].map((value, id) => {
          if (id >= currentBatchLevel[0] && id <= currentBatchLevel[1])
            return (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextBox
                  focusColor={colors.riskUnsure}
                  width={"5vw"}
                  onFocus={() => setEditingBatchIndex(id)}
                  ai={
                    value == dataForChart?.current?.originalBatchLevel[id] &&
                    (editingBatchIndex != id || !editingBatch)
                  }
                  edited={
                    value != dataForChart.current.originalBatchLevel[id] ||
                    (editingBatchIndex == id && editingBatch)
                  }
                  value={Math.round(value)}
                  onChange={(value) => {
                    updateBatchLevel(value, id);
                  }}
                  locked={
                    !editingBatch || dataForChart?.current?.indexValues[0]
                  }
                  err={false}
                  unit={
                    dataForChart.current.unit ? dataForChart.current.unit : ""
                  }
                />
              </div>
            );
        })}
      {6 - batchLevel[batchIndex]?.length + currentBatchLevel[0] > 0 && (
        <div
          style={{
            display: "flex",
            flex: 6 - batchLevel[batchIndex]?.length + currentBatchLevel[0],
          }}
        ></div>
      )}
    </div>
  );
};

export default BatchLevelButtons;
