import { useClerk } from "@clerk/clerk-react";
import { colors } from "../../config/style";
import {
  newCompany,
  newDepartment,
  newJob,
  newLine,
  newLocation,
  newStation,
  newTask,
  newWorkshift,
  updateStation,
  updateTaskNiosh,
  updateTaskOcra,
} from "../../remote/structure";
import {
  BasicText,
  CheckBox,
  InfoButton,
  Row,
  Selector,
  TextBox,
} from "../InfoTab/InfoTab";
import { Switch } from "../ui/switch";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import { useState, useCallback, useEffect, useRef } from "react";
import { getToken } from "../../userManagement/utilities";
import {
  defaultCompany,
  defaultDepartment,
  defaultJob,
  defaultLineWs,
  defaultTask,
  defaultWorkshift,
} from "../../remote/defaultVariable";
import { useTranslation } from "react-i18next";
import { IMPERSONATION } from "../../config/generalConst";
import NotepadIcon from "../../icons/NotepadIcon";
import { defaultVli } from "../../utils/Protocols/NIOSH/vli";
import { tips } from "../../config/tips";
import EditIcon from "../../icons/EditIcon";
import ToolTipIcon from "../../icons/ToolTipIcon";
import { Textarea } from "../../components/ui/textarea";
import { useToast } from "../../hooks/use-toast";
import { ToastAction } from "../ui/toast";

export const PopupAlert = ({
  negative,
  positive,
  negativeLabel,
  positiveLabel,
  message,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <BasicText
        style={{
          width: "80%",
          color: colors.text,
        }}
      >
        {message}
      </BasicText>{" "}
      <div
        style={{
          width: "100%",
          justifyContent: "end",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: "0.5vw",
        }}
      >
        <ButtonTextIcon
          label={negativeLabel}
          color={colors.negative}
          colorH={colors.negative}
          backgroundH={colors.negativePale}
          background={"transparent"}
          onClick={() => negative()}
          style={{
            fontSize: "0.8vw",
            boxShadow: "0px 0px 0px 0px white",
          }}
        />
        <ButtonTextIcon
          label={positiveLabel}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          loadingLabel={" "}
          loadingStyle={{}}
          onClick={() => {
            setLoading(true);
            positive();
          }}
          style={{ width: "9vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupJob = ({
  MainOwnerCompany,
  location,
  department,
  shift,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newJob(
      location,
      department,
      { ...defaultJob, name: name, workshift: shift },
      await getToken(session)
    );
    onSuccess(request);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("jobName")}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
            maxLength={200}
          />
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createJob")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupDepartment = ({ MainOwnerCompany, location, onSuccess }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    let request = await newDepartment(
      location,
      {
        ...defaultDepartment,
        name: name,
      },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("depName")}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw]"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
            maxLength={200}
          />
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createDep")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupShift = ({
  MainOwnerCompany,
  location,
  department,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    let request = await newWorkshift(
      location,
      department,
      {
        ...defaultWorkshift,
        name: name,
      },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("shiftName")}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            maxLength={200}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          />
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createShift")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupLine = ({
  MainOwnerCompany,
  location,
  department,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let requestLine = await newLine(
      location,
      department,
      { name: name },
      await getToken(session)
    );
    let request = await updateStation(
      location,
      department,
      requestLine.line,
      requestLine.id,
      defaultLineWs,
      await getToken(session)
    );
    onSuccess(request);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("areaName")}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            maxLength={200}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          />
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createArea")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupProcessVideos = ({ onProcess }) => {
  const [selectedVideos, setSelectedVideos] = useState({
    left: false,
    right: false,
    hands: false,
  });

  const handleCheckboxChange = (videoType) => {
    setSelectedVideos((prev) => ({
      ...prev,
      [videoType]: !prev[videoType],
    }));
  };

  const handleProcessClick = () => {
    // Passa le selezioni al callback `onProcess`
    onProcess(selectedVideos);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1vw",
        boxSizing: "border-box",
      }}
    >
      <h3 style={{ marginBottom: "1vw", textAlign: "center" }}>
        Seleziona i video che vuoi processare
      </h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "1vw" }}>
        <label>
          <input
            type="checkbox"
            checked={selectedVideos.left}
            onChange={() => handleCheckboxChange("left")}
          />
          Left
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedVideos.right}
            onChange={() => handleCheckboxChange("right")}
          />
          Right
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedVideos.hands}
            onChange={() => handleCheckboxChange("hands")}
          />
          Hands
        </label>
      </div>
      <button
        style={{
          marginTop: "1.5vw",
          padding: "0.5vw 1vw",
          backgroundColor: "#007BFF",
          color: "white",
          border: "none",
          borderRadius: "0.3vw",
          cursor: "pointer",
        }}
        onClick={handleProcessClick}
      >
        Conferma
      </button>
    </div>
  );
};

export const PopupWorkstation = ({
  MainOwnerCompany,
  location,
  department,
  line,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newStation(
      location,
      department,
      line,
      { name: name },
      await getToken(session)
    );
    onSuccess(request);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("wsName")}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            maxLength={200}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          />
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createWs")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupDescription = ({
  MainOwnerCompany,
  location,
  onSuccess,
  placeholder,
  Name,
  Description,
  onCancel,
}) => {
  const [name, setName] = useState(Name);
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [description, setDescription] = useState(Description);
  const [isFocused, setIsFocused] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);
    console.log("DummyDescription");
    setTimeout(() => {
      setLoading(false);
      onSuccess?.();
      onCancel?.();
    }, 500);
    // let request = await newDepartment(
    //   location,
    //   ownerCompany
    //     ? {
    //         ...defaultDepartment,
    //         name: name,
    //         ownerCompany: MainOwnerCompany,
    //       }
    //     : {
    //         ...defaultDepartment,
    //         name: name,
    //       },
    //   await getToken(session)
    // );
    // onSuccess(request.id);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        handleClick();
      }
    },
    [name, handleClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={placeholder ? placeholder : ""}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            maxLength={200}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          />
        </Row>
        {/* {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )} */}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("salvaModifiche")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupDuplicate = ({
  MainOwnerCompany,
  location,
  onSuccess,
  placeholder,
  Name,
  Description,
  department,
  type,
  shift,
}) => {
  const [name, setName] = useState(Name);
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [description, setDescription] = useState(Description);
  const [isFocused, setIsFocused] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleShiftDuplicate = async () => {
    setLoading(true);
    let request = await newWorkshift(
      location,
      department,
      ownerCompany
        ? {
            ...defaultWorkshift,
            name: name,
            ownerCompany: MainOwnerCompany,
          }
        : {
            ...defaultWorkshift,
            name: name,
          },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  const handleJobClick = async () => {
    setLoading(true);

    let request = await newJob(
      location,
      department,
      ownerCompany
        ? {
            ...defaultJob,
            name: name,
            workshift: shift,
            ownerCompany: MainOwnerCompany,
          }
        : { ...defaultJob, name: name, workshift: shift },
      await getToken(session)
    );
    onSuccess(request);
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter" && name.trim() !== "") {
        if (type === "shift") {
          handleShiftDuplicate();
        } else if (type === "job") {
          handleJobClick();
        }
      }
    },
    [name, handleShiftDuplicate, handleJobClick, type]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleButtonClick = () => {
    if (type === "shift") {
      handleShiftDuplicate();
    } else if (type === "job") {
      handleJobClick();
    }
  };

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row disableJustifyContent gap="0vw">
          <div className="flex items-center justify-start w-[11.5vw]">
            <div className="flex justify-center items-center m-0  mr-[0.2vw] cursor-pointer">
              <EditIcon size={"1.875vw"} fill={"#212040"} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"13.2813vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={placeholder ? placeholder : ""}
          />
        </Row>
        <Row disableJustifyContent alignItems={"start"} gap="0vw">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "11.5vw",
              paddingTop: "0.5vw",
            }}
          >
            <div className="flex justify-center items-center m-0  mr-[0.2vw]">
              <ToolTipIcon size={"1.875vw"} hover={false} />
            </div>
            <BasicText
              style={{
                width: "5vw",
                color: colors.popupText,
              }}
            >
              {t("descrizione")}
            </BasicText>
          </div>
          <Textarea
            className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
            maxLength={200}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Row>
        {/* {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )} */}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "3.47vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("salvaModifiche")}
          disabled={name == ""}
          backgroundH={colors.mainH}
          loading={loading}
          background={colors.main}
          onClick={handleButtonClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupTask = ({
  MainOwnerCompany,
  location,
  department,
  line,
  station,
  onSuccess,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [ownerCompany, setOwnerCompany] = useState(false);
  const [niosh, setNiosh] = useState(false);
  const [ocra, setOcra] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newTask(
      location,
      department,
      line,
      station,
      ownerCompany
        ? {
            name: name,
            description: defaultTask.description,
            ownerCompany: MainOwnerCompany,
          }
        : { name: name, description: defaultTask.description },
      await getToken(session)
    );

    if (ocra) {
      let updateOcra = await updateTaskOcra(
        request.id,
        {},
        await getToken(session)
      );
    }

    if (niosh) {
      let updateNiosh = await updateTaskNiosh(
        request.id,
        defaultVli,
        await getToken(session)
      );
    }

    onSuccess(request.id);
  };

  const isSubmitDisabled = () => {
    return name.trim() === "" || (!ocra && !niosh);
  };

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border">
        {" "}
        <Row justifyContent={"space-between"} height={"2.5vw"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <img
              src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
              style={{
                width: "1.875vw",
                height: "1.875vw",
                margin: "0px",
                marginRight: "0.2vw",
              }}
            />
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("name")}
            </BasicText>
          </div>
          <TextBox
            width={"16.4vw"}
            value={name}
            onChange={(value) => {
              setName(value);
            }}
            locked={false}
            err={false}
            placeholder={t("taskName")}
          />
        </Row>
        <Row justifyContent={"space-between"} height={"2.5vw"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5vw",
            }}
          >
            <InfoButton
              info={"ciao"}
              std={true}
              addStyle={{ margin: "0vw", marginLeft: "0.2vw" }}
            />
            <BasicText
              style={{
                width: "10vw",
                color: colors.text,
              }}
            >
              {"Analisi OCRA?"}
            </BasicText>
          </div>

          <Switch
            value={ocra}
            onClick={() => {
              setOcra(!ocra);
            }}
            className="cursor-pointer h-[1.25vw] w-[2.5vw]"
          />
        </Row>
        <Row justifyContent={"space-between"} height={"2.5vw"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5vw",
            }}
          >
            <InfoButton
              info={"ciao"}
              std={true}
              addStyle={{ margin: "0vw", marginLeft: "0.2vw" }}
            />
            <BasicText
              style={{
                width: "10vw",
                color: colors.text,
              }}
            >
              {"Analisi NIOSH"}
            </BasicText>
          </div>
          <Switch
            value={niosh}
            onClick={() => {
              setNiosh(!niosh);
            }}
            className="cursor-pointer h-[1.25vw] w-[2.5vw]"
          />
        </Row>
        <Row justifyContent={"space-between"} height={"2.5vw"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "25.1vw",
              gap: "0.2vw",
            }}
          >
            <NotepadIcon size={"1.875vw"} />
            <BasicText
              style={{
                width: "10vw",
                color: colors.text,
              }}
            >
              {"Protocollo"}
            </BasicText>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: "25.1vw",
              gap: "0.2vw",
            }}
          >
            {ocra && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: colors.background,
                  boxSizing: "border-box",
                  width: "5.3vw",
                  height: "1.4vw",
                  borderRadius: "0.625vw",
                }}
              >
                <p
                  style={{
                    color: colors.text,
                    fontFamily: "Atkinson Hyperlegible",
                    fontSize: "0.9vw",
                    fontWeight: "400",
                    boxSizing: "border-box",
                    margin: 0,
                  }}
                >
                  OCRA
                </p>
              </div>
            )}
            {niosh && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: colors.background,
                  boxSizing: "border-box",
                  width: "5.3vw",
                  height: "1.4vw",
                  borderRadius: "0.625vw",
                }}
              >
                <p
                  style={{
                    color: colors.text,
                    fontFamily: "Atkinson Hyperlegible",
                    fontSize: "0.9vw",
                    fontWeight: "400",
                    boxSizing: "border-box",
                    margin: 0,
                  }}
                >
                  NIOSH
                </p>
              </div>
            )}
          </div>
        </Row>
        {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
          <Row justifyContent={"center"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <CheckBox
                label={t("ownerCompany")}
                value={ownerCompany}
                onClick={() => {
                  setOwnerCompany(!ownerCompany);
                }}
              />
            </div>
          </Row>
        )}
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "5.8vw 0vw 0.9375vw 0vw",
        }}
      >
        <ButtonTextIcon
          label={t("createTask")}
          backgroundH={colors.mainH}
          loading={loading}
          disabled={isSubmitDisabled()}
          background={colors.main}
          onClick={handleClick}
          style={{
            width: "15.469vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "2.5vw",
            borderRadius: "0.6vw",
            fontSize: "0.8210vw",
            boxShadow: "rgba(113,109,242,0.30)",
          }}
          shadowColor={"0px 10px 20px 0px"}
        />
      </div>
    </>
  );
};

export const PopupDelete = ({
  MainOwnerCompany,
  location,
  onSuccess,
  onCancel,
  onConfirm,
  Name,
  OnCancelDelete,
}) => {
  const [name, setName] = useState(Name);
  const [loading, setLoading] = useState(false);
  const deleteTimeoutRef = useRef(null);
  const undoTriggeredRef = useRef(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleDelete = () => {
    const timeoutDuration = 5000;

    onSuccess();

    toast({
      description: `${Name} Eliminato.`,
      className:
        "bg-[#212040] w-[10.345vw]  h-[0vw]   2xl:h-[0.625vw]  text-center flex justify-around  items-center rounded-[0.625vw] text-white ",
      action: (
        <ToastAction
          altText="Goto schedule to undo"
          className="flex items-center justify-center  cursor-pointer border-none bg-transparent hover:bg-[#515073]  rounded-md px-[4px]"
        >
          <div
            className="flex text-white items-center justify-center gap-1 w-full"
            onClick={() => handleUndo()}
          >
            <svg
              width="1.875vw"
              height="1.875vw"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.63604 8.63604C6 11.2721 6 15.5147 6 24C6 32.4853 6 36.7279 8.63604 39.364C11.2721 42 15.5147 42 24 42C32.4853 42 36.7279 42 39.364 39.364C42 36.7279 42 32.4853 42 24C42 15.5148 42 11.2721 39.364 8.63604C36.7279 6 32.4853 6 24 6C15.5147 6 11.2721 6 8.63604 8.63604ZM19.0657 16.7531C19.6135 16.2474 19.6477 15.3933 19.142 14.8455C18.6363 14.2976 17.7822 14.2634 17.2343 14.7691L13.1843 18.5076C12.9075 18.7632 12.75 19.1228 12.75 19.4996C12.75 19.8764 12.9075 20.236 13.1843 20.4916L17.2343 24.23C17.7822 24.7358 18.6363 24.7016 19.142 24.1537C19.6477 23.6059 19.6135 22.7518 19.0657 22.2461L17.5528 20.8496H27.6692C30.3648 20.8496 32.55 23.0348 32.55 25.7304C32.55 28.4259 30.3648 30.6111 27.6692 30.6111H19.5C18.7544 30.6111 18.15 31.2155 18.15 31.9611C18.15 32.7067 18.7544 33.3111 19.5 33.3111H27.6692C31.856 33.3111 35.25 29.9171 35.25 25.7304C35.25 21.5436 31.856 18.1496 27.6692 18.1496H17.5528L19.0657 16.7531Z"
                fill="white"
              />
            </svg>
            <span className=" flex font-atkinson font-bold text-[0.82034vw] ">
              Annulla
            </span>
          </div>
        </ToastAction>
      ),
      duration: timeoutDuration,
    });

    deleteTimeoutRef.current = setTimeout(() => {
      if (!undoTriggeredRef.current) {
        onConfirm();
      }
    }, timeoutDuration);
  };

  const handleUndo = () => {
    clearTimeout(deleteTimeoutRef.current);
    undoTriggeredRef.current = true;
    OnCancelDelete();
  };

  return (
    <>
      <div className="flex w-[100%] flex-col gap-[0.7vw] pt-[0.9375vw] box-border justify-between">
        {" "}
        <span className=" text-[0.821vw] font-atkinson font-normal text-popupText leading-normal">
          {`Eliminando ${name}, perderai definitivamente tutti gli elementi che contiene. Questa azione è irreversibile. Confermi di voler procedere?`}
        </span>
        <div
          style={{
            width: "100%",
            justifyContent: "end",
            alignItems: "center",
            display: "flex",
            height: "2.5vw",
            gap: "0.5vw",
          }}
        >
          <ButtonTextIcon
            label={"Anulla"}
            color={"#6D69F2"}
            colorH={"#8D8AF5"}
            loading={loading}
            background={"Transparent"}
            onClick={() => onCancel()}
            style={{
              width: "10vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "2.5vw",
              borderRadius: "0.6vw",
              fontSize: "0.8210vw",
              fontFamily: "Atkinson Hyperlegible",
              lineHeight: "normal",
            }}
            noShadow="true"
          />
          <ButtonTextIcon
            label={"Elimina"}
            backgroundH={"#FF8080"}
            loading={loading}
            background={"#EB5E5E"}
            onClick={handleDelete}
            style={{
              width: "10vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "2.5vw",
              borderRadius: "0.6vw",
              fontSize: "0.8210vw",
              fontFamily: "Atkinson Hyperlegible",
              fontWeight: "700",
            }}
            shadowColor={"rgba(235,94,94,0.30)"}
          />
        </div>
      </div>
    </>
  );
};

export const PopupCompany = ({ onSuccess }) => {
  const [name, setName] = useState("");
  const [owner, setOwner] = useState("");
  const [loading, setLoading] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newCompany(
      {
        name: name,
        description: defaultCompany.description,
      },
      await getToken(session)
    );
    onSuccess(request.id);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.8vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"center"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("companyName")}
        />
      </Row>
      {user?.publicMetadata?.role == "admin" && IMPERSONATION && (
        <Row justifyContent={"center"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "10vw",
            }}
          >
            <img
              src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
              style={{
                width: "1.875vw",
                height: "1.875vw",
                margin: "0px",
                marginRight: "0.2vw",
              }}
            />
            <BasicText
              style={{
                width: "5vw",
                color: colors.text,
              }}
            >
              {t("owner")}
            </BasicText>
          </div>
          <TextBox
            width={"16.4vw"}
            value={owner}
            onChange={(value) => {
              setOwner(value);
            }}
            locked={false}
            err={false}
            placeholder={""}
          />
        </Row>
      )}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonTextIcon
          label={t("createCompany")}
          backgroundH={colors.mainH}
          loading={loading}
          disabled={name == ""}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export const PopupLocation = ({ company, MainOwnerCompany, onSuccess }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [description, setDescription] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newLocation(
      company,
      { name: name },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.625vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"space-between"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("locationName")}
        />
      </Row>
      <Row height={"auto"} disableJustifyContent alignItems={"start"} gap="0vw">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "11.5vw",
            paddingTop: "0.5vw",
          }}
        >
          <div className="flex justify-center items-center m-0  mr-[0.2vw]">
            <ToolTipIcon size={"1.875vw"} hover={false} />
          </div>
          <BasicText
            style={{
              width: "5vw",
              color: colors.popupText,
            }}
          >
            {t("descrizione")}
          </BasicText>
        </div>
        <Textarea
          className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          maxLength={200}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Row>
      <Row justifyContent={"center"}></Row>
      <Row justifyContent={"center"}>
        <ButtonTextIcon
          label={t("createLocation")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </Row>
    </div>
  );
};

export const Popup = ({ company, MainOwnerCompany, onSuccess }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [description, setDescription] = useState(false);

  const { session, user } = useClerk();
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    let request = await newLocation(
      company,
      { name: name },
      await getToken(session)
    );
    onSuccess(request.id);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.625vw",
        // marginTop: "0.8vw",
        paddingTop: "0.8vw",
        boxSizing: "border-box",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Row justifyContent={"space-between"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "10vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Documentnotes.png"}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              margin: "0px",
              marginRight: "0.2vw",
            }}
          />
          <BasicText
            style={{
              width: "5vw",
              color: colors.text,
            }}
          >
            {t("name")}
          </BasicText>
        </div>
        <TextBox
          width={"16.4vw"}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          locked={false}
          err={false}
          placeholder={t("locationName")}
        />
      </Row>
      <Row height={"auto"} disableJustifyContent alignItems={"start"} gap="0vw">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "11.5vw",
            paddingTop: "0.5vw",
          }}
        >
          <div className="flex justify-center items-center m-0  mr-[0.2vw]">
            <ToolTipIcon size={"1.875vw"} hover={false} />
          </div>
          <BasicText
            style={{
              width: "5vw",
              color: colors.popupText,
            }}
          >
            {t("descrizione")}
          </BasicText>
        </div>
        <Textarea
          className="w-[14vw] lg:w-[14.5vw] 2xl:w-[14.9vw] h-[10.014vw] rounded-[0.31vw] border-[0.14vw] border-textboxPurple focus:border-[rgba(109,105,242,1)] focus:shadow-[0px_7px_10px_-1px_rgba(113,109,242,0.3)] outline-none  text-[0.82vw] font-atkinson text-popupText pt-[0.69vw] pl-[0.6vw] "
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={isFocused ? "" : "Massimo 200 caratteri..."}
          maxLength={200}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Row>
      <Row justifyContent={"center"}></Row>
      <Row justifyContent={"center"}>
        <ButtonTextIcon
          label={t("createLocation")}
          loading={loading}
          disabled={name == ""}
          backgroundH={colors.mainH}
          background={colors.main}
          onClick={handleClick}
          style={{ width: "14vw", display: "flex", justifyContent: "center" }}
        />
      </Row>
    </div>
  );
};
