import Chart from "chart.js/auto";
import _ from "lodash";
import { FILTER_VISIBILITY } from "../../config/generalConst";
import {
  disabledColors,
  indexColors,
  lineColor,
  rightColors,
  wrongColors,
} from "../../config/graphsConfig";
import { colors } from "../../config/style";
import {
  butterLowpassFilter,
  extractVisibilityIntervals,
  filterArrayDiscrete,
} from "../../utils/SignalProcessing";

export function calculateTotalDuration(currentData, data, selectedChart) {
  let duration = data.graph[selectedChart].stdDuration;
  if (currentData !== null) {
    const totalLength = currentData.errors[0].length; // Assuming all arrays are the same length

    if (duration === 0) {
      // CASO DURATA
      // Step 1: Combine all error arrays into one, marking 1 for any position where any array has 1
      const combinedErrors = [];

      for (let i = 0; i < totalLength; i++) {
        // let hasError = currentData.addedErrors[i] === 1 ? 1 : 0; // Check addedErrors first
        let hasError = 0;
        // Check all errors arrays
        currentData.addedErrors.forEach((errorArray) => {
          if (errorArray[i] === 1) hasError = 1;
        });

        // Check all errors arrays
        currentData.errors.forEach((errorArray) => {
          if (errorArray[i] === 1) hasError = 1;
        });

        combinedErrors.push(hasError);
      }

      // Step 2: Calculate durations for each continuous segment of 1s in the combined array
      let totalDuration = 0;
      let segmentStart = -1; // Start of the current segment of 1s

      for (let i = 0; i < combinedErrors.length; i++) {
        if (combinedErrors[i] === 1 && segmentStart === -1) {
          // Start of a new segment of 1s
          segmentStart = i;
        } else if (combinedErrors[i] === 0 && segmentStart !== -1) {
          // End of the current segment of 1s
          totalDuration +=
            currentData.labels[i] - currentData.labels[segmentStart];
          segmentStart = -1; // Reset for the next possible segment
        }
      }

      // Check if the last segment goes till the end of the array
      if (segmentStart !== -1) {
        totalDuration +=
          currentData.labels[combinedErrors.length - 1] -
          currentData.labels[segmentStart];
      }

      // Step 3: Return the total duration of all 1 segments
      return totalDuration;
    } else {
      // CASO PICCHI
      if (currentData.type === 0) {
        // Function to count segments in an error array
        function countSegments(errorArray) {
          let segmentCount = 0;
          let segmentStart = false;

          for (let i = 0; i < totalLength; i++) {
            if (errorArray[i] === 1 && !segmentStart) {
              segmentStart = true; // Start of a new segment
            } else if (errorArray[i] === 0 && segmentStart) {
              segmentStart = false; // End of a segment
              segmentCount++; // Increment segment count
            }
          }

          // If the last segment goes until the end
          if (segmentStart) {
            segmentCount++;
          }

          return segmentCount;
        }

        // Calculate number of segments for addedErrors
        let addedErrorsSegmentCount = currentData.addedErrors.reduce(
          (acc, errorArray) => acc + countSegments(errorArray),
          0
        );

        // Calculate total number of segments for each error array
        let totalErrorSegments = currentData.errors.reduce(
          (acc, errorArray) => acc + countSegments(errorArray),
          0
        );

        // Calculate total time as number of segments multiplied by duration for addedErrors and all errors
        let totalTime =
          (addedErrorsSegmentCount + totalErrorSegments) * duration;

        return totalTime;
      }
      // CASO ONDA QUADRA
      else if (currentData.type === 1) {
        return (
          currentData?.batchLevel?.reduce(
            (acc, valoreCorrente) => acc + valoreCorrente,
            0
          ) * duration
        );
      } else if (currentData.type === 2) {
        if (currentData.unit) {
          const durataFissaBatch = 10; // Durata fissa di ogni batch in secondi
          const durataTotale =
            currentData.labels[currentData.labels.length - 1];
          // Calcola la durata dell'ultimo batch
          const durataUltimoBatch =
            currentData.batchLevel.length > 1
              ? durataTotale -
                durataFissaBatch * (currentData.batchLevel.length - 1)
              : durataTotale;

          return currentData?.batchLevel?.reduce(
            (acc, valoreCorrente, indice, array) => {
              // Se è l'ultimo elemento nell'array, usa 'durataUltimoBatch', altrimenti usa 'durataFissaBatch'
              const durataBatch =
                indice === array.length - 1
                  ? durataUltimoBatch
                  : durataFissaBatch;
              return acc + (valoreCorrente * durataBatch) / 100;
            },
            0
          );
        } else {
          return (
            currentData?.batchLevel?.reduce(
              (acc, valoreCorrente) => acc + valoreCorrente,
              0
            ) * duration
          );
        }
      }
    }
  }
}

export function findClosestIndexAndCheckError(dataForChart, targetTime) {
  if (!dataForChart) return false;
  // Trova l'indice del tempo più vicino a targetTime
  let closestIndex = dataForChart.labels.reduce(
    (prev, curr, index) =>
      Math.abs(curr - targetTime) <
      Math.abs(dataForChart.labels[prev] - targetTime)
        ? index
        : prev,
    0
  );

  // Controlla se c'è un errore all'indice trovato
  let isError = dataForChart.errors.some(
    (errorArray) => errorArray[closestIndex] === 1
  );

  return isError;
}

export function calculateBatchErrorCounts(labels, errors, batchDuration) {
  let batchErrorCounts = [];
  let batchStart = 0;
  // Nessun controllo iniziale necessario qui, viene gestito nel ciclo e dopo il ciclo

  for (let i = 0; i < labels.length; i++) {
    if (labels[i] >= batchStart + batchDuration) {
      const errorsInBatch = errors.filter(
        (errorIndex) =>
          labels[errorIndex] >= batchStart &&
          labels[errorIndex] < batchStart + batchDuration
      ).length;

      batchErrorCounts.push(errorsInBatch);
      batchStart += batchDuration;
    }
  }

  // Controlla l'ultimo batch, indipendentemente dalla sua lunghezza
  const errorsInLastBatch = errors.filter(
    (errorIndex) =>
      labels[errorIndex] >= batchStart &&
      labels[errorIndex] < labels[labels.length - 1]
  ).length;

  // Aggiungi l'ultimo conteggio degli errori solo se il batch è iniziato (cioè ci sono dati rimanenti)
  if (batchStart < labels[labels.length - 1]) {
    batchErrorCounts.push(errorsInLastBatch);
  }

  return batchErrorCounts;
}

export function createValueArray(
  labels,
  batchErrorCounts,
  batchErrorCountsOriginal,
  batchDuration
) {
  const values = new Array(labels.length).fill(0);
  const errors = new Array(labels.length).fill(1);
  let batchStart = 0;
  let batchIndex = 0;
  let firstElementOfBatch = true;

  for (let i = 0; i < labels.length; i++) {
    if (labels[i] >= batchStart + batchDuration) {
      // Imposta l'ultimo elemento del batch precedente a null
      if (i > 0) {
        values[i - 1] = null;
      }

      batchStart += batchDuration;
      batchIndex++;
      firstElementOfBatch = true;
    }

    const currentErrorsInBatch = batchErrorCounts[batchIndex] || 0;
    const previousErrorsInBatch = batchErrorCountsOriginal[batchIndex] || 0;

    if (firstElementOfBatch) {
      values[i] = null;
      firstElementOfBatch = false;
    } else {
      if (currentErrorsInBatch === previousErrorsInBatch) {
        values[i] = currentErrorsInBatch;
        errors[i] = 1;
      } else {
        values[i] = currentErrorsInBatch;
        errors[i] = 0;
      }
    }
  }

  // Imposta l'ultimo elemento dell'ultimo batch a null
  if (labels.length > 0) {
    values[labels.length - 1] = null;
  }

  return { values, errors };
}

export function reconstructSignal(labels, batchLevels, batchDuration) {
  const signal = []; // Array per il segnale ricostruito

  labels.forEach((label) => {
    const batchIndex = Math.floor(label / batchDuration); // Calcola l'indice del batch corrispondente
    const level = batchLevels[batchIndex] || 0; // Ottieni il livello del batch, usa 0 se l'indice è fuori dall'array
    signal.push(level); // Aggiungi il livello del batch al segnale ricostruito
  });

  return signal;
}

export function calculateZeroPercentage(array) {
  let count = 0; // Contatore per gli zeri

  // Conta tutti gli zeri nell'array
  array.forEach((element) => {
    if (element === 0) {
      count++;
    }
  });

  // Calcola la percentuale di zeri rispetto alla lunghezza totale dell'array
  const percentage = (count / array.length) * 100;

  return percentage.toFixed(2); // Ritorna la percentuale con due cifre decimali
}

export function setAlpha(color, alpha) {
  // Verifica se il colore è in formato RGBA
  if (/^rgba\(/.test(color)) {
    // Estrai i valori R, G, B e A dal colore
    const matches = color.match(/(\d+(\.\d+)*)/g);
    if (matches.length === 4) {
      const r = matches[0];
      const g = matches[1];
      const b = matches[2];
      // Imposta la nuova trasparenza
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  }
  // Se il colore non è in formato RGBA o non ha il formato corretto, restituisci il colore originale
  return color;
}

export const isArrayOfArrays = (arr) => {
  if (arr.length === 0) {
    return false; // Considera un array vuoto come semplice
  }
  return arr.some(Array.isArray);
};

export function checkpoint(
  historyIndex,
  dataForChartHistory,
  dataForChart,
  memoryDepth,
  setDuration,
  data,
  selectedChart
) {
  if (historyIndex.current > 0) {
    dataForChartHistory.current.splice(0, historyIndex.current);
    historyIndex.current = 0;
  }
  // Add current data on top of history
  let newEl = _.cloneDeep(dataForChart);

  dataForChartHistory.current.unshift(newEl);
  // Limit history to max memoryDepth elements
  if (dataForChartHistory.current.length > memoryDepth) {
    dataForChartHistory.current.pop();
  }

  setDuration(calculateTotalDuration(dataForChart, data, selectedChart));
}

export function load(
  graphMemory,
  dataForChart,
  data,
  state,
  videoLoaded,
  selectedChart,
  dataForChartSecondary,
  createChart,
  chartRef,
  chartInstance,
  maxSpan,
  user,
  selectedSpan,
  formatXAxisLabel,
  setBatchLevel,
  setVisibilityScore,
  setGraphMemory
) {
  let fps = state.current_data[data.side].sampling_frequency //Updated with new JSON
    ? state.current_data[data.side].sampling_frequency
    : state.current_data[data.side][data.debug ? data.debug : "debug"]
        .sampling_frequency;

  //CARICO I DATI DEL GRAFICO PRIMARIO
  dataForChart.current = loadData(
    data,
    fps,
    videoLoaded,
    selectedChart,
    graphMemory,
    state,
    maxSpan,
    setBatchLevel,
    setVisibilityScore,
    selectedChart
  );

  //CARICO I DATI DEL GRAFICO SECONDARIO SE ESISTE
  if (data.graph.length > 1 && !graphMemory?.[data?.id_local]?.[1]) {
    dataForChartSecondary.current = loadData(
      data,
      fps,
      videoLoaded,
      1,
      graphMemory,
      state,
      maxSpan,
      setBatchLevel,
      setVisibilityScore,
      selectedChart
    );
  }

  // dataForChart.current = {
  //   ...graphMemory[data.id_local][selectedChart],
  // };

  if (graphMemory?.[data?.id_local]?.[selectedChart]) {
    Object.keys(graphMemory[data.id_local][selectedChart]).forEach((key) => {
      if (
        dataForChart.current.hasOwnProperty(key) &&
        key !== "originalBatchLevel"
      ) {
        if (key === "addedErrors") {
          if (isArrayOfArrays(graphMemory[data.id_local][selectedChart][key]))
            dataForChart.current[key] = graphMemory[data.id_local][
              selectedChart
            ][key].slice(0, dataForChart.current.errors.length);
          else {
            // COMPATIBILITA CON VECCHI SALVATAGGI

            dataForChart.current[key] = dataForChart.current.errors.map(
              (innerArray) => innerArray.map(() => 0)
            );
            dataForChart.current[key][0] =
              graphMemory[data.id_local][selectedChart][key];
          }
        } else {
          dataForChart.current[key] =
            graphMemory[data.id_local][selectedChart][key];
        }
      }
    });
  }

  createChart(
    dataForChart.current,
    chartRef.current.getContext("2d"),
    graphMemory,
    selectedChart,
    data,
    chartInstance,
    setAlpha,
    maxSpan,
    user,
    selectedSpan,
    formatXAxisLabel,
    dataForChartSecondary,
    setGraphMemory
  );
  setBatchLevel([dataForChart.current.batchLevel]);
}

function loadData(
  analysis,
  fps,
  video,
  selectedChartLocal,
  graphMemory,
  state,
  maxSpan,
  setBatchLevel,
  setVisibilityScore,
  selectedChart
) {
  let component =
    state.current_data[analysis.side][
      analysis.debug ? analysis.debug : "debug"
    ][analysis.component];
  let version = state?.current_data[analysis.side]?.version ? true : false;
  // ---------------------VISIBILITY
  let visibility_cumulative = [];
  for (
    let i = 0;
    i < analysis.graph[selectedChartLocal]?.visibility?.length;
    i++
  ) {
    let visibility =
      state.current_data[analysis.side].visibility[
        analysis.graph[selectedChartLocal].visibility[i].joint
      ];

    let thr = analysis.graph[selectedChartLocal].visibility[i].thr;
    if (visibility?.length > 0) {
      let visibility_filtered = butterLowpassFilter(
        visibility,
        analysis.graph[selectedChartLocal].visibility[i].cutoff,
        fps
      );
      visibility_cumulative = (function (
        visibility_cumulative,
        visibility_filtered,
        thr
      ) {
        if (visibility_cumulative.length > 0) {
          return visibility_filtered.map((x, id) => {
            if (x > thr) return visibility_cumulative[id];
            else return 0;
          });
        } else {
          return visibility_filtered.map((x, id) => {
            if (x > thr) return 1;
            else return 0;
          });
        }
      })(visibility_cumulative, visibility_filtered, thr);
    }
  }
  if (visibility_cumulative.length > 0) {
    visibility_cumulative = filterArrayDiscrete(
      visibility_cumulative,
      Math.round(
        state.current_data[analysis.side].sampling_frequency * FILTER_VISIBILITY
      )
    );
    setVisibilityScore(calculateZeroPercentage(visibility_cumulative));
  }

  // -----------------------WISIBILITY ENDDDDD

  // VALUES
  let values = [];
  if (analysis.graph[selectedChartLocal].valuesFromFrames)
    values = analysis.graph[selectedChartLocal].values[0];
  else values = component[analysis.graph[selectedChartLocal].values[0]];
  if (analysis.graph[selectedChartLocal].abs)
    values = values.map((value) => (value < 1 ? 1 : value));

  // LABELS
  let labels = values.map((_, index) => index / fps);
  if (video.duration > 0) {
    let correctionTime = labels[labels.length - 1] / video.duration;
    labels = labels.map((elemento) => elemento / correctionTime);
    if (video.duration < maxSpan.current) {
      // maxSpan.current = video.duration;
    }
  }

  // ERRORS
  let errors = [];
  for (let i = 0; i < analysis.graph[selectedChartLocal].errors.length; i++) {
    errors.push(component[analysis.graph[selectedChartLocal].errors[i]]);
  }

  let batchErrorCounts = 0;

  // IF TYPE 1 COUNT ERRORS
  if (analysis.graph[selectedChartLocal].type === 1) {
    batchErrorCounts = calculateBatchErrorCounts(
      labels,
      errors.flat(),
      analysis.graph[selectedChartLocal].batch
    );

    // setBatchLevel(batchErrorCounts);
    if (selectedChart === selectedChartLocal) {
      setBatchLevel([batchErrorCounts]);
    }
    let res = createValueArray(
      labels,
      batchErrorCounts,
      batchErrorCounts,
      analysis.graph[selectedChartLocal].batch
    );
    values = res.values;
    errors = [res.errors];
  } else if (analysis.graph[selectedChartLocal].type === 2) {
    batchErrorCounts = errors[0];

    // setBatchLevel(batchErrorCounts);
    if (selectedChart === selectedChartLocal) {
      setBatchLevel([batchErrorCounts]);
    }
    let res = createValueArray(
      labels,
      batchErrorCounts,
      batchErrorCounts,
      analysis.graph[selectedChartLocal].batch
    );

    values = res.values;
    errors = [res.errors];
  }
  // If peaks elaborate errors to transform in true false
  else if (analysis.graph[selectedChartLocal].peaks.length > 0) {
    let peaks = component[analysis.graph[selectedChartLocal].peaks[0]];
    errors = markErrorsInSignal(values.length, errors, peaks);
  }

  let thr_values = [];

  if (version) {
    thr_values = analysis.graph[selectedChartLocal].thresholds.map(
      (thr, id) => component[thr]
    );
  } else {
    thr_values = analysis.graph[selectedChartLocal].thresholds;
  }
  let completeData = aggiungiPuntiSoglia(
    values,
    errors,
    visibility_cumulative,
    thr_values,
    labels,
    values?.length < errors[0]?.length ? false : true //Se differenza di array vuol dire che errori hanno già integrato punti soglia
  );

  let thr = [];
  for (let i = 0; i < thr_values.length; i++) {
    thr.push(completeData.dati.map((_, index) => thr_values[i]));
  }

  let visibility_batch = extractVisibilityIntervals(
    completeData.visibility,
    completeData.tempi
  );

  let dataForChartNew = {
    labels: completeData.tempi,
    posizioniAggiunte: completeData.posizioniAggiunte,
    values: [completeData.dati],
    valuesLabels: analysis.graph[selectedChartLocal].valuesLabels,
    errors: completeData.errori,
    indexSpan: completeData.dati.map((x) => 0),
    errorsLabels: analysis.graph[selectedChartLocal].errorsLabels,
    addedErrors: Array.from({ length: completeData.errori.length }, () =>
      Array.from({ length: completeData.dati.length }, () => 0)
    ),
    addingError: Array.from({ length: completeData.dati.length }, () => 0),
    visibility: visibility_batch,
    thr: thr,
    type: analysis.graph[selectedChartLocal].type,
    unit: analysis.graph[selectedChartLocal]?.unit,
    errorsIndex: component[analysis.graph[selectedChartLocal].errorsIndex],
    errorsIndexOriginal:
      component[analysis.graph[selectedChartLocal].errorsIndex],
  };

  if (analysis.graph[selectedChartLocal].type >= 1) {
    dataForChartNew.batchLevel = [...batchErrorCounts];
    dataForChartNew.originalBatchLevel = [...batchErrorCounts];
  }

  return JSON.parse(JSON.stringify(dataForChartNew));
}

export const positionButtons = (chart, buttonsContainer) => {
  const chartArea = chart?.chartArea;

  buttonsContainer.current.style.marginLeft = chartArea.left + "px";
  buttonsContainer.current.style.width =
    chartArea.right - chartArea.left + "px";
};

export function changeSpan(
  span,
  chartInstance,
  maxSpan,
  setCurrentBatchLevel,
  selectedSpan
) {
  let lastData =
    chartInstance.current.data.labels[
      chartInstance.current.data.labels.length - 1
    ];
  let startPoint = maxSpan.current * span;
  let endPoint = maxSpan.current * (span + 1);
  if (lastData < endPoint) {
    // endPoint = lastData;
    // startPoint = endPoint - maxSpan.current;
  }
  let newLevels = [6 * span, 6 * (span + 1) - 1];
  setCurrentBatchLevel(newLevels);

  chartInstance.current.config.options.scales.x.min = startPoint;
  chartInstance.current.config.options.scales.x.max = endPoint;

  chartInstance.current.update("none");
  //chartInstance.update();
  selectedSpan.current = span;
}

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
}

export function markErrorsInSignal(totalFrames, errorArrays, peaks) {
  // Funzione per trovare il prossimo picco dopo un errore
  function findNextPeak(startFrame, peaks) {
    for (let peak of peaks) {
      if (peak > startFrame) {
        return peak;
      }
    }
    return totalFrames; // se non ci sono picchi dopo l'errore
  }

  // Elaborazione per ogni array di errori
  return errorArrays.map((errors) => {
    let signal = new Array(totalFrames).fill(0); // Inizializza l'array di segnale

    for (let errorStart of errors) {
      let nextPeak = findNextPeak(errorStart, peaks);

      for (let i = errorStart; i < nextPeak && i < totalFrames; i++) {
        signal[i] = 1; // Imposta 1 tra l'errore e il picco successivo
      }
    }

    return signal; // Restituisce l'array di segnale per questo set di errori
  });
}

export function aggiungiPuntiSoglia(
  datiInput,
  erroriInput,
  visibilityInput,
  soglie,
  tempiInput,
  enable
) {
  let puntiDaAggiungere = [];
  let dati = [...datiInput]; // Copia superficiale di 'datiInput'
  let tempi = [...tempiInput]; // Copia superficiale di 'tempiInput'
  let errori = erroriInput.map((errore) => [...errore]); // Copia superficiale di ogni array in 'erroriInput'
  let visibility = [...visibilityInput];
  let posizioniAggiunte = [];

  // Prima, identifica tutte le posizioni in cui aggiungere i nuovi punti
  for (let i = 0; i < soglie.length; i++) {
    for (let j = 0; j < dati.length - 1; j++) {
      if (
        (dati[j] < soglie[i] && dati[j + 1] >= soglie[i]) ||
        (dati[j] >= soglie[i] && dati[j + 1] < soglie[i])
      ) {
        let rapporto = (soglie[i] - dati[j]) / (dati[j + 1] - dati[j]);
        let nuovoTempo = tempi[j] + rapporto * (tempi[j + 1] - tempi[j]);

        puntiDaAggiungere.push({
          indice: j,
          tempo: nuovoTempo,
          value: soglie[i],
        });
      }
    }
  }

  puntiDaAggiungere.sort((a, b) => a.indice - b.indice);

  let comulative = 0;
  // Poi, aggiungi i punti identificati
  for (let punto of puntiDaAggiungere) {
    let j = punto.indice;

    let tempo = (tempi[j + comulative] + tempi[j + 1 + comulative]) / 2;

    // Aggiungi il primo punto che eredita le caratteristiche del punto precedente
    dati.splice(j + 1 + comulative, 0, punto.value);
    tempi.splice(j + 1 + comulative, 0, tempo);
    posizioniAggiunte.push(j + 1 + comulative); // Salva la posizione aggiunta
    if (enable) {
      for (let k = 0; k < errori.length; k++) {
        errori[k].splice(j + 1 + comulative, 0, errori[k][j + comulative]);
      }
    }
    if (visibility?.length > 0)
      visibility.splice(j + 1 + comulative, 0, visibility[j + comulative]);

    // Aggiungi il secondo punto che eredita le caratteristiche del punto successivo
    dati.splice(j + 2 + comulative, 0, punto.value);
    tempi.splice(j + 2 + comulative, 0, tempo);
    posizioniAggiunte.push(j + 2 + comulative); // Salva la posizione aggiunta
    if (enable) {
      for (let k = 0; k < errori.length; k++) {
        errori[k].splice(j + 2 + comulative, 0, errori[k][j + 3 + comulative]);
      }
    }
    if (visibility?.length > 0)
      visibility.splice(j + 2 + comulative, 0, visibility[j + 3 + comulative]);
    comulative = comulative + 2;
  }

  return { dati, errori, tempi, visibility, posizioniAggiunte };
}

export function rimuoviPuntiDaArray(arrayList, posizioniAggiunte) {
  // Ordina le posizioni in ordine decrescente per evitare problemi durante la rimozione
  posizioniAggiunte.sort((a, b) => b - a);

  // Rimuove i punti aggiunti da ciascun array nella lista
  for (let posizione of posizioniAggiunte) {
    for (let array of arrayList) {
      array.splice(posizione, 1);
    }
  }

  return arrayList;
}

// export function aggiungiPuntiSogliaOld(
//   datiInput,
//   erroriInput,
//   visibilityInput,
//   soglie,
//   tempiInput,
//   enable
// ) {
//   let puntiDaAggiungere = [];
//   let dati = [...datiInput]; // Copia superficiale di 'datiInput'
//   let tempi = [...tempiInput]; // Copia superficiale di 'tempiInput'
//   let errori = erroriInput.map((errore) => [...errore]); // Copia superficiale di ogni array in 'erroriInput'
//   let visibility = [...visibilityInput];

//   // Prima, identifica tutte le posizioni in cui aggiungere i nuovi punti
//   for (let i = 0; i < soglie.length; i++) {
//     for (let j = 0; j < dati.length - 1; j++) {
//       if (
//         (dati[j] < soglie[i] && dati[j + 1] >= soglie[i]) ||
//         (dati[j] >= soglie[i] && dati[j + 1] < soglie[i])
//       ) {
//         let rapporto = (soglie[i] - dati[j]) / (dati[j + 1] - dati[j]);
//         let nuovoTempo = tempi[j] + rapporto * (tempi[j + 1] - tempi[j]);

//         puntiDaAggiungere.push({
//           indice: j,
//           tempo: nuovoTempo,
//           value: soglie[i],
//         });
//       }
//     }
//   }

//   puntiDaAggiungere.sort((a, b) => a.indice - b.indice);

//   let comulative = 0;
//   // Poi, aggiungi i punti identificati
//   for (let punto of puntiDaAggiungere) {
//     let j = punto.indice;

//     let tempo = (tempi[j + comulative] + tempi[j + 1 + comulative]) / 2;

//     // Aggiungi il primo punto che eredita le caratteristiche del punto precedente
//     dati.splice(j + 1 + comulative, 0, punto.value);
//     tempi.splice(j + 1 + comulative, 0, tempo);
//     if (enable) {
//       for (let k = 0; k < errori.length; k++) {
//         errori[k].splice(j + 1 + comulative, 0, errori[k][j + comulative]);
//       }
//     }
//     if (visibility?.length > 0)
//       visibility.splice(j + 1 + comulative, 0, visibility[j + comulative]);

//     // Aggiungi il secondo punto che eredita le caratteristiche del punto successivo
//     dati.splice(j + 2 + comulative, 0, punto.value);
//     tempi.splice(j + 2 + comulative, 0, tempo);
//     if (enable) {
//       for (let k = 0; k < errori.length; k++) {
//         errori[k].splice(j + 2 + comulative, 0, errori[k][j + 3 + comulative]);
//       }
//     }
//     if (visibility?.length > 0)
//       visibility.splice(j + 2 + comulative, 0, visibility[j + 3 + comulative]);
//     comulative = comulative + 2;
//   }

//   return { dati, errori, tempi, visibility };
// }

export function save(
  graphMemory,
  selectedChart,
  dataForChart,
  data,
  dataForChartSecondary,
  setGraphMemory
) {
  // Check if dataForChart.current exists before accessing properties
  if (dataForChart) {
    dataForChart.newValue =
      Math.round(
        calculateTotalDuration(dataForChart, data, selectedChart) * 10
      ) / 10;

    let graphMemoryNew = {};
    if (graphMemory?.[data.id_local]) {
      graphMemoryNew = { ...graphMemory[data.id_local] };
    }

    let editData = {
      errors: dataForChart["errors"],
      addedErrors: dataForChart["addedErrors"],
      newValue: dataForChart["newValue"],
      batchLevel: dataForChart["batchLevel"],
      originalBatchLevel: dataForChart["originalBatchLevel"],
      errorsIndex: dataForChart["errorsIndex"],
      errorsIndexOriginal: dataForChart["errorsIndexOriginal"],
      posizioniAggiunte: dataForChart["posizioniAggiunte"],
    };

    graphMemoryNew[selectedChart] = editData;

    // Check if dataForChartSecondary.current exists before accessing properties
    if (dataForChartSecondary?.current) {
      dataForChartSecondary.current.newValue =
        Math.round(
          calculateTotalDuration(
            dataForChartSecondary.current,
            data,
            selectedChart
          ) * 10
        ) / 10;

      let editDataSec = {
        errors: dataForChartSecondary.current["errors"],
        addedErrors: dataForChartSecondary.current["addedErrors"],
        newValue: dataForChartSecondary.current["newValue"],
        batchLevel: dataForChartSecondary.current["batchLevel"],
        originalBatchLevel: dataForChartSecondary.current["originalBatchLevel"],
        errorsIndex: dataForChartSecondary.current["errorsIndex"],
        errorsIndexOriginal:
          dataForChartSecondary.current["errorsIndexOriginal"],
        posizioniAggiunte: dataForChartSecondary.current["posizioniAggiunte"],
      };

      graphMemoryNew[1] = { ...editDataSec };
      dataForChartSecondary.current = null; // Reset after saving
    }

    // Save the updated graph memory

    setGraphMemory({ ...graphMemory, [data.id_local]: graphMemoryNew });
  } else {
    console.error("dataForChart.current is undefined.");
  }
}

export const createChart = (
  dataForChart,
  ctx,
  graphMemory,
  selectedChart,
  data,
  chartInstance,
  setAlpha,
  maxSpan,
  user,
  selectedSpan,
  formatXAxisLabel,
  dataForChartSecondary,
  setGraphMemory
) => {
  save(
    graphMemory,
    selectedChart,
    dataForChart,
    data,
    dataForChartSecondary,
    setGraphMemory
  );

  const calculateBorderWidth = () => {
    const vw = window.innerWidth / 100;
    return vw * 0.2; // 0.5vw come esempio
  };

  // Inizializza un array vuoto per memorizzare il risultato
  let globalError = [];

  for (let i = 0; i < dataForChart.errors[0].length; i++) {
    let logicalOr = false; // Inizializza con 'false' o 'true' a seconda del tuo requisito
    for (let j = 0; j < dataForChart.errors.length; j++) {
      logicalOr =
        logicalOr ||
        dataForChart.errors[j][i] ||
        dataForChart.addedErrors[j][i] ||
        dataForChart?.current?.indexSpan[i] ||
        dataForChart?.indexSpan[i];
    }

    globalError.push(logicalOr);
  }

  let rightDatasets = [];
  for (let i = 0; i < dataForChart.values.length; i++) {
    rightDatasets.push({
      label: dataForChart.valuesLabels[i],
      data: dataForChart.values[i].map((value, index) =>
        globalError[index] ? null : value === 0 ? 0.1 : value
      ),
      borderColor:
        data.graph[selectedChart].type >= 1 ? wrongColors[0] : rightColors[i],
      borderWidth: calculateBorderWidth(),
      pointRadius: 0,
      fill: data.graph[selectedChart].type >= 1 ? "start" : false,
      backgroundColor:
        data.graph[selectedChart].type >= 1 && setAlpha(colors.riskUnsure, 0.2),
      tension: 2,
    });
  }

  let errorDatasets = [];
  // Errors
  for (let i = 0; i < dataForChart.errors.length; i++) {
    errorDatasets.push({
      label: dataForChart.errorsLabels[i],
      data: dataForChart.values[0].map(
        (
          value,
          index //Se vogliamo più segnali bisogna aggiungere un ciclo
        ) => {
          if (dataForChart.indexSpan[index]) return null;
          if (dataForChart.errors[i][index] === 1) {
            if (value === 0) return 0.1;
            else {
              return value;
            }
          } else return null;
        }
      ),
      borderColor: wrongColors[i],
      borderWidth: calculateBorderWidth(),
      pointRadius: 0,
      fill: "start",
      tension: 2,
      backgroundColor: setAlpha(wrongColors[i], 0.2),
    });
  }

  // Index errors
  for (let i = 0; i < dataForChart?.indexValues?.length; i++) {
    errorDatasets.push({
      label: dataForChart.errorsLabels[0],
      data: dataForChart.indexSpan.map((value, id) =>
        value == 1 ? dataForChart.indexValues[i][id] : null
      ),
      borderColor: dataForChart.indexValid[i] ? indexColors[i] : "transparent",
      borderWidth: calculateBorderWidth(),
      pointRadius: 0,
      fill: i == dataForChart?.indexValues?.length - 1 ? "start" : "+1",
      tension: 2,
      backgroundColor: !dataForChart.indexValid[i]
        ? "transparent"
        : dataForChart.indexEdit[i]
        ? setAlpha(colors.riskUnsure, 0.2)
        : setAlpha(indexColors[i], 0.2),
    });
  }

  // Disabled errors
  for (let i = 0; i < dataForChart.errors.length; i++) {
    errorDatasets.push({
      label: i === 0 ? "Errore rimosso" : "thr",
      data: dataForChart.values[0].map(
        (
          value,
          index //Se vogliamo più segnali bisogna aggiungere un ciclo
        ) =>
          dataForChart.errors[i][index] === 2 &&
          dataForChart.addedErrors[i][index] === 0
            ? value
            : null
      ),
      borderColor: user?.publicMetadata?.custom?.includes("wizard")
        ? rightColors[0]
        : disabledColors[0],
      borderWidth: calculateBorderWidth(),
      pointRadius: 0,
      fill: "start",
      tension: 2,
      backgroundColor: user?.publicMetadata?.custom?.includes("wizard")
        ? "transparent"
        : setAlpha(disabledColors[0], 0.2),
    });
  }
  // Added errors
  for (let i = 0; i < dataForChart.errors.length; i++) {
    errorDatasets.push({
      label: "Added " + dataForChart.errorsLabels[i],
      data: dataForChart.values[0].map(
        (
          value,
          index //Se vogliamo più segnali bisogna aggiungere un ciclo
        ) => (dataForChart.addedErrors[i][index] === 1 ? value : null)
      ),
      borderColor: wrongColors[i],
      borderWidth: calculateBorderWidth(),
      pointRadius: 0,
      fill: "start",
      tension: 2,
      backgroundColor: setAlpha(colors.riskUnsure, 0.2),
    });
  }

  // Adding error
  errorDatasets.push({
    label: "Adding errors",
    data: dataForChart.values[0].map(
      (
        value,
        index //Se vogliamo più segnali bisogna aggiungere un ciclo
      ) => (dataForChart.addingError[index] === 1 ? value : null)
    ),
    borderColor: wrongColors[0],
    borderWidth: calculateBorderWidth(),
    pointRadius: 0,
    fill: "start",
    tension: 2,
    backgroundColor: setAlpha(colors.riskUnsure, 0.15),
  });

  let thrDatasets = [];
  for (let i = 0; i < dataForChart.thr.length; i++) {
    thrDatasets.push({
      data: dataForChart.thr[i],
      borderColor: wrongColors[i],
      label: "thr",
      borderWidth: Math.round(calculateBorderWidth() / 2),
      fill: false,
      pointRadius: 0,
    });
  }

  let datasets = rightDatasets.concat(errorDatasets).concat(thrDatasets);

  if (chartInstance.current) {
    // Aggiorna il grafico esistente
    chartInstance.current.data.labels = dataForChart.labels;
    chartInstance.current.data.datasets = datasets;

    chartInstance.current.options.scales.y = {
      min: data.graph[selectedChart].y_min,
      max: data.graph[selectedChart].y_max,
      title: {
        display: true,
        text: data.graph[selectedChart].y_label,
        font: {
          size: 12,
          family: "Atkinson Hyperlegible", // Imposta la font family dei tick
        },
      },
      ticks: {
        color: colors.neutral,
        font: {
          size: 12,
          family: "Atkinson Hyperlegible", // Imposta la font family dei tick
        },
      },
    };

    let startPoint = maxSpan.current * selectedSpan.current;
    let endPoint = maxSpan.current * (selectedSpan.current + 1);

    chartInstance.current.options.scales.x = {
      type: "linear",
      title: {
        display: true,
        text: data.graph[selectedChart].x_label,
        padding: { top: 0, left: 0, right: 0, bottom: 0 },
        font: {
          size: 12,
          family: "Atkinson Hyperlegible", // Imposta la font family dei tick
        },
      },
      min: startPoint,
      max: endPoint, // dataForChart.labels[dataForChart.labels.length - 1]
      ticks: {
        callback: formatXAxisLabel,
        color: colors.neutral,
        font: {
          size: 12,
          family: "Atkinson Hyperlegible", // Imposta la font family dei tick
        },
      },
    };
    // Disabilita le animazioni per l'aggiornamento
    chartInstance.current.options.animation = false;

    // Ridisegna il grafico con i nuovi dati
    chartInstance.current.update();
  } else {
    chartInstance.current = new Chart(ctx, {
      plugins: [],
      type: "line",
      lineColor: lineColor,
      data: {
        labels: dataForChart.labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              // Modifica la label per aggiungere 'Secondi: ' prima del valore di x
              title: function (context) {
                return null; // o `return [];` per un array vuoto
              },
              label: function (context) {
                let labelParts = [];
                let label = context.dataset.label || "";

                if (label.includes("thr")) {
                  labelParts.push(`Soglia ${context.parsed.y.toFixed(2)}`);
                  return labelParts;
                }

                if (context.parsed.y !== null) {
                  // Prima aggiungi il valore di y
                  if (label) {
                    labelParts.push(label);
                  } else {
                    labelParts.push(context.parsed.y.toFixed(2));
                  }
                }
                // Poi aggiungi "Secondi: " seguito dal valore di x approssimato
                // Assumendo che il valore di x sia numerico e richieda l'approssimazione
                let xValue = context.parsed.x;
                labelParts.push(`Valore: ${context.parsed.y.toFixed(2)}`);
                labelParts.push(`Secondi: ${xValue.toFixed(2)}`);

                return labelParts; // Questo ritorna un array di stringhe, ogni stringa su una nuova riga nel tooltip
              },
            },
          },
          legend: {
            display: false,
            // position: "right",
            // labels: {
            //   filter: (item) => item.text !== "thr",
            // },
          },
          // title: {
          //   display: true,
          //   text: data.graph[selectedChart].label,
          // },
        },
        animation: true,
        scales: {
          y: {
            min: data.graph[selectedChart].y_min,
            max: data.graph[selectedChart].y_max,
            title: {
              display: true,
              text: data.graph[selectedChart].y_label,
              font: {
                size: 12,
                family: "Atkinson Hyperlegible", // Imposta la font family dei tick
              },
            },
            ticks: {
              color: colors.neutral,
              font: {
                size: 12,
                family: "Atkinson Hyperlegible", // Imposta la font family dei tick
              },
            },
          },
          x: {
            type: "linear",
            title: {
              display: true,
              text: data.graph[selectedChart].x_label,
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
              font: {
                size: 12,
                family: "Atkinson Hyperlegible", // Imposta la font family dei tick
              },
            },
            min: 0,
            max: maxSpan.current, // dataForChart.labels[dataForChart.labels.length - 1],

            ticks: {
              callback: formatXAxisLabel,
              color: colors.neutral,
              font: {
                size: 12,
                family: "Atkinson Hyperlegible", // Imposta la font family dei tick
              },
            },
          },
        },
        // responsive: true, // Imposta il grafico come responsive

        maintainAspectRatio: false, // Non mantenere un rapporto di aspetto fisso
      },
    });

    // CONVERTI ARRAY INIZIALE IN BATCH FATTI IN QUESTO MODO E PASSALI ALLA FUNZIONE PER DISEGNARE
    chartInstance.current.visibility = dataForChart.visibility;
    chartInstance.current.update();
  }

  return dataForChart.values[0].length;
};

export function extractRangeArray(inputArray, rangeIndex) {
  // Trova le fasce
  const ranges = [];
  let start = -1;

  for (let i = 0; i < inputArray.length; i++) {
    if (inputArray[i] === null) {
      if (start !== -1) {
        ranges.push([start, i - 1]);
        start = -1;
      }
    } else if (start === -1) {
      start = i;
    }
  }

  // Aggiungi l'ultima fascia se finisce senza null alla fine dell'array
  if (start !== -1) {
    ranges.push([start, inputArray.length - 1]);
  }

  // Crea l'array di output
  const outputArray = inputArray.map(() => null);
  let rangeStart = null;
  let rangeEnd = null;

  // Controlla se l'indice di fascia è valido
  if (rangeIndex >= 0 && rangeIndex < ranges.length) {
    [rangeStart, rangeEnd] = ranges[rangeIndex];

    // Inserisci "1" nelle posizioni della fascia selezionata
    for (let i = rangeStart; i <= rangeEnd; i++) {
      outputArray[i] = 1;
    }
  }

  return {
    outputArray,
    rangeStart,
    rangeEnd,
  };
}
