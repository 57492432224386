import React from "react";

const EditIcon = ({ size, fill = "none" }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 36 36"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.013 26.9862H19.6208C19.076 26.9862 18.6338 27.4336 18.6338 27.985C18.6338 28.5363 19.076 28.9838 19.6208 28.9838H28.013C28.5578 28.9838 29 28.5363 29 27.985C29 27.4336 28.5578 26.9862 28.013 26.9862Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5346 12.326C14.6654 12.1499 14.9125 12.1143 15.0868 12.2464L21.9037 17.414C22.0784 17.5464 22.1138 17.797 21.9828 17.9736L15.64 26.523C14.1134 28.5872 11.8104 29 10.218 29C9.23101 29 8.52036 28.8402 8.4414 28.8269C8.27032 28.7869 8.1124 28.6671 8.02027 28.5073C7.92815 28.3341 5.66461 24.2591 8.19136 20.8632L14.5346 12.326Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5231 14.5508L23.6413 15.737C23.5104 15.9131 23.2634 15.9487 23.0891 15.8166L16.2711 10.6482C16.0969 10.5161 16.0612 10.2662 16.1913 10.0896L17.0744 8.89104C17.9825 7.65254 19.3906 7 20.8119 7C21.7858 7 22.7596 7.3063 23.6019 7.94552C24.5889 8.7046 25.2337 9.80993 25.418 11.0484C25.589 12.3002 25.2732 13.5387 24.5231 14.5508Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default EditIcon;
