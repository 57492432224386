import { useClerk } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DividerSmall } from "../../AnalysisSelector/AnalysisSelector";
import {
  getDepartment,
  getInspection,
  getTaskPerStation,
} from "../../../remote/structure";
import { getToken } from "../../../userManagement/utilities";
import { colors } from "../../../config/style";
import formattaData from "../../FormattaData/FormattaData";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";
import JobTable from "../JobTable/JobTable";
import { getTasks } from "../../../remote/tasks";
import { useStateValue } from "../../../stores/services/StateProvider";
import { handleLoadDepartment } from "../../../remote/utils";

const InspectionTable = ({ inspection, inspectionIndex, onSave, owner }) => {
  const [state, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);
  const [loadedJob, setLoadedJobs] = useState(0);
  const [inspectionDetails, setInspectionDetails] = useState();

  const loadedJobRef = useRef();
  loadedJobRef.current = loadedJob;

  const { session } = useClerk();

  const { t } = useTranslation();

  useEffect(() => {
    if (inspectionIndex == 0) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {}, [loadedJob]);

  useEffect(() => {
    if (open) {
      loadInspectionDetail();
    }
  }, [open]);

  console.log("inspectionDetails", inspectionDetails);

  const loadInspectionDetail = async (onSuccess) => {
    let token = await getToken(session);
    let res;
    console.log("inspection", inspection);
    if (inspection?.type == "STATION") {
      let depLoaded = await getDepartment(
        inspection.locationId,
        inspection.departmentId,
        await getToken(session)
      );
      let stationsDetail = [];
      for (const stationId in inspection.stations) {
        let stationDetail = findId(inspection.stations[stationId], depLoaded);
        console.log("stationDetail", depLoaded, stationDetail);
        let jwt = await getToken(session);
        let tasks = await getTasks(
          stationDetail.tasks.map((taskWithDuration) => taskWithDuration.task),
          jwt
        );
        stationDetail.tasks = stationDetail.tasks.map(
          (taskWithDuration, id) => {
            return { ...tasks[id], durata: taskWithDuration.perc };
          }
        );
        stationsDetail.push(stationDetail);
      }

      setInspectionDetails({ ...inspection, stations: stationsDetail });
    } else {
      // res = await getInspection(inspection.id, token);
      setInspectionDetails(inspection);
    }
  };

  function inspectionDataLabel() {
    if (inspection?.type == "STATION")
      return (
        <>
          {" "}
          {inspection.stations.length} {t("mainStructureStationAnalyzed")}
        </>
      );
    else
      return (
        <>
          {" "}
          {Object.keys(inspection.jobs).length} {t("mainStructureJobAnalyzed")}
        </>
      );
  }

  return (
    <div
      key={inspection.id + inspectionIndex}
      style={{
        marginBottom: "1vw",
        background: "white",
        width: "72.8vw",
        borderRadius: "0.3vw",
        paddingTop: "0.5vw",
        paddingBottom: "0.5vw",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1.25vw",
          paddingRight: "1.25vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          {owner && (
            <p
              style={{
                color: colors.text,
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "1.0vw",
                fontWeight: "400",
                boxSizing: "border-box",
                margin: "0",
                textDecoration: "underline",
                marginRight: "1vw",
              }}
            >
              {owner ? (
                <>
                  {" "}
                  <b>{inspection.ownerCompany}</b>{" "}
                </>
              ) : (
                <></>
              )}
            </p>
          )}
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
            style={{
              width: "1.9vw",
              height: "1.9vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{inspection.department}</b> |{" "}
            {formattaData(inspection.creationDate, t)}{" "}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            width: "10vw",
          }}
        >
          <ButtonTextIcon
            label={open ? t("close") : t("open")}
            color={colors.text}
            loading={open && !inspectionDetails}
            // loading={true}
            loadingLabel={" "}
            loadingStyle={{}}
            backgroundH={colors.details}
            background={"transparent"}
            icon={
              open
                ? process.env.REACT_APP_RESOURCES_BUCKET + "ArrowUpEmpty.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "ArrowDownEmpty.png"
            }
            iconH={
              open
                ? process.env.REACT_APP_RESOURCES_BUCKET + "ArrowUpEmpty.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "ArrowDownEmpty.png"
            }
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              fontSize: "0.9vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "400",
              width: "5.5vw",
              justifyContent: open && !inspectionDetails ? "center" : "start",
            }}
          />
          {/* <ButtonIcon
              icon={
                process.env.REACT_APP_RESOURCES_BUCKET+"Delete.png"
              }
              iconH={
                process.env.REACT_APP_RESOURCES_BUCKET+"DeleteHw.png"
              }
              onClick={() => {}}
              style={{ width: "1.875vw" }}
            /> */}
        </div>
      </div>
      <DividerSmall margin={"0.25vw 1.25vw 0.25vw 1.25vw"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "1.25vw",
          paddingRight: "1.25vw",
        }}
      >
        {" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0vw",
          }}
        >
          {inspectionDataLabel()}
        </p>
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0vw",
            marginLeft: "0.4vw",
          }}
        >
          {t("mainStructureCreatedBy")} {inspection.author}
        </p>
      </div>

      {open &&
        inspectionDetails &&
        (inspection?.type == "STATION"
          ? inspectionDetails.stations.map((station, id) => {
              return (
                <JobTable
                  icon={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
                  loadedJobs={true}
                  key={station.id}
                  tasks={station.tasks}
                  jobIndex={id}
                  header={{ name: station.name }}
                  columns={["name", "duration", "protocol", "state", "score"]}
                  duration={-1}
                  date={inspection.creationDate}
                  onSave={onSave}
                  setLoadedJobs={() => {
                    loadedJobRef.current = loadedJobRef.current + 1;
                    setLoadedJobs(loadedJobRef.current);
                  }}
                />
              );
            })
          : Object.keys(inspectionDetails.jobs).map((jobKey, jobKeyIndex) => {
              let job = inspectionDetails.jobs[jobKey];
              return (
                <JobTable
                  loadedJobs={
                    loadedJobRef.current == Object.keys(inspection.jobs).length
                  }
                  key={job.id}
                  tasks={job.tasks.map((item) => {
                    return { ...item.task, durata: item.durata };
                  })}
                  jobIndex={jobKeyIndex}
                  header={{ name: job.name }}
                  columns={[
                    "name",
                    "ws",
                    "duration",
                    "protocol",
                    "state",
                    "score",
                  ]}
                  duration={-1}
                  date={inspection.creationDate}
                  // onSave={onSave}
                  setLoadedJobs={() => {
                    loadedJobRef.current = loadedJobRef.current + 1;
                    setLoadedJobs(loadedJobRef.current);
                  }}
                />
              );
            }))}
    </div>
  );
};

export function findId(targetId, obj) {
  for (const line of Object.values(obj.lines)) {
    if (line[targetId]) {
      return line[targetId]; // Restituisce l'oggetto associato all'id
    }
  }
  return null; // Non trovato
}

export default InspectionTable;
