import React from "react";

const ExpandMoreIcon = ({ fill = "none", size, menuOpen }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-md
      group ${
        menuOpen ? "bg-[#BEBCFB]" : "hover:bg-[#BEBCFB]"
      } cursor-pointer p-[0.2vw]`}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 4"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="20.2511"
          cy="3.00084"
          r="2.67857"
          transform="rotate(90 20.2511 3.00084)"
          className={`fill-[#212040] ${
            menuOpen
              ? "fill-[rgba(109,105,242,1)]"
              : "group-hover:fill-[rgba(109,105,242,1)]"
          }`}
        />
        <circle
          cx="11.6808"
          cy="3.00084"
          r="2.67857"
          transform="rotate(90 11.6808 3.00084)"
          className={`fill-[#212040] ${
            menuOpen
              ? "fill-[rgba(109,105,242,1)]"
              : "group-hover:fill-[rgba(109,105,242,1)]"
          }`}
        />
        <circle
          cx="3.10854"
          cy="3.00084"
          r="2.67857"
          transform="rotate(90 3.10854 3.00084)"
          className={`fill-[#212040] ${
            menuOpen
              ? "fill-[rgba(109,105,242,1)]"
              : "group-hover:fill-[rgba(109,105,242,1)]"
          }`}
        />
      </svg>
    </div>
  );
};

export default ExpandMoreIcon;
