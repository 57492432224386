export function shift_global(input_data) {
  let durata_complessiva_pause_mensa =
    input_data.d_pause + input_data.d_pause_mensa;
  let durata_non_ripetitivi = input_data.task_non_ripetitivi
    ? input_data.t_altro +
      input_data.t_pulizie +
      input_data.t_ragg_post +
      input_data.t_vestirsi
    : 0;
  let durata_media_netta = calcola_durata_media_netta(
    input_data.durata,
    durata_non_ripetitivi,
    input_data.d_pause,
    input_data.d_pause_mensa
  );

  let numero_ore_senza_recupero_automatico = calcola_ore_senza_recupero(
    false,
    input_data.d_no_rec,
    input_data.durata,
    tabella_ricerca,
    input_data.n_pause_effettive +
      input_data.n_pause_extra +
      (input_data.d_pause_mensa > 0 ? 1 : 0)
  );

  let moltiplicatore_recupero = trova_moltiplicatore_recupero(
    false,
    input_data.d_no_rec,
    numero_ore_senza_recupero_automatico
  );

  return {
    output: {
      durata_complessiva_pause_mensa: durata_complessiva_pause_mensa,
      durata_non_ripetitivi: durata_non_ripetitivi,
      durata_media_netta: durata_media_netta,
      numero_ore_senza_recupero_automatico:
        numero_ore_senza_recupero_automatico,
      moltiplicatore_recupero: moltiplicatore_recupero,
    },
    input: input_data,
  };
}

export function single_task_global(input_data) {
  // PROCESSING
  // console.log("input dataaa", input_data);

  let percentuale_tempo_attivo = calcola_percentuale_tempo_attivo(
    input_data.totale_tempo_attivo,
    input_data.veri_cicli ? input_data.tempo_ciclo_osservato : 0,
    input_data.veri_cicli ? 0 : input_data.tempo_ciclo_osservato
  );
  let tempo_osservazione_rappresentativo = input_data.tempo_ciclo_osservato;
  let totale_secondi_tempo_passivo = calcola_tempo_passivo_medio_osservato(
    input_data.flag_tempi_recupero,
    input_data.tempo_ciclo_osservato,
    tempo_osservazione_rappresentativo,
    input_data.totale_tempo_attivo
  );
  let percentuale_tempo_passivo = calcola_percentuale_tempo_passivo(
    totale_secondi_tempo_passivo,
    input_data.tempo_ciclo_osservato,
    tempo_osservazione_rappresentativo
  );
  let durata_media_netta = 400;
  let durata_turno_effettiva = 480;
  let numero_cicli = Math.round(
    (durata_media_netta * 60) / input_data.targa_ciclo
  );
  let minuti_recupero_ciclo = calcola_minuti_recupero_ciclo(
    totale_secondi_tempo_passivo,
    numero_cicli
  );
  let rapporto_tempo_attivo_passivo = calcola_rapporto_tempo_attivo_passivo(
    input_data.totale_tempo_attivo,
    totale_secondi_tempo_passivo
  );

  let durata_media_netta_esclusi_recuperi =
    calcola_durata_media_netta_esclusi_recuperi(
      minuti_recupero_ciclo,
      durata_media_netta
    );
  let durata_ciclo_netto_secondi = input_data.targa_ciclo;
  let percentuale_differenza_ciclo = calcola_percentuale_differenza(
    input_data.tempo_ciclo_osservato,
    durata_ciclo_netto_secondi,
    input_data.veri_cicli
  );
  let moltiplicatore = trova_moltiplicatore(
    durata_media_netta_esclusi_recuperi
  );
  let minuti_non_giustificati = calcola_minuti_non_giustificati(
    durata_media_netta,
    percentuale_differenza_ciclo,
    numero_cicli
  );
  let numero_ore_senza_recupero_automatico = calcola_ore_senza_recupero(
    input_data.flag_tempi_recupero,
    input_data.numero_ore_senza_recupero_manuale,
    durata_turno_effettiva,
    tabella_ricerca,
    3
  );

  let moltiplicatore_recupero = trova_moltiplicatore_recupero(
    input_data.flag_tempi_recupero,
    input_data.numero_ore_senza_recupero_manuale,
    numero_ore_senza_recupero_automatico
  );

  let moltiplicatore_durata = trova_moltiplicatore_durata(
    durata_media_netta_esclusi_recuperi
  );

  let tempo_ciclo_posture_forza = !input_data.veri_cicli
    ? tempo_osservazione_rappresentativo
    : durata_ciclo_netto_secondi;

  let freq_azioni_dinamiche = calcola_freq_azioni_tecniche(
    false,
    false,
    input_data.numero_azioni_dinamiche_dx,
    input_data.numero_azioni_dinamiche_sx,
    tempo_ciclo_posture_forza,
    input_data.totale_tempo_attivo
  );
  let freq_azioni_dinamiche_dx = freq_azioni_dinamiche[0];
  let freq_azioni_dinamiche_sx = freq_azioni_dinamiche[1];
  let rischio_AT_dx = trova_corrispondenza_frequenza_rischio(
    freq_azioni_dinamiche_dx >= max_azioni_tecniche
      ? max_azioni_tecniche
      : freq_azioni_dinamiche_dx,
    input_data.flag_azioni_statiche_dx,
    dizionario_rischio_azioni_tecniche_dinamiche,
    input_data.ritmo_macchina_dx <= 1 ? 1 : 0 //Interruzioni quinsi il contrario di ritmo imposto
  );
  let rischio_AT_sx = trova_corrispondenza_frequenza_rischio(
    freq_azioni_dinamiche_sx >= max_azioni_tecniche
      ? max_azioni_tecniche
      : freq_azioni_dinamiche_sx,
    input_data.flag_azioni_statiche_sx,
    dizionario_rischio_azioni_tecniche_dinamiche,
    input_data.ritmo_macchina_dx <= 1 ? 1 : 0 //Interruzioni quinsi il contrario di ritmo imposto
  );
  let percentuali_incongrue = {
    mano_dx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_mano_incongrua_dx / tempo_ciclo_posture_forza) *
              100
          ),
    spalla_dx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_spalla_incongrua_dx /
              tempo_ciclo_posture_forza) *
              100
          ),
    polso_dx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_polso_incongrua_dx /
              tempo_ciclo_posture_forza) *
              100
          ),
    gomito_dx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_gomito_incongrua_dx /
              tempo_ciclo_posture_forza) *
              100
          ),
    mano_sx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_mano_incongrua_sx / tempo_ciclo_posture_forza) *
              100
          ),
    spalla_sx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_spalla_incongrua_sx /
              tempo_ciclo_posture_forza) *
              100
          ),
    polso_sx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_polso_incongrua_sx /
              tempo_ciclo_posture_forza) *
              100
          ),
    gomito_sx:
      input_data.targa_ciclo < 0
        ? -1
        : roundToTwo(
            (input_data.secondi_gomito_incongrua_sx /
              tempo_ciclo_posture_forza) *
              100
          ),
  };

  // Calcola i livelli di rischio per le parti della mano, polso e gomito (destra e sinistra)
  let rischio_spalla_dx = trova_corrispondenza_percentuale_rischio(
    {
      spalla_dx: percentuali_incongrue["spalla_dx"],
    },
    mappatura_rischio_spalla
  );

  let rischio_spalla_sx = trova_corrispondenza_percentuale_rischio(
    {
      spalla_sx: percentuali_incongrue["spalla_sx"],
    },
    mappatura_rischio_spalla
  );

  // Calcola i livelli di rischio per le parti della mano, polso e gomito (destra e sinistra)
  let rischio_mano_polso_gomito_dx = trova_corrispondenza_percentuale_rischio(
    {
      mano_dx: percentuali_incongrue["mano_dx"],
      polso_dx: percentuali_incongrue["polso_dx"],
      gomito_dx: percentuali_incongrue["gomito_dx"],
    },
    mappatura_rischio_mano_polso_gomito
  );

  let rischio_mano_polso_gomito_sx = trova_corrispondenza_percentuale_rischio(
    {
      mano_sx: percentuali_incongrue["mano_sx"],
      polso_sx: percentuali_incongrue["polso_sx"],
      gomito_sx: percentuali_incongrue["gomito_sx"],
    },
    mappatura_rischio_mano_polso_gomito
  );

  // Unisci tutti i rischi in un unico dizionario
  let rischi_totali = {
    ...rischio_spalla_dx,
    ...rischio_mano_polso_gomito_dx,
    ...rischio_spalla_sx,
    ...rischio_mano_polso_gomito_sx,
  };

  // Calcola il rischio totale per posture (destra e sinistra)
  let rischio_posture_dx = Math.max(
    rischio_spalla_dx["spalla_dx"],
    rischio_mano_polso_gomito_dx["mano_dx"],
    rischio_mano_polso_gomito_dx["polso_dx"],
    rischio_mano_polso_gomito_dx["gomito_dx"]
  );
  let rischio_posture_sx = Math.max(
    rischio_spalla_sx["spalla_sx"],
    rischio_mano_polso_gomito_sx["mano_sx"],
    rischio_mano_polso_gomito_sx["polso_sx"],
    rischio_mano_polso_gomito_sx["gomito_sx"]
  );

  let stereotipia_durata = mappingCicleStereo(tempo_ciclo_posture_forza);

  // Calcola la stereotipia per ciascun lato
  let stereotipia_dx = calcola_stereotipia(
    stereotipia_durata,
    input_data.stereotipia_ripetizione_dx
  );

  let stereotipia_sx = calcola_stereotipia(
    stereotipia_durata,
    input_data.stereotipia_ripetizione_sx
  );

  let rischio_totale_dx =
    stereotipia_dx == -1 ? -1 : rischio_posture_dx + stereotipia_dx;
  let rischio_totale_sx =
    stereotipia_sx == -1 ? -1 : rischio_posture_sx + stereotipia_sx;

  let complementare_dx = calcola_punteggio(
    input_data.uso_martelli_dx,
    input_data.uso_mani_colpi_dx,
    input_data.altro_dx,
    input_data.strumenti_vibranti_dx,
    input_data.ritmo_macchina_dx
  );
  let complementare_sx = calcola_punteggio(
    input_data.uso_martelli_sx,
    input_data.uso_mani_colpi_sx,
    input_data.altro_sx,
    input_data.strumenti_vibranti_sx,
    input_data.ritmo_macchina_dx //Uso solo dx perchè ne serve solo uno
  );

  let percentuali_dx = calcola_percentuali(
    tempo_ciclo_posture_forza,
    input_data.forza_moderata_dx_sec,
    input_data.forza_elevata_dx_sec,
    input_data.picchi_di_forza_dx_sec
  );
  let percentuali_sx = calcola_percentuali(
    tempo_ciclo_posture_forza,
    input_data.forza_moderata_sx_sec,
    input_data.forza_elevata_sx_sec,
    input_data.picchi_di_forza_sx_sec
  );

  let risk_score_moderate_dx =
    input_data.forza_moderata_dx_sec == -1
      ? -1
      : calculate_risk_score_moderate(
          percentuali_dx["moderata"],
          moderate_force_mapping
        );
  let risk_score_elevated_dx =
    input_data.forza_elevata_dx_sec == -1
      ? -1
      : calculate_risk_score(percentuali_dx["elevata"], elevated_force_mapping);
  let risk_score_peaks_dx =
    input_data.picchi_di_forza_dx_sec == -1
      ? -1
      : calculate_risk_score(percentuali_dx["picchi"], force_peaks_mapping);

  let risk_score_moderate_sx =
    input_data.forza_moderata_sx_sec == -1
      ? -1
      : calculate_risk_score_moderate(
          percentuali_sx["moderata"],
          moderate_force_mapping
        );
  let risk_score_elevated_sx =
    input_data.forza_elevata_sx_sec == -1
      ? -1
      : calculate_risk_score(percentuali_sx["elevata"], elevated_force_mapping);
  let risk_score_peaks_sx =
    input_data.picchi_di_forza_sx_sec == -1
      ? -1
      : calculate_risk_score(percentuali_sx["picchi"], force_peaks_mapping);

  let risk_score_borg_dx =
    risk_score_moderate_dx + risk_score_elevated_dx + risk_score_peaks_dx;
  let risk_score_borg_sx =
    risk_score_moderate_sx + risk_score_elevated_sx + risk_score_peaks_sx;

  let punteggio_parziale_dx;
  if (
    rischio_AT_dx < 0 ||
    rischio_totale_dx < 0 ||
    complementare_dx < 0 ||
    risk_score_borg_dx < 0
  ) {
    punteggio_parziale_dx = -1;
  } else {
    punteggio_parziale_dx = roundToN(
      rischio_AT_dx + rischio_totale_dx + complementare_dx + risk_score_borg_dx,
      1
    );
  }

  let punteggio_parziale_sx;
  if (
    rischio_AT_sx < 0 ||
    rischio_totale_sx < 0 ||
    complementare_sx < 0 ||
    risk_score_borg_sx < 0
  ) {
    punteggio_parziale_sx = -1;
  } else {
    punteggio_parziale_sx = roundToN(
      rischio_AT_sx + rischio_totale_sx + complementare_sx + risk_score_borg_sx,
      1
    );
  }

  let punteggio_parziale_intrinseco_dx = roundToN(
    punteggio_parziale_dx * moltiplicatore_recupero,
    1
  );

  let punteggio_parziale_intrinseco_sx = roundToN(
    punteggio_parziale_sx * moltiplicatore_recupero,
    1
  );

  let punteggio_finale_dx = roundToN(
    punteggio_parziale_intrinseco_dx * moltiplicatore_durata,
    2
  );
  let punteggio_finale_sx = roundToN(
    punteggio_parziale_intrinseco_sx * moltiplicatore_durata,
    2
  );

  return {
    // OUTPUT
    output: {
      percentuale_tempo_attivo: percentuale_tempo_attivo,
      totale_secondi_tempo_passivo: totale_secondi_tempo_passivo,
      percentuale_tempo_passivo: percentuale_tempo_passivo,
      minuti_recupero_ciclo: minuti_recupero_ciclo,
      rapporto_tempo_attivo_passivo: rapporto_tempo_attivo_passivo,

      durata_media_netta: durata_media_netta,
      durata_media_netta_esclusi_recuperi: durata_media_netta_esclusi_recuperi,
      durata_ciclo_netto_secondi: durata_ciclo_netto_secondi,
      moltiplicatore: moltiplicatore,
      moltiplicatore_durata: moltiplicatore_durata,
      moltiplicatore_recupero: moltiplicatore_recupero,
      percentuale_differenza_ciclo: Math.abs(percentuale_differenza_ciclo),
      minuti_non_giustificati: minuti_non_giustificati,
      tempo_ciclo_posture_forza: tempo_ciclo_posture_forza,

      freq_azioni_dinamiche_dx:
        input_data.targa_ciclo < 0 ? -1 : freq_azioni_dinamiche_dx,
      freq_azioni_dinamiche_sx:
        input_data.targa_ciclo < 0 ? -1 : freq_azioni_dinamiche_sx,
      rischio_AT_dx: input_data.targa_ciclo < 0 ? -1 : rischio_AT_dx,
      rischio_AT_sx: input_data.targa_ciclo < 0 ? -1 : rischio_AT_sx,

      percentuali_incongrue: percentuali_incongrue,
      rischi_totali: rischi_totali,
      rischio_posture_dx: input_data.targa_ciclo < 0 ? -1 : rischio_posture_dx,
      rischio_posture_sx: input_data.targa_ciclo < 0 ? -1 : rischio_posture_sx,
      stereotipia_dx: input_data.targa_ciclo < 0 ? -1 : stereotipia_dx,
      stereotipia_sx: input_data.targa_ciclo < 0 ? -1 : stereotipia_sx,
      rischio_totale_dx: input_data.targa_ciclo < 0 ? -1 : rischio_totale_dx,
      rischio_totale_sx: input_data.targa_ciclo < 0 ? -1 : rischio_totale_sx,

      complementare_dx: input_data.targa_ciclo < 0 ? -1 : complementare_dx,
      complementare_sx: input_data.targa_ciclo < 0 ? -1 : complementare_sx,

      borg_percentuali_dx: input_data.targa_ciclo < 0 ? -1 : percentuali_dx,
      borg_percentuali_sx: input_data.targa_ciclo < 0 ? -1 : percentuali_sx,
      risk_score_borg_dx: input_data.targa_ciclo < 0 ? -1 : risk_score_borg_dx,
      risk_score_borg_sx: input_data.targa_ciclo < 0 ? -1 : risk_score_borg_sx,

      punteggio_parziale_dx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_parziale_dx,
      punteggio_parziale_sx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_parziale_sx,

      punteggio_parziale_intrinseco_dx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_parziale_intrinseco_dx,
      punteggio_parziale_intrinseco_sx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_parziale_intrinseco_sx,

      punteggio_finale_dx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_finale_dx,
      punteggio_finale_sx:
        input_data.targa_ciclo < 0 ? -1 : punteggio_finale_sx,

      numero_cicli: numero_cicli,
    },

    // INPUT
    input: {
      veri_cicli: input_data.veri_cicli,
      numero_cicli: input_data.numero_cicli,
      tempo_ciclo_osservato: input_data.tempo_ciclo_osservato,
      flag_tempi_recupero: input_data.flag_tempi_recupero,
      totale_tempo_attivo: input_data.totale_tempo_attivo,

      flag_azioni_dinamiche_dx: input_data.flag_azioni_dinamiche_dx,
      flag_azioni_dinamiche_sx: input_data.flag_azioni_dinamiche_sx,

      flag_azioni_statiche_dx: input_data.flag_azioni_statiche_dx,
      flag_azioni_statiche_sx: input_data.flag_azioni_statiche_sx,

      numero_azioni_dinamiche_dx: input_data.numero_azioni_dinamiche_dx,
      numero_azioni_dinamiche_sx: input_data.numero_azioni_dinamiche_sx,

      secondi_mano_incongrua_dx: input_data.secondi_mano_incongrua_dx,
      secondi_spalla_incongrua_dx: input_data.secondi_spalla_incongrua_dx,
      secondi_polso_incongrua_dx: input_data.secondi_polso_incongrua_dx,
      secondi_gomito_incongrua_dx: input_data.secondi_gomito_incongrua_dx,

      secondi_mano_incongrua_sx: input_data.secondi_mano_incongrua_sx,
      secondi_spalla_incongrua_sx: input_data.secondi_spalla_incongrua_sx,
      secondi_polso_incongrua_sx: input_data.secondi_polso_incongrua_sx,
      secondi_gomito_incongrua_sx: input_data.secondi_gomito_incongrua_sx,

      stereotipia_durata: stereotipia_durata,
      stereotipia_ripetizione_dx: input_data.stereotipia_ripetizione_dx,
      stereotipia_ripetizione_sx: input_data.stereotipia_ripetizione_sx,

      uso_martelli_dx: input_data.uso_martelli_dx,
      uso_mani_colpi_dx: input_data.uso_mani_colpi_dx,
      strumenti_vibranti_dx: input_data.strumenti_vibranti_dx,
      ritmo_macchina_dx: input_data.ritmo_macchina_dx,
      altro_dx: input_data.altro_dx,
      uso_martelli_sx: input_data.uso_martelli_sx,
      uso_mani_colpi_sx: input_data.uso_mani_colpi_sx,
      strumenti_vibranti_sx: input_data.strumenti_vibranti_sx,
      ritmo_macchina_sx: input_data.ritmo_macchina_sx,
      altro_sx: input_data.altro_sx,

      forza_moderata_dx_sec: input_data.forza_moderata_dx_sec,
      forza_elevata_dx_sec: input_data.forza_elevata_dx_sec,
      picchi_di_forza_dx_sec: input_data.picchi_di_forza_dx_sec,
      forza_moderata_sx_sec: input_data.forza_moderata_sx_sec,
      forza_elevata_sx_sec: input_data.forza_elevata_sx_sec,
      picchi_di_forza_sx_sec: input_data.picchi_di_forza_sx_sec,
    },
  };
}

function mappingCicleStereo(cicle) {
  if (!cicle) return 0;
  if (cicle <= 8) return 2;
  if (cicle <= 15) return 1;
  return 0;
}

function roundToN(num, n) {
  return +(Math.round(num + "e+" + n) + "e-" + n);
}

function calcola_percentuali(
  tempo_ciclo,
  secondi_moderata,
  secondi_elevata,
  secondi_picchi
) {
  // if (tempo_ciclo === 0) {
  //   throw new Error("Il tempo del ciclo deve essere maggiore di zero.");
  // }

  let percentuale_moderata =
    secondi_moderata == -1 ? 0 : (secondi_moderata / tempo_ciclo) * 100;
  let percentuale_elevata =
    secondi_elevata == -1 ? 0 : (secondi_elevata / tempo_ciclo) * 100;
  let percentuale_picchi =
    secondi_picchi == -1 ? 0 : (secondi_picchi / tempo_ciclo) * 100;

  return {
    moderata: percentuale_moderata,
    elevata: percentuale_elevata,
    picchi: percentuale_picchi,
  };
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

function calcola_punteggio(
  uso_martelli,
  uso_mani,
  altro,
  strumenti_vibranti,
  ritmo_macchina
) {
  if (ritmo_macchina == -1) return -1;
  let punteggio = 0;
  if (strumenti_vibranti) {
    punteggio += 4;
  } else {
    let incremento = 2 * (uso_martelli + uso_mani + altro);
    punteggio += incremento <= 2 ? incremento : 3;
  }

  if (ritmo_macchina === 1) {
    punteggio += 1;
  } else if (ritmo_macchina === 2) {
    punteggio += 1.5;
  } else if (ritmo_macchina === 3) {
    punteggio += 2;
  }

  return punteggio;
}

// Totale secondi tempo attivo medio osservato (in percentuale)
export function calcola_percentuale_tempo_attivo(
  totale_tempo_attivo,
  tempo_ciclo_osservato,
  tempo_osservazione_rappresentativo
) {
  // Verifica che i valori per la divisione non siano zero per evitare l'errore di divisione per zero
  if (totale_tempo_attivo === 0) {
    return 0;
  } else if (tempo_osservazione_rappresentativo > 0) {
    return Math.round(
      (totale_tempo_attivo / tempo_osservazione_rappresentativo) * 100
    );
  } else if (tempo_ciclo_osservato > 0) {
    return Math.round((totale_tempo_attivo / tempo_ciclo_osservato) * 100);
  } else {
    // Restituisce un valore predefinito o genera un errore se entrambi i tempi sono zero
    return "Non applicabile"; // o potrebbe generare un errore specifico se necessario
  }
}

// Totale secondi tempo passivo medio osservato (CONSECUTIVO E STABILE) [TOTALE]
export function calcola_tempo_passivo_medio_osservato(
  flag_tempi_recupero,
  tempo_ciclo_osservato,
  tempo_osservazione_rappresentativo,
  totale_tempo_attivo
) {
  if (flag_tempi_recupero === false) {
    return 0;
  } else if (totale_tempo_attivo < 0) {
    return -1;
  } else if (tempo_ciclo_osservato === 0) {
    return tempo_osservazione_rappresentativo - totale_tempo_attivo;
  } else {
    return tempo_ciclo_osservato - totale_tempo_attivo;
  }
}
function calcola_stereotipia(stereotipia_durata, stereotipia_ripetizione) {
  if (stereotipia_ripetizione == -1) return -1;
  // Calcola il valore per la durata della stereotipia
  function calcola_valore_durata(stereotipia) {
    if (stereotipia === 0) {
      return 0;
    } else if (stereotipia === 1) {
      return 1.5;
    } else if (stereotipia === 2) {
      return 3;
    } else {
      return null;
    }
  }

  // Calcola il valore per la ripetizione della stereotipia
  function calcola_valore_ripetizione(stereotipia) {
    if (stereotipia === 0) {
      return 0;
    } else if (stereotipia === 1) {
      return 1.5;
    } else if (stereotipia === 2) {
      return 3;
    } else {
      return null;
    }
  }

  var valore_durata = calcola_valore_durata(stereotipia_durata);
  var valore_ripetizione = calcola_valore_ripetizione(stereotipia_ripetizione);

  // Se entrambi i valori non sono null, allora restituisci il massimo dei due valori
  if (valore_durata !== null && valore_ripetizione !== null) {
    return Math.max(valore_durata, valore_ripetizione);
  }
  // Se uno dei due valori è null, restituisci un messaggio di errore
  else {
    return "Verifica i valori inseriti";
  }
}

// Totale secondi tempo passivo medio osservato (CONSECUTIVO E STABILE) [PERCENTUALE]
export function calcola_percentuale_tempo_passivo(
  totale_secondi_tempo_passivo,
  tempo_ciclo_osservato,
  tempo_osservazione_rappresentativo
) {
  if (totale_secondi_tempo_passivo === 0) {
    return 0;
  } else if (tempo_ciclo_osservato === 0) {
    return (
      (totale_secondi_tempo_passivo / tempo_osservazione_rappresentativo) * 100
    );
  } else {
    return (totale_secondi_tempo_passivo / tempo_ciclo_osservato) * 100;
  }
}

// Calcolo dei minuti totali di recupero interno al ciclo nel turno
export function calcola_minuti_recupero_ciclo(
  totale_secondi_tempo_passivo,
  numero_cicli
) {
  return (totale_secondi_tempo_passivo * numero_cicli) / 60;
}

export function calcola_rapporto_tempo_attivo_passivo(
  totale_tempo_attivo,
  totale_secondi_tempo_passivo
) {
  // nb: per essere presente il tempo di recupero interni al ciclo, tale numero deve essere = o inf a 6,5
  if (totale_tempo_attivo === "") {
    return 0;
  } else if (totale_tempo_attivo < 0) {
    return -1;
  } else {
    return totale_tempo_attivo / totale_secondi_tempo_passivo || 0;
  }
}

// Calcolo della durata media NETTA nel turno del lavoro ripetitivo (in minuti)
export function calcola_durata_media_netta(
  durata_turno_effettiva,
  durata_lavori_non_ripetitivi,
  durata_pause_effettiva_complessiva,
  durata_pausa_mensa_interna
) {
  return (
    durata_turno_effettiva -
    durata_lavori_non_ripetitivi -
    durata_pause_effettiva_complessiva -
    durata_pausa_mensa_interna
  );
}

export function calcola_durata_ciclo_netto(durata_media_netta, numero_cicli) {
  if (numero_cicli === 0 || numero_cicli == -1) {
    return 0;
  } else {
    return (durata_media_netta * 60) / numero_cicli || "Non applicabile";
  }
}

export function calcola_durata_media_netta_esclusi_recuperi(
  minuti_recupero_ciclo,
  durata_media_netta
) {
  // Restituisce la durata media netta se non ci sono recuperi, altrimenti restituisce i minuti di recupero
  return minuti_recupero_ciclo === 0
    ? durata_media_netta
    : minuti_recupero_ciclo;
}

// Tabella di mapping tempo netto-moltiplicatore
export const tabella_moltiplicatori = {
  0: 0.5,
  181: 0.75,
  241: 0.85,
  301: 0.925,
  361: 0.95,
  421: 1,
  480: 1.2,
  540: 1.5,
  600: 2,
  660: 2.8,
  720: 4,
};

// Trova il moltiplicatore basato sulla durata netta
export function trova_moltiplicatore(durata_netta) {
  const durata_netta_key = Math.floor(durata_netta);
  const chiavi_ordinate = Object.keys(tabella_moltiplicatori)
    .map(Number)
    .sort((a, b) => a - b);
  const chiave_trovata = chiavi_ordinate.reduce(
    (prev, curr) => (curr <= durata_netta_key ? curr : prev),
    0
  );

  return tabella_moltiplicatori[chiave_trovata];
}

// Calcola i minuti non giustificati
export function calcola_minuti_non_giustificati(
  durata_media_netta,
  percentuale_differenza_ciclo,
  numero_cicli
) {
  if (numero_cicli === 0) {
    return "";
  }
  return durata_media_netta * (percentuale_differenza_ciclo / 100);
}

// Calcola la percentuale di differenza tra tempo_ciclo_osservato e durata_ciclo_netto_secondi
export function calcola_percentuale_differenza(
  tempo_ciclo_osservato,
  durata_ciclo_netto_secondi,
  flag_cicli
) {
  if (
    durata_ciclo_netto_secondi === 0 ||
    durata_ciclo_netto_secondi == -1 ||
    !flag_cicli
  ) {
    return 0;
  } else {
    return Math.round(
      (Math.abs(durata_ciclo_netto_secondi - tempo_ciclo_osservato) /
        durata_ciclo_netto_secondi) *
        100
    );
  }
}

// Tabella di ricerca per le ore senza adeguato recupero
export const tabella_ricerca = {
  0: 0,
  120: 1,
  180: 2,
  210: 2.5,
  240: 3,
  250: 3,
  270: 3.5,
  300: 4,
  330: 4.5,
  360: 5,
  390: 5.5,
  420: 6,
  440: 6.5,
  460: 7,
  480: 7,
  540: 8,
  600: 9,
};

export function calcola_ore_senza_recupero(
  flag_recupero,
  ore_senza_recupero_manuale,
  durata_turno,
  tabella_ricerca,
  n_tot_pause
) {
  if (!flag_recupero) {
    const valore_piu_vicino = Object.keys(tabella_ricerca)
      .map(Number)
      .reduce((prev, curr) => (curr <= durata_turno ? curr : prev), 0);
    return tabella_ricerca[valore_piu_vicino] - n_tot_pause;
  } else {
    return ore_senza_recupero_manuale;
  }
}

// Tabella di riferimento per il moltiplicatore di recupero
export const tabella_recupero = {
  0: 1,
  0.5: 1.025,
  1: 1.05,
  1.5: 1.086,
  2: 1.12,
  2.5: 1.16,
  3: 1.2,
  3.5: 1.265,
  4: 1.33,
  4.5: 1.4,
  5: 1.48,
  5.5: 1.58,
  6: 1.7,
  6.5: 1.83,
  7: 2,
  7.5: 2.25,
  8: 2.5,
  9: 3,
};

// Trova il moltiplicatore di recupero basato sulle ore senza adeguato recupero
export function trova_moltiplicatore_recupero(
  flag_tempi_recupero,
  numero_ore_senza_recupero_manuale,
  numero_ore_senza_recupero_automatico
) {
  if (!flag_tempi_recupero) {
    const ore_senza_recupero_manualeVSautomatico =
      numero_ore_senza_recupero_manuale === 0 ||
      numero_ore_senza_recupero_manuale === "" ||
      numero_ore_senza_recupero_manuale === -1
        ? numero_ore_senza_recupero_automatico
        : numero_ore_senza_recupero_manuale;

    let chiavi_valide = Object.keys(tabella_recupero).filter(
      (k) => parseFloat(k) <= ore_senza_recupero_manualeVSautomatico
    );

    if (chiavi_valide.length === 0) {
      // If there are no valid keys, i.e., all keys are greater than 'ore_senza_recupero'
      return 1; // Returns 1 as a default value or handle the error as you prefer
    }

    let chiave_piu_vicina = Math.max(
      ...chiavi_valide.map((k) => parseFloat(k))
    );

    return tabella_recupero[chiave_piu_vicina.toString()];
  } else {
    return 1;
  }
}

// Dizionario che rappresenta la tabella dei moltiplicatori (da X4 a Y15)
export const tabella_moltiplicatori_durata = {
  0: 0.5,
  181: 0.75,
  241: 0.85,
  301: 0.925,
  361: 0.95,
  421: 1,
  480: 1.2,
  540: 1.5,
  600: 2,
  660: 2.8,
  720: 4,
};

// Funzione per trovare il moltiplicatore basato sulla durata media netta esclusi i recuperi
export function trova_moltiplicatore_durata(durata_netta) {
  let moltiplicatore_applicabile = tabella_moltiplicatori_durata[0]; // Imposta il valore di default

  for (let chiave in tabella_moltiplicatori_durata) {
    // Converte la chiave in un numero
    let chiaveNumerica = Number(chiave);

    // Aggiorna il moltiplicatore se la chiave è minore o uguale a durata_netta
    if (chiaveNumerica <= durata_netta) {
      moltiplicatore_applicabile =
        tabella_moltiplicatori_durata[chiaveNumerica];
    }
  }

  return moltiplicatore_applicabile;
}

// Definizione parametri
export const max_azioni_tecniche = 72;

// Calcola la frequenza delle azioni tecniche
export function calcola_freq_azioni_tecniche(
  flag_azioni_dinamiche_dx,
  flag_azioni_dinamiche_sx,
  numero_azioni_dinamiche_dx,
  numero_azioni_dinamiche_sx,
  durata_ciclo_netto_secondi,
  totale_tempo_attivo
) {
  const tempo_ciclo_frequenza =
    totale_tempo_attivo <= 0 ? durata_ciclo_netto_secondi : totale_tempo_attivo;

  const freq_azioni_dinamiche_dx = flag_azioni_dinamiche_dx
    ? max_azioni_tecniche
    : (numero_azioni_dinamiche_dx * 60) / tempo_ciclo_frequenza;
  const freq_azioni_dinamiche_sx = flag_azioni_dinamiche_sx
    ? max_azioni_tecniche
    : (numero_azioni_dinamiche_sx * 60) / tempo_ciclo_frequenza;

  return [
    Math.round(freq_azioni_dinamiche_dx, 1),
    Math.round(freq_azioni_dinamiche_sx, 1),
  ];
}

// Dizionario per il rischio delle azioni tecniche dinamiche
export const dizionario_rischio_azioni_tecniche_dinamiche = {
  0.0: 0.0,
  2.5: 0.0,
  7.5: 0.0,
  12.5: 0.0,
  17.5: 0.0,
  20.0: 0.0,
  22.5: 0.5,
  27.5: 1.0,
  30.0: 1.0,
  32.5: 2.0,
  35.0: 2.0,
  37.5: 3.0,
  40.0: 3.0,
  42.5: 4.0,
  45.0: 4.0,
  47.5: 5.0,
  50.0: 5.0,
  52.5: 6.0,
  55.0: 6.0,
  57.5: 7.0,
  60.0: 7.0,
  62.5: 8.0,
  65.0: 8.0,
  67.5: 9.0,
  70.0: 9.0,
  72.5: 9.0,
};

function trova_corrispondenza_frequenza_rischio(
  freqAzioniTecnicheDestra,
  flagAzioniStatiche,
  dizionario,
  interruzioni
) {
  if (flagAzioniStatiche == -1) return -1;
  const coefficienteAzioniStatiche1 = 2.5; // rischio considerato se è mantenuto un oggetto in presa statica per una durata di almeno 5sec., che occupa 2/3 del tempo ciclo o del periodo di osservazione;
  const coefficienteAzioniStatiche2 = 4.5; // rischio considerato se è mantenuto un oggetto in presa statica per una durata di almeno 5sec., che occupa 3/3 del tempo ciclo o del periodo di osservazione;

  // Trova la chiave più vicina inferiore o uguale a 'freqAzioniTecnicheDestra'
  let chiaviValide = Object.keys(dizionario).filter(
    (k) => parseFloat(k) <= freqAzioniTecnicheDestra
  );
  if (chiaviValide.length === 0) {
    // Se non ci sono chiavi valide, cioè tutte le chiavi sono maggiori di 'freqAzioniTecnicheDestra'
    return null; // Restituisce null o gestisce l'errore come preferisci
  }
  let chiavePiuVicina = Math.max(...chiaviValide.map((k) => parseFloat(k)));

  // RISCHIO STATICO
  let rischioStatico = 0;
  if (flagAzioniStatiche === 2) {
    rischioStatico = coefficienteAzioniStatiche2;
  } else if (flagAzioniStatiche === 1) {
    rischioStatico = coefficienteAzioniStatiche1;
  }
  let output = dizionario[chiavePiuVicina];

  if (interruzioni === 0 && (output > 2 || chiavePiuVicina == 30)) {
    output = output + 1;
  }

  let output_finale = Math.max(output, rischioStatico);

  return output_finale;
}

// Mappatura del rischio per mano, polso, gomito
export const mappatura_rischio_mano_polso_gomito = {
  0.0: 0.0,
  0.05: 0.0,
  0.1: 0.5,
  0.15: 1.0,
  0.2: 1.5,
  0.25: 2.0,
  0.31: 2.5,
  0.37: 3.0,
  0.44: 3.5,
  0.5: 4.0,
  0.54: 4.5,
  0.57: 5.0,
  0.61: 5.5,
  0.65: 6.0,
  0.69: 6.5,
  0.72: 7.0,
  0.76: 7.5,
  0.8: 8.0,
  1.0: 8.0,
};

// Mappatura del rischio per la spalla
export const mappatura_rischio_spalla = {
  0.0: 0.0,
  0.03: 0.5,
  0.05: 1.0,
  0.08: 1.5,
  0.1: 2.0,
  0.12: 2.5,
  0.14: 3.0,
  0.16: 3.5,
  0.18: 4.0,
  0.2: 4.5,
  0.22: 5.0,
  0.24: 5.5,
  0.25: 6.0,
  0.28: 6.5,
  0.31: 7.0,
  0.34: 7.5,
  0.37: 8.0,
  0.4: 9.0,
  0.43: 10.0,
  0.46: 11.0,
  0.5: 12.0,
  0.54: 13.0,
  0.58: 14.0,
  0.62: 15.0,
  0.66: 16.0,
  0.7: 17.0,
  0.74: 18.0,
};

// Trova la corrispondenza tra percentuale di rischio e parte del corpo
export function trova_corrispondenza_percentuale_rischio(
  percentuali,
  mappatura_rischio
) {
  const livelli_rischio = {};
  for (const [parte, percentuale] of Object.entries(percentuali)) {
    const percentuale_rischio = percentuale / 100;
    if (percentuale_rischio < 0) {
      livelli_rischio[parte] = -1;
      continue;
    }
    const chiavi_valide = Object.keys(mappatura_rischio).filter(
      (k) => k <= percentuale_rischio
    );
    if (chiavi_valide.length === 0) {
      livelli_rischio[parte] = 0;
    } else {
      const chiave_piu_vicina = Math.max(...chiavi_valide);
      livelli_rischio[parte] = mappatura_rischio[chiave_piu_vicina];
    }
  }
  return livelli_rischio;
}

// STEREOTIPIA

export function calcolaStereotipia(stereotipiaDurata, stereotipiaRipetizione) {
  function calcolaValore(stereotipia) {
    if (stereotipia === 1) {
      return 1.5;
    } else if (stereotipia === 2) {
      return 3;
    } else if (stereotipia === 0) {
      return 0;
    } else {
      return null;
    }
  }

  const valoreDurata = calcolaValore(stereotipiaDurata);
  const valoreRipetizione = calcolaValore(stereotipiaRipetizione);

  if (valoreDurata === null && valoreRipetizione === null) {
    return "Verifica i valori inseriti";
  }

  return Math.max(valoreDurata, valoreRipetizione);
}

// FORZA DI BORG

export function calcolaPercentuali(
  tempoCiclo,
  secondiModerata,
  secondiElevata,
  secondiPicchi
) {
  if (tempoCiclo === 0) {
    throw new Error("Il tempo del ciclo deve essere maggiore di zero.");
  }

  const percentualeModerata = (secondiModerata / tempoCiclo) * 100;
  const percentualeElevata = (secondiElevata / tempoCiclo) * 100;
  const percentualePicchi = (secondiPicchi / tempoCiclo) * 100;

  return {
    moderata: percentualeModerata,
    elevata: percentualeElevata,
    picchi: percentualePicchi,
  };
}

// Dizionari per il mapping da % su TC a Val Intr per ciascun tipo di forza

let moderate_force_mapping = {
  0.0: 0.0,
  0.05: 0.5,
  0.1: 0.5,
  0.18: 1.0,
  0.26: 1.5,
  0.33: 2.0,
  0.37: 2.5,
  0.42: 3.0,
  0.46: 3.5,
  0.5: 4.0,
  0.54: 4.5,
  0.58: 5.0,
  0.63: 5.5,
  0.67: 6.0,
  0.75: 6.5,
  0.83: 7.0,
  0.92: 7.5,
  1.0: 8.0,
};

let elevated_force_mapping = {
  0.0: 0.0,
  0.16: 2.0,
  0.33: 4.0,
  0.66: 6.0,
  1.0: 8.0,
  1.5: 9.0,
  2.0: 10.0,
  2.5: 11.0,
  3.0: 12.0,
  3.5: 13.0,
  4.0: 14.0,
  4.5: 15.0,
  5.0: 16.0,
  5.63: 17.0,
  6.25: 18.0,
  6.88: 19.0,
  7.5: 20.0,
  8.13: 21.0,
  8.75: 22.0,
  9.38: 23.0,
  10.0: 24.0,
};

let force_peaks_mapping = {
  0.0: 0.0,
  0.16: 3.0,
  0.33: 6.0,
  0.66: 9.0,
  1.0: 12.0,
  1.33: 13.0,
  1.67: 14.0,
  2.0: 15.0,
  2.33: 16.0,
  2.67: 17.0,
  3.0: 18.0,
  3.33: 19.0,
  3.67: 20.0,
  4.0: 21.0,
  4.33: 22.0,
  4.67: 23.0,
  5.0: 24.0,
  5.63: 25.0,
  6.25: 26.0,
  6.88: 27.0,
  7.5: 28.0,
  8.13: 29.0,
  8.75: 30.0,
  9.38: 31.0,
  10.0: 32.0,
};
function calculate_risk_score_moderate(force_percentage, force_mapping) {
  let sorted_keys = Object.keys(force_mapping).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  let adjusted_percentage = force_percentage / 100;
  let closest_previous_percentage = Math.max(
    ...sorted_keys.filter((k) => parseFloat(k) <= adjusted_percentage)
  );
  return force_mapping[closest_previous_percentage];
}

function calculate_risk_score(force_percentage, force_mapping) {
  let sorted_keys = Object.keys(force_mapping).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  let closest_previous_percentage = Math.max(
    ...sorted_keys.filter((k) => parseFloat(k) <= force_percentage)
  );
  return force_mapping[closest_previous_percentage];
}
