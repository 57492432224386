import * as ExcelJS from "exceljs";
import _ from "lodash";

function divideUnequal(N) {
  if (N % 0.5 !== 0) {
    // Genera i due numeri
    const A = N / 2;
    const B = N - A;

    return { A, B };
  }

  // Calcolo base
  const base = N / 2;

  // Calcolo A e B in modo che siano multipli di 0.5
  const A = Math.round((base + 0.25) * 2) / 2; // Arrotondamento a 0.5
  const B = N - A; // Garantisce la somma

  return { A, B };
}

export async function ExcelDownloader(config, file, data, name, onSuccess) {
  // Funzione per calcolare i valori derivati
  const computeValues = (data) => {
    let timeBorgSumDx;
    let borgDx;

    if (data?.tasksData?.index["index_borg5_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg5_dx"];
      borgDx = 5;
    } else if (data?.tasksData?.index["index_borg6_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg6_dx"];
      borgDx = 6;
    } else if (data?.tasksData?.index["index_borg7_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg7_dx"];
      borgDx = 7;
    } else if (data?.tasksData?.index["index_borg8_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg8_dx"];
      borgDx = 8;
    } else if (data?.tasksData?.index["index_borg9_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg9_dx"];
      borgDx = 9;
    } else if (data?.tasksData?.index["index_borg10_dx"] > 0) {
      timeBorgSumDx = data?.tasksData?.index["index_borg10_dx"];
      borgDx = 10;
    } else {
      timeBorgSumDx = parseFloat(data.tasksData["osservato"]);

      borgDx =
        (parseInt(data.tasksData.index["index_borg1_dx"]) * 1 +
          parseInt(data.tasksData.index["index_borg2_dx"]) * 2 +
          parseInt(data.tasksData.index["index_borg3_dx"]) * 3 +
          parseInt(data.tasksData.index["index_borg4_dx"]) * 4 +
          (parseFloat(data.tasksData["osservato"]) -
            (parseInt(data.tasksData.index["index_borg1_dx"]) +
              parseInt(data.tasksData.index["index_borg2_dx"]) +
              parseInt(data.tasksData.index["index_borg3_dx"]) +
              parseInt(data.tasksData.index["index_borg4_dx"]))) *
            0.5) /
        parseFloat(data.tasksData["osservato"]);
    }

    const { A: pronoDx, B: supinaDx } = divideUnequal(
      parseFloat(
        data.tasksData.index.indexResults.rightElbow["pronoSupinationTime"]
      )
    );

    let timeBorgSumSx;
    let borgSx;

    if (data?.tasksData?.index["index_borg5_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg5_sx"];
      borgSx = 5;
    } else if (data?.tasksData?.index["index_borg6_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg6_sx"];
      borgSx = 6;
    } else if (data?.tasksData?.index["index_borg7_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg7_sx"];
      borgSx = 7;
    } else if (data?.tasksData?.index["index_borg8_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg8_sx"];
      borgSx = 8;
    } else if (data?.tasksData?.index["index_borg9_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg9_sx"];
      borgSx = 9;
    } else if (data?.tasksData?.index["index_borg10_sx"] > 0) {
      timeBorgSumSx = data?.tasksData?.index["index_borg10_sx"];
      borgSx = 10;
    } else {
      timeBorgSumSx = parseFloat(data.tasksData["osservato"]);

      borgSx =
        (parseInt(data.tasksData.index["index_borg1_sx"]) * 1 +
          parseInt(data.tasksData.index["index_borg2_sx"]) * 2 +
          parseInt(data.tasksData.index["index_borg3_sx"]) * 3 +
          parseInt(data.tasksData.index["index_borg4_sx"]) * 4 +
          (parseFloat(data.tasksData["osservato"]) -
            (parseInt(data.tasksData.index["index_borg1_sx"]) +
              parseInt(data.tasksData.index["index_borg2_sx"]) +
              parseInt(data.tasksData.index["index_borg3_sx"]) +
              parseInt(data.tasksData.index["index_borg4_sx"]))) *
            0.5) /
        parseFloat(data.tasksData["osservato"]);
    }

    const { A: pronoSx, B: supinaSx } = divideUnequal(
      data.tasksData.index.indexResults.leftElbow["pronoSupinationTime"]
    );

    return {
      timeBorgSumDx,
      borgDx,
      pronoDx,
      supinaDx,
      timeBorgSumSx,
      borgSx,
      pronoSx,
      supinaSx,
    };
  };

  // Calcola i valori derivati
  const computed = computeValues(data);

  // Funzione per scaricare e modificare il file Excel
  const handleDownload = async () => {
    try {
      const response = await fetch(file);
      const arrayBuffer = await response.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      // Usa la configurazione per mappare i dati
      config.pages.forEach((page) => {
        const sheet = workbook.getWorksheet(page.page);
        page.mapping.forEach(({ key, type, cell }) => {
          const value = key in computed ? computed[key] : _.get(data, key); // Ottieni dal calcolato o dai dati
          const targetCell = sheet.getCell(cell);
          if (type === "string") {
            targetCell.value = value;
          } else {
            targetCell.value = Math.round(value * 10) / 10;
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();

      const blob = new Blob([buffer], { type: "application/octet-stream" });

      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = name + ".xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
      onSuccess();
    } catch (error) {
      console.error("Errore nel download o nella modifica del file:", error);
    }
  };

  handleDownload();
}

export default ExcelDownloader;
