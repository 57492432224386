import {
  editWorkshift,
  getLines,
  newDepartment,
  newJob,
  newJobTask,
  newLine,
  newStation,
  newWorkshift,
  updateDepartment,
  updateJob,
  updateLineCar,
  updateStation,
} from "@/remote/structure";
import ActionMenu from "../../components/ActionMenu/ActionMenu";
import TreeToolTip from "../../components/TreeToolTip/TreeToolTip";
import { colors } from "../../config/style";
import { tips } from "../../config/tips";
import ExpandMoreIcon from "../../icons/ExpandMoreIcon";
import FactoryIcon from "../../icons/FactoryIcon";
import PlusIcon from "../../icons/PlusIcon";
import ToolTipIcon from "../../icons/ToolTipIcon";
import {
  handleLoadDepartment,
  handleLoadDepartments,
} from "../../remote/utils";
import { LOAD_LOCATION } from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import {
  PopupDelete,
  PopupDepartment,
  PopupDescription,
  PopupDuplicate,
  PopupJob,
  PopupLine,
  PopupShift,
  PopupWorkstation,
} from "../Popup/PopupContent";
import Spinner from "../Spinner/Spinner";
import { StyledTreeItem } from "./Tree";
import {
  handleDeleteDepartment,
  handleDeleteJob,
  handleDeleteLine,
  handleDeleteShift,
  handleDeleteWorkspace,
  handleSaveDepartmentName,
  handleSaveJob,
  handleSaveLineName,
  handleSaveShift,
  handleSaveWorkspace,
} from "./TreeHandlers";
import {
  defaultDepartment,
  defaultJob,
  defaultLineWs,
  defaultWorkshift,
} from "@/remote/defaultVariable";
import { getToken } from "@/userManagement/utilities";
import { useState } from "react";

const contentJob = ``;
const contentDepartment = ``;

export const renderWorkspaces = ({
  workspaces,
  line,
  depId,
  selectedId,
  loadingId,
  setSelectedId,
  setCurrentDepartment,
  setData,
  openMenuId,
  handleOpenChange,
  editingName,
  setEditingName,
  editedWorkspaceName,
  setEditedWorkspaceName,
  state,
  session,
  setDepartmentDetail,
  content,
  width,
  setLoadingId,
  setPopupContent,
  popupRef,
  t,
  setSelected,
  popupDeleteRef,
  deleted,
  departmentDetail,
  expanded,
  setExpanded,
  loadingName,
  setLoadingName,
}) => {
  console.log("workspacesworkspaces", workspaces);
  function editWsPopupContent(ws, department) {
    return {
      title: t("editworkspacePopupTitle"),
      initialName: ws.name,
      initialDescription: ws.description,
      actionLabel: t("salvaModifiche"),
      phName: t("wsName"),
      api: async (name, description, setLoading) => {
        setLoading(true);

        let request = await updateStation(
          state.current_location.id,
          department.id,
          line,
          ws.id,
          { ...ws, name: name, description: description },
          await getToken(session)
        );
        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  const sortedKeys = Object.keys(workspaces).sort((a, b) => {
    const nameA = workspaces[a]?.name?.toUpperCase();
    const nameB = workspaces[b]?.name?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const workspaceItems = sortedKeys.map((workspaceId) => {
    const workspace = workspaces[workspaceId];
    const isSelected = selectedId === workspace.id;
    return (
      <StyledTreeItem
        key={`ws-${workspace.id}`}
        nodeId={`ws-${workspace.id}`}
        className="group/workspaces "
        labelText={
          loadingId === workspaceId ? (
            <div className="flex justify-center items-center">
              <Spinner size="1.5vw" />
            </div>
          ) : editingName === workspaceId ? (
            <input
              type="text"
              className="relative overflow-hidden text-ellipsis whitespace-nowrap p-[0.15vw] font-atkinson  rounded-[0.31vw] border-[0.15vw] border-solid border-[#6D69F2] focus:outline-none focus:border-[#6D69F2] focus:shadow-[0_0_8px_rgba(113,109,242,0.30)] inline-grid w-full font-inherit text-[0.85vw] pl-[0.3vw]"
              value={loadingName ? "CARICAMENTO" : editedWorkspaceName}
              onChange={(e) => setEditedWorkspaceName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (editedWorkspaceName.trim() !== workspace.name.trim()) {
                    handleSaveWorkspace(
                      workspaceId,
                      state,
                      session,
                      workspaces,
                      departmentDetail[depId],
                      editedWorkspaceName,
                      setEditingName,
                      setLoadingName,
                      setDepartmentDetail
                    );
                  } else {
                    setEditingName(null);
                  }
                } else if (e.key === "Escape") {
                  setEditedWorkspaceName(workspace.name);
                  setEditingName(null);
                }
              }}
              autoFocus
            />
          ) : (
            <div className="inline-grid">
              <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-6">
                {workspace.name}
              </span>
            </div>
          )
        }
        bgColor={colors.mainOpaque}
        font={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.85vw",
          fontWeight: "400",
          width,
        }}
        labelIcon={
          loadingId === workspaceId
            ? null
            : process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"
        }
        extraIcon={
          editingName === workspaceId ? null : (
            <div
              className={`flex 2xl:gap-2 md:gap-1 items-center w-[100%] pl-2 h-full ${
                isSelected
                  ? " bg-gradient-selected"
                  : openMenuId === workspace.id
                  ? "bg-gradient-hover"
                  : " bg-gradient-default"
              }  ${
                openMenuId === workspace.id
                  ? "visible"
                  : "invisible group-hover/workspaces:visible"
              } `}
              onClick={(event) => event.stopPropagation()}
            >
              <>
                <TreeToolTip
                  icon={
                    <div>
                      <ToolTipIcon
                        size="1.35vw"
                        disabled={!workspace.description}
                      />
                    </div>
                  }
                  heading={"DESCRIZIONE"}
                  content={workspace.description}
                  onEmptyClick={() => {
                    setSelected([]);
                    setPopupContent(
                      editWsPopupContent(
                        workspace,
                        departmentDetail[workspace.department]
                      )
                    );
                    popupRef.current.open();
                  }}
                />
                <TreeToolTip
                  icon={
                    <ActionMenu
                      icon={
                        <ExpandMoreIcon
                          size="1.2vw"
                          menuOpen={openMenuId === workspace.id}
                        />
                      }
                      onDelete={() => {
                        setPopupContent({
                          title: t("deleteworkspacePopupTitle"),
                          children: (
                            <PopupDelete
                              Name={workspace.name}
                              onConfirm={() => {
                                handleDeleteWorkspace(
                                  depId,
                                  line,
                                  workspaceId,
                                  state,
                                  session,
                                  setDepartmentDetail,
                                  setLoadingId,
                                  setData,
                                  workspace.name,
                                  deleted
                                );
                                popupDeleteRef.current.close();
                              }}
                              onCancel={() => popupDeleteRef.current.close()}
                              onSuccess={() => {
                                const updatedLines = {
                                  ...departmentDetail[depId].lines,
                                };
                                const updatedWorkspaces = {
                                  ...updatedLines[line],
                                };
                                delete updatedWorkspaces[workspaceId];
                                updatedLines[line] = updatedWorkspaces;
                                const updatedDepartmentDetail = {
                                  ...departmentDetail,
                                  [depId]: {
                                    ...departmentDetail[depId],
                                    lines: updatedLines,
                                  },
                                };
                                setDepartmentDetail(updatedDepartmentDetail);
                                popupDeleteRef.current.close();
                              }}
                              OnCancelDelete={() => {
                                const restoredWorkspaces = {
                                  ...workspaces,
                                  [workspaceId]: workspace,
                                };
                                const updatedLines = {
                                  ...departmentDetail[depId].lines,
                                  [line]: restoredWorkspaces,
                                };
                                const updatedDepartmentDetail = {
                                  ...departmentDetail,
                                  [depId]: {
                                    ...departmentDetail[depId],
                                    lines: updatedLines,
                                  },
                                };

                                setDepartmentDetail(updatedDepartmentDetail);
                              }}
                            />
                          ),
                        });
                        popupDeleteRef.current.open();
                      }}
                      onClickEdit={() => {
                        setEditingName(workspaceId);
                        setEditedWorkspaceName(workspace.name);
                      }}
                      onOpenChange={(open) =>
                        handleOpenChange(workspace.id, open)
                      }
                      itemName={workspace.name}
                      onClickDescription={() => {
                        setSelected([]);
                        setPopupContent(
                          editWsPopupContent(
                            workspace,
                            departmentDetail[workspace.department]
                          )
                        );
                        popupRef.current.open();
                      }}
                    />
                  }
                  content={"Rinomina, descrizione, duplica, elimina"}
                  className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
                  shouldClose={openMenuId === workspace.id}
                />
              </>
            </div>
          )
        }
        onClick={() => {
          setCurrentDepartment(workspace.department);
          setData({
            type: "ws",
            id: workspace.id,
            line: workspace.line,
          });
          setSelectedId(workspace.id);
        }}
      ></StyledTreeItem>
    );
  });

  return workspaceItems;
};

export const renderLines = ({
  departmentDetail,
  depId,
  state,
  session,
  editingLineId,
  editedLineName,
  setEditedLineName,
  dispatch,
  setDepartmentDetail,
  setEditingLineId,
  openMenuId,
  content,
  handleOpenChange,
  setCurrentDepartment,
  setPopupContent,
  handleLoadDepartment,
  popupRef,
  width,
  setData,
  setSelectedId,
  setSelected,
  selectedId,
  setExpanded,
  expanded,
  loadingId,
  setLoadingId,
  editingName,
  setEditingName,
  editedWorkspaceName,
  setEditedWorkspaceName,
  t,
  popupDeleteRef,
  deleted,
  loadingName,
  setLoadingName,
}) => {
  function createWsPopupContent(department, line) {
    console.log("departmentdepartmentdepartment", department, line);
    return {
      title: t("workspacePopupTitle"),
      actionLabel: t("createWs"),
      phName: t("wsName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        let request = await newStation(
          state.current_location.id,
          department.id,
          line,
          { name: name, description: description },
          await getToken(session)
        );

        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
            setData({
              type: "ws",
              id: request.id,
              line: request.line,
            });
            setExpanded([
              ...expanded,
              `line-${request.line + request.department}`,
            ]);
            setSelected("ws-" + request.id);
            setSelectedId(request.id);
          },
          department,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  function editLinePopupContent(department, line) {
    return {
      title: t("editareaPopupTitle"),
      initialName: line.name,
      initialDescription: line.description,
      actionLabel: t("salvaModifiche"),
      phName: t("areaName"),
      api: async (name, description, setLoading) => {
        setLoading(true);

        let request = await updateLineCar(
          state.current_location.id,
          department.id,
          line.id,
          {
            name: name,
            description: description,
          },
          await getToken(session)
        );

        handleLoadDepartments(
          state.current_location.id,
          (locationUpdated) => {
            dispatch({
              type: DATA_A,
              subtype: LOAD_LOCATION,
              action: locationUpdated,
            });
            popupRef.current.close();
            setSelected(null);
          },
          session
        );
      },
    };
  }

  let lines;
  let linesDetail = false;

  if (departmentDetail?.[depId]?.["linesData"]) {
    lines = departmentDetail?.[depId]?.["linesData"];
    linesDetail = true;
  } else lines = departmentDetail?.[depId]?.["lines"];

  let lineItems;

  if (!lines) {
    lineItems = [];
  } else {
    const sortedKeys = Object.keys(lines).sort((a, b) => {
      const nameA = linesDetail
        ? lines[a].name.toUpperCase()
        : a?.toUpperCase();
      const nameB = linesDetail
        ? lines[b].name.toUpperCase()
        : b?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    console.log("sortedKeyssortedKeys", sortedKeys, lines);

    lineItems = sortedKeys.map((lineId) => {
      return (
        <StyledTreeItem
          key={`line-${lineId + depId}`}
          nodeId={`line-${lineId + depId}`}
          className="group/lines"
          labelText={
            editingLineId === lineId ? (
              <input
                type="text"
                value={editedLineName}
                onChange={(e) => {
                  e.stopPropagation();
                  setEditedLineName(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    if (editedLineName.trim() !== lineId.trim()) {
                      handleSaveLineName(
                        depId,
                        lineId,
                        state,
                        session,
                        editedLineName,
                        setDepartmentDetail,
                        dispatch,
                        setEditingLineId
                      );
                    } else {
                      setEditingLineId(null);
                    }
                  } else if (e.key === "Escape") {
                    setEditedLineName(lineId);
                    setEditingLineId(null);
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                autoFocus
                className="relative overflow-hidden text-ellipsis whitespace-nowrap p-[0.15vw] font-atkinson rounded-[0.31vw] border-[0.15vw] border-solid border-[#6D69F2] focus:outline-none focus:border-[#6D69F2] focus:shadow-[0_0_8px_rgba(113,109,242,0.30)] inline-grid w-full font-inherit text-[0.85vw] pl-[0.3vw]"
              />
            ) : (
              <div className="inline-grid">
                <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-6   sm:pr-[2.1vw]  xl:pr-[1.8vw]">
                  {linesDetail ? lines[lineId].name : lineId}
                </span>
              </div>
            )
          }
          bgColor={colors.mainOpaque}
          extraIcon={
            editingLineId === lineId ? null : (
              <div
                className={`flex 2xl:gap-2 md:gap-1 items-center relative w-[100%] `}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div
                  className={`flex 2xl:gap-2 gap-1 items-center w-[100%] pl-2 h-full ${
                    openMenuId === lineId + depId
                      ? "bg-gradient-hover"
                      : "bg-gradient-default"
                  } ${
                    openMenuId === lineId + depId
                      ? "visible"
                      : "invisible group-hover/lines:visible"
                  }`}
                >
                  <TreeToolTip
                    icon={
                      <div>
                        <ToolTipIcon
                          size="1.35vw"
                          disabled={!lines[lineId].description}
                        />{" "}
                      </div>
                    }
                    heading={"DESCRIZIONE"}
                    content={lines[lineId].description}
                    onEmptyClick={() => {
                      setSelected([]);
                      setPopupContent(
                        editLinePopupContent(
                          departmentDetail[depId],
                          lines[lineId]
                        )
                      );
                      popupRef.current.open();
                    }}
                  />

                  <TreeToolTip
                    icon={
                      <ActionMenu
                        icon={
                          <ExpandMoreIcon
                            size="1.2vw"
                            menuOpen={openMenuId === lineId + depId}
                          />
                        }
                        onDelete={() => {
                          setPopupContent({
                            title: t("deleteareaPopupTitle"),
                            children: (
                              <PopupDelete
                                Name={lineId}
                                onConfirm={() => {
                                  handleDeleteLine(
                                    depId,
                                    lineId,
                                    state,
                                    session,
                                    setDepartmentDetail,
                                    setData,
                                    lineId
                                  );
                                  popupDeleteRef.current.close();
                                }}
                                onCancel={() => popupDeleteRef.current.close()}
                                onSuccess={() => {
                                  const updatedLines = {
                                    ...departmentDetail[depId].lines,
                                  };
                                  delete updatedLines[lineId];
                                  const updatedDepartmentDetail = {
                                    ...departmentDetail,
                                    [depId]: {
                                      ...departmentDetail[depId],
                                      lines: updatedLines,
                                    },
                                  };
                                  setDepartmentDetail(updatedDepartmentDetail);
                                  popupDeleteRef.current.close();
                                }}
                                OnCancelDelete={() => {
                                  const restoredLines = {
                                    ...departmentDetail[depId].lines,
                                    [lineId]: lines[lineId],
                                  };
                                  const updatedDepartmentDetail = {
                                    ...departmentDetail,
                                    [depId]: {
                                      ...departmentDetail[depId],
                                      lines: restoredLines,
                                    },
                                  };
                                  setDepartmentDetail(updatedDepartmentDetail);
                                }}
                              />
                            ),
                          });
                          popupDeleteRef.current.open();
                        }}
                        onClickEdit={() => {
                          setEditingLineId(lineId);
                          setEditedLineName(lineId);
                        }}
                        onOpenChange={(open) =>
                          handleOpenChange(lineId + depId, open)
                        }
                        itemName={lineId}
                        onClickDescription={() => {
                          setSelected([]);
                          setPopupContent(
                            editLinePopupContent(
                              departmentDetail[depId],
                              lines[lineId]
                            )
                          );
                          popupRef.current.open();
                        }}
                      />
                    }
                    content={"Rinomina, descrizione, duplica, elimina"}
                    className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
                    shouldClose={openMenuId === lineId + depId}
                  />
                </div>

                <div className="flex bg-[#E8E5FC]  plus-icon-container">
                  <TreeToolTip
                    icon={
                      <PlusIcon
                        size="0.9vw"
                        onClick={(event) => {
                          event.stopPropagation();

                          setCurrentDepartment(depId);
                          setPopupContent(
                            createWsPopupContent(
                              departmentDetail[depId],
                              lineId
                            )
                          );
                          popupRef.current.open();
                        }}
                      />
                    }
                    content={"Aggiungi postazione"}
                    className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)]"
                  />
                </div>
              </div>
            )
          }
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "400",
            marginLeft: "0.3vw",
          }}
        >
          {renderWorkspaces({
            workspaces: departmentDetail[depId]["lines"][lineId]
              ? Object.entries(departmentDetail[depId]["lines"][lineId]).map(
                  ([key, ws]) => ({
                    ...ws,
                    lineDetail: departmentDetail[depId]["linesData"][ws.line],
                  })
                )
              : [],
            line: lineId,
            depId,
            selectedId,
            loadingId,
            setSelectedId,
            setCurrentDepartment,
            setData,
            openMenuId,
            handleOpenChange,
            editingName,
            setEditingName,
            editedWorkspaceName,
            setEditedWorkspaceName,
            state,
            session,
            setDepartmentDetail,
            content,
            width,
            setLoadingId,
            setPopupContent,
            popupRef,
            t,
            setSelected,
            popupDeleteRef,
            deleted,
            departmentDetail,
            expanded,
            setExpanded,
            loadingName,
            setLoadingName,
          })}
        </StyledTreeItem>
      );
    });
  }

  // Aggiungi un nuovo StyledTreeItem alla fine per "Nuova" linea

  return lineItems;
};

export const renderShifts = ({
  departmentDetail,
  depId,
  selectedId,
  openMenuId,
  setCurrentDepartment,
  setData,
  setSelectedId,
  content,
  handleOpenChange,
  setPopupContent,
  popupRef,
  t,
  editedShiftName,
  setEditedShiftName,
  editingName,
  setEditingName,
  setDepartmentDetail,
  state,
  session,
  departmentId,
  setExpanded,
  department,
  setSelected,
  deleted,
  toast,
  dispatch,
  loadingName,
  setLoadingName,
}) => {
  let shiftsItems;
  let shifts = departmentDetail?.[depId]?.["workshifts"];

  function editShiftPopupContent(shift) {
    return {
      title: t("editshiftPopupTitle"),
      initialName: shift.name,
      initialDescription: shift.description,
      actionLabel: t("salvaModifiche"),
      phName: t("shiftName"),
      api: async (name, description, setLoading) => {
        setLoading(true);

        let request = await editWorkshift(
          shift.id,
          {
            ...shift,
            name: name,
            description: description,
          },
          await getToken(session)
        );

        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }
  function duplicateShiftPopupContent(shift, department) {
    return {
      title: t("copyshiftPopupTitle"),
      initialName: t("copy") + " " + shift.name,
      initialDescription: shift.description
        ? t("copy") + " " + shift.description
        : "",
      actionLabel: t("createShift"),
      phName: t("shiftName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        let requestShift = await newWorkshift(
          state.current_location.id,
          department.id,
          {
            ...defaultWorkshift,
            name: name,
            description: description,
          },
          await getToken(session)
        );
        let request = await editWorkshift(
          requestShift.id,
          {
            ...shift,
            name: name,
            description: description,
          },
          await getToken(session)
        );

        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
            setData({
              type: "shift",
              id: request.id,
            });
            setExpanded((prevExpanded) => [
              ...prevExpanded,
              `shifts-${department.name}`,
              `shift-${request.id}`,
            ]);
            setSelected("shift-" + request.id);
            setSelectedId(request.id);
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  if (!shifts) {
    shiftsItems = [];
  } else {
    const sortedKeys = Object.keys(shifts).sort((a, b) => {
      const nameA = shifts[a]?.name?.toUpperCase();
      const nameB = shifts[b]?.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    shiftsItems = sortedKeys.map((shiftId) => {
      const shift = shifts[shiftId];
      const isSelected = selectedId === shift.id;
      const isOpen = openMenuId === shiftId;

      return (
        <StyledTreeItem
          key={`shift-${shift.id}`}
          nodeId={`shift-${shift.id}`}
          className="group/shifts"
          labelText={
            editingName === shiftId ? (
              <input
                type="text"
                className="relative overflow-hidden text-ellipsis whitespace-nowrap p-[0.15vw] font-atkinson rounded-[0.31vw] border-[0.15vw] border-solid border-[#6D69F2] focus:outline-none focus:border-[#6D69F2] focus:shadow-[0_0_8px_rgba(113,109,242,0.30)] inline-grid w-full font-inherit text-[0.85vw] pl-[0.3vw]"
                value={loadingName ? "CARICO" : editedShiftName}
                onChange={(e) => setEditedShiftName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (editedShiftName.trim() !== shift.name.trim()) {
                      handleSaveShift(
                        shiftId,
                        state,
                        session,
                        shifts,
                        depId,
                        editedShiftName,
                        setEditingName,
                        setDepartmentDetail,
                        setLoadingName,
                        department
                      );
                    } else {
                      setEditingName(null);
                    }
                  } else if (e.key === "Escape") {
                    setEditedShiftName(shift.name);
                    setEditingName(null);
                  }
                }}
                autoFocus
              />
            ) : (
              <div className="inline-grid">
                <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-6">
                  {shift.name}
                </span>
              </div>
            )
          }
          bgColor={colors.mainOpaque}
          extraIcon={
            editingName === shiftId ? null : (
              <div
                className={` flex 2xl:gap-2 md:gap-1 items-center w-[100%] pl-2 h-full ${
                  isSelected
                    ? " bg-gradient-selected"
                    : openMenuId === shift.id
                    ? "bg-gradient-hover"
                    : " bg-gradient-default"
                }   ${
                  isOpen ? "visible" : "invisible group-hover/shifts:visible"
                }`}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <>
                  <TreeToolTip
                    icon={
                      <div>
                        <ToolTipIcon
                          size="1.35vw"
                          disabled={!shift.description}
                        />{" "}
                      </div>
                    }
                    heading={"DESCRIZIONE"}
                    content={shift.description}
                    onEmptyClick={() => {
                      setPopupContent(editShiftPopupContent(shift));
                      popupRef.current.open();
                    }}
                  />

                  <TreeToolTip
                    icon={
                      <ActionMenu
                        icon={
                          <ExpandMoreIcon
                            size="1.2vw"
                            menuOpen={openMenuId === shift.id}
                          /> // Select the job node
                        }
                        onOpenChange={(open) => {
                          handleOpenChange(shift.id, open);
                        }}
                        itemName={shift.name}
                        onClickDescription={() => {
                          setPopupContent(editShiftPopupContent(shift));
                          popupRef.current.open();
                        }}
                        disableDuplicate={true}
                        onClickDuplicate={() => {
                          // if (selectedId !== shiftId) {
                          //   setSelectedId(shiftId);
                          //   setSelected(`shift-${shiftId}`);
                          // }

                          setPopupContent(
                            duplicateShiftPopupContent(shift, department)
                          );
                          popupRef.current.open();
                        }}
                        onClickEdit={() => {
                          setEditingName(shiftId);
                          setEditedShiftName(shift.name);
                        }}
                        onDelete={() =>
                          handleDeleteShift({
                            locationId: state.current_location.id,
                            departmentId: depId,
                            shiftId: shift.id,
                            session,
                            setDepartmentDetail,
                            setData,
                            name: shift.name,
                            setSelectedId,
                            departmentDetail,
                            deleted,
                            toast,
                            onSuccess: () => {
                              const updatedShifts = {
                                ...departmentDetail[depId]?.workshifts,
                              };
                              delete updatedShifts[shift.id];

                              const updatedCurrentDepartment = {
                                ...departmentDetail[depId],
                                workshifts: updatedShifts,
                              };

                              const updatedDepartmentDetails = {
                                ...departmentDetail,
                                [depId]: updatedCurrentDepartment,
                              };

                              setDepartmentDetail(updatedDepartmentDetails);
                            },
                            OnCancelDelete: () => {
                              const restoredShifts = {
                                ...departmentDetail[depId]?.workshifts,
                                [shiftId]: shift,
                              };

                              const restoredDepartment = {
                                ...departmentDetail[depId],
                                workshifts: restoredShifts,
                              };

                              setDepartmentDetail({
                                ...departmentDetail,
                                [depId]: restoredDepartment,
                              });

                              console.log(
                                `Undo delete for shift: ${shift.name}`
                              );
                            },
                          })
                        }
                      />
                    }
                    content={"Rinomina, descrizione, duplica, elimina"}
                    className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
                    shouldClose={isOpen}
                  />
                </>
              </div>
            )
          }
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "400",
            marginLeft: "0.3vw",
          }}
          onClick={() => {
            setCurrentDepartment(depId);
            setData({ type: "shift", id: shift.id });
            setSelectedId(shift.id);
          }}
        ></StyledTreeItem>
      );
    });
  }

  return shiftsItems;
};

export const renderJobs = ({
  departmentDetail,
  depId,
  selectedId,
  openMenuId,
  setCurrentDepartment,
  setData,
  setSelectedId,
  content,
  handleOpenChange,
  setPopupContent,
  popupRef,
  t,
  popupDeleteRef,
  setEditingName,
  editingName,
  editedJobName,
  setEditedJobName,
  state,
  setDepartmentDetail,
  session,
  dispatch,
  department,
  firstShift,
  setExpanded,
  setSelected,
  deleted,
  loadingId,
  setLoadingId,
  loadingName,
  setLoadingName,
}) => {
  let jobsItems;
  let jobs = departmentDetail?.[depId]?.["jobs"];
  let shift = departmentDetail?.[depId]?.["workshifts"];

  function editJobPopupContent(job) {
    return {
      title: t("editPopupJob"),
      initialName: job.name,
      initialDescription: job.description,
      actionLabel: t("salvaModifiche"),
      phName: t("jobName"),
      api: async (name, description, setLoading) => {
        setLoading(true);

        let request = await updateJob(
          state.current_location.id,
          job.department,
          {
            name: name,
            description: description,
            workshift: job.workshift,
            n_employees: job.n_employees,
          },
          job.id,
          await getToken(session)
        );

        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
          },
          department,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  function duplicateJobPopupContent(job, department, defaultShift) {
    return {
      title: t("copyPopupJob"),
      initialName: t("copy") + " " + job.name,
      initialDescription: job.description
        ? t("copy") + " " + job.description
        : "",
      actionLabel: t("createJob"),
      phName: t("jobName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        let taskLists = job.tasks.map((jobTask, id) => {
          return { durata: jobTask.durata, task: jobTask.task.id };
        });

        let requestJob = await newJob(
          state.current_location.id,
          department.id,
          {
            ...defaultJob,
            name: name,
            description: description,
            workshift: defaultShift,
          },
          await getToken(session)
        );

        let response = await newJobTask(
          state.current_location.id,
          department.id,
          requestJob.id,
          taskLists,
          await getToken(session)
        );

        handleLoadDepartment(
          department.id,
          () => {
            setData({
              type: "job",
              id: requestJob.id,
            });
            setExpanded((prevExpanded) => [
              ...prevExpanded,
              `jobs-${department.name}`,
              `job-${requestJob.id}`,
            ]);

            setSelected("job-" + requestJob.id);
            setSelectedId(requestJob.id);
            popupRef.current.close();
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  if (jobs && Object.keys(jobs).length > 0) {
    // Filter out invalid jobs
    const validJobIds = Object.keys(jobs).filter(
      (jobId) => jobs[jobId].valid !== false
    );

    const sortedJobIds = validJobIds.sort((a, b) => {
      const nameA = jobs[a]?.name?.toUpperCase();
      const nameB = jobs[b]?.name?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    jobsItems = sortedJobIds.map((jobId) => {
      const job = jobs[jobId];
      console.log("job", job);
      const isSelected = selectedId === job.id;
      const isOpen = openMenuId === jobId;
      return (
        <StyledTreeItem
          key={`job-${job.id}`}
          nodeId={`job-${job.id}`}
          className="group/jobs"
          labelText={
            loadingId === jobId ? (
              <div className="flex justify-center items-center">
                <Spinner size="1.5vw" />
              </div>
            ) : editingName === jobId ? (
              <input
                type="text"
                className="relative overflow-hidden text-ellipsis whitespace-nowrap p-[0.15vw] font-atkinson rounded-[0.31vw] border-[0.15vw] border-solid border-[#6D69F2] focus:outline-none focus:border-[#6D69F2] focus:shadow-[0_0_8px_rgba(113,109,242,0.30)] inline-grid w-full font-inherit text-[0.85vw] pl-[0.3vw]"
                value={loadingName ? "CARICO" : editedJobName}
                onChange={(e) => setEditedJobName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (editedJobName.trim() !== job.name.trim()) {
                      handleSaveJob(
                        jobId,
                        state,
                        session,
                        jobs,
                        depId,
                        editedJobName,
                        setEditingName,
                        setDepartmentDetail,
                        department,
                        setLoadingName
                      );
                    } else {
                      setEditingName(null);
                    }
                  } else if (e.key === "Escape") {
                    setEditedJobName(job.name);
                    setEditingName(null);
                  }
                }}
                autoFocus
              />
            ) : (
              <div className="inline-grid">
                <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-6">
                  {job.name}
                </span>
              </div>
            )
          }
          bgColor={colors.mainOpaque}
          extraIcon={
            editingName === jobId ? null : (
              <div
                className={`flex 2xl:gap-2 md:gap-1 items-center w-[100%] pl-2 h-full ${
                  isSelected
                    ? " bg-gradient-selected"
                    : openMenuId === jobId
                    ? "bg-gradient-hover"
                    : " bg-gradient-default"
                }  ${
                  isOpen ? "visible" : "invisible group-hover/jobs:visible"
                }`}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <>
                  <TreeToolTip
                    icon={
                      <div>
                        <ToolTipIcon
                          size="1.35vw"
                          disabled={!job.description}
                        />{" "}
                      </div>
                    }
                    heading={"DESCRIZIONE"}
                    content={job.description}
                    onEmptyClick={() => {
                      setPopupContent(editJobPopupContent(job));
                      popupRef.current.open();
                    }}
                  />

                  <TreeToolTip
                    icon={
                      <ActionMenu
                        icon={
                          <ExpandMoreIcon
                            size="1.2vw"
                            menuOpen={openMenuId === job.id}
                          />
                        }
                        onOpenChange={(open) => {
                          handleOpenChange(jobId, open);
                        }}
                        itemName={jobId}
                        disableDuplicate={true}
                        onClickDescription={() => {
                          setPopupContent(editJobPopupContent(job));
                          popupRef.current.open();
                        }}
                        onClickDuplicate={() => {
                          if (firstShift) {
                            setPopupContent(
                              duplicateJobPopupContent(
                                job,
                                department,
                                firstShift
                              )
                            );
                            popupRef.current.open();
                          }
                        }}
                        onDelete={() => {
                          setPopupContent({
                            title: t("deletePopupJob"),
                            children: (
                              <PopupDelete
                                Name={job.name}
                                onConfirm={() => {
                                  handleDeleteJob({
                                    locationId: state.current_location.id,
                                    departmentId: depId,
                                    jobId: job.id,
                                    session,
                                    setDepartmentDetail,
                                    setData,
                                    name: job.name,
                                    setSelectedId,
                                    departmentDetail,
                                    deleted,
                                    setLoadingId,
                                  });
                                  popupDeleteRef.current.close();
                                }}
                                onSuccess={() => {
                                  const updatedJobs = {
                                    ...departmentDetail[depId].jobs,
                                  };
                                  delete updatedJobs[job.id];
                                  const updatedCurrentDepartment = {
                                    ...departmentDetail[depId],
                                    jobs: updatedJobs,
                                  };
                                  const updatedDepartmentDetails = {
                                    ...departmentDetail,
                                    [depId]: updatedCurrentDepartment,
                                  };
                                  setDepartmentDetail(updatedDepartmentDetails);
                                  popupDeleteRef.current.close();
                                }}
                                onCancel={() => popupDeleteRef.current.close()}
                                OnCancelDelete={() => {
                                  const restoredJobs = {
                                    ...departmentDetail[depId].jobs,
                                    [job.id]: job,
                                  };
                                  const updatedCurrentDepartment = {
                                    ...departmentDetail[depId],
                                    jobs: restoredJobs,
                                  };
                                  const updatedDepartmentDetails = {
                                    ...departmentDetail,
                                    [depId]: updatedCurrentDepartment,
                                  };
                                  setDepartmentDetail(updatedDepartmentDetails);
                                }}
                              />
                            ),
                          });
                          popupDeleteRef.current.open();
                        }}
                        onClickEdit={() => {
                          setEditingName(jobId);
                          setEditedJobName(job.name);
                        }}
                      />
                    }
                    content={"Rinomina, descrizione, duplica, elimina"}
                    className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
                    shouldClose={isOpen}
                  />
                </>
              </div>
            )
          }
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "400",
            marginLeft: "0.3vw",
          }}
          onClick={() => {
            setCurrentDepartment(depId);
            setData({ type: "job", id: job.id });
            setSelectedId(job.id);
          }}
        ></StyledTreeItem>
      );
    });
  }

  return jobsItems;
};

export const renderDepartments = ({
  departments,
  selectedId,
  openMenuId,
  setCurrentDepartment,
  setPopupContent,
  setDepartmentDetail,
  setSelected,
  setSelectedId,
  dispatch,
  session,
  popupRef,
  t,
  content,
  handleOpenChange,
  editingDepartmentId,
  setEditingDepartmentId,
  editedDepartmentName,
  setEditedDepartmentName,
  setData,
  departmentDetail,
  expanded,
  setExpanded,
  state,
  setEditingLineId,
  editedLineName,
  setEditedLineName,
  editingLineId,
  setEditingName,
  setEditedWorkspaceName,
  loadingId,
  setLoadingId,
  width,
  editingName,
  editedWorkspaceName,
  popupDeleteRef,
  setEditedJobName,
  editedJobName,
  editedShiftName,
  setEditedShiftName,
  deleted,
  toast,
  loadingName,
  setLoadingName,
}) => {
  const sortedDepartmentKeys = Object.keys(departments).sort((a, b) => {
    const nameA = departments[a]?.name?.toUpperCase();
    const nameB = departments[b]?.name?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  // POPUP CONTENTS
  function createDepPopupContent() {
    return {
      title: t("departmentPopupTitle"),
      actionLabel: t("createDep"),
      phName: t("depName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        let request = await newDepartment(
          state.current_location.id,
          {
            ...defaultDepartment,
            name: name,
            description: description,
          },
          await getToken(session)
        );
        handleLoadDepartments(
          state.current_location.id,
          (locationUpdated) => {
            dispatch({
              type: DATA_A,
              subtype: LOAD_LOCATION,
              action: locationUpdated,
            });
            popupRef.current.close();
            setSelected(null);
          },
          session
        );
      },
    };
  }

  function editDepPopupContent(department) {
    return {
      title: t("editdepartmentPopupTitle"),
      initialName: department.name,
      initialDescription: department.description,
      actionLabel: t("salvaModifiche"),
      phName: t("depName"),
      api: async (name, description, setLoading) => {
        setLoading(true);

        let request = await updateDepartment(
          state.current_location.id,
          department.id,
          {
            name: name,
            description: description,
          },
          await getToken(session)
        );

        handleLoadDepartments(
          state.current_location.id,
          (locationUpdated) => {
            dispatch({
              type: DATA_A,
              subtype: LOAD_LOCATION,
              action: locationUpdated,
            });
            popupRef.current.close();
            setSelected(null);
          },
          session
        );
      },
    };
  }

  function createShiftPopupContent(department) {
    return {
      title: t("shiftPopupTitle"),
      actionLabel: t("createShift"),
      phName: t("shiftName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        let request = await newWorkshift(
          state.current_location.id,
          department.id,
          {
            ...defaultWorkshift,
            name: name,
            description: description,
          },
          await getToken(session)
        );
        handleLoadDepartment(
          department.id,
          () => {
            popupRef.current.close();
            setData({
              type: "shift",
              id: request.id,
            });
            setExpanded((prevExpanded) => [
              ...prevExpanded,
              `shifts-${department.name}`,
              `shift-${request.id}`,
            ]);
            setSelected("shift-" + request.id);
            setSelectedId(request.id);
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  function createJobPopupContent(department, defaultShift) {
    return {
      title: t("shiftPopupJob"),
      actionLabel: t("createJob"),
      phName: t("jobName"),
      api: async (name, description, setLoading) => {
        console.log("hereeee", name, description);
        setLoading(true);
        let request = await newJob(
          state.current_location.id,
          department.id,
          {
            ...defaultJob,
            name: name,
            description: description,
            workshift: defaultShift,
          },
          await getToken(session)
        );
        handleLoadDepartment(
          department.id,
          () => {
            setData({
              type: "job",
              id: request.id,
            });
            setExpanded((prevExpanded) => [
              ...prevExpanded,
              `jobs-${department.name}`,
              `job-${request.id}`,
            ]);

            setSelected("job-" + request.id);
            setSelectedId(request.id);
            popupRef.current.close();
          },
          departmentDetail,
          session,
          state.current_location.id,
          (x) => setDepartmentDetail(x)
        );
      },
    };
  }

  function createArea(department) {
    return {
      title: t("areaPopupTitle"),
      actionLabel: t("createArea"),
      phName: t("areaName"),
      api: async (name, description, setLoading) => {
        setLoading(true);
        // let requestLine = await getLines(
        //   state.current_location.id,
        //   department.id,
        //   await getToken(session)
        // );
        // console.log("get linesss", requestLine);
        // TEMP TEST LINES
        let requestLine = await newLine(
          state.current_location.id,
          department.id,
          { name: name, description: description },
          await getToken(session)
        );
        // DA AGGIUSTARE
        // let request = await updateStation(
        //   state.current_location.id,
        //   department.id,
        //   requestLine.line,
        //   requestLine.id,
        //   defaultLineWs,
        //   await getToken(session)
        // );
        // handleLoadDepartment(
        //   department.id,
        //   () => {
        //     popupRef.current.close();
        //     setData({
        //       type: "ws",
        //       id: request.id,
        //       line: request.line,
        //     });
        //     setExpanded((prevExpanded) => [
        //       ...prevExpanded,
        //       `areas-${department.name}`,
        //       `line-${request.line + request.department}`,
        //     ]);
        //     setSelected("ws-" + request.id);
        //     setSelectedId(request.id);
        //   },
        //   departmentDetail,
        //   session,
        //   state.current_location.id,
        //   (x) => setDepartmentDetail(x)
        // );
      },
    };
  }
  // END POPUP CONTENTS

  const departmentItems = [
    <div className="">
      <StyledTreeItem
        id="newDepartment"
        key="newDepartment"
        nodeId="dept-new"
        labelText={
          <div className="flex items-center justify-center">
            <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-tight xl:leading-6 pl-[0.3vw]">
              {t("departments")}
            </span>
          </div>
        }
        bgColor={colors.mainOpaque}
        font={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "600",
          marginLeft: "0.3vw",
        }}
        endIcon={<FactoryIcon />}
        extraIcon={
          <TreeToolTip
            icon={<PlusIcon size=".9vw" />}
            content={"Aggiungi dipartimento"}
            className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
          />
        }
        className=""
        onClick={() => {
          setSelected([]);
          setPopupContent(createDepPopupContent());
          popupRef.current.open();
        }}
      />
    </div>,
  ];

  sortedDepartmentKeys.forEach((departmentId) => {
    let department = departments[departmentId];
    const isSelected = selectedId === department.id;

    const firstShift = Object.keys(
      departmentDetail?.[department.id]?.workshifts || {}
    )[0];

    departmentItems.push(
      <StyledTreeItem
        key={`dep-${departmentId}`}
        nodeId={`dep-${departmentId}`}
        className="group/departments"
        labelText={
          editingDepartmentId === departmentId ? (
            <input
              type="text"
              value={loadingName ? "CARICO" : editedDepartmentName}
              onChange={(e) => {
                e.stopPropagation();
                setEditedDepartmentName(e.target.value);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  handleSaveDepartmentName(
                    department,
                    state,
                    session,
                    editedDepartmentName,
                    setDepartmentDetail,
                    setEditingDepartmentId,
                    dispatch,
                    setLoadingName
                  );
                } else if (e.key === "Escape") {
                  setEditedDepartmentName(department.name);
                  setEditingDepartmentId(null);
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoFocus
              className="relative overflow-hidden text-ellipsis whitespace-nowrap p-[0.15vw] font-atkinson rounded-[0.31vw] border-[0.15vw] border-solid border-[#6D69F2] focus:outline-none focus:border-[#6D69F2] focus:shadow-[0_0_8px_rgba(113,109,242,0.30)] inline-grid w-full font-inherit text-[0.85vw] pl-[0.3vw]"
            />
          ) : (
            <div className="inline-grid">
              <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-6">
                {editingDepartmentId === departmentId &&
                editedDepartmentName != department.name
                  ? editedDepartmentName
                  : department.name}
              </span>
            </div>
          )
        }
        bgColor={colors.mainOpaque}
        font={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.95vw",
          fontWeight: "800",
          marginLeft: "0.3vw",
        }}
        labelIcon={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
        extraIcon={
          editingDepartmentId === departmentId ? null : (
            <div
              className={`flex 2xl:gap-2 md:gap-1 items-center w-[100%] pl-2 h-full ${
                openMenuId === departmentId
                  ? "bg-gradient-hover"
                  : " bg-gradient-default"
              } ${
                openMenuId === departmentId
                  ? "visible"
                  : "invisible group-hover/departments:visible"
              }`}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <>
                <TreeToolTip
                  icon={
                    <div>
                      <ToolTipIcon
                        size="1.35vw"
                        disabled={!department.description}
                      />
                    </div>
                  }
                  heading={"DESCRIZIONE"}
                  content={department.description}
                  onEmptyClick={() => {
                    setSelected([]);
                    setPopupContent(editDepPopupContent(department));
                    popupRef.current.open();
                  }}
                />

                <TreeToolTip
                  icon={
                    <ActionMenu
                      icon={
                        <ExpandMoreIcon
                          size="1.2vw"
                          menuOpen={openMenuId === department.id}
                        />
                      }
                      onDelete={() => {
                        setPopupContent({
                          title: t("deleteDepartmentPopupTitle"),
                          children: (
                            <PopupDelete
                              Name={department.name}
                              onConfirm={() => {
                                handleDeleteDepartment(
                                  state.current_location.id,
                                  departmentId,
                                  session.jwt,
                                  dispatch,
                                  setData,
                                  department.name
                                );
                                popupDeleteRef.current.close();
                              }}
                              onCancel={() => popupDeleteRef.current.close()}
                              onSuccess={() => {
                                dispatch({
                                  type: DATA_A,
                                  subtype: "DELETE_DEPARTMENT",
                                  action: {
                                    departmentId,
                                  },
                                });
                                popupDeleteRef.current.close();
                              }}
                              OnCancelDelete={() => {
                                dispatch({
                                  type: DATA_A,
                                  subtype: "RESTORE_DEPARTMENT",
                                  action: {
                                    department,
                                  },
                                });

                                const restoredDepartmentDetails = {
                                  ...departmentDetail,
                                  [departmentId]: {
                                    ...department,
                                    jobs: department.jobs || {},
                                    lines: department.lines || {},
                                    workshifts: department.workshifts || {},
                                  },
                                };
                                setDepartmentDetail(restoredDepartmentDetails);
                              }}
                            />
                          ),
                        });
                        popupDeleteRef.current.open();
                      }}
                      onClickEdit={() => {
                        setEditingDepartmentId(departmentId);
                        setEditedDepartmentName(department.name);
                      }}
                      onOpenChange={(open) =>
                        handleOpenChange(departmentId, open)
                      }
                      itemName={department.name}
                      onClickDescription={() => {
                        setSelected([]);
                        setPopupContent(editDepPopupContent(department));
                        // setPopupContent({
                        //   info: tips[document.language].dipartimento,
                        //   title: t("editdepartmentPopupTitle"),
                        //   children: (
                        //     <PopupDescription
                        //       Name={department.name}
                        //       Description={content.replace(/\s+/g, " ").trim()}
                        //       onCancel={() => popupRef.current.close()}
                        //       // MainOwnerCompany={
                        //       //   state.current_location.MainOwnerCompany
                        //       // }
                        //       // location={state.current_location.id}
                        //       // onSuccess={(newId) => {
                        //       //   handleLoadDepartments(
                        //       //     state.current_location.id,
                        //       //     (locationUpdated) => {
                        //       //       dispatch({
                        //       //         type: DATA_A,
                        //       //         subtype: LOAD_LOCATION,
                        //       //         action: locationUpdated,
                        //       //       });
                        //       //       popupRef.current.close();
                        //       //       setSelected(null);
                        //       //     },
                        //       //     session
                        //       //   );
                        //       // }}
                        //       placeholder={t("depName")}
                        //     />
                        //   ),
                        // });
                        popupRef.current.open();
                      }}
                    />
                  }
                  content={"Rinomina, descrizione, elimina"}
                  className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
                  shouldClose={openMenuId === departmentId}
                />
              </>
            </div>
          )
        }
        onClick={() => {
          handleLoadDepartment(
            departmentId,
            () => {},
            departmentDetail,
            session,
            state.current_location.id,
            (x) => setDepartmentDetail(x)
          );
        }}
      >
        <StyledTreeItem
          nodeId={`shifts-${department.name}`}
          labelText={
            <div className="flex items-center justify-center">
              <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-tight xl:leading-0">
                {t("shifts")}
              </span>
            </div>
          }
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "800",
            marginLeft: "0.3vw",
          }}
          labelIcon={process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"}
          extraIcon={
            <TreeToolTip
              icon={
                <PlusIcon
                  size=".9vw"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCurrentDepartment(departmentId);
                    setPopupContent(createShiftPopupContent(department));
                    popupRef.current.open();
                  }}
                />
              }
              content={"Aggiungi turno"}
              className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
            />
          }
        >
          {renderShifts({
            departmentDetail,
            depId: department.id,
            selectedId,
            openMenuId,
            setCurrentDepartment,
            setData,
            setSelectedId,
            content,
            handleOpenChange,
            setPopupContent,
            popupRef,
            t,
            editedShiftName,
            setEditedShiftName,
            editingName,
            setEditingName,
            setDepartmentDetail,
            state,
            session,
            departmentId,
            setExpanded,
            department,
            setSelected,
            deleted,
            toast,
            dispatch,
            loadingName,
            setLoadingName,
          })}
        </StyledTreeItem>
        <StyledTreeItem
          nodeId={`jobs-${department.name}`}
          labelText={
            <div className="flex items-center justify-center">
              <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-none">
                {t("jobs")}
              </span>
            </div>
          }
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "800",
            marginLeft: "0.3vw",
          }}
          labelIcon={process.env.REACT_APP_RESOURCES_BUCKET + "Worker.png"}
          extraIcon={
            <TreeToolTip
              icon={
                <PlusIcon
                  size=".9vw"
                  onClick={(event) => {
                    event.stopPropagation();

                    if (firstShift) {
                      setCurrentDepartment(department.id);
                      setPopupContent(
                        createJobPopupContent(department, firstShift)
                      );
                      popupRef.current.open();
                    } else {
                      alert(t("jobPopupAlert"));
                    }
                  }}
                />
              }
              content={"Aggiungi mansione"}
              className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
            />
          }
        >
          {renderJobs({
            departmentDetail,
            depId: department.id,
            selectedId,
            openMenuId,
            setCurrentDepartment,
            setData,
            setSelectedId,
            content,
            handleOpenChange,
            setPopupContent,
            popupRef,
            t,
            popupDeleteRef,
            setEditingName,
            editingName,
            editedJobName,
            setEditedJobName,
            state,
            setDepartmentDetail,
            session,
            dispatch,
            department,
            firstShift,
            setExpanded,
            setSelected,
            deleted,
            loadingId,
            setLoadingId,
            loadingName,
            setLoadingName,
          })}
        </StyledTreeItem>
        <StyledTreeItem
          nodeId={`areas-${department.name}`}
          labelText={
            <div className="flex items-center justify-center">
              <span className="relative overflow-hidden text-ellipsis whitespace-nowrap leading-none">
                {t("areas")}
              </span>
            </div>
          }
          bgColor={colors.mainOpaque}
          font={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.95vw",
            fontWeight: "800",
            marginLeft: "0.3vw",
          }}
          labelIcon={process.env.REACT_APP_RESOURCES_BUCKET + "Area.png"}
          extraIcon={
            <div className="flex gap-2 items-center">
              <TreeToolTip
                icon={
                  <PlusIcon
                    size=".9vw"
                    onClick={(event) => {
                      event.stopPropagation();
                      setCurrentDepartment(department.id);
                      setPopupContent(createArea(department));
                      popupRef.current.open();
                    }}
                  />
                }
                content={"Aggiungi area"}
                className="text-white bg-tooltipBackground font-atkinson rounded-md border-none shadow-[0px 2px 8px 0px rgba(33, 32, 64, 0.18)"
              />
            </div>
          }
        >
          {renderLines({
            departmentDetail,
            depId: department.id,
            state,
            session,
            editingLineId,
            editedLineName,
            setEditedLineName,
            dispatch,
            setDepartmentDetail,
            setEditingLineId,
            openMenuId,
            content,
            handleOpenChange,
            setCurrentDepartment,
            setPopupContent,
            handleLoadDepartment,
            popupRef,
            width,
            setData,
            setSelectedId,
            setSelected,
            selectedId,
            setExpanded,
            expanded,
            loadingId,
            setLoadingId,
            editingName,
            setEditingName,
            editedWorkspaceName,
            setEditedWorkspaceName,
            t,
            popupDeleteRef,
            deleted,
            loadingName,
            setLoadingName,
          })}
        </StyledTreeItem>
        <div className="h-[1vw]"></div> {/* Ajusta 'height' come necessario */}
      </StyledTreeItem>
    );
  });

  return departmentItems;
};
