import { useClerk } from "@clerk/clerk-react";
import { TreeView } from "@mui/x-tree-view";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../config/style";
import { useStateValue } from "../../stores/services/StateProvider";
import { PopupCreate, PopupCreateEdit, PopupDelete } from "../Popup/Popup";
import ButtonTextIcon from "../buttons/ButtonTextIcon/ButtonTextIcon";
import RightArrowIcon from "../../icons/RightArrowIcon";
import DownArrowIcon from "../../icons/DownArrowIcon";
import { renderDepartments } from "./TreeRenderers";
import { useToast } from "../../hooks/use-toast";

export const TreeStructure = ({
  width,
  setData,
  setDepartmentDetail,
  departmentDetail,
  setCurrentDepartment,
  deleted,
}) => {
  const [state, dispatch] = useStateValue();
  console.log("deaprtmentDetail", departmentDetail);

  const [expanded, setExpanded] = useState([]);
  const [popupContent, setPopupContent] = useState({
    info: "",
    children: "",
    actionLabel: "",
  });

  const [editingDepartmentId, setEditingDepartmentId] = useState(null);
  const [editedDepartmentName, setEditedDepartmentName] = useState("");
  const [editingLineId, setEditingLineId] = useState(null);
  const [editedLineName, setEditedLineName] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [editingName, setEditingName] = useState(null);
  const [editedWorkspaceName, setEditedWorkspaceName] = useState("");
  const [editedJobName, setEditedJobName] = useState("");
  const [editedShiftName, setEditedShiftName] = useState("");
  const [loadingName, setLoadingName] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  const { toast } = useToast();

  const popupRef = useRef();
  const popupDeleteRef = useRef();

  const { session } = useClerk();

  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);

  const content = ` Questa è una descrizione generica di una postazione di lavoro in un
        ambiente di manifattura labor-intensive caratterizzato da attrezzature
        specifiche per.`;

  const handleTreeSelect = (event, nodeIds) => {
    if (
      !nodeIds.startsWith("dep-") &&
      !nodeIds.startsWith("shifts-") &&
      !nodeIds.startsWith("jobs-") &&
      !nodeIds.startsWith("areas-") &&
      !nodeIds.startsWith("line-")
    ) {
      setSelected(nodeIds);
      const idParts = nodeIds.split("-");
      const numericId = idParts[1];
      setSelectedId(numericId);
    } else {
      event.target.blur();
    }
  };

  const handleToggle = (event, nodeIds) => {
    event.stopPropagation();
    setExpanded(nodeIds);
  };

  const handleFocus = (event, nodeIds) => {};

  const handleOpenChange = (id, isOpen) => {
    setOpenMenuId(isOpen ? id : null);
  };

  return (
    <>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        selected={selected}
        onNodeSelect={handleTreeSelect}
        onNodeFocus={handleFocus}
        aria-label="departments navigator"
        defaultCollapseIcon={<DownArrowIcon size={"0.65vw"} />}
        defaultExpandIcon={<RightArrowIcon size={"0.65vw"} />}
        sx={{
          //   height: "100%",
          flexGrow: 1,
          maxWidth: width,
          width: width,
          height: "1vw",
          paddingRight: "0.5vw",
          marginBottom: "1vw",
          overflowY: "scroll",

          "& .MuiTreeItem-content": {
            "& .MuiTreeItem-iconContainer": {
              marginRight: "0px",
              marginLeft: "0.6vw",
            },
            "& .MuiTreeItem-label": {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              marginLeft: "0px",
              paddingLeft: "0.5vw",
            },
          },
        }}
      >
        {renderDepartments({
          departments: state.current_location.departments,
          selectedId,
          openMenuId,
          setCurrentDepartment,
          setPopupContent,
          setDepartmentDetail,
          setSelected,
          setSelectedId,
          dispatch,
          session,
          popupRef,
          t,
          content,
          handleOpenChange,
          editingDepartmentId,
          setEditingDepartmentId,
          editedDepartmentName,
          setEditedDepartmentName,
          setData,
          departmentDetail,
          expanded,
          setExpanded,
          state,
          setEditingLineId,
          editedLineName,
          setEditedLineName,
          editingLineId,
          setEditingName,
          setEditedWorkspaceName,
          loadingId,
          setLoadingId,
          width,
          editingName,
          editedWorkspaceName,
          popupDeleteRef,
          setEditedJobName,
          editedJobName,
          editedShiftName,
          setEditedShiftName,
          deleted,
          toast,
          loadingName,
          setLoadingName,
        })}
      </TreeView>
      <ButtonTextIcon
        label={t("compressAll")}
        icon={process.env.REACT_APP_RESOURCES_BUCKET + "CompressMain.png"}
        iconH={process.env.REACT_APP_RESOURCES_BUCKET + "CompressMain.png"}
        backgroundH={colors.mainOpaque}
        background={""}
        color={colors.main}
        onClick={() => setExpanded([])}
        margin={"0vw 0vw 1vw 0vw"}
        className="!shadow-none p-[0.5vw]"
      />

      {/* <PopupCreate
        ref={popupRef}
        info={popupContent.info}
        title={popupContent.title}
        actionLabel={popupContent.actionLabel}
        children={popupContent.children}
      /> */}
      <PopupCreateEdit
        ref={popupRef}
        info={popupContent.info}
        title={popupContent.title}
        actionLabel={popupContent.actionLabel}
        children={popupContent.children}
        popupContent={popupContent}
      />
      <PopupDelete
        ref={popupDeleteRef}
        info={popupContent.info}
        title={popupContent.title}
        actionLabel={popupContent.actionLabel}
        children={popupContent.children}
      />
    </>
  );
};
