export const rightColors = [
  "rgba(0, 0, 0, 1)",
  "rgba(75, 172, 162, 1)",
  "rgba(75, 152, 152, 1)",
];

export const wrongColors = [
  "rgba(226, 0, 116, 1)",
  "rgba(250, 137, 175, 1)",
  "rgba(101, 97, 242, 1)",
];
export const indexColors = [
  "rgba(101, 97, 242, 1)",
  "rgba(156, 153, 255, 1)",
  "rgba(163, 20, 124, 1)",
  "rgba(217, 119, 196, 1)",
];

export const editColors = ["rgba(232, 136, 70, 1)"];
export const disabledColors = ["rgba(161, 159, 204, 1)"];

export const lineColor = "rgba(0, 0, 0, 1)";

export const barColor = "#716DF2";
