import React from "react";
import {
  handleClick,
  handleDoubleClick,
  handleMouseOut,
  handleMouseMove,
} from "../ChartHandler";

const ChartCanvas = ({
  id,
  chartRef,
  chartInstance,
  videoLoaded,
  dataForChart,
  editingRef,
  updateErrori,
  data,
  selectedChart,
  handleChartUpdate,
  enableMouseLocation,
}) => {
  return (
    <canvas
      key={id}
      id={id}
      ref={chartRef}
      style={{ width: "99%", height: "100%" }}
      onClick={(evt) =>
        handleClick(
          id,
          evt,
          chartInstance,
          videoLoaded,
          dataForChart,
          updateErrori,
          editingRef
        )
      }
      onDoubleClick={(evt) =>
        handleDoubleClick(
          id,
          evt,
          chartInstance,
          data,
          selectedChart,
          handleChartUpdate
        )
      }
      onMouseOut={(evt) => handleMouseOut(evt, chartInstance)}
      onMouseMove={(evt) =>
        handleMouseMove(
          evt,
          chartInstance,
          enableMouseLocation,
          id,
          editingRef,
          videoLoaded,
          dataForChart,
          chartRef,
          updateErrori
        )
      }
    />
  );
};

export default ChartCanvas;
