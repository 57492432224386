import { useClerk } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner/Spinner";
import ButtonTextIcon from "../components/buttons/ButtonTextIcon/ButtonTextIcon";
import ButtonToggle from "../components/buttons/ButtonToggle/ButtonToggle";
import { colors } from "../config/style";
import { getCustomers, getVideoByStatus } from "../remote/structure";
import { getToken } from "../userManagement/utilities";
import InspectionTable from "../components/Tables/InspectionTable/InspectionTable";
// commento
export const MainDataset = ({ dataTestId }) => {
  const [inspections, setInspections] = useState();
  const [filteredInspections, setFilteredInspections] = useState();

  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedDates, setSelectedDates] = useState(0);

  const [loaded, setLoaded] = useState(false);

  const [dataset, setDataset] = useState({});

  const { session } = useClerk();

  const { t } = useTranslation();

  const datesSelector = [
    { label: "Tutte le date", days: -1 },
    { label: "Ultimi 7 giorni", days: 7 },
    { label: "Ultimi 30 giorni", days: 30 },
  ];

  const companiesSelector = ["Tutte le aziende"];


  useEffect(() => {
    loadRecords();
  }, []);

  useEffect(() => {
    if (inspections) filterInspections();
  }, [selectedCompany, selectedDates, inspections]);

  // Funzione per convertire l'oggetto
  function convertObject(obj) {
    const converted = {
      left: [],
      right: [],
      hands: [],
    };

    // Iteriamo su ogni chiave dell'oggetto originale
    Object.values(obj).forEach((entry) => {
      converted.left.push(entry.LEFT);
      converted.right.push(entry.RIGHT);
      converted.hands.push(entry.HANDS);
    });

    return converted;
  }

  // Funzione per copiare il testo negli appunti
  function copyToClipboard(text) {
    const tempInput = document.createElement("textarea");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  const loadRecords = async (init) => {
    //Load all locations

    let jwt = await getToken(session);

    let inspections = await getVideoByStatus("REVIEWED", jwt);

    let customers = await getCustomers(jwt);

    let reviewable = customers.map((x) => {
      if (x.reviewable == true) {
        return x.name;
      }
    });

    const filteredRecords = inspections.filter((inspection) =>
      reviewable.includes(inspection.ownerCompany)
    );

    setInspections(ordinaPerDataCreazione(filteredRecords));
  };

  const filterInspections = () => {
    let filteredInspections = inspections;

    // Company
    if (selectedCompany != "")
      filteredInspections = inspections.filter(
        (inspection) => inspection.ownerCompany === selectedCompany
      );

    // Dates
    if (selectedDates != 0) {
      const now = new Date();
      filteredInspections = filteredInspections.filter((record) => {
        const creationDate = new Date(record.creationDate);
        const differenceInTime = now - creationDate;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= datesSelector[selectedDates].days;
      });
    }

    setFilteredInspections(filteredInspections);
  };

  function ordinaPerDataCreazione(array) {
    return array.sort((a, b) => {
      // Converti le date in oggetti Date per il confronto
      let dataA = new Date(a.creationDate);
      let dataB = new Date(b.creationDate);

      // Ordina in modo crescente
      return dataB - dataA;
    });
  }

  return (
    <div
      style={{
        width: "88vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          width: "73.5vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "0.8vw",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "1.6vw",
              fontWeight: "700",

              marginTop: "1.9vw",
              boxSizing: "border-box",
            }}
          >
            {"Revisione"}
          </p>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.5vw" }}>
            <ButtonTextIcon
              width={"8.5vw"}
              label={"Copia dataset"}
              backgroundH={colors.mainH}
              background={colors.main}
              color={"white"}
              onClick={() => {
                const convertedObject = convertObject(dataset);
                const jsonString = JSON.stringify(convertedObject, null, 2); // Formatta l'oggetto come stringa JSON
                copyToClipboard(jsonString);
              }}
            />

            <ButtonTextIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
              label={t("refresh")}
              backgroundH={colors.mainH}
              background={colors.main}
              color={"white"}
              onClick={() => {
                setInspections();
                setFilteredInspections();
                loadRecords();
              }}
            />
          </div>
        </div>
        {inspections ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2vw",
                marginBottom: "1vw",
                width: "100%",
              }}
            >
              {[
                ...companiesSelector,
                ...new Set(inspections.map((record) => record.ownerCompany)),
              ].map((company) => {
                return (
                  <ButtonToggle
                    onClick={() => {
                      if (company == companiesSelector[0])
                        setSelectedCompany("");
                      else setSelectedCompany(company);
                    }}
                    label={company}
                    toggle={
                      company == companiesSelector[0]
                        ? selectedCompany == ""
                        : selectedCompany == company
                    }
                    disabled={false}
                  />
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2vw",
                marginBottom: "1vw",
                width: "100%",
              }}
            >
              {datesSelector.map((dates, id) => {
                return (
                  <ButtonToggle
                    onClick={() => {
                      setSelectedDates(id);
                    }}
                    label={dates.label}
                    toggle={selectedDates == id}
                    disabled={false}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            overflowY: "scroll",
            width: "73.5vw",
            height: "88vh",
            // padding: "2vw",
          }}
        >
          {" "}
          {true && loaded ? (
            <div
              style={{
                width: "72.8vw",
                height: "100%",
                borderRadius: "0.3vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_RESOURCES_BUCKET + "InspectionPh.png"
                }
                style={{
                  width: "23.5vw",
                }}
              />{" "}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "400",
                  marginLeft: "0vw",
                  marginTop: "0vw",
                  boxSizing: "border-box",
                  textAlign: "center",
                  width: "25vw",
                  marginTop: "1vw",
                }}
              >
                {t("mainInspectionNoIsp")}
              </p>
              <ButtonTextIcon
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
                label={t("refresh")}
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => {}}
              />
            </div>
          ) : inspections ? (
            <div style={{}}>
              {filteredInspections?.map((inspection, index) => {
                return (
                  <InspectionTable
                    key={inspection.id}
                    inspection={inspection}
                    inspectionIndex={index}
                    onSave={() => {}}
                    owner={true}
                    dataset={dataset}
                    setDataset={setDataset}
                  />
                );
              })}
            </div>
          ) : (
            <Spinner />
          )}
          {/* <InspectionTable inspections={inspections} /> */}
        </div>
      </div>
    </div>
  );
};
