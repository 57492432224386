import { lineColor } from "../../config/graphsConfig";
import { setAlpha } from "./ChartUtlis";

export const backgroundPlugin = (dataForChart) => ({
  id: "customCanvasBackground",
  beforeDraw: (chart, args, options) => {
    let area = chart.editing;
    if (area) {
      let ctx = chart.ctx;
      let chartArea = chart.chartArea;
      // Calcola larghezza e altezza corrette
      let leftEnd = area.start ? area.start : area.center;
      let rightStart = area.end ? area.end : area.center;

      leftEnd = chart.scales.x.getPixelForValue(
        dataForChart.current.labels[leftEnd]
      );
      rightStart = chart.scales.x.getPixelForValue(
        dataForChart.current.labels[rightStart]
      );
      let leftWidth = leftEnd - chartArea.left;
      let rightWidth = chartArea.right - rightStart;
      let height = chartArea.bottom - chartArea.top;

      ctx.save();
      ctx.fillStyle = "rgba(200, 200, 200, 0.5)";
      ctx.fillRect(chartArea.left, chartArea.top, leftWidth, height);
      ctx.fillRect(rightStart, chartArea.top, rightWidth, height);
      ctx.restore();
    }
  },
});

export const visibilityPlugin = {
  id: "visibilityPlugin",
  beforeDraw: (chart, args, options) => {

    let areas = chart.visibility;
    if (areas) {
      let ctx = chart.ctx;
      let chartArea = chart.chartArea;
      let xScale = chart.scales["x"]; // Assume the x-axis is named 'x'
      let yScale = chart.scales["y"]; // Assume the y-axis is named 'y'

      ctx.save();
      // ctx.fillStyle = colors.mainBackground;

      ctx.fillStyle = "#F0F0F0";

      areas.forEach((area) => {
        let startPixel = xScale.getPixelForValue(area.start);
        let endPixel = xScale.getPixelForValue(area.end);
        let width = endPixel - startPixel;
        let height = chartArea.bottom - chartArea.top;

        ctx.fillRect(startPixel, chartArea.top, width, height);
      });

      ctx.restore();
    }
  },
};

export const lineaVerticalePlugin = (dataForChart) => ({
  id: "lineaVerticale",
  afterDraw: (chart, args, options) => {
    var ctx = chart.ctx;
    var x = chart.scales.x.getPixelForValue(options.currentTime); // Linea per il tempo corrente
    // Disegna la linea per il tempo corrente
    drawLine(ctx, x, chart.chartArea, chart.lineColor); // Colore solido

    if (chart.cursorPosition) {
      // Disegna la linea per la posizione del cursore
      drawLine(
        ctx,
        chart.cursorPosition,
        chart.chartArea,
        chart.lineColor
          ? setAlpha(chart.lineColor, 0.3)
          : setAlpha(lineColor, 0.3)
      ); // Colore trasparente
    }
    if (chart?.editing?.startFix) {
      drawLine(
        ctx,
        chart.scales.x.getPixelForValue(
          dataForChart.current.labels[chart.editing.start]
        ),
        chart.chartArea,
        chart.lineColor
      );
    }
    if (chart?.editing?.endFix) {
      drawLine(
        ctx,
        chart.scales.x.getPixelForValue(
          dataForChart.current.labels[chart.editing.end]
        ),
        chart.chartArea,
        chart.lineColor
      );
    }
  },
});

function drawLine(ctx, x, chartArea, color) {
  if (x < chartArea.left || x > chartArea.right) {
    return; // Non disegnare la linea se è fuori dall'area del grafico
  }
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, chartArea.top);
  ctx.lineTo(x, chartArea.bottom);
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  ctx.stroke();
  ctx.restore();
}
