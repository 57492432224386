import {
  deleteLine,
  updateStation,
  updateDepartment,
  updateLine,
  deleteStation,
  deleteDepartment,
  updateJob,
  editWorkshift,
  deleteJob,
  deleteWorkshift,
} from "../../remote/structure";
import { getToken } from "../../userManagement/utilities";
import { LOAD_LOCATION } from "../../stores/actions/DataAction";
import { DATA_A } from "../../stores/actions/TypeActions";
import { useToast } from "../../hooks/use-toast";
import { ToastAction } from "../ui/toast";
import { handleLoadDepartment, handleLoadDepartments } from "@/remote/utils";

export const handleDeleteLine = async (
  depId,
  lineId,
  state,
  session,
  setDepartmentDetail,
  setData,
  name
) => {
  try {
    const locationId = state.current_location.id;
    const jwt = await getToken(session);

    // await deleteLine(locationId, depId, lineId, jwt);

    setDepartmentDetail((prevDetails) => {
      const updatedLines = { ...prevDetails[depId].lines };
      delete updatedLines[lineId];
      return {
        ...prevDetails,
        [depId]: {
          ...prevDetails[depId],
          lines: updatedLines,
        },
      };
    });

    setData({ type: "delete", element: name });

    console.log(`Line ${lineId} deleted successfully.`);
  } catch (error) {
    console.error(`Failed to delete line ${lineId}:`, error);
  }
};

export const handleDeleteWorkspace = async (
  depId,
  lineId,
  workspaceId,
  state,
  session,
  setDepartmentDetail,
  setLoadingId,
  setData,
  name,
  deleted
) => {
  try {
    deleted.current = true;
    setLoadingId(workspaceId);

    const locationId = state.current_location.id;
    const jwt = await getToken(session);

    await deleteStation(locationId, depId, lineId, workspaceId, jwt);

    setDepartmentDetail((prevDetails) => {
      const updatedLines = { ...prevDetails[depId].lines };

      const line = updatedLines[lineId];
      if (line && line[workspaceId]) {
        delete line[workspaceId];
      }

      return {
        ...prevDetails,
        [depId]: {
          ...prevDetails[depId],
          lines: updatedLines,
        },
      };
    });

    setData({ type: "delete", element: name });

    console.log(
      `Workspace ${workspaceId} deleted successfully from line ${lineId}.`
    );
  } catch (error) {
    console.error(
      `Failed to delete workspace ${workspaceId} from line ${lineId}:`,
      error
    );
  } finally {
    setLoadingId(null);
  }
};

export const handleDeleteDepartment = async (
  locationId,
  departmentId,
  jwt,
  dispatch,
  setData,
  name
) => {
  try {
    // await deleteDepartment(locationId, departmentId, jwt);

    console.log(
      `Department ${departmentId} deleted successfully from backend.`
    );

    dispatch({
      type: DATA_A,
      subtype: "DELETE_DEPARTMENT",
      action: {
        departmentId,
      },
    });

    setData({ type: "delete", element: name });
    console.log(`Department ${departmentId} removed from state.`);
  } catch (error) {
    console.error(`Failed to delete department ${departmentId}:`, error);
  }
};

export const handleSaveWorkspace = async (
  workspaceId,
  state,
  session,
  workspaces,
  department,
  editedWorkspaceName,
  setEditingName,
  setLoadingName,
  setDepartmentDetail
) => {
  try {
    setLoadingName(true);
    const currentName = workspaces[workspaceId].name.trim();
    const newName = editedWorkspaceName.trim();

    if (currentName === newName) {
      setEditingName(null);
      return;
    }

    const location = state.current_location.id;

    const line = workspaces[workspaceId].line;
    const jwt = await getToken(session);

    let request = await updateStation(
      location,
      department.id,
      line,
      workspaces[workspaceId].id,
      { ...workspaces[workspaceId], name: newName },
      await getToken(session)
    );
    handleLoadDepartment(
      department.id,
      () => {
        setLoadingName(false);
        setEditingName(null);
      },
      department,
      session,
      state.current_location.id,
      (x) => setDepartmentDetail(x)
    );

    console.log(`Workstation ${workspaceId} name updated successfully.`);
  } catch (error) {
    console.error(`Failed to update workstation ${workspaceId} name:`, error);
  }
};

export const handleSaveLineName = async (
  depId,
  oldLineId,
  state,
  session,
  editedLineName,
  setDepartmentDetail,
  dispatch,
  setEditingLineId
) => {
  try {
    const locationId = state.current_location.id;
    const jwt = await getToken(session);

    const newLineId = editedLineName;

    // await updateLine(locationId, depId, oldLineId, newLineId, jwt);

    setDepartmentDetail((prevDetails) => {
      const prevLines = prevDetails[depId].lines;

      const { [oldLineId]: oldLineData, ...otherLines } = prevLines;

      const newLines = {
        ...otherLines,
        [newLineId]: oldLineData,
      };

      return {
        ...prevDetails,
        [depId]: {
          ...prevDetails[depId],
          lines: newLines,
        },
      };
    });

    dispatch({
      type: DATA_A,
      subtype: LOAD_LOCATION,
      action: {
        ...state.current_location,
        departments: {
          ...state.current_location.departments,
          [depId]: {
            ...state.current_location.departments[depId],
            lines: {
              ...(() => {
                const prevLines =
                  state.current_location.departments[depId].lines;
                const { [oldLineId]: oldLineData, ...otherLines } = prevLines;
                return {
                  ...otherLines,
                  [newLineId]: oldLineData,
                };
              })(),
            },
          },
        },
      },
    });

    setEditingLineId(null);

    console.log(`Line ${oldLineId} renamed to ${newLineId} successfully.`);
  } catch (error) {
    console.error(`Failed to update line ${oldLineId}:`, error);
  }
};

export const handleSaveDepartmentName = async (
  department,
  state,
  session,
  editedDepartmentName,
  setDepartmentDetail,
  setEditingDepartmentId,
  dispatch,
  setLoadingName
) => {
  try {
    setLoadingName(true);
    let request = await updateDepartment(
      state.current_location.id,
      department.id,
      {
        ...department,
        name: editedDepartmentName,
      },
      await getToken(session)
    );

    handleLoadDepartments(
      state.current_location.id,
      (locationUpdated) => {
        dispatch({
          type: DATA_A,
          subtype: LOAD_LOCATION,
          action: locationUpdated,
        });
        setLoadingName(false);
        setEditingDepartmentId(null);
      },
      session
    );

    // const location = state.current_location.id;
    // const jwt = await getToken(session);

    // const updatedData = { name: editedDepartmentName };

    // // await updateDepartment(location, departmentId, updatedData, jwt);

    // setDepartmentDetail((prevDetails) => ({
    //   ...prevDetails,
    //   [department.id]: {
    //     ...prevDetails[department.id],
    //     name: editedDepartmentName,
    //   },
    // }));

    // dispatch({
    //   type: DATA_A,
    //   subtype: LOAD_LOCATION,
    //   action: {
    //     ...state.current_location,
    //     departments: {
    //       ...state.current_location.departments,
    //       [departmentId]: {
    //         ...state.current_location.departments[departmentId],
    //         name: editedDepartmentName,
    //       },
    //     },
    //   },
    // });

    // setEditingDepartmentId(null);

    console.log(`Department ${department.id} name updated successfully.`);
  } catch (error) {
    console.error(`Failed to update department ${department.id} name:`, error);
  }
};

export const handleSaveJob = async (
  jobId,
  state,
  session,
  jobs,
  depId,
  editedJobName,
  setEditingName,
  setDepartmentDetail,
  department,
  setLoadingName
) => {
  try {
    setLoadingName(true);
    const currentName = jobs[jobId].name.trim();
    const newName = editedJobName.trim();

    if (currentName === newName) {
      setEditingName(null);
      return;
    }

    const job = jobs[jobId];

    let request = await updateJob(
      state.current_location.id,
      job.department,
      {
        name: newName,
        description: job.description,
        workshift: job.workshift,
        n_employees: job.n_employees,
      },
      job.id,
      await getToken(session)
    );

    handleLoadDepartment(
      department.id,
      () => {
        setLoadingName(false);
        setEditingName(null);
      },
      department,
      session,
      state.current_location.id,
      (x) => setDepartmentDetail(x)
    );

    console.log(`Job ${jobId} name updated successfully.`);
  } catch (error) {
    console.error(`Failed to update job ${jobId} name:`, error);
  }
};

export const handleSaveShift = async (
  shiftId,
  state,
  session,
  shifts,
  depId,
  editedShiftName,
  setEditingName,
  setDepartmentDetail,
  setLoadingName,
  department
) => {
  try {
    setLoadingName(true);
    const currentName = shifts[shiftId].name.trim();
    const newName = editedShiftName.trim();

    if (currentName === newName) {
      setEditingName(null);
      return;
    }

    const jwt = await getToken(session);

    const shift = shifts[shiftId];

    const shiftData = {
      ...shift,
      name: newName,
    };

    let request = await editWorkshift(shiftId, shiftData, jwt);

    handleLoadDepartment(
      department.id,
      () => {
        setLoadingName(false);
        setEditingName(null);
      },
      department,
      session,
      state.current_location.id,
      (x) => setDepartmentDetail(x)
    );

    // setDepartmentDetail((prevDetail) => {
    //   const currentDepartment = prevDetail[depId];
    //   const updatedShifts = {
    //     ...currentDepartment.workshifts,
    //     [shiftId]: shiftData,
    //   };

    //   return {
    //     ...prevDetail,
    //     [depId]: {
    //       ...currentDepartment,
    //       workshifts: updatedShifts,
    //     },
    //   };
    // });

    console.log(`Shift ${shiftId} name updated successfully.`);
  } catch (error) {
    console.error(`Failed to update shift ${shiftId} name:`, error);
  }
};

export const handleDeleteJob = async ({
  locationId,
  departmentId,
  jobId,
  session,
  setDepartmentDetail,
  setData,
  name,
  setSelectedId,
  departmentDetail,
  deleted,
  setLoadingId,
}) => {
  try {
    deleted.current = true;

    const response = await deleteJob(
      locationId,
      departmentId,
      jobId,
      await getToken(session)
    );

    console.log("Delete Job Response:", response);
    if (!response || response.error) {
      throw new Error("Failed to delete the job");
    }
    const updatedJobs = {
      ...departmentDetail[departmentId].jobs,
    };

    delete updatedJobs[jobId];

    const updatedCurrentDepartment = {
      ...departmentDetail[departmentId],
      jobs: updatedJobs,
    };

    const updatedDepartmentDetails = {
      ...departmentDetail,
      [departmentId]: updatedCurrentDepartment,
    };
    setData({ type: "delete", element: name });
    setDepartmentDetail(updatedDepartmentDetails);
    setSelectedId(null);
  } catch (error) {
    console.error("Error while deleting the job:", error);
  }
};

export const handleDeleteShift = async ({
  locationId,
  departmentId,
  shiftId,
  session,
  setDepartmentDetail,
  setData,
  name,
  departmentDetail,
  deleted,
  onSuccess,
  OnCancelDelete,
  toast,
}) => {
  deleted.current = true;

  const timeoutDuration = 5000;
  let deleteTimeout;
  let undoTriggered = false;

  onSuccess();

  const onConfirm = async () => {
    try {
      const token = await getToken(session);
      await deleteWorkshift(shiftId, token);

      const updatedWorkshifts = {
        ...departmentDetail[departmentId].workshifts,
      };
      delete updatedWorkshifts[shiftId];

      const updatedCurrentDepartment = {
        ...departmentDetail[departmentId],
        workshifts: updatedWorkshifts,
      };

      const updatedDepartmentDetails = {
        ...departmentDetail,
        [departmentId]: updatedCurrentDepartment,
      };

      setDepartmentDetail(updatedDepartmentDetails);
      console.log(`${name} deleted permanently.`);
    } catch (error) {
      console.error("Error deleting shift:", error.message);
    }

    setData({ type: "delete", element: name });
  };

  toast({
    description: `${name} Eliminato.`,
    className:
      " bg-[#212040] w-[10.345vw]  h-[0vw]   2xl:h-[0.625vw]  text-center flex justify-around px-4 items-center rounded-[0.625vw] text-white ",
    action: (
      <ToastAction
        altText="Goto schedule to undo"
        className="flex items-center justify-center  cursor-pointer border-none bg-transparent hover:bg-[#515073]  rounded-md px-[4px]"
      >
        <div
          className="flex text-white items-center justify-center gap-1 w-full"
          onClick={() => {
            undoTriggered = true;
            handleUndo(deleteTimeout);
          }}
        >
          <svg
            width="1.875vw"
            height="1.875vw"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.63604 8.63604C6 11.2721 6 15.5147 6 24C6 32.4853 6 36.7279 8.63604 39.364C11.2721 42 15.5147 42 24 42C32.4853 42 36.7279 42 39.364 39.364C42 36.7279 42 32.4853 42 24C42 15.5148 42 11.2721 39.364 8.63604C36.7279 6 32.4853 6 24 6C15.5147 6 11.2721 6 8.63604 8.63604ZM19.0657 16.7531C19.6135 16.2474 19.6477 15.3933 19.142 14.8455C18.6363 14.2976 17.7822 14.2634 17.2343 14.7691L13.1843 18.5076C12.9075 18.7632 12.75 19.1228 12.75 19.4996C12.75 19.8764 12.9075 20.236 13.1843 20.4916L17.2343 24.23C17.7822 24.7358 18.6363 24.7016 19.142 24.1537C19.6477 23.6059 19.6135 22.7518 19.0657 22.2461L17.5528 20.8496H27.6692C30.3648 20.8496 32.55 23.0348 32.55 25.7304C32.55 28.4259 30.3648 30.6111 27.6692 30.6111H19.5C18.7544 30.6111 18.15 31.2155 18.15 31.9611C18.15 32.7067 18.7544 33.3111 19.5 33.3111H27.6692C31.856 33.3111 35.25 29.9171 35.25 25.7304C35.25 21.5436 31.856 18.1496 27.6692 18.1496H17.5528L19.0657 16.7531Z"
              fill="white"
            />
          </svg>
          <span className=" flex font-atkinson font-bold text-[0.82034vw] ">
            Annulla
          </span>
        </div>
      </ToastAction>
    ),
    duration: timeoutDuration,
  });

  deleteTimeout = setTimeout(() => {
    if (!undoTriggered) {
      onConfirm();
    }
  }, timeoutDuration);

  const handleUndo = (deleteTimeout) => {
    clearTimeout(deleteTimeout);
    OnCancelDelete();
  };
};
