import { colors } from "../../../config/style";
import { useTranslation } from "react-i18next";
import { NIOSH_ID, protocols } from "../../../config/availableProtocols";
import formattaData from "../../FormattaData/FormattaData";

const StatusColumn = ({ header, err, data, exp, protocol, result }) => {
  const { t } = useTranslation();

  console.log("RESULT ST CL: ", result)

  function getColors(err, exp, protocol) {
    if (protocol?.ref == protocols[NIOSH_ID].ref) {
      if (result?.taskDetail?.niosh?.m)
        return [colors.positivePale, colors.positive, t("completeStatus")];
      else
        return [
          colors.riskUnsurePale,
          colors.riskUnsure,
          t("incompleteStatus"),
        ];
    } else {
      if (err == 5) {
        if (exp == "expert")
          return [
            colors.positivePale,
            colors.positive,
            data ? formattaData(data, t, true) : t("completeStatus"),
          ];
        else if (exp == "reviewer" || exp == "dataset")
          return [
            colors.positivePale,
            colors.positive,
            data ? formattaData(data, t, true) : "Revisionare",
          ];
        else return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 7) {
        return [
          colors.positivePale,
          colors.positive,
          data ? formattaData(data, t, true) : t("completeStatus"),
        ];
      }
      if (err == 6) {
        return [
          colors.riskUnsurePale,
          colors.riskUnsure,
          t("incompleteStatus"),
        ];
      }
      if (err == 3) {
        return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 2) {
        return [colors.neutralPale, colors.neutral, t("missingStatus")];
      }
      if (err == -1) {
        return [colors.mainOpaque, colors.main, t("queueStatus")];
      }
      if (err == 4) {
        if (exp == "reviewer" || exp == "dataset")
          return [colors.mainOpaque, colors.main, t("reviewStatus")];
        else if (exp == "expert")
          return [
            colors.riskUnsurePale,
            colors.riskUnsure,
            t("incompleteStatus"),
          ];
        else return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 1) {
        return [colors.negativePale, colors.negative, t("errorStatus")];
      } else return [colors.positivePale, colors.positive, "Unkno"];
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 0.8,
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              // width: columnWidth,
              flex: 1,
            }}
          >
            {t("mainStructureStatus")}
          </p>
        </>
      ) : (
        <div
          style={{
            width: "6.25vw",
            padding: "0.23vw",
            boxSizing: "border-box",
            background: getColors(err, exp, protocol)[0],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            borderRadius: "0.78vw",
            gap: "0.23vw",
          }}
        >
          <div
            style={{
              width: "0.94vw",
              height: "0.94vw",
              borderRadius: "0.94vw",
              background: getColors(err, exp, protocol)[1],
            }}
          ></div>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.82vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {getColors(err, exp, protocol)[2]}
          </p>
        </div>
        // <>
        //   <img
        //     src={img}
        //     style={{
        //       width: "6.25vw",
        //       // height: "3.1vw",

        //       marginRight: "0.3vw",
        //     }}
        //   />
        // </>
      )}
    </div>
  );
};

export default StatusColumn;
