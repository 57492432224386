import { useClerk, useUser } from "@clerk/clerk-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors, layout } from "../../../config/style";
import { tips } from "../../../config/tips";
import { useStateValue } from "../../../stores/services/StateProvider";
import { isPositiveInteger } from "../../AnalysisContainer/AnalysisContainerFunctions";
import InfoTab, {
  BasicText,
  InfoBox,
  InfoTabScroller,
  Row,
  Selector,
  TextBox,
} from "../../InfoTab/InfoTab";
import Spinner from "../../Spinner/Spinner";
import ButtonIcon from "../../buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../../buttons/ButtonTextIcon/ButtonTextIcon";

import {
  filterInvalidTasks,
  handleLoadDepartment,
  updateProtocols,
} from "../../../remote/utils";
import ExcelDownloader from "../../../utils/Excel";
import { shift_global } from "../../../utils/Protocols/OCRA/singleTask";
import { Pill } from "../../Pill/Pill";
import { PopupAdd } from "../../Popup/Popup";
import ReportGenerator from "../../ReportGenerator/ReportGenerator";
import JobTable from "../../Tables/JobTable/JobTable";
import { multitaskResult } from "../StructureComponents";
import {
  NIOSH_ID,
  OCRA_ID,
  protocols,
} from "../../../config/availableProtocols";

const JobScreen = ({
  id,
  setDepartmentDetail,
  departmentDetail,
  currentDepartment,
  setData,
  data,
  location,
  handleDeleteJobTask,
  handleSaving,
  handleDelete,
  handleChangeTime,
  saving,
  savingEnabled,
  setSavingEnabled,
  deleted,
  jobRef,
  tasks,
  setTasks,
  popupRef,
}) => {
  for (
    let i = 0;
    i < departmentDetail[currentDepartment]?.jobs[id]?.tasks?.length;
    i++
  ) {
    departmentDetail[currentDepartment].jobs[id].tasks[i] = {
      ...departmentDetail[currentDepartment].jobs[id].tasks[i],
    };
  }
  // END STATIC
  const [state, dispatch] = useStateValue();

  const [job, setJob] = useState({
    ...departmentDetail[currentDepartment]?.jobs[id],
  });
  const [shift, setShift] = useState(
    departmentDetail[currentDepartment]?.workshifts[
      departmentDetail[currentDepartment].jobs[id].workshift
    ]
  );
  const [filteredTasks, setFilteredTasks] = useState([]);

  const [loadedJobs, setLoadedJobs] = useState(false);
  const [shiftRes, setShiftRes] = useState();
  const [generating, setGenerating] = useState(false);
  const [multitask, setMultitask] = useState();
  const [singletask, setSingletask] = useState();
  const [reportEnabled, setReportEnabled] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [resultComponent, setResultComponent] = useState(
    <Spinner size={"3vw"} />
  );
  const [availableProtocols, setAvailableProtocols] = useState();
  const [selectedProtocol, setSelectedProtocol] = useState(-1);

  const resultComponentRef = useRef();
  const multitaskRef = useRef();
  const singletaskRef = useRef();

  const { user } = useUser();
  const { t } = useTranslation();

  resultComponentRef.current = resultComponent;
  singletaskRef.current = singletask;
  multitaskRef.current = multitask;
  jobRef.current = job;
  const { session } = useClerk();
  const shiftOptions = Object?.keys(
    departmentDetail[currentDepartment]?.workshifts
  ).map((key) => ({
    value: key,
    label: departmentDetail[currentDepartment]?.workshifts[key].name,
  }));

  console.log("filtered Tasks", filteredTasks);

  // const extractTaskList = (tasks, shiftRes) => {
  //   return tasks.map((x, id) => {
  //     return {
  //       percentuale_originale: x.durata / 100,
  //       min_lavorati_per_compito: 0,
  //       percentuale_su_480_min: 0,
  //       minuti_turno: 0,
  //       moltiplicatore_recupero: shiftRes.moltiplicatore_recupero,
  //       durata_media_netta: shiftRes.durata_media_netta,
  //       n_ore_senza_recupero: shiftRes.numero_ore_senza_recupero_automatico,
  //       sx: x.task?.ocra?.sx,
  //       dx: x.task?.ocra?.dx,
  //     };
  //   });
  // };

  useEffect(() => {
    const updatedCurrentDepartment = {
      ...departmentDetail[currentDepartment],
      jobs: {
        ...departmentDetail[currentDepartment].jobs,
        [id]: job,
      },
    };

    //Update local department details
    const updatedDepartmentDetails = {
      ...departmentDetail,
      [currentDepartment]: updatedCurrentDepartment,
    };
    setDepartmentDetail(updatedDepartmentDetails);
    setSavingEnabled(true);
  }, [job]);

  useEffect(() => {
    if (shift) setShiftRes(shift_global(shift));
  }, [shift]);

  useEffect(() => {
    updateProtocols(tasks, setAvailableProtocols, setSelectedProtocol);
    setFilteredTasks(filterJobTaskProtocol(tasks));
  }, [tasks, selectedProtocol]);

  useEffect(() => {
    if (loadedJobs) {
      let res = multitaskResult(
        shift,
        filteredTasks.map((x) => {
          return { durata: x.durata, task: x }; //Converto a modello job task per usare stessa funzione
        }),
        setReportEnabled,
        setShiftRes,
        setSingletask,
        setMultitask,
        t,
        availableProtocols,
        selectedProtocol
      );
      setResultComponent(res);
    }
  }, [loadedJobs, filteredTasks, selectedProtocol, refresh]);

  useEffect(() => {
    handleLoadDepartment(
      currentDepartment,
      (newDetail) => {
        setData({ ...data });

        setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
      },
      departmentDetail,
      session,
      departmentDetail[currentDepartment].locationId,
      (x) => setDepartmentDetail(x)
    );

    updateProtocols(tasks, setAvailableProtocols, setSelectedProtocol);

    return () => {
      if (!deleted.current) {
        //Create an updated version of the department
        const updatedCurrentDepartment = {
          ...departmentDetail[currentDepartment],
          jobs: {
            ...departmentDetail[currentDepartment].jobs,
            [id]: jobRef.current,
          },
        };

        //Update local department details
        const updatedDepartmentDetails = {
          ...departmentDetail,
          [currentDepartment]: updatedCurrentDepartment,
        };
        setDepartmentDetail(updatedDepartmentDetails);

        //Update online department details
        handleSaving(id, jobRef.current);
      }
    };
  }, []);

  function filterJobTaskProtocol(arrayOggetti) {
    const result = arrayOggetti
      .map((oggetto) => {
        // Filtra i task se selezionato un protocollo specifico
        if (
          selectedProtocol == -1 ||
          oggetto.hasOwnProperty(availableProtocols[selectedProtocol].ref) ||
          (availableProtocols[selectedProtocol].ref == protocols[OCRA_ID].ref &&
            !oggetto.hasOwnProperty(protocols[NIOSH_ID].ref))
        ) {
          return {
            ...oggetto.task,
            durata: oggetto.durata,
            idLink: oggetto.id,
          };
        }
        // Se l'oggetto non soddisfa la condizione, restituisce undefined
      })
      .filter((result) => result !== undefined); // Rimuove undefined
    return result;
  }

  return (
    <>
      <div
        style={{
          // height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60.1vw",
          marginTop: layout.paddingTop,
        }}
      >
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "1.6vw",
            fontWeight: "400",

            margin: "0vw",
            boxSizing: "border-box",
            width: "65%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <b>{job.name}</b>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1vw",
          }}
        >
          <ButtonIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"}
            onClick={handleDelete}
            style={{ width: "1.875vw" }}
          />
          <ButtonTextIcon
            label={t("save")}
            color={colors.main}
            backgroundH={colors.mainOpaque}
            background={"transparent"}
            loading={saving}
            loadingLabel={""}
            loadingStyle={{}}
            onClick={() => {
              handleSaving(id, jobRef.current);
            }}
            style={{
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "700",
              width: "3vw",
              boxSizing: "border-box",
            }}
          />

          <ButtonTextIcon
            label={"Report mansione"}
            color={"white"}
            backgroundH={colors.mainH}
            background={colors.main}
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
            iconH={process.env.REACT_APP_RESOURCES_BUCKET + "download.png"}
            loadingStyle={{}}
            loading={generating}
            loadingLabel={""}
            disabled={!reportEnabled}
            onClick={() => {
              ReportGenerator({
                job: job,
                availableProtocols: availableProtocols,
                tasks: tasks,
                shift: shift,
                multitask: multitaskRef.current,
                singleTask: singletaskRef.current,
                department: departmentDetail[currentDepartment],
                location: location,
                user: user,
                session: session,
                setGenerating: setGenerating,
                t: t,
              });
            }}
            style={{
              width: "9.1vw",
              fontSize: "0.82vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "700",

              boxSizing: "border-box",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          width: "60.1vw",
          gap: "0.5vw",
          height: "2.5vw",
          background: colors.mainOpaque,
          borderRadius: "1.25vw",
          boxSizing: "border-box",
          padding: "0.6vw",
        }}
      >
        {(availableProtocols?.length > 1 ||
          availableProtocols?.length == 0 ||
          !availableProtocols?.length) && (
          <Pill
            label={"Riepilogo"}
            toggle={selectedProtocol === -1}
            background={"white"}
            backgroundToggle={colors.main}
            color={colors.text}
            colorToggle={"white"}
            onClick={() => {
              setSelectedProtocol(-1);
            }}
          />
        )}
        {availableProtocols?.map((protocol, id) => {
          return (
            <Pill
              label={protocol.mid}
              toggle={selectedProtocol === id}
              background={"white"}
              backgroundToggle={colors.main}
              color={colors.text}
              colorToggle={"white"}
              onClick={() => setSelectedProtocol(id)}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          width: "100%",
          gap: "0.5vw",
          alignItems: "stretch",
        }}
      >
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("jobScreenDetails")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={[
            <>
              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Worker.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "5vw",
                      color: colors.text,
                    }}
                  >
                    {t("name")}
                  </BasicText>
                </div>
                <TextBox
                  width={"16.4vw"}
                  value={job.name}
                  onChange={(value) => {
                    setJob({ ...job, name: value });
                  }}
                  locked={false}
                />
              </Row>

              <Row justifyContent={"space-between"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_RESOURCES_BUCKET + "Timetables2.png"
                    }
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenShift")}
                  </BasicText>
                </div>
                <Selector
                  value={shiftOptions.find((x) => x.value == job.workshift)}
                  options={shiftOptions}
                  onChange={(value) => {
                    setJob({ ...job, workshift: value.value });
                    setShift(
                      departmentDetail[currentDepartment].workshifts[
                        value.value
                      ]
                    );
                  }}
                  single={true}
                  width={"16.4vw"}
                  height={"2.5vw"}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8.3vw",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenShiftDuration")}
                  </BasicText>
                </div>
                <InfoBox
                  value={
                    shiftRes?.output?.durata_media_netta
                      ? shiftRes.output?.durata_media_netta
                      : "-"
                  }
                  unit={"m"}
                  ai={false}
                  info={tips[document.language].durata_media_netta}
                />
              </Row>
              <Row justifyContent={"start"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={process.env.REACT_APP_RESOURCES_BUCKET + "People.png"}
                    style={{
                      width: "1.875vw",
                      height: "1.875vw",
                      margin: "0px",
                      marginRight: "0.2vw",
                    }}
                  />
                  <BasicText
                    style={{
                      width: "8.3vw",
                      color: colors.text,
                    }}
                  >
                    {t("jobScreenWorkersNumber")}
                  </BasicText>
                </div>
                <TextBox
                  value={job.n_employees}
                  onChange={(value) => {
                    if (isPositiveInteger(value)) {
                      setJob({ ...job, n_employees: value });
                    }
                  }}
                  locked={false}
                  infoBox={true}
                />
              </Row>
            </>,
          ]}
        />
        <InfoTab
          width={"29.8vw"}
          height={""}
          title={[t("SCORES")]}
          justifyContent={"start"}
          gap={"0.5vw"}
          justifyContentMain={"start"}
          content={resultComponentRef.current}
        />
      </div>
      <InfoTabScroller
        style={{ width: "60.1vw", overflow: "hidden" }}
        key={job.id}
        infoTab={
          <InfoTab
            fullWidth={true}
            width={"100%"}
            height={"100%"}
            title={[t("jobScreenTasksJob")]}
            justifyContent={"start"}
            gap={"0.5vw"}
            justifyContentMain={"start"}
            // style={{ flex: 0.95 }}
            content={
              <JobTable
                refresh={() => setRefresh(!refresh)}
                externalSelectedProtocol={
                  selectedProtocol >= 0 && availableProtocols[selectedProtocol]
                }
                loadedJobs={loadedJobs}
                setLoadedJobs={() => setLoadedJobs(true)}
                tasks={filteredTasks}
                unlinkIcon={true}
                onSave={() => {
                  handleLoadDepartment(
                    currentDepartment,
                    (newDetail) => {
                      setData({ ...data });
                      setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
                      popupRef.current.close();
                    },
                    departmentDetail,
                    session,
                    departmentDetail[currentDepartment].locationId,
                    (x) => setDepartmentDetail(x)
                  );
                }}
                columns={
                  selectedProtocol == -1
                    ? ["name", "protocol", "state", "score"]
                    : availableProtocols[selectedProtocol].ref ==
                      protocols[OCRA_ID].ref
                    ? ["name", "duration", "state", "score"]
                    : ["name", "state", "score"]
                }
                duration={
                  shiftRes?.output?.durata_media_netta
                    ? shiftRes?.output?.durata_media_netta
                    : 0
                }
                structure={true}
                deleteBtn={(x) => handleDeleteJobTask(x)}
                onChangeTime={handleChangeTime}
                empty={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      gap: "0.5vw",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES_BUCKET +
                        "JobPlaceholder.png"
                      }
                      style={{
                        width: "11.7vw",
                        marginTop: "-2vw",
                      }}
                    />{" "}
                    <p
                      style={{
                        color: colors.text,
                        fontFamily: "Atkinson Hyperlegible",
                        fontSize: "0.9vw",
                        fontWeight: "400",
                        marginLeft: "0vw",
                        marginTop: "0vw",
                        boxSizing: "border-box",
                        textAlign: "center",
                        width: "12vw",
                      }}
                    >
                      {t("jobScreenNoTasks")}
                    </p>
                    <ButtonTextIcon
                      label={t("add")}
                      icon={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      iconH={
                        process.env.REACT_APP_RESOURCES_BUCKET + "CrossWh.png"
                      }
                      backgroundH={colors.tertiaryH}
                      background={colors.tertiary}
                      color={"white"}
                      onClick={() => {
                        popupRef.current.open();
                      }}
                      style={{ paddingTop: "0.3vw", paddingBottom: "0.3vw" }}
                    />
                  </div>
                }
              />
            }
            titleButton={
              <ButtonTextIcon
                label={t("jobScreenAddTask")}
                color={colors.main}
                backgroundH={colors.details}
                background={"transparent"}
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                iconH={process.env.REACT_APP_RESOURCES_BUCKET + "Plus.png"}
                onClick={() => {
                  popupRef.current.open();
                }}
                style={{
                  fontSize: "0.82vw",
                  boxShadow: "0px 0px 0px 0px white",
                  height: "2vw",
                  fontWeight: "700",
                  marginRight: "0.5vw",
                  padding: "0.3vw",
                  paddingRight: "0.4vw",

                  boxSizing: "border-box",
                }}
              />
            }
          />
        }
      />
      <PopupAdd
        MainOwnerCompany={state.current_location.MainOwnerCompany}
        ref={popupRef}
        info={""}
        department={departmentDetail[currentDepartment]}
        id={id}
        tasksAssigned={tasks}
        title={t("jobTasksPopupTitle")}
        jobName={job.name}
        actionLabel={"action"}
        onSuccess={() => {
          handleLoadDepartment(
            currentDepartment,
            (newDetail) => {
              setData({ ...data });
              setTasks(filterInvalidTasks(newDetail.jobs[id].tasks));
              popupRef.current.close();
            },
            departmentDetail,
            session,
            departmentDetail[currentDepartment].locationId,
            (x) => setDepartmentDetail(x)
          );
        }}
      />
    </>
  );
};
export default JobScreen;
