export function getAvailableAnalysis(current_data, t) {
  return [
    {
      category: t("posturalWrong"),
      analysis: {
        rightShoulder: rightShoulder(current_data, t),
        leftShoulder: leftShoulder(current_data, t),
        rightElbow: rightElbow(current_data, t),
        leftElbow: leftElbow(current_data, t),
        rightWrist: rightWrist(current_data, t),
        leftWrist: leftWrist(current_data, t),
        rightHand: rightHand(current_data, t),
        leftHand: leftHand(current_data, t),
      },
    },
    {
      category: t("freqDynAction"),
      analysis: {
        dynamicRight: dynamicRight(current_data, t),
        dynamicLeft: dynamicLeft(current_data, t),
      },
    },
  ];
}

function rightShoulder(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.right_video?.version ||
    (current_data?.right_video?.version &&
      current_data?.right_video?.version?.district?.shoulder?.version == "v000")
  )
    return {
      label: t("shoulder") + " " + t("RI"),
      id_local: "rightShoulder",
      value: current_data?.right_video?.ocra?.shoulder?.right_wrong_time,
      unit: "s",
      side: "right_video",
      component: "shoulder",
      graph: [
        {
          label: t("rightShouldAngle"),
          type: 0,
          values: ["right_shoulder_angle"],
          valuesLabels: ["Angolo spalla destra"],
          errors: ["right_flexion_errors", "right_abduction_errors"],
          errorsLabels: ["Errore di flessione", "Errore di abduzione"],
          peaks: [],
          stdDuration: 0,
          thresholds: [
            current_data?.config?.shoulders_params?.angle_flexion,
            current_data?.config?.shoulders_params?.angle_abduction,
          ],
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("shoulderTorsoAngle") },
            { img: "GraphE1.png", label: t("flexion") },
            { img: "GraphTh1.png", label: t("more80") },
            { img: "GraphE2.png", label: t("abduction") },
            { img: "GraphTh2.png", label: t("more45") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_right_shoulder", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_right_hip", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.right_video?.version &&
    current_data?.right_video?.version?.district?.shoulder?.version == "v001"
  ) {
    return {
      label: t("shoulder") + " " + t("RI"),
      id_local: "rightShoulder",
      value: current_data?.right_video?.ocra?.shoulder_wrong_time,
      unit: "s",
      side: "right_video",
      component: "shoulder",
      graph: [
        {
          label: t("rightShouldAngle"),
          type: 0,
          values: ["angle"],
          valuesLabels: ["Angolo spalla destra"],
          errors: ["flexion_errors", "abduction_errors", "extension_errors"],
          errorsLabels: [
            "Errore di flessione",
            "Errore di abduzione",
            "Errore di estensione",
          ],
          peaks: [],
          stdDuration: 0,
          thresholds: ["angle_flexion", "angle_abduction", "angle_extension"],
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("shoulderTorsoAngle") },
            { img: "GraphE1.png", label: t("flexion") },
            { img: "GraphTh1.png", label: t("more80") },
            { img: "GraphE2.png", label: t("abduction") },
            { img: "GraphTh2.png", label: t("more45") },
            { img: "GraphE3.png", label: t("extension") },
            { img: "GraphTh3.png", label: t("more20") },
          ],
          visibility: [
            { joint: "visibility_right_shoulder", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_right_hip", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}

function leftShoulder(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.left_video?.version ||
    (current_data?.left_video?.version &&
      current_data?.left_video?.version?.district?.shoulder?.version == "v000")
  )
    return {
      label: t("shoulder") + " " + t("LE"),
      id_local: "leftShoulder",
      value: current_data?.left_video?.ocra?.shoulder?.left_wrong_time,
      unit: "s",
      side: "left_video",
      component: "shoulder",
      graph: [
        {
          label: t("leftShouldAngle"),
          type: 0,
          values: ["left_shoulder_angle"],
          valuesLabels: ["Angolo spalla destra"],
          errors: ["left_flexion_errors", "left_abduction_errors"],
          errorsLabels: ["Errore di flessione", "Errore di abduzione"],
          peaks: [],
          stdDuration: 0,
          thresholds: [
            current_data?.config?.shoulders_params?.angle_flexion,
            current_data?.config?.shoulders_params?.angle_abduction,
          ],
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("shoulderTorsoAngle") },
            { img: "GraphE1.png", label: t("flexion") },
            { img: "GraphTh1.png", label: t("more80") },
            { img: "GraphE2.png", label: t("abduction") },
            { img: "GraphTh2.png", label: t("more45") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_left_shoulder", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_left_hip", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.left_video?.version &&
    current_data?.left_video?.version?.district?.shoulder?.version == "v001"
  ) {
    return {
      label: t("shoulder") + " " + t("LE"),
      id_local: "leftShoulder",
      value: current_data?.left_video?.ocra?.shoulder_wrong_time,
      unit: "s",
      side: "left_video",
      component: "shoulder",
      graph: [
        {
          label: t("leftShouldAngle"),
          type: 0,
          values: ["angle"],
          valuesLabels: ["Angolo spalla sinistra"],
          errors: ["flexion_errors", "abduction_errors", "extension_errors"],
          errorsLabels: [
            "Errore di flessione",
            "Errore di abduzione",
            "Errore di estensione",
          ],
          peaks: [],
          stdDuration: 0,
          thresholds: ["angle_flexion", "angle_abduction", "angle_extension"],
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("shoulderTorsoAngle") },
            { img: "GraphE1.png", label: t("flexion") },
            { img: "GraphTh1.png", label: t("more80") },
            { img: "GraphE2.png", label: t("abduction") },
            { img: "GraphTh2.png", label: t("more45") },
            { img: "GraphE3.png", label: t("extension") },
            { img: "GraphTh3.png", label: t("more20") },
          ],
          visibility: [
            { joint: "visibility_left_shoulder", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_left_hip", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}

function rightElbow(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.right_video?.version ||
    (current_data?.right_video?.version &&
      current_data?.right_video?.version?.district?.elbow?.version == "v000")
  )
    return {
      label: t("elbow") + " " + t("RI"),
      id_local: "rightElbow",
      value: current_data?.right_video?.ocra?.elbow?.right_wrong_time,
      unit: "s",
      side: "right_video",
      component: "elbow",
      graph: [
        {
          label: t("elbowAngle"),
          type: 0,
          values: ["right_arm_forearm_angle"],
          valuesLabels: ["Angolo gomito sinistro"],
          errors: ["ascent_movements_right", "descent_movements_right"],
          errorsLabels: ["Errore di estensione", "Errore di flessione"],
          peaks: ["retained_peaks_right"],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("elbowAngleSh") },
            { img: "GraphE1.png", label: t("fastEst") },
            { img: "GraphE2.png", label: t("fastFlex") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
        {
          label: t("pronoDx"),
          type: 1,
          batch: 10,
          values: ["right_fingers_distance"],
          valuesLabels: ["Numero prono supinazioni"],
          errors: [
            "ascent_movements_right_prono",
            "descent_movements_right_prono",
          ],
          errorsLabels: ["Errore di flessione", "Errore di estensione"],
          peaks: ["retained_peaks_right_prono"],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("numberPronoSup") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.right_video?.version &&
    current_data?.right_video?.version?.district?.elbow?.version == "v001"
  ) {
    return {
      label: t("elbow") + " " + t("RI"),
      id_local: "rightElbow",
      value: current_data?.right_video?.ocra?.elbow_wrong_time,
      unit: "s",
      side: "right_video",
      component: "elbow",
      graph: [
        {
          label: t("elbowAngle"),
          type: 0,
          values: ["angle"],
          valuesLabels: ["Angolo gomito destro"],
          errors: ["extension_errors", "flexion_errors"],
          errorsLabels: ["Errore di estensione", "Errore di flessione"],
          peaks: [],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("elbowAngleSh") },
            { img: "GraphE1.png", label: t("fastEst") },
            { img: "GraphE2.png", label: t("fastFlex") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
        {
          label: t("pronoDx"),
          type: 2,
          batch: 10,
          unit: "",
          values: ["angle"],
          valuesLabels: ["Numero prono supinazioni"],
          errors: ["prono_supinations_per_batch"],
          errorsLabels: ["Prono supinazioni"],
          peaks: [],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("numberPronoSup") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_right_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}

function leftElbow(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.left_video?.version ||
    (current_data?.left_video?.version &&
      current_data?.left_video?.version?.district?.elbow?.version == "v000")
  )
    return {
      label: t("elbow") + " " + t("LE"),
      id_local: "leftElbow",
      value: current_data?.left_video?.ocra?.elbow?.left_wrong_time,
      unit: "s",
      side: "left_video",
      component: "elbow",
      graph: [
        {
          label: t("elbowAngle"),
          type: 0,
          values: ["left_arm_forearm_angle"],
          valuesLabels: ["Angolo gomito sinistro"],
          errors: ["ascent_movements_left", "descent_movements_left"],
          errorsLabels: ["Errore di estensione", "Errore di flessione"],
          peaks: ["retained_peaks_left"],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("elbowAngleSh") },
            { img: "GraphE1.png", label: t("fastEst") },
            { img: "GraphE2.png", label: t("fastFlex") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
        {
          label: t("pronoDx"),
          type: 1,
          batch: 10,
          values: ["left_fingers_distance"],
          valuesLabels: ["Numero prono supinazioni"],
          errors: [
            "ascent_movements_left_prono",
            "descent_movements_left_prono",
          ],
          errorsLabels: ["Errore di flessione", "Errore di estensione"],
          peaks: ["retained_peaks_left_prono"],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("numberPronoSup") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.left_video?.version &&
    current_data?.left_video?.version?.district?.elbow?.version == "v001"
  ) {
    return {
      label: t("elbow") + " " + t("LE"),
      id_local: "leftElbow",
      value: current_data?.left_video?.ocra?.elbow_wrong_time,
      unit: "s",
      side: "left_video",
      component: "elbow",
      graph: [
        {
          label: t("elbowAngle"),
          type: 0,
          values: ["angle"],
          valuesLabels: ["Angolo gomito destro"],
          errors: ["extension_errors", "flexion_errors"],
          errorsLabels: ["Errore di estensione", "Errore di flessione"],
          peaks: [],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 180,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("elbowAngleSh") },
            { img: "GraphE1.png", label: t("fastEst") },
            { img: "GraphE2.png", label: t("fastFlex") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
        {
          label: t("pronoDx"),
          type: 2,
          batch: 10,
          unit: "",
          values: ["angle"],
          valuesLabels: ["Numero prono supinazioni"],
          errors: ["prono_supinations_per_batch"],
          errorsLabels: ["Prono supinazioni"],
          peaks: [],
          thresholds: [],
          stdDuration: 0.5,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("numberPronoSup") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_left_elbow", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}

function rightWrist(current_data, t) {
  // CASO VERSIONE VECCHIA (Hands senza versione => polsi nei laterali)
  if (
    !current_data?.right_video?.version ||
    (current_data?.right_video?.version &&
      current_data?.right_video?.version?.district?.wrist?.version == "v000")
  )
    return {
      label: t("wrist") + " " + t("RI"),
      id_local: "rightWrist",
      value: current_data?.right_video?.ocra?.wrist?.right_wrong_time,
      unit: "s",
      side: "right_video",
      component: "wrist",
      graph: [
        {
          label: t("rightWristAngle"),
          type: 0,
          values: ["right_wrist_angle_index2D"],
          valuesLabels: ["Angolo polso destro"],
          errors: ["ascent_movements_right", "descent_movements_right"],
          errorsLabels: ["Errore di flessione", "Errore di estensione"],
          peaks: ["retained_peaks_right"],
          thresholds: [],
          stdDuration: 0.5,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("wristAngle") },
            { img: "GraphE1.png", label: t("fastFlex") },
            { img: "GraphE2.png", label: t("fastEst") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_right_wrist", thr: 0.8, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.hands?.version &&
    current_data?.hands?.version?.district?.hands?.version == "v001"
  ) {
    return {
      label: t("wrist") + " " + t("RI"),
      id_local: "rightWrist",
      value: current_data?.hands?.ocra_right?.wrist_wrong_time,
      unit: "s",
      side: "hands",
      debug: "debug_right",
      component: "wrist",
      graph: [
        {
          label: t("rightWristAngle"),
          type: 2,
          batch: 10,
          valuesFromFrames: true,
          values: [
            Array(current_data?.hands?.debug_right?.total_frames).fill(0),
          ],
          valuesLabels: ["Incongrue polso destro"],
          errors: ["batch_wrong_percentages"],
          errorsLabels: ["Posture incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          unit: "%",
          y_min: 0,
          y_max: 100,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("wristAngle") },
            { img: "GraphE1.png", label: t("fastFlex") },
            { img: "GraphE2.png", label: t("fastEst") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_right_wrist", thr: 0.8, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
          errorsIndex: ["batch_index_percentages"],
          orderIndex: ["estensione", "flessione", "dev_ulnare", "dev_radiale"],
          index2checklist: [
            "estensione",
            "flessione",
            "dev_ulnare",
            "dev_radiale",
          ],
          indexNoError: "neutra",
        },
      ],
    };
  }
}

function leftWrist(current_data, t) {
  // CASO VERSIONE VECCHIA (Hands senza versione => polsi nei laterali)
  if (
    !current_data?.left_video?.version ||
    (current_data?.left_video?.version &&
      current_data?.left_video?.version?.district?.wrist?.version == "v000")
  )
    return {
      label: t("wrist") + " " + t("LE"),
      id_local: "leftWrist",
      value: current_data?.right_video?.ocra?.wrist?.left_wrong_time,
      unit: "s",
      side: "left_video",
      component: "wrist",
      graph: [
        {
          label: t("leftWristAngle"),
          type: 0,
          values: ["left_wrist_angle_index2D"],
          valuesLabels: ["Angolo polso destro"],
          errors: ["ascent_movements_left", "descent_movements_left"],
          errorsLabels: ["Errore di flessione", "Errore di estensione"],
          peaks: ["retained_peaks_left"],
          thresholds: [],
          stdDuration: 0.5,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("wristAngle") },
            { img: "GraphE1.png", label: t("fastFlex") },
            { img: "GraphE2.png", label: t("fastEst") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_left_wrist", thr: 0.8, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.hands?.version &&
    current_data?.hands?.version?.district?.hands?.version == "v001"
  ) {
    return {
      label: t("wrist") + " " + t("LE"),
      id_local: "leftWrist",
      value: current_data?.hands?.ocra_left?.wrist_wrong_time,
      unit: "s",
      side: "hands",
      debug: "debug_left",
      component: "wrist",
      graph: [
        {
          label: t("leftWristAngle"),
          type: 2,
          batch: 10,
          valuesFromFrames: true,
          values: [
            Array(current_data?.hands?.debug_left?.total_frames).fill(0),
          ],
          valuesLabels: ["Incongrue polso destro"],
          errors: ["batch_wrong_percentages"],
          errorsLabels: ["Posture incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          unit: "%",
          y_min: 0,
          y_max: 100,
          y_label: t("angle") + " [°]",
          x_label: t("time") + " [s]",
          legend: [
            { img: "Graph.png", label: t("wristAngle") },
            { img: "GraphE1.png", label: t("fastFlex") },
            { img: "GraphE2.png", label: t("fastEst") },
            { img: "GraphAd.png", label: t("addedWrong") },
            { img: "GraphIg.png", label: t("ignoredWrong") },
          ],
          visibility: [
            { joint: "visibility_left_wrist", thr: 0.8, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
          errorsIndex: ["batch_index_percentages"],
          orderIndex: ["estensione", "flessione", "dev_ulnare", "dev_radiale"],
          index2checklist: [
            "estensione",
            "flessione",
            "dev_ulnare",
            "dev_radiale",
          ],
          indexNoError: "neutra",
        },
      ],
    };
  }
}

function rightHand(current_data, t) {
  // CASO VERSIONE VECCHIA (Hands senza versione => polsi nei laterali)
  if (!current_data?.hands?.version)
    return {
      label: t("hand") + " " + t("RI"),
      id_local: "rightHand",
      value:
        (current_data?.hands?.ocra?.grabs?.right_wrong_percentage *
          current_data?.hands?.debug?.grabs?.video_length) /
        100,
      unit: "s",
      side: "hands",
      component: "grabs",
      graph: [
        {
          label: t("wrongRightHold"),
          type: 2,
          batch: 10,
          unit: "%",
          values: ["signals"],
          valuesLabels: ["Percentuale prese incongrue"],
          errors: ["right_wrong_percentages"],
          errorsLabels: ["% prese incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 100,
          abs: true,
          y_label: t("percWrong2"),
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("percWrong") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
        },
      ],
    };
  else if (
    current_data?.hands?.version &&
    current_data?.hands?.version?.district?.hands?.version == "v001"
  ) {
    return {
      label: t("hand") + " " + t("RI"),
      id_local: "rightHand",
      value: current_data?.hands?.ocra_right?.grabs_wrong_time,
      unit: "s",
      side: "hands",
      component: "grabs",
      debug: "debug_right",
      graph: [
        {
          label: t("wrongRightHold"),
          type: 2,
          batch: 10,
          unit: "%",
          valuesFromFrames: true,
          values: [
            Array(current_data?.hands?.debug_right?.total_frames).fill(0),
          ],
          valuesLabels: ["Percentuale prese incongrue"],
          errors: ["batch_wrong_percentages"],
          errorsLabels: ["% prese incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 100,
          abs: true,
          y_label: t("percWrong2"),
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("percWrong") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          errorsIndex: ["batch_index_percentages"],
          orderIndex: ["grip_stretto", "grip_largo", "pinch", "altro"],
          index2checklist: ["pinch", "altro"],
          indexNoError: "nessuna_presa",
        },
      ],
    };
  }
}

function leftHand(current_data, t) {
  // CASO VERSIONE VECCHIA (Hands senza versione => polsi nei laterali)
  if (!current_data?.hands?.version)
    return {
      label: t("hand") + " " + t("LE"),
      id_local: "leftHand",
      value:
        (current_data?.hands?.ocra?.grabs?.left_wrong_percentage *
          current_data?.hands?.debug?.grabs?.video_length) /
        100,
      unit: "s",
      side: "hands",
      component: "grabs",
      graph: [
        {
          label: t("wrongLeftHold"),
          type: 2,
          batch: 10,
          unit: "%",
          values: ["signals"],
          valuesLabels: ["Percentuale prese incongrue"],
          errors: ["left_wrong_percentages"],
          errorsLabels: ["% prese incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 100,
          abs: true,
          y_label: t("percWrong2"),
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("percWrong") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
        },
      ],
    };
  else if (
    current_data?.hands?.version &&
    current_data?.hands?.version?.district?.hands?.version == "v001"
  ) {
    return {
      label: t("hand") + " " + t("LE"),
      id_local: "leftHand",
      value: current_data?.hands?.ocra_left?.grabs_wrong_time,
      unit: "s",
      side: "hands",
      component: "grabs",
      debug: "debug_left",
      graph: [
        {
          label: t("wrongLeftHold"),
          type: 2,
          batch: 10,
          unit: "%",
          valuesFromFrames: true,
          values: [
            Array(current_data?.hands?.debug_left?.total_frames).fill(0),
          ],
          valuesLabels: ["Percentuale prese incongrue"],
          errors: ["batch_wrong_percentages"],
          errorsLabels: ["% prese incongrue"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 100,
          abs: true,
          y_label: t("percWrong2"),
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("percWrong") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          errorsIndex: ["batch_index_percentages"],
          orderIndex: ["grip_stretto", "grip_largo", "pinch", "altro"],
          index2checklist: ["pinch", "altro"],
          indexNoError: "nessuna_presa",
        },
      ],
    };
  }
}

function dynamicRight(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.right_video?.version ||
    (current_data?.right_video?.version &&
      current_data?.right_video?.version?.district?.technical_actions
        ?.version == "v000")
  )
    return {
      label: t("azDyn") + " " + t("RI"),
      id_local: "dynamicRight",
      value:
        current_data?.right_video?.ocra?.technical_actions
          ?.right_dynamic_actions,
      unit: "",
      side: "right_video",
      component: "technical_actions",
      graph: [
        {
          label: t("dynRight"),
          type: 1,
          batch: 10,
          values: ["signals"],
          valuesLabels: ["N. azioni tecniche"],
          errors: ["right_instants_frames"],
          errorsLabels: ["N. azioni tecniche"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: "Numero di azioni",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("actNumber") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_right_wrist", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.right_video?.version &&
    current_data?.right_video?.version?.district?.technical_actions?.version ==
      "v001"
  ) {
    return {
      label: t("azDyn") + " " + t("RI"),
      id_local: "dynamicRight",
      value: current_data?.right_video?.ocra?.technical_actions,
      unit: "",
      side: "right_video",
      component: "technical_actions",
      graph: [
        {
          label: t("dynRight"),
          type: 2,
          unit: false,
          batch: 10,
          valuesFromFrames: true,
          values: [
            Array(current_data?.right_video?.debug?.total_frames).fill(0),
          ],
          valuesLabels: ["N. azioni tecniche"],
          errors: ["actions_per_batch"],
          errorsLabels: ["N. azioni tecniche"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: "Numero di azioni",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("actNumber") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_right_wrist", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}

function dynamicLeft(current_data, t) {
  // CASO VERSIONE VECCHIA
  if (
    !current_data?.left_video?.version ||
    (current_data?.left_video?.version &&
      current_data?.left_video?.version?.district?.technical_actions?.version ==
        "v000")
  )
    return {
      label: t("azDyn") + " " + t("LE"),
      id_local: "dynamicLeft",
      value:
        current_data?.left_video?.ocra?.technical_actions?.left_dynamic_actions,
      unit: "",
      side: "left_video",
      component: "technical_actions",
      graph: [
        {
          label: t("dynLeft"),
          type: 1,
          batch: 10,
          values: ["signals"],
          valuesLabels: ["N. azioni tecniche"],
          errors: ["left_instants_frames"],
          errorsLabels: ["N. azioni tecniche"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: "Numero di azioni",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("actNumber") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_left_wrist", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  else if (
    current_data?.left_video?.version &&
    current_data?.left_video?.version?.district?.technical_actions?.version ==
      "v001"
  ) {
    return {
      label: t("azDyn") + " " + t("LE"),
      id_local: "dynamicLeft",
      value: current_data?.left_video?.ocra?.technical_actions,
      unit: "",
      side: "left_video",
      component: "technical_actions",
      graph: [
        {
          label: t("dynLeft"),
          type: 2,
          unit: false,
          batch: 10,
          valuesFromFrames: true,
          values: [
            Array(current_data?.left_video?.debug?.total_frames).fill(0),
          ],
          valuesLabels: ["N. azioni tecniche"],
          errors: ["actions_per_batch"],
          errorsLabels: ["N. azioni tecniche"],
          peaks: [],
          thresholds: [],
          stdDuration: 1,
          y_min: 0,
          y_max: 20,
          abs: true,
          y_label: "Numero di azioni",
          x_label: t("time") + " [s]",
          legend: [
            { img: "GraphSq1.png", label: t("actNumber") },
            { img: "GraphSq2.png", label: t("editedNumber") },
          ],
          visibility: [
            { joint: "visibility_left_wrist", thr: 0.5, cutoff: 1 },
            { joint: "visibility_nose", thr: 0.5, cutoff: 1 },
          ],
        },
      ],
    };
  }
}
