import { useState } from "react";

export const Pill = ({
  label,
  toggle,
  background,
  backgroundToggle,
  color,
  colorToggle,
  onClick,
}) => {
  const [hover, setHover] = useState(false);

  function getColors() {
    if (toggle || hover) return [colorToggle, backgroundToggle];
    else return [color, background];
  }

  return (
    <div
      onClick={() => onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        display: "flex",
        width: "5.3vw",
        height: "1.4vw",
        background: getColors()[1],
        borderRadius: "0.7vw",
        alignItems: "center",
        justifyContent: "center",
        cursor: toggle ? "default" : hover ? "pointer" : "default",
      }}
    >
      <p
        style={{
          color: getColors()[0],
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.82vw",
          fontWeight: "400",
          boxSizing: "border-box",
          margin: "0",
        }}
      >
        {label}
      </p>
    </div>
  );
};
