import { getOcraColor } from "../../utils/Protocols/OCRA/OCRA";
import { colors } from "../../config/style";

export const ScoreIndicator = ({ score, side }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "6vw",
        height: "2vw",
        background: getOcraColor(score)[1],
        color: getOcraColor(score)[0],
        fontFamily: "Atkinson Hyperlegible",
        fontSize: "0.9vw",
        fontWeight: "700",
        borderRadius: "0.3vw",
      }}
    >
      {score > 0 ? (
        <>
          {side} {score}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export const ModularScoreIndicator = ({ scores, label, getColor }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "2.5vw",
        background: "white",
        border: `0.14vw solid ${colors.background}`,
        borderRadius: "0.31vw",
        padding: "0.43vw",
        boxSizing: "border-box",
        width: "8.4vw",
      }}
    >
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: "300",
          boxSizing: "border-box",
          margin: "0",
          width: "20%",
        }}
      >
        {label}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: scores.length > 1 ? "space-between" : "center",
          width: "80%",
        }}
      >
        {scores.map((score, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: colors.disabledText,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.7vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: "0",
                }}
              >
                {score.label}
              </p>
              <p
                style={{
                  color: getColor(score.score)[0],
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.95vw",
                  fontWeight: "700",
                  boxSizing: "border-box",
                  margin: "0",
                }}
              >
                {score.score >= 0
                  ? score.score >= 999
                    ? "CRI."
                    : score.score
                  : "--"}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
