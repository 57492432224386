const URL = process.env.REACT_APP_API_URL;

async function updateStationTasks(location,department,line,station,tasks, jwt = null) {
    try {
      const requestObject = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          //"User-Agent": "PostmanRuntime/7.29.2", // TODO: change
          Authorization: "Bearer " + jwt,
        }),
        body: JSON.stringify({tasks}),
      };

      let url = URL + "locations/" + location + "/departments/"+department+"/lines/"+line+"/stations/"+station+"/percentages/update"
  
      console.log("URL: ",url)

      const response = await fetch(
        url,
        requestObject
      );
  
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        // Se la risposta non è ok, gestisci l'errore
        throw new Error("Errore nella richiesta");
      }
    } catch (error) {
      // Gestisci l'errore qui
      console.error(error);
      throw error; // Puoi anche lanciare l'errore per gestirlo più avanti, se necessario
    }
  }

  export {updateStationTasks}

