import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { colors } from "../../config/style";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  // marginRight: "0.5vw",
  [`& .${treeItemClasses.content}`]: {
    paddingLeft: "0vw",
    paddingRight: "0.03vw",
    borderRadius: "0.5vw",
    height: "2vw",

    marginTop: "0.2vw",
    "&:hover": {
      backgroundColor: colors.hoverBackground + "!important", // Imposta il colore di sfondo per hover e active su rosso
    },
    "&:hover .plus-icon-container": {
      backgroundColor: colors.hoverBackground,
    },
    " &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: colors.mainOpaque, // Imposta il colore di sfondo per hover e active su rosso
      "&:hover": {
        backgroundColor: colors.mainOpaque + "!important", // No hover effect when selected
      },
    },
    " &.Mui-focused": {
      backgroundColor: "transparent", // Imposta il colore di sfondo per hover e active su rosso
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    boxSizing: "border-box",
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: "1.875vw",
    },
  },
  [`& .${treeItemClasses.group} .${treeItemClasses.group}`]: {
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: `calc(1.875vw + 1.875vw)`,
    },
  },
}));

export const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props,
  ref
) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    font,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    extraIcon, // Passa l'URL dell'immagine extra
    width,
    className,
    endIcon,
    ...other
  } = props;

  const styleProps = {
    "--tree-view-color": color,
    "--tree-view-bg-color": bgColor,
  };

  const handleClick = () => {
    console.log("cliccking father");
    if (props.onClick && typeof props.onClick === "function") {
      props.onClick();
    }
  };

  return (
    <StyledTreeItemRoot
      endIcon={
        endIcon &&
        (typeof endIcon === "string" ? (
          <img
            src={endIcon}
            style={{
              width: "1.875vw",
              height: "1.875vw",
              marginRight: "0",
            }}
            alt=""
          />
        ) : (
          <div className="flex items-center justify-center w-[1.875vw] h-[1.875vw] mr-0">
            {endIcon}
          </div>
        ))
      }
      label={
        <div
          className={`flex items-center relative ${
            width ? width : "w-auto"
          } ${className}`}
        >
          {LabelIcon &&
            (typeof LabelIcon === "string" ? (
              <img
                src={LabelIcon}
                style={{
                  width: "1.875vw",
                  height: "1.875vw",
                  marginLeft: "0",
                }}
                alt=""
              />
            ) : (
              <div className="flex items-center justify-center w-[1.875vw] h-[1.875vw] ml-0">
                {LabelIcon}
              </div>
            ))}
          <Typography
            className="h-[1.875vw] flex items-center !ml-[0.3vw] pr-[0.2vw]"
            variant="body2"
            sx={{ ...font }}
          >
            {labelText}
          </Typography>
          {extraIcon && (
            <div className="ml-auto absolute right-[0.2vw] h-full flex items-center justify-center">
              {extraIcon}
            </div>
          )}
        </div>
      }
      style={styleProps}
      onClick={handleClick}
      {...other}
      ref={ref}
    />
  );
});
