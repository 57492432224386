import React from "react";

const ToolTipIcon = ({
  fill = "none",
  size,
  hover = true,
  disabled = false,
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full ${
        hover && !disabled ? "group hover:bg-[#BEBCFB]" : ""
      } cursor-pointer`}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 36 36"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30Z"
          className={`${
            disabled
              ? "stroke-[rgba(161,159,204,1)]"
              : hover
              ? "group-hover:stroke-[rgba(109,105,242,1)] stroke-black"
              : "stroke-black"
          }`}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.959 17.4097H17.9715V23.9909H18.984"
          className={`${
            disabled
              ? "stroke-[rgba(161,159,204,1)]"
              : hover
              ? "group-hover:stroke-[rgba(109,105,242,1)] stroke-black"
              : "stroke-black"
          }`}
          strokeWidth="2.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.719 14.1699C18.7255 14.1699 19.5415 13.3539 19.5415 12.3474C19.5415 11.3409 18.7255 10.5249 17.719 10.5249C16.7124 10.5249 15.8965 11.3409 15.8965 12.3474C15.8965 13.3539 16.7124 14.1699 17.719 14.1699Z"
          className={`${
            disabled
              ? "fill-[rgba(161,159,204,1)]"
              : hover
              ? "group-hover:fill-[rgba(109,105,242,1)] fill-black"
              : "fill-black"
          }`}
        />
      </svg>
    </div>
  );
};

export default ToolTipIcon;
